import { SystemModeType } from "constants/enum";
import { NormalDisplayItem } from ".";
import { getStatusColor, onLabelClick } from "./utils";
import { FONT_SIZE_DISPLAY_ORDER } from "utils/forge";

export const documentLabel = (
  item: NormalDisplayItem,
  options?: { isSelected?: boolean }
) => {
  let documentStatusColor = getStatusColor(
    item.status,
    SystemModeType.Document
  );
  let fontWeight = "500";
  let labelName = item.title ?? `${Number(item.displayOrder) + 1}`;
  const displayOrderLength = labelName.length;
  let fontSizeLabel =
    FONT_SIZE_DISPLAY_ORDER[
      displayOrderLength as keyof typeof FONT_SIZE_DISPLAY_ORDER
    ];

  if (item.isAddPin) {
    labelName = "+";
    fontSizeLabel = "24px";
    fontWeight = "bold";
    documentStatusColor = "#E6E6E6";
  }

  const $label = $(`
    <label class="document-label markup update ${
      options?.isSelected ? "selected-label" : ""
    }" data-id="${item.id}">
        <div class="marker-pin">
          <div class='pin-on-map' style="--bg-color: ${documentStatusColor}">
            <div class='inner-pin-on-map'>
              <span class="marker-label  ${
                item.unVisible && "invisible"
              }" style="font-weight: ${fontWeight}; font-size: ${fontSizeLabel};color: white;}">${labelName}</span>
            </div>
          </div>
        </div>
    </label>`);

  $label.on("click", (e) => {
    onLabelClick(item, SystemModeType.Document);
  });

  return $label;
};
