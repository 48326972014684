import { Icon, IconProps } from "@chakra-ui/icons";

const CloseIcon = (props: IconProps) => {
  return (
    <Icon
      width="1em"
      height="1em"
      fill="currentcolor"
      viewBox="0 0 14 14"
      {...props}
    >
      <path
        id="_Color"
        data-name=" ↳Color"
        d="M14,1.41,12.59,0,7,5.59,1.41,0,0,1.41,5.59,7,0,12.59,1.41,14,7,8.41,12.59,14,14,12.59,8.41,7Z"
      />
    </Icon>
  );
};

export default CloseIcon;
