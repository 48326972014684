import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  bimFileApi,
  forgeApi,
  partnerCompanyApi,
  projectApi,
} from "apiClient/v2";
import { TYPE_USER } from "constants/app";
import { REDUCER_KEY } from "constants/redux";
import { DataProjectModel } from "interfaces/models/dataProjectModel";
import { PartnerCompany } from "interfaces/models/partnerCompany";
import { ForgeProject, ProjectDetail } from "interfaces/models/project";
import { arrayToObject } from "utils/object";

export interface ProjectState {
  projects: ProjectDetail[];
  dataProjects: DataProjectModel[];
  projectDetail: ProjectDetail;
  dataProjectDetail?: DataProjectModel;
  isLoadedProjects: boolean;
  isLoadedDataProjects: boolean;
  cachingBimFileId: string | undefined;
  lastCheckCacheProject: number;
  partnerCompanies: PartnerCompany[];
  isDeleteBimFile: boolean;
  forgeProjects: ForgeProject[];
  isFetchingDataProjectDetail: boolean;
  isFetchingPartnerCompanies: boolean;
  isFetchedPartnerCompanies: boolean;
  isRevertProject?: boolean;
  isDeletingProject?: boolean;
}

const initialState: ProjectState = {
  projects: [],
  dataProjects: [],
  projectDetail: {} as ProjectDetail,
  dataProjectDetail: {} as DataProjectModel,
  isLoadedProjects: false,
  isLoadedDataProjects: false,
  cachingBimFileId: undefined,
  lastCheckCacheProject: Date.now(),
  partnerCompanies: [],
  isDeleteBimFile: false,
  forgeProjects: [],
  isFetchingDataProjectDetail: false,
  isFetchingPartnerCompanies: false,
  isFetchedPartnerCompanies: false,
  isRevertProject: false,
  isDeletingProject: false,
};

export const fetchProjects = createAsyncThunk(
  "project/fetchProjectList",
  async () => {
    const { data } = await projectApi.getProjectList();

    return data;
  }
);

export const fetchForceProjects = createAsyncThunk(
  "project/fetchForceProjects",
  async (forceGet?: boolean) => {
    const { data } = await forgeApi.getProjects(forceGet);

    return data;
  }
);

export const fetchDataProjects = createAsyncThunk(
  "project/fetchProjectDataList",
  async (params?: {
    forceGet?: boolean;
    isCheckDataProjectExists?: boolean;
  }) => {
    const { forceGet = false, isCheckDataProjectExists = true } = params || {};
    const currentDataProjects: DataProjectModel[] =
      (await bimFileApi.getProjectList())?.data || [];
    // omit field levelData for light payload
    let dataProjects: DataProjectModel[] = currentDataProjects.map(
      ({ levelData, ...dataProject }) => dataProject
    );

    if (isCheckDataProjectExists) {
      dataProjects = [];
      const forgeProjects = (await forgeApi.getProjects(forceGet))?.data || [];
      const mapProject = arrayToObject(forgeProjects, "id");
      const mapProjectKeys = Object.keys(mapProject);

      currentDataProjects.forEach(({ levelData, ...dataProject }) => {
        if (mapProjectKeys.includes(dataProject.projectId)) {
          dataProjects.push(dataProject);
        }
      });

      return { forgeProjects, dataProjects };
    }

    return { forgeProjects: [], dataProjects };
  }
);

export const fetchProjectDetail = createAsyncThunk(
  "project/fetchProjectDetail",
  async ({ projectId }: { projectId: string }) => {
    const { data } = await projectApi.getProject(projectId);

    return data;
  }
);

export const fetchDataProjectDetail = createAsyncThunk<
  DataProjectModel | undefined,
  string,
  {
    rejectValue: undefined;
  }
>(
  "project/fetchDataProjectDetail",
  async (bimFileId: string, { rejectWithValue }) => {
    try {
      const response = await bimFileApi.getProject(bimFileId);

      return response.data;
    } catch (err) {
      rejectWithValue(undefined);
    }
  }
);

export const fetchPartnerCompanies = createAsyncThunk(
  "project/fetchPartnerCompanies",
  async (bimFileId: string) => {
    return await partnerCompanyApi.getPartnerList({ bimFileId });
  }
);

export const createParentProject = createAsyncThunk(
  "project/createUpdateProject",
  async (payload: ProjectDetail) => {
    const { data: response } = await projectApi.createProject(payload);

    return { ...response, ...payload };
  }
);

export const createUpdateProjectApi = createAsyncThunk(
  "project/createUpdateProjectBimFile",
  async (payload: DataProjectModel & { userRole: string }) => {
    if (payload.userRole !== TYPE_USER.ROLE_ADMIN) return {} as any;
    const isCreate = payload.isCreate;
    delete payload.isCreate;
    const { data: response } = isCreate
      ? await bimFileApi.createProject(payload)
      : await bimFileApi.updateProject(payload);

    return response;
  }
);

export const deleteBimFile = createAsyncThunk(
  "projectBimFile/delete",
  async (id: string) => {
    await bimFileApi.deleteBimFileList([id]);

    return id;
  }
);

export const deleteProject = createAsyncThunk(
  "project/delete",
  async (id: string) => {
    return await projectApi.deleteProject(id);
  }
);

export const projectSlice = createSlice({
  name: REDUCER_KEY.PROJECT,
  initialState,
  reducers: {
    setProjects: (state, action: PayloadAction<ProjectDetail[]>) => {
      state.projects = Array.isArray(action.payload) ? action.payload : [];
    },
    setProjectDetail: (state, action: PayloadAction<ProjectDetail>) => {
      state.projectDetail = action.payload;
    },
    setDataProjectDetail: (
      state,
      action: PayloadAction<DataProjectModel | undefined>
    ) => {
      state.dataProjectDetail = action.payload;
    },
    resetLoadDataProjectDetail: (state) => {
      state.dataProjectDetail = {} as DataProjectModel;
      state.isFetchingDataProjectDetail = false;
    },
    resetLoadProjectDetail: (state) => {
      state.projectDetail = {} as ProjectDetail;
    },
    setDataProjects: (state, action: PayloadAction<DataProjectModel[]>) => {
      state.dataProjects = Array.isArray(action.payload) ? action.payload : [];
    },
    setCachingProject: (state, action: PayloadAction<string | undefined>) => {
      state.cachingBimFileId = action.payload;
    },
    checkCacheProject: (state) => {
      state.lastCheckCacheProject = Date.now();
    },
    resetFetchedPartnerCompanies: (state) => {
      state.isFetchedPartnerCompanies = false;
    },
    resetLoadedProjects: (state) => {
      state.isLoadedProjects = false;
      state.isLoadedDataProjects = false;
    },
    setIsRevertProject: (state, action: PayloadAction<boolean>) => {
      state.isRevertProject = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjects.fulfilled, (state, { payload }) => {
      state.projects = Array.isArray(payload) ? payload : [];
      state.isLoadedProjects = true;
    });
    builder.addCase(fetchProjects.pending, (state) => {
      state.isLoadedProjects = false;
    });
    builder.addCase(fetchDataProjects.pending, (state) => {
      state.isLoadedDataProjects = false;
    });
    builder.addCase(fetchDataProjects.fulfilled, (state, action) => {
      const { forgeProjects, dataProjects } = action.payload;
      state.dataProjects = Array.isArray(dataProjects) ? dataProjects : [];
      state.forgeProjects = forgeProjects;
      state.isLoadedDataProjects = true;
    });
    builder.addCase(fetchProjectDetail.fulfilled, (state, action) => {
      state.projectDetail = action.payload;
    });
    builder.addCase(fetchDataProjectDetail.pending, (state) => {
      state.dataProjectDetail = undefined;
      state.isFetchingDataProjectDetail = true;
    });
    builder.addCase(fetchDataProjectDetail.fulfilled, (state, action) => {
      state.dataProjectDetail = action.payload;
      state.isFetchingDataProjectDetail = false;
    });
    builder.addCase(fetchPartnerCompanies.pending, (state) => {
      state.isFetchingPartnerCompanies = true;
    });
    builder.addCase(fetchPartnerCompanies.fulfilled, (state, action) => {
      state.partnerCompanies = action.payload.data || [];
      state.isFetchingPartnerCompanies = false;
      state.isFetchedPartnerCompanies = true;
    });
    builder.addCase(createUpdateProjectApi.fulfilled, (state, action) => {
      if (!action.payload?.id) return;
      state.dataProjectDetail = action.payload as any;
      const newDataProjects = [...state.dataProjects].map((dataProject) => {
        return dataProject.id === action.payload.id
          ? action.payload
          : dataProject;
      });
      state.dataProjects = newDataProjects;
    });
    builder.addCase(deleteBimFile.pending, (state) => {
      state.isDeleteBimFile = true;
    });
    builder.addCase(deleteBimFile.fulfilled, (state, action) => {
      state.isDeleteBimFile = false;
      state.dataProjects = state.dataProjects.filter(
        (node) => node.id !== action.payload
      );
    });
    builder.addCase(fetchForceProjects.fulfilled, (state, action) => {
      state.forgeProjects = action.payload;
    });
    builder.addCase(createParentProject.fulfilled, (state, action) => {
      if (action.payload.isRevert) {
        state.isRevertProject = true;
      }
      state.projects.push(action.payload);
    });
    builder.addCase(deleteProject.pending, (state) => {
      state.isDeletingProject = true;
    });
    builder.addCase(deleteProject.fulfilled, (state, action) => {
      state.isDeletingProject = false;

      const dataResponse = action.payload.data;
      if (dataResponse) {
        const deleteId = dataResponse[0];
        state.dataProjects = state.dataProjects.filter(
          (e) => e.id !== deleteId
        );
        state.projects = state.projects.filter((e) => e.id !== deleteId);
      }
    });
  },
});

export const {
  setProjects,
  setDataProjects,
  setProjectDetail,
  setDataProjectDetail,
  setCachingProject,
  checkCacheProject,
  resetFetchedPartnerCompanies,
  resetLoadedProjects,
  resetLoadDataProjectDetail,
  resetLoadProjectDetail,
  setIsRevertProject,
} = projectSlice.actions;

export default projectSlice.reducer;
