import { BoxProps, Flex, Image, Spinner } from "@chakra-ui/react";
import React, { forwardRef, useEffect, useMemo, useState } from "react";
import { getPreSignUrl } from "utils/file";

interface Props extends BoxProps {
  file?: string;
}

const DocImage = forwardRef(({ file, children, ...rest }: Props, ref) => {
  const [fileData, setFileData] = useState<string>(file || "");
  const [isLoadingImage, setIsLoadingImage] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      if (file && !file.includes("X-Amz-SignedHeaders=host")) {
        const presignedUrl = await getPreSignUrl("", file);
        setFileData(presignedUrl);
      }
      setIsLoadingImage(false);
    })();
  }, [file]);

  const loading = useMemo(() => {
    return (
      <Flex
        position="absolute"
        zIndex="100"
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Spinner />
      </Flex>
    );
  }, []);

  if (isLoadingImage) return loading;

  return (
    <>
      <Image {...rest} src={fileData} crossOrigin="anonymous" />
      {children}
    </>
  );
});

export default DocImage;
