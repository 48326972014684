export const S3_PATH = {
  Photo: "photo",
  Audio: "audio",
  Document: "document",
  KeyNoteData: "keynote-data",
  DocumentLog: "document-log",
  Avatar: "avatar",
  Task: "task",
  TaskSheet: "task-sheet",
  DocumentPageLayout: "document-page-layout",
  DocumentPdf: "document-pdf",
};

export const FORGE_DATA_FOLDER_PATH = "forge-data";
export const FORGE_DATA_INSTANCE = "f-instance";
export const FORGE_DATA_VIEW = "f-view";

export const S3_URL = `${process.env.REACT_APP_S3_URL}`;
