import { s3Api } from "apiClient/v2";
import { PROP_TYPE } from "constants/enum";
import { FORGE_DATA_FOLDER_PATH, FORGE_DATA_INSTANCE } from "constants/s3";
import { getCurrentViewer } from "utils/forge";
import { getInstanceTableName } from "utils/forge/s3Data";
import { getAllItems, getTreeNode } from "utils/forge/viewerData";

import { logError } from "utils/logs";
import { uploadMultipartToS3 } from "utils/upload-multipart";

export enum CommonPropertyName {
  TYPE_NAME = "TypeName",
  FAMILY_NAME = "FamilyName",
}

export const PREFIX_COMMON = "c_";
export const PREFIX_OTHER = "o_";

export enum TYPE_NODE {
  Root = 0,
  Category = 1,
  Family = 2,
  Type = 3,
  Instance = 4,
  NestedInstance = 5,
}

/*
  common level: Model => Category => Family => Type => Instance => Nested instance
 */
export const LEVEL_INSTANCE = 4;

export type InfoForGenerateAllInstance = {
  rootNode: any;
  mapDbId: Map<number, Autodesk.Viewing.PropertyResult>;
};

export const IS_FORMAT_VALUE_WITH_UNIT_FOR_BIM_PROPERTIES = true;

export default class GenAllFamilyInstance {
  private _bimFileId;
  private _version;
  constructor(bimFileId: string, version: string) {
    this._bimFileId = bimFileId;
    this._version = version;
  }

  async getInfoForGenAllInstance() {
    try {
      const model = getCurrentViewer()?.model!; // current viewer = NOP_VIEWER
      if (!model) {
        return;
      }
      const rootNode = getTreeNode();
      const instancesProperties = await getAllItems();
      const mapDbId = new Map(); // using map instead object when write large object
      instancesProperties.forEach((item: any) => {
        mapDbId.set(item.dbId, item);
      });

      return { rootNode, mapDbId };
    } catch (e) {
      return;
    }
  }

  async checkGenerated() {
    const subPath = getInstanceTableName({
      bimFileId: this._bimFileId,
      version: this._version,
    });
    const filePathInstance = `${FORGE_DATA_INSTANCE}/${subPath}`;
    const fileNameInstance = "instance.json.gz";
    const encodeBimFile = encodeURIComponent(this._bimFileId);
    const fileNameCategory = `f-categories-props-${encodeBimFile}-v${this._version}.json`;
    // const result = await s3Api.checkS3FileExist({ fileName, filePath });
    const fileChecks = [
      {
        fileName: fileNameInstance,
        filePath: filePathInstance,
      },
      {
        fileName: fileNameCategory,
        filePath: FORGE_DATA_FOLDER_PATH,
      },
    ];
    const results = await Promise.all(
      fileChecks.map((file) => {
        return s3Api.getObjectMetadata(file);
      })
    );

    return results.every((result) => !!result.data.isExists);
  }

  async generateMapCategory(
    dataInfo: InfoForGenerateAllInstance,
    fileName: string,
    filePath: string,
    excludes: (string | RegExp)[]
  ) {
    const mapCategories = await this.getMappingCategory(dataInfo, excludes);
    if (!mapCategories) {
      return false;
    }
    await uploadMultipartToS3({
      filePath,
      fileName,
      fileData: mapCategories,
    });
  }

  generateAllFamilyInstance = async (
    dataInfo: InfoForGenerateAllInstance,
    fileName: string,
    filePath: string
  ) => {
    const instanceString = await this.getAllInstanceData(dataInfo);

    if (!instanceString) {
      return false;
    }

    //@ts-ignore
    await uploadMultipartToS3({
      filePath,
      fileName,
      fileData: instanceString,
    });
  };

  getMappingCategory = (
    info: InfoForGenerateAllInstance,
    excludes?: (string | RegExp)[]
  ) => {
    try {
      const { rootNode, mapDbId } = info;
      // get model instance tree and root component
      const mapCategories: any = {};

      // end get tree object
      const objectTraversal = (
        node: any,
        categoryName?: string,
        familyName?: string,
        typeName?: string,
        level = 0
      ) => {
        if (
          categoryName &&
          excludes?.find((exclude) =>
            typeof exclude === "string"
              ? exclude === categoryName
              : exclude.test(categoryName.toUpperCase())
          )
        )
          return;
        const nodeInfo = mapDbId.get(node.dbId);
        // remove levels from list categories with logic that considers items containing elevation property as levels
        if (categoryName && !familyName) {
          const isLevelNode = nodeInfo?.properties.some(
            (p) => p.attributeName === "Elevation"
          );
          if (isLevelNode) {
            return;
          }
        }
        // only get category for for level has actual family instance
        if (categoryName && nodeInfo && level <= LEVEL_INSTANCE) {
          if (!mapCategories[categoryName]) {
            mapCategories[categoryName] = {};
          }
          const commonProps = [];
          if (familyName) {
            // when instance has nested instance => using name of nested instance as family name
            commonProps.push({
              displayValue:
                level > LEVEL_INSTANCE ? nodeInfo?.name : familyName, // display value of property
              attributeName: CommonPropertyName.FAMILY_NAME, // attribute name of property
              displayName: "ファミリ名",
              type: PROP_TYPE.STRING,
            });
          }
          if (typeName) {
            commonProps.push({
              displayValue: typeName, // display value of property
              attributeName: CommonPropertyName.TYPE_NAME, // attribute name of property
              displayName: "タイプ名",
              type: PROP_TYPE.STRING,
            });
          }
          commonProps.forEach((itm: any) => {
            this.mappingAttribute(mapCategories, categoryName, itm);
          });
          if (!node.children?.length) {
            // add other field
            nodeInfo?.properties.forEach((itm: any) => {
              this.mappingAttribute(
                mapCategories,
                categoryName,
                itm,
                PREFIX_OTHER
              );
            });
          }
        }
        // recursive all child of object
        (node.children || []).forEach((child: any) => {
          //@ts-ignore
          const childName = mapDbId.get(child.dbId)?.name;
          objectTraversal(
            child,
            categoryName ?? childName,
            categoryName && !familyName ? childName : familyName,
            categoryName && familyName && !typeName ? childName : typeName,
            level + 1
          );
        });
      };

      console.time("time get map category data");
      objectTraversal(rootNode);
      console.timeEnd("time get map category data");
      // change type of value from set to array
      for (const key in mapCategories) {
        for (const subKey in mapCategories[key]) {
          mapCategories[key][subKey].value = Array.from(
            mapCategories[key][subKey].value
          );
        }
      }

      return mapCategories;
    } catch (err) {
      logError(err);

      return undefined;
    }
  };

  async getAllInstanceData(info: InfoForGenerateAllInstance) {
    try {
      const { rootNode, mapDbId } = info;
      let instancesString = "";

      const getNodeType = (
        node: any,
        categoryName?: string,
        familyName?: string,
        typeName?: string,
        level = 0
      ) => {
        if (level > LEVEL_INSTANCE) {
          return TYPE_NODE.NestedInstance;
        }
        if (
          level === LEVEL_INSTANCE ||
          (level < LEVEL_INSTANCE && !node?.children?.length)
        ) {
          return TYPE_NODE.Instance;
        }
        if (typeName && familyName && categoryName) {
          return TYPE_NODE.Type;
        }
        if (!typeName && familyName && categoryName) {
          return TYPE_NODE.Family;
        }
        if (!typeName && !familyName && categoryName) {
          return TYPE_NODE.Category;
        }
      };
      // end get tree object
      const objectTraversal = (
        node: any,
        categoryName?: string,
        familyName?: string,
        typeName?: string,
        level = 0
      ) => {
        const nodeInfo = mapDbId.get(node.dbId);
        if (categoryName && nodeInfo) {
          // const nodeType =

          const instance: any = {
            eid: nodeInfo.externalId, // externalId
            cn: categoryName, // category name
            cp: [], // common properties
            n: nodeInfo.name, // family instance name
            p: nodeInfo.properties, // object properties
            // type of instance
            t: getNodeType(node, categoryName, familyName, typeName, level),
          };

          if (familyName) {
            // when instance has nested instance => using name of nested instance as family name
            instance.cp.push({
              dv: level > LEVEL_INSTANCE ? nodeInfo?.name : familyName, // display value of property
              atn: "FamilyName", // attribute name of property
            });
          }
          if (typeName) {
            instance.cp.push({
              dv: typeName, // display value of property
              atn: "TypeName", // attribute name of property
            });
          }

          // only add node at level level instance
          instance.p.forEach((itm: any, index: number) => {
            let val = itm.displayValue;
            if (val === null || val === undefined) {
              val = "";
            }
            // type - For example: 1=boolean, 2=integer, 3=double, 20=string, 24=Position
            // https://developer.api.autodesk.com/derivativeservice/v2/viewers/viewer3D.js?v=2.16
            // search with text 'formatValueWithUnits'
            if (IS_FORMAT_VALUE_WITH_UNIT_FOR_BIM_PROPERTIES) {
              val = Autodesk.Viewing.Private.formatValueWithUnits(
                val,
                itm.units,
                itm.type,
                itm.precision
              );
            }

            instance.p[index] = {
              dv: String(val), // display value with units of property
              atn: itm.attributeName, // attribute name of property
            };
          });
          instancesString += `${JSON.stringify(instance)}\n`;
        }

        // recursive all child of object
        (node.children || []).forEach((child: any) => {
          //@ts-ignore
          const childName = mapDbId.get(child.dbId)?.name;
          objectTraversal(
            child,
            categoryName ?? childName,
            categoryName && !familyName ? childName : familyName,
            categoryName && familyName && !typeName ? childName : typeName,
            level + 1
          );
        });
      };

      objectTraversal(rootNode);

      //@ts-ignore
      return instancesString;
    } catch (err) {
      logError(err);

      return undefined;
    }
  }

  mappingAttribute(
    mapCategories: any,
    cateName: string,
    attr: any,
    prefix = PREFIX_COMMON
  ) {
    const keyMapping = `${prefix}${attr.attributeName}`;
    if (!mapCategories[cateName][keyMapping]) {
      mapCategories[cateName][keyMapping] = {
        type: attr.type,
        displayName: attr.displayName,
        value: new Set(),
      };
    }
    // assign value to map
    if (attr.displayValue === null || attr.displayValue === undefined) {
      attr.displayValue = "";
    }
    if (attr.displayValue !== "") {
      mapCategories[cateName][keyMapping].value.add(attr.displayValue);
    }
  }
}
