import { Attribute, Node } from "@tiptap/core";
import { inlineStyleFromComponent } from "components/containers/InspectionFormat/Content/SettingFontSize/utils/style";
import { Plugin, PluginKey, TextSelection } from "@tiptap/pm/state";
import {
  DEFAULT_PIN_COMPONENT_STYLE,
  PinComponentLinkedData,
  PinComponentStyle,
} from "interfaces/models/pinComponent";
import { NodeType } from "../type";

export interface PinComponentNodeAttrs {
  style: PinComponentStyle;
  offsetTop: number;
  offsetLeft: number;
  top: number;
  left: number;
  width: number;
  height: number;
  linkedData: PinComponentLinkedData;
  pinId: string | undefined;
  componentId: string;
  isUpdated?: boolean;
  updatedAt?: Date;
}

const SelectPinComponentKey = new PluginKey<number>("selectPinComponent");

export const PinComponentNode = Node.create({
  name: NodeType.PIN_COMPONENT,
  group: "block",

  // should put node type NORMAL_TEXT first
  // because when all text's deleted, the default node type will be NORMAL_TEXT
  content: `(${NodeType.NORMAL_TEXT} | ${NodeType.IMAGE} | ${NodeType.TASK_LIST})*`,
  selectable: false,
  allowGapCursor: false,

  addAttributes() {
    const attrs: {
      [key in keyof PinComponentNodeAttrs]: Attribute;
    } = {
      style: { default: DEFAULT_PIN_COMPONENT_STYLE },
      offsetTop: { default: 0 },
      offsetLeft: { default: 0 },
      top: { default: 0 },
      left: { default: 0 },
      width: { default: 0 },
      height: { default: 0 },
      linkedData: { default: {} },
      pinId: { default: undefined },
      componentId: { default: undefined },
      isUpdated: { default: false },
      updatedAt: { default: undefined },
    };

    return attrs;
  },
  parseHTML() {
    return [
      {
        tag: "div",
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    const {
      offsetTop,
      offsetLeft,
      top,
      left,
      width,
      height,
      style: _style,
    } = HTMLAttributes as PinComponentNodeAttrs;
    const otherStyle = inlineStyleFromComponent(_style);
    const style =
      "position: absolute; color: black; pointer-events: auto; " +
      `top: calc(${top}% + ${offsetTop}%); ` +
      `left: calc(${left}% + ${offsetLeft}%); ` +
      `width: ${width}%; ` +
      `height: ${height}%; ${otherStyle}`;

    return ["div", { style }, 0];
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: SelectPinComponentKey,
        appendTransaction(_, __, state) {
          const sel = state.selection;
          const end = sel.$from.end();
          const isDiffParent = !sel.$anchor.sameParent(sel.$head);

          if (isDiffParent) {
            return state.tr.setSelection(
              TextSelection.create(state.doc, sel.anchor, end)
            );
          }

          return state.tr;
        },
      }),
    ];
  },
});
