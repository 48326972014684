import { TaskDTO } from "interfaces/dtos/taskDTO";
import { FileUploadInfo } from "interfaces/models";
import { ApiResponse } from "interfaces/models/api";
import { TaskComment } from "interfaces/models/task";
import { axiosECS } from "services/baseAxios";
import { validateBodyReq } from "utils/common";
import { presignedAndDownloadFileS3 } from "utils/file";

interface GetTaskCommentsReq {
  taskId: string;
  cursor?: string;
  limit?: number;
}
const pathV1 = "/v1/task-comments";

const FIELD_LOGS_NEED_CACHE: (keyof TaskDTO)[] = [
  "images",
  "confirmedImages",
  "attaches",
];

const getCommentList = async (
  params: GetTaskCommentsReq,
  shouldCache = false
): Promise<ApiResponse<TaskComment[]>> => {
  return axiosECS.get(pathV1, {
    params: { ...params, limit: params.limit || 20, shouldCache },
  });
};

export const createComment = async (
  comment: Partial<TaskComment>
): Promise<ApiResponse<TaskComment>> => {
  return axiosECS.post(pathV1, validateBodyReq(comment));
};

export const updateComment = async (
  comment: TaskComment
): Promise<ApiResponse<TaskComment>> => {
  return axiosECS.patch(pathV1, validateBodyReq(comment));
};

export const deleteCommentList = async (
  ids: string[]
): Promise<ApiResponse<string[]>> => {
  return axiosECS.delete(pathV1, { data: { ids } });
};

export const handleGetAllTaskComments = async (
  body: GetTaskCommentsReq,
  shouldCache = false
) => {
  let token: any = "";
  do {
    const result = await handleGetTaskComments(
      {
        ...body,
        cursor: token || "",
      },
      shouldCache
    );

    token = result?.pagination?.cursor ?? null;
  } while (token);
};

const handleCacheTaskComments = async (comments: TaskComment[]) => {
  const urls: string[] = [];
  comments.forEach((comment) => {
    const field = comment.field as keyof TaskDTO;
    const data = comment?.value?.data;
    const isArray = Array(data);
    if (!(FIELD_LOGS_NEED_CACHE.includes(field) && isArray)) {
      return;
    }
    if (field === "attaches") {
      urls.push(...data);
    }
    urls.push(...((data || []) as FileUploadInfo[]).map((image) => image.src));
  });

  await Promise.all(urls.map((url) => presignedAndDownloadFileS3(url, true)));
};

export const handleGetTaskComments = async (
  body: GetTaskCommentsReq,
  shouldCache = false
) => {
  if (!body.taskId) return;
  const res = await getCommentList(body, shouldCache);

  if (shouldCache) {
    await handleCacheTaskComments(res.data || []);
  }

  return res;
};
