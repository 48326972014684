import { BoxProps, Flex, Box, Spinner } from "@chakra-ui/react";
import { memo } from "react";
import Loading from "./Loading";

interface Props {
  title?: string;
  children?: React.ReactNode;
  isLoading?: boolean;
}

const FieldCheckboxPreviewWrapper = (props: Props & BoxProps) => {
  const { title, children, isLoading, ...rest } = props;

  return (
    <Flex flexDirection="column" gap="0.5rem" {...rest}>
      <Flex
        fontWeight="bold"
        fontSize={"1.6rem"}
        gap="0.5rem"
        className="field-title"
        alignItems={"center"}
      >
        <Box flex="0 0 8px" w="8px" h="8px" bg="#525252" />
        <Flex gap="0.5rem" alignItems={"center"} position={"relative"}>
          {title}
          {isLoading && (
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              position={"absolute"}
              right={"-3rem"}
              top={0}
              w={"3rem"}
              h="100%"
              zIndex={10}
            >
              <Spinner />
            </Flex>
          )}
        </Flex>
      </Flex>
      <Box className="field-wrapper">{children}</Box>
    </Flex>
  );
};

export default memo(FieldCheckboxPreviewWrapper);
