import { Box, Checkbox, Flex } from "@chakra-ui/react";
import FieldCheckboxPreviewWrapper from "../../../components/FieldCheckboxPreviewWrapper";
import { FieldPreviewProps } from "../../types";
import Draggable from "../draggable";

export interface CheckboxPreviewerProps extends FieldPreviewProps<any> {}

const CheckboxPreviewer: React.FC<CheckboxPreviewerProps> = (props) => {
  const { item, value, isLoading, isReadOnly, isDraggable, onChange } = props;
  const additionInfoCheckbox = Object.values(
    item?.additionInfo?.checkbox?.mapValues || {}
  );

  if (isDraggable) {
    return <Draggable {...props} additionInfoCheckbox={additionInfoCheckbox} />;
  }

  return (
    <FieldCheckboxPreviewWrapper
      title={item.name}
      data-type={item.dataType}
      isLoading={isLoading}
    >
      <Box w="27.5rem" px="0.5rem" maxW={"100%"}>
        <Flex
          flexDirection="row"
          gap="1rem"
          w="100%"
          justifyContent={"space-between"}
        >
          {additionInfoCheckbox.map((data, index) => (
            <Checkbox
              isReadOnly={isReadOnly}
              width="fit-content"
              key={index}
              isChecked={String(index) === value}
              color="#171717"
              onChange={() => {
                onChange?.(String(index));
              }}
            >
              {data.displayValue}
            </Checkbox>
          ))}
        </Flex>
      </Box>
    </FieldCheckboxPreviewWrapper>
  );
};

export default CheckboxPreviewer;
