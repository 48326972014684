import { useEffect, useRef } from "react";

const useFocusOut = (eventKeys: string[] = ["Enter"]) => {
  const ref = useRef<HTMLElement>();

  useEffect(() => {
    if (!ref.current) return;
    const element = ref.current;

    const onKeydown = (e: KeyboardEvent) => {
      if (!eventKeys.includes(e.key)) return;
      ref.current?.blur();
    };

    element.addEventListener("keydown", onKeydown);

    return () => {
      element.removeEventListener("keydown", onKeydown);
    };
  }, [eventKeys]);

  return ref;
};

export default useFocusOut;
