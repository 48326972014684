import chunk from "lodash/chunk";
import { PATH_API } from "constants/serviceWorker";
import { ApiResponse } from "interfaces/models/api";
import {
  GetInspectionContentList,
  InspectionContent,
  InspectionContentDTO,
  UpdateInspectionContent,
} from "interfaces/models/inspectionContent";
import { InspectionItem } from "interfaces/models/inspectionItem";
import { InspectionItemResult } from "interfaces/models/inspectionItemResult";
import { axiosECS } from "services/baseAxios";
import { getInspectionItems } from "./inspectionItemApi";
import { getInspectionItemResults } from "./inspectionItemResultApi";

const pathV2 = `/v1/${PATH_API.INSPECTION_CONTENT}`;

export const getInspectionContents = async (
  params?: GetInspectionContentList
): Promise<ApiResponse<InspectionContentDTO[]>> => {
  const response = await axiosECS.get(pathV2, {
    params,
  });

  if (params?.shouldCache) {
    await cacheInpsectionContent(response.data);
  }

  return response;
};

export const updateInspectionContent = async (
  params: UpdateInspectionContent
): Promise<
  ApiResponse<{
    inspectionContent?: InspectionContent;
    contentItems?: InspectionItem[];
    itemResults?: InspectionItemResult[];
    deleteInspectionItemIds?: string[];
    deleteItemResultIds?: string[];
  }>
> => {
  return axiosECS.patch(`${pathV2}`, params);
};

export const cacheInpsectionContent = async (
  contents: InspectionContentDTO[]
) => {
  for (const content of contents) {
    const { data: items } = await getInspectionItems({
      inspectionTypeId: content.inspectionTypeId,
    });
    const chunks = chunk(items, 5);
    for (const groups of chunks) {
      await Promise.all(
        groups.map(() => {
          return getInspectionItemResults({
            inspectionTypeId: content.inspectionTypeId,
          });
        })
      );
    }
  }
};
