import { ApiResponse, Pagination } from "interfaces/models/api";
import { InspectionDocument } from "interfaces/models/inspectionDocument";
import { transformBodyDocument } from "models/document";
import { axiosECS } from "services/baseAxios";

interface GetDocumentsReq extends Pagination {
  inspectionTypeId: string;
  name?: string;
  cursor?: string;
  shouldCache?: boolean;
}

const pathV2 = "/v1/documents";
const DEFAULT_LIMIT = 100;

export const getDocumentList = async (
  params: GetDocumentsReq
): Promise<ApiResponse<InspectionDocument[]>> => {
  const { limit, ...rest } = params;

  return axiosECS.get(pathV2, {
    params: { limit: limit || DEFAULT_LIMIT, ...rest },
  });
};

export const getDocumentById = async (
  id: string
): Promise<ApiResponse<InspectionDocument>> => {
  return axiosECS.get(`${pathV2}/${id}`);
};

export const createDocument = async (
  document: { inspectionTypeId: string } & Partial<InspectionDocument>
): Promise<ApiResponse<any>> => {
  return axiosECS.post(pathV2, document);
};

export const updateDocument = async (
  document: Partial<InspectionDocument>
): Promise<ApiResponse<InspectionDocument>> => {
  const body = transformBodyDocument(document);

  return axiosECS.patch(pathV2, body);
};

export const deleteDocument = async (
  id: string
): Promise<ApiResponse<string[]>> => {
  return axiosECS.delete(pathV2, { data: { id } });
};
