import { useLayoutFrameBuilderContext } from "../context/layoutFrameBuilderContext";
import { useHotkeys } from "react-hotkeys-hook";

const useKeyboardEvent = () => {
  const { isLoading, onDelete, itemSelectedId } =
    useLayoutFrameBuilderContext();

  const handleDelete = () => {
    if (isLoading || !itemSelectedId) return;
    onDelete(itemSelectedId);
  };

  useHotkeys("Delete", handleDelete);
  useHotkeys("Backspace", handleDelete);
};

export default useKeyboardEvent;
