import { useState, useRef, useEffect } from "react";

function useMouseInside() {
  const [isInside, setIsInside] = useState(false);
  const ref = useRef<any>(null);

  useEffect(() => {
    const handleMouseMove = (event: any) => {
      if (!ref.current) return;

      const rect = ref.current.getBoundingClientRect();
      const { clientX: x, clientY: y } = event;

      // Kiểm tra nếu chuột nằm trong phạm vi của thẻ
      const isInsideElement =
        x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom;

      setIsInside(isInsideElement);
    };

    // Thêm sự kiện lắng nghe mousemove trên window
    window.addEventListener("mousemove", handleMouseMove);

    // Cleanup khi component unmount
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return { ref, isInside };
}

export default useMouseInside;
