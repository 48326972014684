import { Types, FormStatePayload } from "./types";

export const setFieldValue = (
  payload: FormStatePayload[typeof Types.SET_FIELD_VALUE]
): any => ({
  type: Types.SET_FIELD_VALUE,
  payload,
});

export const setInitValue = (
  payload: FormStatePayload[typeof Types.INIT_VALUE]
): any => ({
  type: Types.INIT_VALUE,
  payload,
});

export const setFormValue = (
  payload: FormStatePayload[typeof Types.INIT_VALUE]
): any => ({
  type: Types.INIT_VALUE,
  payload,
});

export const setFieldAtrributes = (
  payload: FormStatePayload[typeof Types.SET_FIELD_ATTRIBUTES]
): any => ({
  type: Types.SET_FIELD_ATTRIBUTES,
  payload,
});
