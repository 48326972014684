export type BoundingRect = {
  top: number;
  left: number;
  width: number;
  height: number;
};

export const elementIsVisibleInViewport = (
  el: any,
  partiallyVisible = false
) => {
  const { top, left, bottom, right } = el.getBoundingClientRect();
  const { innerHeight, innerWidth } = window;

  return partiallyVisible
    ? ((top > 0 && top < innerHeight) ||
        (bottom > 0 && bottom < innerHeight)) &&
        ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
    : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
};

export const isVisibleInContainer = (
  element: HTMLElement | null | undefined,
  container: HTMLElement | null | undefined
) => {
  if (!element || !container) {
    return false;
  }
  const rect = element.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();
  const { innerHeight, innerWidth } = window;

  // Check if element is within container's viewport

  return (
    ((rect.top > 0 &&
      rect.top < containerRect.top + containerRect.height &&
      rect.top < innerHeight) ||
      (rect.bottom > 0 &&
        rect.bottom < containerRect.top + containerRect.height &&
        rect.bottom < innerHeight)) &&
    ((rect.left > 0 &&
      rect.left < containerRect.left + containerRect.width &&
      rect.left < innerWidth) ||
      (rect.right > 0 &&
        rect.right < containerRect.left + containerRect.width &&
        rect.right < innerWidth))
  );
};

export const getSizeAndPosOfElm = (e: any) => {
  const bounding = e.getBoundingClientRect();

  return {
    top: Number(e.style.top.replace("px", "")),
    left: Number(e.style.left.replace("px", "")),
    width: bounding.width,
    height: bounding.height,
  };
};

export const updateStyleElm = (e: any, bounding: BoundingRect) => {
  const { top, left, width, height } = bounding;
  e.style.top = `${top}px`;
  e.style.left = `${left}px`;
  e.style.width = `${width}px`;
  e.style.height = `${height}px`;
};

export function isContainElement(
  element: HTMLDivElement,
  container: HTMLDivElement
) {
  const target = element.getBoundingClientRect();
  const position = {
    left: target.left,
    top: target.top,
    right: target.left + target.width,
    bottom: target.top + target.height,
  };
  const containerBounding = container.getBoundingClientRect();

  return (
    position.right > containerBounding.left &&
    position.left < containerBounding.right &&
    position.bottom > containerBounding.top &&
    position.top < containerBounding.bottom
  );
}

export const convertRemToPixels = (rem: number) => {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
};

export const convertToPixel = (size: number | string) => {
  // only support pixel, rem
  if (typeof size === "number") {
    return size;
  }
  if (size.includes("px")) {
    return Number(size.replace("px", ""));
  }

  return convertRemToPixels(Number(size.replace("rem", "")));
};
