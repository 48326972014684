import { FormControl } from "@chakra-ui/react";
import {
  AdditionInfo,
  CheckboxAdditionalInfo,
  InspectionItemResult,
  MapValuesAdditionalInfo,
} from "interfaces/models/inspectionItemResult";
import { ElementType, TypeSchema } from "../context/FormBuilderContext/types";
import { useFieldState } from "./useFieldState";

export type Path = (
  | keyof TypeSchema
  | number
  | keyof TypeSchema["inspectionContent"]
  | keyof ElementType<TypeSchema["itemResults"]>
  | keyof AdditionInfo
  | keyof MapValuesAdditionalInfo
  | keyof CheckboxAdditionalInfo
)[];

interface Props<T = any> {
  item: InspectionItemResult;
  isReadOnly?: boolean;
  isDraggable?: boolean;
}

function withReviewer<ComponentProps>(
  Component: React.ComponentType<ComponentProps>
) {
  return (props: Props & ComponentProps) => {
    const { isInvalid, ...attributes } = useFieldState(props.item?.id, {
      isReadOnly: props.isReadOnly,
    });

    return (
      <FormControl isInvalid={isInvalid} scrollMarginTop="60px">
        <Component {...(props as ComponentProps)} {...attributes} />
      </FormControl>
    );
  };
}

export default withReviewer;
