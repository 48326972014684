export const routePath = Object.freeze({
  // Login: "/login",
  SignUp: "/signup",
  Profile: "/profile",
  ChangePassword: "/change-password",
  Home: "/",
  ForgeViewer: "/:projectId/forge-viewer/:bimFileId/:version",
  ProjectList: "/project-list",
  ProjectOverviewCreate: "/:projectId/project-overview/create",
  ProjectOverview: "/:projectId/project-overview/:id",
  PhotoList: "/:projectId/photo-list",
  Blackboard: "/:projectId/blackboard",
  Document: "/:projectId/document",
  DocumentDetail: "/:projectId/document/:documentId",
  DocumentFloor: "/:projectId/document-floor",
  DocumentLevel: "/:bimFileId/document-level",
  DocumentTemplate: "/document/document-template",
  DocumentTemplateEdit: "/document/document-template/edit/:templateId",
  DocumentTemplateView: "/document/document-template/:templateId",
  UserDashboard: "/user-dashboard",
  ProjectDashboard: "/project-dashboard/:projectId",
  Shorten: "/shorten/:id",
  // DocumentTemplateList: "/document/template-list/",
  DocumentTaskList: "/:bimFileId/document-task-list",
  ListTaskType: "/task-type-list",
  // blackboardTemplateList: "/document/blackboard-template-list/",
  // blackboardTemplateEdit: "/document/blackboard-template/edit/:templateId",
  blackboardTemplate: "/document/blackboard-template",
  TaskSheetTemplate: "/document/tasksheet-template",
  TaskSheetTemplateList: "/document/tasksheet-template-list",
  TaskSheetTemplateEdit: "/document/tasksheet-template/edit/:templateId",
  TaskSheetTemplateView: "/document/tasksheet-template/:templateId",
  AccessError: "/access-error",
  NotFound: "/not-found",
  SettingFamily: "/setting-family",
  Schedules: "/:projectId/schedules",
  InspectionList: "/:projectId/inspection/:bimId/list",
  InspectionEditSetting: "/:projectId/inspection/:bimId/setting/:id",
  InspectionCreateSetting: "/:projectId/inspection/:bimId/setting",
  DocumentTemplateV2: "/document-template-v2",
  InspectionDetail: "/:projectId/inspection/:bimId/:id",
  InspectionLayout: "/:projectId/inspection/:bimId/layout/:id",
  InspectionLayoutList:
    "/:projectId/inspection/:bimId/layouts/:inspectionTypeId",
});

export const offlineRoutes = [
  routePath.Home,
  routePath.ForgeViewer,
  routePath.DocumentLevel,
  routePath.DocumentTaskList,
  routePath.AccessError,
  routePath.NotFound,
  routePath.InspectionList,
  routePath.InspectionEditSetting,
  routePath.InspectionDetail,
  routePath.InspectionCreateSetting,
];

export const SHOW_OFFLINE_ICON_ROUTES = [
  routePath.ForgeViewer,
  routePath.InspectionEditSetting,
  routePath.InspectionList,
];
