import { PATH_API } from "constants/serviceWorker";
import { ApiResponse } from "interfaces/models/api";
import {
  GetInspectionItemList,
  InspectionItemDTO,
} from "interfaces/models/inspectionItem";
import { axiosECS } from "services/baseAxios";

const pathV2 = `/v1/${PATH_API.INSPECTION_ITEM}`;

export const getInspectionItems = async (
  params: GetInspectionItemList
): Promise<ApiResponse<InspectionItemDTO[]>> => {
  return axiosECS.get(pathV2, {
    params,
  });
};
