import { Img } from "@chakra-ui/react";
import { Attribute, Node } from "@tiptap/core";
import { NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react";
import { withPresignedUrl } from "components/HOC/presignedUrl";
import { CustomNodeComponentProps, NodeType } from "../type";

const ImgPresigned = withPresignedUrl(Img);

export interface ImageNodeAttrs {
  src: string;
}

export const ImageNode = Node.create({
  name: NodeType.IMAGE,
  group: "block",
  // fence the cursor for regular editing operations
  // [https://tiptap.dev/docs/editor/core-concepts/schema#isolating]
  selectable: false,

  addAttributes() {
    const attrs: {
      [key in keyof ImageNodeAttrs]: Attribute;
    } = {
      src: { default: null },
    };

    return attrs;
  },
  parseHTML() {
    return [
      {
        tag: "image",
      },
    ];
  },
  renderHTML() {
    return ["image", {}, 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(Component);
  },
});

const Component = (props: CustomNodeComponentProps<ImageNodeAttrs>) => {
  const { src } = props.node.attrs;

  return (
    <NodeViewWrapper
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        cursor: "default",
        pointerEvents: "auto",
      }}
      onClick={() => {
        props.editor.commands.blur();
      }}
    >
      <ImgPresigned
        src={src}
        alt=""
        style={{
          maxWidth: "98%",
          maxHeight: "98%",
          margin: "auto",
          display: "block",
          objectFit: "contain",
        }}
        crossOrigin="anonymous"
      />
    </NodeViewWrapper>
  );
};
