import { Attribute, Node } from "@tiptap/core";
import { SOURCE } from "components/containers/InspectionFormat/Content/SettingCommonLayout/context/commonLayoutFrameReducer";
import { inlineStyleFromComponent } from "components/containers/InspectionFormat/Content/SettingFontSize/utils/style";
import {
  DEFAULT_PIN_COMPONENT_STYLE,
  PinComponentLinkedData,
  PinComponentStyle,
} from "interfaces/models/pinComponent";
import { NodeType } from "../type";

export interface PageComponentFrameNodeAttrs {
  componentId: string;
  pageIndex: number;
  style: PinComponentStyle;
  top: number;
  left: number;
  width: number;
  height: number;
  linkedData: PinComponentLinkedData;
  isUpdated?: boolean;
  updatedAt?: Date;
}

export const PageComponentFrameNode = Node.create({
  name: NodeType.PAGE_COMPONENT_FRAME,
  group: "block",

  content: `(${NodeType.NORMAL_TEXT})*`,
  selectable: false,
  allowGapCursor: false,

  addAttributes() {
    const attrs: {
      [key in keyof PageComponentFrameNodeAttrs]: Attribute;
    } = {
      style: { default: DEFAULT_PIN_COMPONENT_STYLE },
      pageIndex: { default: 0 },
      componentId: { default: null },
      top: { default: 0 },
      left: { default: 0 },
      width: { default: 0 },
      height: { default: 0 },
      linkedData: { default: {} },
      isUpdated: { default: false },
      updatedAt: { default: undefined },
    };

    return attrs;
  },
  parseHTML() {
    return [
      {
        tag: "div",
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    const {
      top,
      left,
      width,
      height,
      style: _style,
      linkedData,
    } = HTMLAttributes as PageComponentFrameNodeAttrs;
    if (linkedData.source === SOURCE.BIM) {
      return ["div", { style: "display: none;" }];
    }

    const otherStyle = inlineStyleFromComponent(_style);
    const style =
      "position: absolute; color: black; pointer-events: auto; " +
      `top: ${top}%; ` +
      `left: ${left}%; ` +
      `width: ${width}%; ` +
      `height: ${height}%; ${otherStyle}`;

    return ["div", { style }, 0];
  },
});
