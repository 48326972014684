import { Flex, Text } from "@chakra-ui/react";
import { DropdownTag, DropdownTagsInputProps } from "components/ui/DropdownTag";
import { User } from "interfaces/models/user";
import { CSSProperties, useMemo } from "react";
import AvatarUser from "components/AvatarUser";

interface Props extends DropdownTagsInputProps {
  users: {
    [key: string]: User | null;
  };
  value?: string[];
  onChange?: (value: any) => void;
  placeHolder?: string;
  isShowIcon?: boolean;
  styleTag?: CSSProperties;
  styleWrapTag?: CSSProperties;
  strategy?: "absolute" | "fixed";
  iconProps?: any;
  icon?: any;
  placeholderProps?: any;
  isDisabled?: boolean;
  isLoading?: boolean;
}

const SelectUserMulti = ({
  users = {},
  value = [],
  onChange,
  isShowIcon = true,
  placeHolder = "ユーザーを選択します",
  styleTag,
  styleWrapTag,
  strategy = "absolute",
  isLoading,
  ...rest
}: Props) => {
  const options = useMemo(() => {
    return Object.keys(users).map((userId) => ({
      name: (
        <Flex w="100%" height="4.2rem" alignItems="center">
          <AvatarUser w="2.5rem" h="2.5rem" src={users[userId]?.avatar} />
          <Text
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            as="div"
            ml="1rem"
            color="#171717"
          >
            {users[userId]?.name}
          </Text>
        </Flex>
      ),
      value: userId,
    }));
  }, [users]);

  return (
    <DropdownTag
      options={options}
      allOptions={options}
      styleTag={{
        height: "4rem",
        ...styleTag,
      }}
      value={value}
      onChange={onChange}
      placeHolder={placeHolder}
      name="全てのユーザー"
      styleProps={{ w: "100%" }}
      styleContent={{
        overflowY: "auto",
        height: "auto",
        maxHeight: "30rem",
      }}
      strategy={strategy}
      styleWrapTag={styleWrapTag}
      isLoading={isLoading}
      {...rest}
    />
  );
};

export default SelectUserMulti;
