import { PATH_API } from "constants/serviceWorker";
import { ApiResponse } from "interfaces/models/api";
import {
  GetInspectionItemResultList,
  InspectionItemResult,
} from "interfaces/models/inspectionItemResult";
import { axiosECS } from "services/baseAxios";

const pathV2 = `/v1/${PATH_API.INSPECTION_ITEM_RESULT}`;

export const getInspectionItemResults = async (
  params?: GetInspectionItemResultList
): Promise<ApiResponse<InspectionItemResult[]>> => {
  return axiosECS.get(pathV2, {
    params,
  });
};
