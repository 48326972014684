import { GenerateStatus } from "./index";

import { message } from "components/base";
import { TYPE_USER } from "constants/app";

import { CURRENT_LEVEL_KEY, LEVEL_ALL, LEVEL_OTHER } from "constants/forge";
import useFetchFamilies from "hooks/useFetchFamilies";
import { FamilyInstanceDTO } from "interfaces/dtos/familyInstance";
import { Level } from "interfaces/models";
import { NeptuneArea, Space } from "interfaces/models/area";
import { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setIsLoadingDocument } from "redux/documentSlice";
import {
  setIsGeneratingFamilyInstances,
  setIsLoadedExternalId,
  setLevelSelected,
  setLoadedFamilyInstances,
} from "redux/forgeViewerSlice";
import { RootState } from "redux/store";
import GenFamilyInstance from "services/genFamilyInstance";

import { setFamilyInstances } from "utils/forge/viewerData";
import { getAreaExtension } from "utils/forge/extensions/area-extension";

import { ___viewer3d } from "utils/forge/forge3d";
import { getDataFamilyInstance } from "utils/forge/s3Data";

import { getLocalStorage, setLocalStorage } from "utils/storage";
import useArea from "../useArea";

interface Props {
  generatingStatusRef: React.MutableRefObject<GenerateStatus>;
  isSpaceDataAllLevel: boolean;
  isSettingFloor?: boolean;
  setTimeCheckGenerate: (val: number) => void;
}

export default function useGenerateFamilyInstance(props: Props) {
  const {
    generatingStatusRef,
    isSpaceDataAllLevel,
    isSettingFloor,
    setTimeCheckGenerate,
  } = props;

  const {
    isGeneratingSpaces,
    isGeneratingFamilyInstances,
    isLoadedViewerModelData,
    isLoadedViewer,
    isLoadedNeptuneAreas,
    isLoadedSpaces,
    displayMode,
    levels,
    levelSelected,
    isLoadedLevels,
    isLoadedFamilies,
    isInitialized,
  } = useSelector((state: RootState) => state.forgeViewer);
  const { currentUser } = useSelector((state: RootState) => state.user);

  const dispatch = useDispatch();
  const { bimFileId, version } = useParams();
  const { dataProjectDetail } = useSelector(
    (state: RootState) => state.project
  );
  const hasPermission = useMemo(
    () => !currentUser?.role || currentUser.role === TYPE_USER.ROLE_ADMIN,
    [currentUser?.role]
  );
  const { getObjectTypesOfFamilyInstance } = useFetchFamilies();

  const { allAreas, allSpaces } = useArea({});
  const allAreasRef = useRef<NeptuneArea[]>([]);
  const allSpacesRef = useRef<Space[]>([]);

  allAreasRef.current = allAreas;
  allSpacesRef.current = allSpaces;

  const lastFetchFamilyArgs = useRef<{
    bimFileId: string | null;
    version: string | null;
    levelSelected: Level | null;
  }>({ bimFileId: null, levelSelected: null, version: "" });
  useEffect(() => {
    if (
      isSettingFloor ||
      !bimFileId ||
      !version ||
      !isLoadedFamilies ||
      levelSelected?.guid === undefined ||
      !isInitialized ||
      !dataProjectDetail?.id
    ) {
      return;
    }
    const lastLevelSelected = lastFetchFamilyArgs.current.levelSelected;
    if (
      lastFetchFamilyArgs.current.bimFileId === bimFileId &&
      lastLevelSelected?.guid === levelSelected?.guid &&
      lastFetchFamilyArgs.current.version === version
    ) {
      // if same level -> not need refetch
      return;
    }
    lastFetchFamilyArgs.current = {
      bimFileId,
      version,
      levelSelected,
    };
    generatingStatusRef.current.instance = false;
    dispatch(setIsLoadingDocument(true));
    dispatch(setLoadedFamilyInstances(false));
    setFamilyInstances({});
    (async () => {
      // load family instances data
      const familyInstancesData = await getDataFamilyInstance({
        bimFileId,
        version,
        level: levelSelected?.guid ? levelSelected.label : LEVEL_OTHER.label,
      });

      if (familyInstancesData) {
        const familyInstances = familyInstancesData?.familyInstances || {};
        const familyInstanceKeys = Object.keys(familyInstances);
        familyInstanceKeys.forEach((id: string) => {
          familyInstances[id].objectTypes = getObjectTypesOfFamilyInstance(
            familyInstances[id]
          );
        });
        setFamilyInstances(familyInstances);
        dispatch(setLoadedFamilyInstances(true));
        dispatch(setIsGeneratingFamilyInstances(false));
        generatingStatusRef.current.instance = true;
        setTimeCheckGenerate(Date.now());
      } else {
        dispatch(setIsGeneratingFamilyInstances(true));
        lastFetchFamilyArgs.current.levelSelected = LEVEL_ALL;
        if (levelSelected.guid) {
          const currentLevel = getLocalStorage(CURRENT_LEVEL_KEY) || {};
          currentLevel[String(bimFileId)] = "";
          dispatch(setIsLoadedExternalId(false));
          setLocalStorage(CURRENT_LEVEL_KEY, currentLevel);
          dispatch(setLevelSelected(LEVEL_ALL));
          currentLevel[String(bimFileId)] = "";
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    bimFileId,
    version,
    isGeneratingSpaces,
    isLoadedFamilies,
    isSettingFloor,
    levelSelected,
    isInitialized,
    hasPermission,
    dataProjectDetail,
  ]);

  useEffect(() => {
    if (isGeneratingSpaces) {
      return;
    }

    const urn = dataProjectDetail?.defaultBimPathId?.split("/").pop();
    if (
      !dataProjectDetail?.defaultBimPathId ||
      !isGeneratingFamilyInstances ||
      !bimFileId ||
      !version ||
      !isLoadedLevels ||
      !isLoadedViewerModelData ||
      !isLoadedViewer ||
      !___viewer3d ||
      !bimFileId ||
      !urn ||
      !isLoadedNeptuneAreas ||
      !isLoadedSpaces ||
      !isSpaceDataAllLevel
    ) {
      return;
    }
    (async () => {
      const viewer = ___viewer3d;
      // draw all area when generate family instances
      const areaExtension = getAreaExtension();
      areaExtension?.setAreasToDraw(allAreasRef.current);
      areaExtension?.setSpacesToDraw(allSpacesRef.current);
      await areaExtension?.drawAreas({
        initialize: true,
        isDrawAreaLabel: false,
      });
      const result = await generateFamilyInstancesData({
        viewer,
        bimFileId,
        version,
        projectId: dataProjectDetail.projectId,
      });
      if (!result) {
        return;
      }
      generatingStatusRef.current.instance = true;
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dataProjectDetail?.defaultBimPathId,
    dataProjectDetail?.id,
    bimFileId,
    isLoadedViewerModelData,
    version,
    isLoadedLevels,
    levelSelected.guid,
    displayMode,
    isLoadedNeptuneAreas,
    isLoadedSpaces,
    isLoadedViewer,
    isSpaceDataAllLevel,
    isGeneratingFamilyInstances,
    isGeneratingSpaces,
  ]);

  // generate family instances data in first access
  const generateFamilyInstancesData = async ({
    viewer,
    bimFileId,
    version,
    projectId,
  }: {
    viewer: Autodesk.Viewing.GuiViewer3D;
    bimFileId: string;
    version: string;
    projectId: string;
  }) => {
    const dataInstance = new GenFamilyInstance(bimFileId, version);
    const familyInstances = await dataInstance.getFamilyInstancesProperties({
      viewer,
      projectId,
    });
    if (!familyInstances) {
      return false;
    }
    if (!levelSelected.guid) {
      const uploaded = await dataInstance.uploadFamilyInstancesToS3(
        levels,
        familyInstances
      );

      if (uploaded) {
        const defaultLevel = levels.filter((level) => level.guid)?.[0];
        const newFamilyInstances: { [key: string]: FamilyInstanceDTO } = {};
        Object.keys(familyInstances).forEach((id: string) => {
          if (familyInstances[id].level !== defaultLevel?.label) {
            return;
          }
          familyInstances[id].objectTypes = getObjectTypesOfFamilyInstance(
            familyInstances[id]
          );
        });
        setFamilyInstances(newFamilyInstances);
        dispatch(setLoadedFamilyInstances(true));
        dispatch(setIsGeneratingFamilyInstances(false));

        return true;
      } else {
        message.error("エラーが発生しました。再度実行お願いします。");

        return false;
      }
    }

    return false;
  };

  return { lastFetchFamilyArgs };
}
