import { Flex, FlexProps } from "@chakra-ui/react";
import { NumberInputFormat } from "components/input";
import { useEffect, useRef } from "react";

const ZOOM_STEP = 0.05;
const MAX_ZOOM = 4;
const MIN_ZOOM = 0.25;
const ZOOM_RATIO = 100;

interface Props extends FlexProps {
  ratio: number;
  onChangeRatio: (val: number) => void;
}

export default function FormChangeRatio(props: Props) {
  const { ratio, onChangeRatio, ...rest } = props;

  const ratioRef = useRef(ratio);
  ratioRef.current = ratio;

  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      if (event.ctrlKey || event.metaKey) {
        event.preventDefault();
        if (event.deltaY < 0) {
          const next = ratioRef.current + ZOOM_STEP;
          if (next <= MAX_ZOOM) {
            onChangeRatio(Number(next.toFixed(2)));
          }
        } else if (event.deltaY > 0) {
          const next = ratioRef.current - ZOOM_STEP;
          if (next >= MIN_ZOOM) {
            onChangeRatio(Number(next.toFixed(2)));
          }
        }
      }
    };

    window.addEventListener("wheel", handleWheel, { passive: false });

    return () => {
      window.removeEventListener("wheel", handleWheel);
    };
  }, [onChangeRatio]);

  return (
    <Flex {...rest}>
      <NumberInputFormat
        w="16rem"
        marginLeft="auto"
        mr="2rem"
        height="4rem"
        borderRadius=".4rem"
        unit="%表示"
        initValue={"100"}
        value={`${Math.round(ratio * ZOOM_RATIO)}%表示`}
        min={MIN_ZOOM * ZOOM_RATIO}
        max={MAX_ZOOM * ZOOM_RATIO}
        step={ZOOM_STEP * ZOOM_RATIO}
        textAlign="left"
        onChangeStyle={(_, value) => {
          if (isNaN(value)) {
            return;
          }

          onChangeRatio(value / ZOOM_RATIO);
        }}
      />
    </Flex>
  );
}
