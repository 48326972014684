import { Icon, IconProps } from "@chakra-ui/icons";

// <svg id="icon-navigation-chevron_left_24px" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
// <rect id="Boundary" width="32" height="32" fill="none"/>
// <path id="icon_edit_on" d="M14.4,8.52l1.07,1.07L4.91,20.17H3.84V19.1L14.4,8.52M18.6,1.5a1.2,1.2,0,0,0-.82.34L15.65,3.97l4.37,4.37,2.13-2.13a1.165,1.165,0,0,0,0-1.64L19.42,1.84a1.148,1.148,0,0,0-.83-.34ZM14.4,5.22,1.5,18.12v4.37H5.87l12.9-12.9L14.4,5.22Z" transform="translate(4.006 4.005)"/>
// </svg>

const EditIcon = (props: IconProps) => {
  return (
    <Icon
      width="1em"
      height="1em"
      fill="currentcolor"
      viewBox="0 0 32 32"
      {...props}
    >
      <rect id="Boundary" width="32" height="32" fill="none" />
      <path
        id="icon_edit_on"
        d="M14.4,8.52l1.07,1.07L4.91,20.17H3.84V19.1L14.4,8.52M18.6,1.5a1.2,1.2,0,0,0-.82.34L15.65,3.97l4.37,4.37,2.13-2.13a1.165,1.165,0,0,0,0-1.64L19.42,1.84a1.148,1.148,0,0,0-.83-.34ZM14.4,5.22,1.5,18.12v4.37H5.87l12.9-12.9L14.4,5.22Z"
        transform="translate(4.006 4.005)"
      />
    </Icon>
  );
};

export default EditIcon;
