import { Box, Flex } from "@chakra-ui/react";
import { DroppableProps } from "../../types";
import CameraPlusIcon from "components/icon/CameraPlusIcon";

const ImageDroppable: React.FC<DroppableProps> = ({ isDragging }) => {
  return (
    <Box>
      <CameraPlusIcon />
      {isDragging && (
        <Flex position={"relative"} w="100%" h="100%">
          <Flex
            position={"absolute"}
            top={"100%"}
            left="0"
            p="0.5rem 0"
            alignItems={"center"}
            whiteSpace={"nowrap"}
          >
            <CameraPlusIcon />
            計器撮影写真
          </Flex>
        </Flex>
      )}
    </Box>
  );
};

export default ImageDroppable;
