import { Box, Button, Flex, Spinner, Text } from "@chakra-ui/react";
import TaskModal, { TaskModalHandleType } from "components/modal/TaskModal";
import StatusGenerateData from "components/StatusGenerateData";
import { SvgIcon } from "components/SvgIcon";
import TooltipForgeView from "components/TooltipForgeView";
import ForgeViewer from "components/ui/ForgeViewer";
import { ModalType, SystemModeType } from "constants/enum";
import { DISPLAY_MODE, MAX_SIZE_IMAGE, VIEW_ROLE } from "constants/forge";
import { usePermission, useRoles } from "hooks/usePermission";
import { useProjectBimFile } from "hooks/useProjectBimFile";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { Level } from "interfaces/models";
import { isEmpty } from "lodash";
import { handleSelectTask } from "models/task";
import useBlackboardTemplateList from "pages/document/template-page/hooks/useBlackboardTemplateList";
import useTaskSheetTemplateList from "pages/document/template-page/hooks/useTaskSheetTemplateList";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useDeviceSelectors } from "react-device-detect";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  clearState as clearDocumentState,
  resetDocumentItemIdsCreated,
} from "redux/documentSlice";
import { setModalType } from "redux/forgeViewerSlice";
import { RootState } from "redux/store";
import {
  clearState as clearTaskState,
  resetTaskIdsCreated,
  setTaskSelected,
} from "redux/taskSlice";
import { clearForgeSelection } from "utils/forge";
import { AreaExtension } from "utils/forge/extensions/area-extension";
import { ClickExtension } from "utils/forge/extensions/click-extension";
import { CustomLabelExtension } from "utils/forge/extensions/custom-label";
import { PortalExtension } from "utils/forge/extensions/portal-extension";
import { logDev } from "utils/logs";
import useForgePage from "./hooks";
import useCheckSheet from "./hooks/useCheckSheet";
import useDocument from "./hooks/useDocument";
import useDocumentTask from "./hooks/useDocumentTask";
import { useEventListener } from "./hooks/useEventListener";
import useForgeViewer from "./hooks/useForgeViewer";
import useSyncCommonTaskData from "./hooks/useSyncCommonData";
import useSyncData from "./hooks/useSyncData";
import useTask from "./hooks/useTask";
import { ForgeViewerContext } from "./ForgeViewerContext";
import SendDataSocketHelper from "helper/sendDataSocketHelper";
import useGenerateData from "./hooks/useGenerateData";
import Layout from "components/Layout";
import ForgeHeaderItem from "./ForgeHeaderItem";
import useForgeToolbar from "./hooks/useForgeToolbar";
import ForgeToolbar from "components/ui/ForgeViewer/ForgeToolbar";
import DrawToolModal from "components/modal/DrawToolModal";
import useGetPartnerCompanies from "hooks/useGetPartnerCompanies";
import LeftPanel, { LeftPanelHandleType } from "./LeftPanel";

export const DATE_TIME_VERSION_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const DATE_TIME_VERSION_FORMAT_SELECT = "YYYYMMDDHHmmss";

export const configImageCompress = {
  maxSizeMB: 1.5,
  maxWidthOrHeight: MAX_SIZE_IMAGE,
  useWebWorker: true,
};

export interface DataFromURL {
  dbId: number;
  position: {
    x: number;
    y: number;
    z: number;
  };
  rotation?: {
    x: number;
    y: number;
    z: number;
  };
  view_name?: string;
  image: string;
  fov: string;
  direction: string;
  uranus_url: string;
  uranusStepId?: string;
}

export interface SettingsFromURL {
  attached: string[];
  tags: string[];
  userCreated: string[];
  userAssigned: string[];
  deadlineFrom?: Date;
  deadlineTo?: Date;
  hasImage: boolean;
  hasNoImage: boolean;
  status: string[];
  priority: number[];
  contents: string[];
  objectTypes: string[];
  documentTaskIds?: string[];
}

export interface FilterDataFromURL {
  settings: SettingsFromURL;
  searchValue: string;
  levelSelected: Level;
  taskId?: string;
}

function ForgeViewerPage() {
  useProjectBimFile();
  useBlackboardTemplateList({ isShowLoading: false });
  useTaskSheetTemplateList();
  useCheckSheet({});
  const { sendWebSocketMessage: sendMessageToCommonChannel } =
    useSyncCommonTaskData();
  const { webSocketMessages, sendWebSocketMessage } = useSyncData();
  const location = useLocation();
  const taskModalRef = useRef<TaskModalHandleType>(null);
  const sendMessageRef = useRef(new SendDataSocketHelper());
  sendMessageRef.current.setParams({ sendWebSocketMessage });
  const { isTakasagoGroup } = useRoles();
  const leftPanelRef = useRef<LeftPanelHandleType>(null);

  const { selectedView } = useSelector((state: RootState) => state.forgeViewer);
  const {
    dataProjectDetail,
    isOnline,
    operation,
    systemMode,
    clickInfo,
    levelSelected,
    modalType,
    taskSelected,
    isCreateTask,
    isCreateSelfInspectionTask,
    settings,
    searchTaskValue,
    bimFileId,
    isSettingFloor,
    imageURL,
    isLoadedLevels,
    displayMode,
    isLoadingDocument,
    mapTaskType,
    filterDocumentCategoryOptions,
    clickedLabelInfoRef,
    clickedLabelInfo,
    searchDocumentValue,
    contentHeight,
    areas,
    mapDocumentItemBlockIdsByTemplateId,
    isCaptureKeynoteByOperation,
    isLoadedDocumentTasks,
    documentTemplates,
    onSearch,
    handleOpenDocumentByLevel,
    setSearchTaskValue,
    setSearchDocumentValue,
    handleUpdateClickForgeInfo,
    setClickInfo,
    setClickedLabelInfo,
    dispatch,
    handleRouteToHome,
    onCloseCreateTask,
  } = useForgePage({
    webSocketMessages,
    sendWebSocketMessage,
    sendMessageToCommonChannel,
  });

  const {
    documentCategoryModalRef,
    documentItemModalRef,
    handleClickDocumentGroup,
    filterDocumentGroupAndCategories,
    handleClickDocumentCategory,
    handleClickDocumentItem,
  } = useDocument({
    filterDocumentCategoryOptions,
    clickInfo,
    operation,
    setClickedLabelInfo,
  });
  const { partnerCompanies, isFetchingPartnerCompanies } =
    useGetPartnerCompanies({ bimFileId });
  const { documentTasksByLevel } = useDocumentTask({
    levelSelected,
    bimFileId,
  });

  /** register event listener */
  useEventListener();

  const {
    filterTasks,
    isOpenDraw,
    imageCaptured,
    handleSaveFileDrawled,
    handleCloseTaskModal,
    handleCloseDrawToolModal,
  } = useTask({
    mapTaskType,
    documentTasksByLevel,
    searchValue: searchTaskValue,
    clickInfo,
    imageURL,
  });

  const {
    totalView,
    totalViewGenerated,
    isGeneratingAllFamilyInstance,
    isGeneratingSpaces,
    isGeneratingFamilyInstances,
    isGeneratingView,
    isCheckingGenerate,
  } = useGenerateData({ isSettingFloor });

  const isGenerating =
    isGeneratingAllFamilyInstance ||
    isGeneratingFamilyInstances ||
    isGeneratingSpaces ||
    isGeneratingView;

  const {
    familyInstances,
    urn,
    isLoadedViewer,
    isLoadedViewerModelData,
    forgeViewContainerRef,
    handleChangeSheet,
    isLoadedSheetTransformRatio,
  } = useForgeViewer({
    mapTaskType,
    isSettingFloor,
    isGenerating,
    filterTasks,
    clickedLabelInfoRef,
    taskModalRef,
    filterDocumentGroupAndCategories,
    webSocketMessages,
    setClickInfo,
    setClickedLabelInfo,
    sendWebSocketMessage,
  });

  const {
    orbitToolButton,
    panToolButton,
    change2dSheetButton,
    changeDisplayModeButton,
    labelFilterButton,
    createTaskButton,
    sectionToolButton,
    showElementsButton,
    showAreaButton,
    showHomeButton,
  } = useForgeToolbar({
    handleChangeSheet,
    isLoadedSheetTransformRatio,
    isLoadedViewerModelData,
  });

  const { currentUser } = useSelector((state: RootState) => state.user);
  const { documentCategories } = useSelector(
    (state: RootState) => state.document
  );

  const [{ isMobile }] = useDeviceSelectors(window.navigator.userAgent);
  const { canAccessForgeViewer } = usePermission(currentUser?.role);

  const showTooltipCreateTask = useMemo(() => {
    if (systemMode === SystemModeType.Task) {
      return isCreateTask && isLoadedViewerModelData;
    }

    return isCreateSelfInspectionTask;
  }, [
    systemMode,
    isCreateSelfInspectionTask,
    isCreateTask,
    isLoadedViewerModelData,
  ]);

  // Clear state documentIdsCreate when page unmounted
  useEffect(() => {
    return () => {
      dispatch(resetDocumentItemIdsCreated());
      dispatch(resetTaskIdsCreated());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const autoOpenDocumentItemModal = () => {
    const documentItemSelectedState = (location?.state as any)
      ?.documentItemSelectedState;

    if (
      isLoadedViewer &&
      !isLoadingDocument &&
      systemMode === SystemModeType.Document &&
      !isEmpty(documentItemSelectedState)
    ) {
      handleClickDocumentItem({
        document: documentItemSelectedState,
        modalType,
      });
    }
  };

  const autoOpenDocumentCategoryModal = () => {
    const documentCategorySelectedState = (location.state as any)
      ?.documentCategorySelectedState;
    const newDocumentCategorySelelected = documentCategories?.find(
      (item) => item.id === documentCategorySelectedState?.documentCategoryId
    );

    if (
      isLoadedViewer &&
      !isEmpty(newDocumentCategorySelelected) &&
      modalType === ModalType.NONE &&
      !isLoadingDocument &&
      systemMode === SystemModeType.Document
    ) {
      handleClickDocumentCategory(newDocumentCategorySelelected);
    }
  };

  const clearDocumentAndTaskState = () => {
    dispatch(clearDocumentState());
    dispatch(clearTaskState());
  };

  const clearHistory = () => {
    if (typeof window !== "undefined") {
      window.history.replaceState({}, document.title);
    }
  };

  useEffect(() => {
    autoOpenDocumentItemModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadedViewer, isLoadingDocument]);

  useEffect(() => {
    autoOpenDocumentCategoryModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadedViewer, isLoadingDocument, documentCategories]);

  //Clear history when page unmouted or refesh
  useEffect(() => {
    const cleanUp = (e: any) => {
      e = e || window.event;
      // For IE and Firefox
      if (e) {
        clearHistory();
      }

      // For Safari
      return clearHistory();
    };

    window.addEventListener("beforeunload", cleanUp);

    return () => {
      clearHistory();
      clearDocumentAndTaskState();
      window.removeEventListener("beforeunload", cleanUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // close task modal when sync task has deleted in offline
  useEffect(() => {
    if (modalType === ModalType.TASK && taskSelected === undefined) {
      dispatch(setTaskSelected({} as TaskDTO));
      dispatch(setModalType(ModalType.NONE));
    }
  }, [taskSelected, modalType, dispatch]);

  // select task by location's state
  useEffect(() => {
    const taskSelected = (location.state as any)?.taskSelected;

    if (!isEmpty(taskSelected)) {
      handleSelectTask(taskSelected, dispatch);
    }
  }, [dispatch, location]);

  const renderForge = useMemo(() => {
    if (
      isSettingFloor ||
      !isLoadedLevels ||
      !urn ||
      !displayMode ||
      !levelSelected
    ) {
      return <></>;
    }

    if (isGeneratingSpaces) {
      return (
        <ForgeViewer
          key={`generating-space-${urn}`}
          urn={urn}
          isMasterView={true}
          extensions={{}}
        />
      );
    }

    const is2d = displayMode === DISPLAY_MODE["2D"];
    const guid = is2d
      ? levelSelected.sheetGuid ||
        levelSelected.sheets?.[0]?.guid ||
        levelSelected.guid ||
        ""
      : levelSelected.guid;
    logDev("_____guid", guid, levelSelected);

    return (
      <ForgeViewer
        key={`forge-${urn}`}
        urn={urn}
        guid={selectedView?.guid ?? guid}
        isLoadByModel={!!selectedView}
        isLoadBySvf2={!!selectedView && selectedView.role === VIEW_ROLE.ROLE_3D}
        extensions={{
          ClickExtension: { register: ClickExtension.register },
          CustomLabelExtension: {
            register: CustomLabelExtension.register,
            options: {
              systemMode,
              settings,
            },
          },
          AreaExtension: { register: AreaExtension.register },
          PortalExtension: { register: PortalExtension.register },
        }}
      />
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    //systemMode,
    //isDownloadPdfOnMobile,
    selectedView,
    displayMode,
    isGeneratingSpaces,
    isLoadedLevels,
    isSettingFloor,
    levelSelected,
    urn,
  ]);

  const isCanGenerateData = useMemo(
    () => !(isGenerating && isMobile),
    [isGenerating, isMobile]
  );

  const onHandleCloseCreateTask = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      onCloseCreateTask();
      if (isEmpty(taskSelected)) {
        clearForgeSelection();
      }
    },
    [onCloseCreateTask, taskSelected]
  );

  useEffect(() => {
    const head = document.querySelector("head");
    const script = document.createElement("script");
    script.setAttribute("src", "/Noto_Sans_JP_Bold.min.js");
    head!.appendChild(script);

    return () => {
      head!.removeChild(script);
    };
  }, []);

  const renderForgeToolbar = useMemo(() => {
    if (
      !isLoadedLevels ||
      isSettingFloor ||
      isGenerating ||
      (isOnline && isCheckingGenerate)
    )
      return <></>;

    return (
      <ForgeToolbar>
        {displayMode === DISPLAY_MODE["3D"] && orbitToolButton}
        {displayMode === DISPLAY_MODE["3D"] && panToolButton}
        {changeDisplayModeButton}
        {displayMode === DISPLAY_MODE["2D"] && change2dSheetButton}
        {systemMode === SystemModeType.Task && labelFilterButton}
        {systemMode === SystemModeType.Task &&
          isTakasagoGroup &&
          createTaskButton}
        {displayMode === DISPLAY_MODE["3D"] && sectionToolButton}
        {showElementsButton}
        {showAreaButton}
        {showHomeButton}
      </ForgeToolbar>
    );
  }, [
    isOnline,
    isCheckingGenerate,
    isGenerating,
    isLoadedLevels,
    isSettingFloor,
    displayMode,
    orbitToolButton,
    panToolButton,
    changeDisplayModeButton,
    change2dSheetButton,
    labelFilterButton,
    systemMode,
    isTakasagoGroup,
    createTaskButton,
    sectionToolButton,
    showElementsButton,
    showAreaButton,
    showHomeButton,
  ]);

  const memoSetClickedLabelInfo = useCallback((data: any | undefined) => {
    setClickedLabelInfo(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout
      headerProps={{
        leftMoreContent: <ForgeHeaderItem />,
        isShowSyncDataForOfflineIcon: true,
      }}
    >
      <ForgeViewerContext.Provider
        value={{
          webSocketMessages,
          sendWebSocketMessage,
          sendMessageToCommonChannel,
          socket: sendMessageRef.current,
        }}
      >
        <Flex
          w="100%"
          position="relative"
          flexDir={{ base: "column", md: "row" }}
          h="calc(var(--app-height) - var(--header-height))"
        >
          {!isCaptureKeynoteByOperation &&
            isCanGenerateData &&
            !isGenerating &&
            !(isOnline && isCheckingGenerate) &&
            !!canAccessForgeViewer && (
              <LeftPanel
                ref={leftPanelRef}
                isOnline={isOnline}
                taskModalRef={taskModalRef}
                documentItemModalRef={documentItemModalRef}
                documentCategoryModalRef={documentCategoryModalRef}
                clickInfo={clickInfo}
                forgeViewContainerRef={forgeViewContainerRef}
                filterDocumentCategoryOptions={filterDocumentCategoryOptions}
                isLoadedDocumentTasks={isLoadedDocumentTasks}
                isLoadedSheetTransformRatio={isLoadedSheetTransformRatio}
                documentTasksByLevel={documentTasksByLevel}
                bimFileId={bimFileId}
                taskData={filterTasks}
                documentTemplates={documentTemplates}
                isCreateTask={isCreateTask}
                isLoadedLevels={isLoadedLevels}
                searchInputValue={
                  systemMode === SystemModeType.Task
                    ? searchTaskValue
                    : searchDocumentValue
                }
                filterDocumentGroupAndCategories={
                  filterDocumentGroupAndCategories
                }
                settings={settings}
                isLoadedViewer={
                  isLoadedViewer && !dataProjectDetail?.isDiffVersion
                }
                maxH={{
                  base: contentHeight,
                }}
                areas={areas}
                mapDocumentItemBlockIdsByTemplateId={
                  mapDocumentItemBlockIdsByTemplateId
                }
                setSearchValue={
                  systemMode === SystemModeType.Task
                    ? setSearchTaskValue
                    : setSearchDocumentValue
                }
                onSearch={onSearch}
                onOpenDocLevel={handleOpenDocumentByLevel}
                handleClickDocumentGroup={handleClickDocumentGroup}
                handleClickDocumentCategory={handleClickDocumentCategory}
                handleClickDocumentItem={handleClickDocumentItem}
                handleChangeSheet={handleChangeSheet}
                mapTaskType={mapTaskType}
                partnerCompanies={partnerCompanies}
                isFetchingPartnerCompanies={isFetchingPartnerCompanies}
                setClickedLabelInfo={memoSetClickedLabelInfo}
                handleUpdateClickForgeInfo={handleUpdateClickForgeInfo}
              />
            )}

          {!!canAccessForgeViewer && isCanGenerateData && (
            <Box
              flexGrow="3"
              minW="10%"
              bgColor="#F2F2F2"
              h="calc(var(--app-height) - var(--header-height))"
              position="relative"
              ref={forgeViewContainerRef}
            >
              {showTooltipCreateTask && (
                <TooltipForgeView
                  propButton={{
                    rightIcon: (
                      <SvgIcon
                        cursor="pointer"
                        src="/img/icon-close.svg"
                        width="2.4rem"
                        height="2.4rem"
                        onClick={onHandleCloseCreateTask}
                      />
                    ),
                  }}
                  title={
                    isCreateSelfInspectionTask
                      ? "エリアの指摘追加モード"
                      : "指摘追加モード"
                  }
                />
              )}

              {renderForge}
              {renderForgeToolbar}
              {isOnline &&
                (isGenerating ||
                  (dataProjectDetail?.isDiffVersion &&
                    !levelSelected.guid)) && (
                  <Flex
                    position="absolute"
                    left={0}
                    top={0}
                    width="100%"
                    height="100%"
                    zIndex={2}
                    alignItems="center"
                    justifyContent="center"
                    backgroundColor="rgba(0,0,0,0.2)"
                    flexDirection="column"
                    gap="10px"
                  >
                    <Spinner
                      width="8rem"
                      height="8rem"
                      speed="1s"
                      thickness="1rem"
                      color="rgba(0,0,0,0.4)"
                    ></Spinner>
                    <Flex flexDir="column">
                      <Text
                        fontSize="3rem"
                        fontWeight="600"
                        color="rgba(0,0,0,0.7)"
                      >
                        Generating data...
                      </Text>
                      <StatusGenerateData
                        isLoading={isGeneratingSpaces}
                        textGenerate="Generate spaces"
                      />
                      <StatusGenerateData
                        isLoading={
                          isGeneratingFamilyInstances ||
                          !Object.values(familyInstances || {}).length
                        }
                        textGenerate="Generate family instances"
                      />
                      <StatusGenerateData
                        isLoading={isGeneratingAllFamilyInstance}
                        textGenerate="Generate data for inspection"
                      />

                      <StatusGenerateData
                        isLoading={isGeneratingView}
                        textGenerate={`Generate data for view(${totalViewGenerated}/${totalView})`}
                      />
                      <StatusGenerateData
                        isLoading={dataProjectDetail?.isDiffVersion}
                        textGenerate="Change Rvt file version"
                      />
                    </Flex>
                  </Flex>
                )}
            </Box>
          )}

          {canAccessForgeViewer && !isCanGenerateData && (
            <Box
              height="var(--app-height)"
              width="100%"
              bg="white"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Text mt="4rem" fontSize="1.8rem" color="#333">
                データを生成するには、PCを使用してください。
              </Text>

              <Button mt="4rem" variant="filled" onClick={handleRouteToHome}>
                ホームページへ
              </Button>
            </Box>
          )}

          {!canAccessForgeViewer && !isEmpty(currentUser) && (
            <Box
              height="var(--app-height)"
              width="100%"
              bg="white"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Text mt="4rem" fontSize="1.8rem" color="#333">
                ページにアクセスする権限がありません。
              </Text>

              <Button mt="4rem" variant="filled" onClick={handleRouteToHome}>
                ホームページへ
              </Button>
            </Box>
          )}
          <React.Suspense>
            {systemMode === SystemModeType.Task &&
              modalType === ModalType.TASK &&
              !isEmpty(taskSelected) && (
                <TaskModal
                  key={taskSelected?.id}
                  ref={taskModalRef}
                  leftPanelRef={leftPanelRef}
                  forgeViewContainerRef={forgeViewContainerRef}
                  contentHeight={contentHeight}
                  isOpen={true}
                  filterTasks={filterTasks}
                  task={taskSelected}
                  partnerCompanies={partnerCompanies}
                  isFetchingPartnerCompanies={isFetchingPartnerCompanies}
                  clickedLabelInfo={clickedLabelInfo}
                  imageCaptured={imageCaptured}
                  mapTaskType={mapTaskType}
                  clickInfo={clickInfo}
                  onClose={handleCloseTaskModal}
                  setClickInfo={setClickInfo}
                />
              )}
          </React.Suspense>
        </Flex>
        <React.Suspense>
          {isOpenDraw && (
            <DrawToolModal
              isOpen={isOpenDraw}
              onSelect={handleSaveFileDrawled}
              imageUrl={imageURL.current}
              onClose={handleCloseDrawToolModal}
              fileRef={imageCaptured}
              isViewerLoading={!isLoadedViewer}
              disabledReCapture={true}
            />
          )}
        </React.Suspense>
      </ForgeViewerContext.Provider>
    </Layout>
  );
}

export default React.memo(ForgeViewerPage);
