import isEmpty from "lodash/isEmpty";
import { Badge, BoxProps } from "@chakra-ui/react";
import { Attribute, Editor, Node, RawCommands } from "@tiptap/core";
import { Node as ModalNode } from "@tiptap/pm/model";
import {
  NodeViewContent,
  NodeViewWrapper,
  ReactNodeViewRenderer,
} from "@tiptap/react";
import { LINE_HEIGHT_DEFAULT } from "components/modal/PreviewDocumentCategory/hooks/useHandleTextOverflow";
import { NORMAL_TEXT_NODE_ERROR_CLASS } from "constants/styleProps";
import { CellType } from "interfaces/models/component";
import { DEFAULT_TEXT_COLOR } from "pages/document/template-page/hooks";
import { useEffect, useMemo, useRef, useState } from "react";
import { transformSizeForTextElement } from "utils/download-pdf";
import { isValidFloatAndIntNumber } from "utils/number";
import { CustomNodeComponentProps, NodeType, NodeTypeText } from "../type";
import { TextSelection } from "@tiptap/pm/state";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    text: {
      setStyle: (stype: Partial<CellType["style"]>) => ReturnType;
    };
  }
}

const commands: Partial<RawCommands> | undefined = {
  setStyle:
    (style: Partial<CellType["style"]>) =>
    ({ commands }) => {
      // Commands are functions that take a state and a an optional transaction dispatch function and
      // determine whether they apply to this state
      // if not, return false
      // if dispatch was passed, perform their effect, possibly by passing a transaction to dispatch
      // return true;
      commands.command(({ tr, state, dispatch }) => {
        if (!dispatch) {
          return false;
        }

        // filter valid node
        const nodesTypeText: ModalNode[] = [];
        tr.selection.ranges.forEach((range) => {
          state.doc.nodesBetween(range.$from.pos, range.$to.pos, (node) => {
            if (NodeTypeText.includes(node.type.name as any)) {
              nodesTypeText.push(node);
            }
          });
        });

        // update attributes
        nodesTypeText.forEach((node) => {
          commands.updateAttributes(node.type.name, {
            style: { ...node.attrs.style, ...style },
          });
        });

        return true;
      });

      return true;
    },
};

export interface NormalTextNodeAttrs {
  style?: CellType["style"];
  isMultiple?: boolean;
  isJustify?: boolean;
  height?: number;
  indent?: number;
  isSubCell?: boolean;
  isRequired?: boolean;
  isInt?: boolean;
  isFloat?: boolean;
  defaultText?: string;
}

export const NormalTextNode = Node.create({
  name: NodeType.NORMAL_TEXT,
  group: "block",
  content: `inline*`,
  isolating: true,
  allowGapCursor: false,

  addAttributes() {
    const attrs: {
      [key in keyof NormalTextNodeAttrs]: Attribute;
    } = {
      style: { default: {} },
      indent: { default: 0 },
      isSubCell: { default: false },
      isRequired: { default: false },
      isInt: { default: false },
      isFloat: { default: false },
      defaultText: { default: undefined },
    };

    return attrs;
  },
  parseHTML() {
    return [
      {
        tag: "normal-text",
      },
    ];
  },
  renderHTML() {
    return ["normal-text", {}, 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(Component);
  },

  addCommands() {
    return commands;
  },

  addKeyboardShortcuts() {
    // avoid move other node when move caret by arrow key
    const arrowByDir = (dir: "left" | "up" | "down" | "right") => {
      const view = this.editor.view;
      const isOutNode = view.endOfTextblock(dir, this.editor.state);

      return isOutNode;
    };

    return {
      "Ctrl-a": () => this.editor.commands.selectAllCurrentText(),
      "Mod-a": () => this.editor.commands.selectAllCurrentText(),
      ArrowLeft: () => arrowByDir("left"),
      ArrowDown: () => arrowByDir("down"),
      ArrowRight: () => arrowByDir("right"),
      ArrowUp: () => arrowByDir("up"),
    };
  },
});

export const MultipleNormalTextNode = Node.create({
  name: NodeType.MULTIPLE_NORMAL_TEXT,
  group: "block",
  content: `inline*`,

  addAttributes() {
    const attrs: {
      [key in keyof NormalTextNodeAttrs]: Attribute;
    } = {
      style: { default: {} },
      isMultiple: { default: true },
      isJustify: { default: false },
      indent: { default: 0 },
    };

    return attrs;
  },
  parseHTML() {
    return [
      {
        tag: "multiple-normal-text",
      },
    ];
  },
  renderHTML() {
    return ["multiple-normal-text", {}, 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(Component);
  },

  addCommands() {
    return commands;
  },
});

export const JustifyTextNode = Node.create({
  name: NodeType.JUSTIFY_TEXT,
  group: "block",
  content: `${NodeType.MULTIPLE_NORMAL_TEXT}*`,

  addAttributes() {
    const attrs: {
      [key in keyof NormalTextNodeAttrs]: Attribute;
    } = {
      style: { default: {} },
      isMultiple: { default: true },
      height: { default: undefined },
    };

    return attrs;
  },
  parseHTML() {
    return [
      {
        tag: "div",
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    const { height } = HTMLAttributes as NormalTextNodeAttrs;
    let style = "display: flex; justify-content: space-between; ";
    if (height) {
      style = `${style} height: ${height}px`;
    }

    return [
      "div",
      {
        style,
      },
      0,
    ];
  },
});

const Component = (props: CustomNodeComponentProps<NormalTextNodeAttrs>) => {
  const {
    style: _style,
    isMultiple = false,
    indent = 0,
    isRequired,
    isInt,
    isFloat,
  } = props.node.attrs;
  const { editor } = props;
  const nodeContent = props.node.content;
  const position = props.getPos();
  // const nodePos = editor.$pos(position);
  const parentNode = editor.view.state.doc.nodeAt(position - 1);
  // using parent style (eg: pinComponent node) if style is empty
  const style = isEmpty(_style) ? parentNode?.attrs?.style || {} : _style;

  // const lastTextRef = useRef<string>();
  const isNumber = isInt || isFloat;
  const alignItems = style?.alignItems || (isNumber ? "center" : "flex-start");
  const size = props.node.content.size;
  const text = (nodeContent as any).content?.[0]?.text;

  const editorAttributes = props.editor.options.editorProps?.attributes as any;
  const isPreview = editorAttributes?.isPreview === "true";

  // const [initShowComponent, setInitShowComponent] = useState(false);
  const [msgError, setMsgError] = useState("");

  const isInvalidContent = useMemo(() => {
    if (isRequired && !size) {
      setMsgError("ピン名を空白にすることはできません。");

      return true;
    }

    if ((isInt || isFloat) && text && !isValidFloatAndIntNumber(text)) {
      setMsgError("数値を入力してください。");

      return true;
    }

    return false;
  }, [isRequired, size, text, isInt, isFloat]);

  const sizeDefault = useMemo(() => {
    const fontSizeDefault = style?.fontSize || 14;

    return {
      fontSize: fontSizeDefault,
      lineHeight: fontSizeDefault * LINE_HEIGHT_DEFAULT,
    };
  }, [style?.fontSize]);

  const textDecoration = useMemo(() => {
    return style?.underline && style?.lineThrough
      ? "underline line-through"
      : style?.underline
      ? "underline"
      : style?.lineThrough
      ? "line-through"
      : "";
  }, [style?.lineThrough, style?.underline]);

  const nodeViewWrapperStyle = useMemo((): BoxProps["style"] => {
    return {
      position: isMultiple ? "relative" : "absolute",
      top: 0,
      left: 0,
      width: "100%",
      minHeight: "100%",
      cursor: "text",
      display: "flex",
      alignItems,
      padding: 0,
      border: !isPreview && isInvalidContent ? "1px solid red" : "none",
    };
  }, [isPreview, isInvalidContent, isMultiple, alignItems]);

  const nodeViewContentStyle = useMemo((): BoxProps["style"] => {
    return {
      textAlign: (style?.textAlign || "center") as any,
      minHeight: "1em",
      height: "fit-content",
      marginLeft: `${indent * 3}rem`,
      width: "100%",
      lineHeight: `${sizeDefault.lineHeight}px`,
      fontSize: `${sizeDefault.fontSize}px`,
      textDecoration,
    };
  }, [sizeDefault, textDecoration, indent, style?.textAlign]);

  // TODO: Temporarily hide feature auto reduce size if text is too long
  // const node = editor.view.nodeDOM(position);
  // useEffect(
  // () => {
  // // use state to check if element is visible on dom or not
  // if (!initShowComponent) {
  // setInitShowComponent(true);

  // return;
  // }

  // const element: HTMLDivElement | null = node as any;
  // const nodeViewContent = element?.querySelector(
  // "div[data-node-view-content]"
  // );
  // const elementCheckOverflow = nodeViewContent as any;
  // const elementContainsText: HTMLDivElement | null = nodeViewContent
  // ?.childNodes?.[0] as any;
  // const elementChangeStyle: HTMLDivElement | null = nodeViewContent as any;

  // if (
  // !element ||
  // !elementContainsText ||
  // !elementChangeStyle ||
  // !elementCheckOverflow
  // ) {
  // return;
  // }

  // const { text, shouldUpdate } = transformSizeForTextElement({
  // isShowMessage: false,
  // element,
  // elementCheckOverflow,
  // elementChangeStyle,
  // elementContainsText,
  // lastTextRef,
  // sizeDefault,
  // });

  // if (shouldUpdate) {
  // elementChangeStyle.style.color = "red";
  // elementContainsText.innerHTML = text;
  // lastTextRef.current = text;
  // } else {
  // elementChangeStyle.style.color = style?.color || DEFAULT_TEXT_COLOR;
  // }
  // },
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // [nodePos.content.toJSON(), style?.color, isJustify, initShowComponent]
  // );

  return (
    <NodeViewWrapper data-indent={indent} style={nodeViewWrapperStyle}>
      {isInvalidContent && !isPreview && (
        <Badge
          className={NORMAL_TEXT_NODE_ERROR_CLASS}
          position="absolute"
          top="-2rem"
          left="0px"
          colorScheme="red"
          cursor="pointer"
          zIndex={1}
          sx={{
            fontSize: "1rem !important",
            textTransform: "none !important",
          }}
        >
          {msgError}
        </Badge>
      )}
      <NodeViewContent
        data-indent={indent}
        data-type={NodeType.TEXT}
        style={nodeViewContentStyle}
      />
    </NodeViewWrapper>
  );
};
