import Focus from "@tiptap/extension-focus";
import Link from "@tiptap/extension-link";
import Underline from "@tiptap/extension-underline";
import StarterKit from "@tiptap/starter-kit";
import {
  Enter,
  FrameOriginNode,
  ImageNode,
  JustifyTextNode,
  KeynoteNode,
  LoadContent,
  MultipleNormalTextNode,
  NormalTextNode,
  PageWrapperNode,
  PinComponentNode,
  PinLayoutNode,
  Selection,
  TaskItem,
  TaskList,
  TaskOptNode,
  TextBoxNode,
  CustomDocumentNode,
  PageContainerNode,
  PageComponentFrameNode,
} from ".";

export const EditorExtensions = [
  CustomDocumentNode,
  LoadContent,
  Focus,
  Selection,
  Underline,
  Enter,
  Link.configure({
    openOnClick: false,
    autolink: true,
    defaultProtocol: "https",
    HTMLAttributes: {
      class: "tiptap-link",
    },
  }),
  StarterKit.configure({
    document: false,
  }),
  NormalTextNode,
  MultipleNormalTextNode,
  JustifyTextNode,
  TextBoxNode,
  TaskList,
  TaskItem,
  TaskOptNode,
  KeynoteNode,

  PageContainerNode,
  PageWrapperNode,
  FrameOriginNode,
  PinLayoutNode,
  PinComponentNode,
  PageComponentFrameNode,
  ImageNode,
];
