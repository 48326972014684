export const DEFAULT_SHEET_UNIT_SCALE = 0.001;
//TransformedVector = TranslationMatrix * RotationMatrix * ScaleMatrix * OriginalVector;

const readMatrixFromArray12 = (params: any) => {
  return (new THREE.Matrix4() as any).fromArray([
    params[0],
    params[1],
    params[2],
    0.0,
    params[3],
    params[4],
    params[5],
    0.0,
    params[6],
    params[7],
    params[8],
    0.0,
    params[9],
    params[10],
    params[11],
    1.0,
  ]);
};

const getViewportBounds = (vp: any, sheetUnitScale: number) => {
  // viewport region in foot as array (6 floats)
  const values = vp.geometryViewportRegion;
  if (!values) {
    return null;
  }
  // Convert from foot to sheet units (usually inches)
  const scale = getFeetToSheetUnits(sheetUnitScale);
  // The viewport region returned by Revit is incorrect. It's enlarged by an offset of 0.01 ft.
  // We remove that offset before scaling.
  // See https://thebuildingcoder.typepad.com/blog/2010/09/view-location-on-sheet.html (search for 0.01 in that page)
  const boundsCorrection = 0.01;

  const res = new THREE.Box2();
  res.min.x = (values[0] + boundsCorrection) * scale;
  res.min.y = (values[1] + boundsCorrection) * scale;
  res.max.x = (values[3] - boundsCorrection) * scale;
  res.max.y = (values[4] - boundsCorrection) * scale;

  return res;
};

export const getFeetToSheetUnits = (sheetUnitScale: number) => {
  const FeetToMeter = 0.3048;
  const MeterToSheetUnits = 1.0 / sheetUnitScale;

  return FeetToMeter * MeterToSheetUnits;
};

const get3DTo2DModelSheetTransform = (vp: any, sheetUnitScale: number) => {
  if (!vp.modelToSheetTransform) {
    return null;
  }

  const values = vp.modelToSheetTransform;
  const matrix = readMatrixFromArray12(values);
  const feetToSheetUnits = getFeetToSheetUnits(sheetUnitScale);
  // apply post-scaling from feet to sheet-units
  // Note that using multiplyScalar() here is only similar, but not the same: E.g., it would also multiply component 15
  // which may cause weird side-effects when multiplying with other matrices.
  // The Z value of the scale is set to feetToSheetUnits as well so that getMaxScaleOnAxis
  // returns a meaningful result (used to be 1, which would skew the results)
  const scaleTf = new THREE.Matrix4().makeScale(
    feetToSheetUnits,
    feetToSheetUnits,
    feetToSheetUnits
  );
  matrix.multiplyMatrices(scaleTf, matrix);

  return matrix;
};

// Computes a Matrix4 that maps the 'from' rectangle to the 'to' rectangle in xy.
const remapRectangle = (
  xMinFrom: number,
  yMinFrom: number,
  xMaxFrom: number,
  yMaxFrom: number,
  xMinTo: number,
  yMinTo: number,
  xMaxTo: number,
  yMaxTo: number
) => {
  const scaleX = (xMaxTo - xMinTo) / (xMaxFrom - xMinFrom);
  const scaleY = (yMaxTo - yMinTo) / (yMaxFrom - yMinFrom);
  // Note that the translation component of the matrix works on the scaled values.
  // The scaling alone would map xMinFrom to scaleX * xMinFrom. We want
  // to map it to xMinTo instead. (analog for y)

  const offsetX = xMinTo - scaleX * xMinFrom;
  const offsetY = yMinTo - scaleY * yMinFrom;
  const matrix = new THREE.Matrix4();
  matrix.elements[0] = scaleX;
  matrix.elements[5] = scaleY;
  // Set the Z scale to be the same as X scale. This is similar to what we do at the end of get3DTo2DModelSheetTransform.
  // In general, for a transform that comes from Revit the scale should be uniform in all directions, otherwise the sheet
  // would be distorted.
  matrix.elements[10] = scaleX;
  matrix.elements[12] = offsetX;
  matrix.elements[13] = offsetY;

  return matrix;
};

// Handle available view rotation modes in Revit
// If a view was rotated in Revit, this swapped/inverted some viewport axes. We must revert this modification when transforming back to world coords.
// This function returns a Matrix4 that performs this rotation on an input vector p in normalized viewport coords (in [0,1])
const getInverseViewportRotation = (viewRotationType: number) => {
  const matrix = new THREE.Matrix4();
  switch (viewRotationType) {
    // No rotation => done
    case 0:
      return matrix;
    // Revit view was rotated by 90 degrees clockwise => Rotate by 90 degrees ccw
    case 1: {
      // Note that a rotation would actually mean result.x = -y. But, we just revert the effect the rotation effect on the
      // viewport axes here. I.e., input and output are in [0,1]. For this, swapping an axis means taking 1.0-value, not just the negative.
      // Therefore, the desired effect of the matrix is:
      //
      //  (x, y) => (1-y, x)
      //
      // Note the memory layout of THREE matrices is column-major.

      // Matrix4(
      //   scaleX, skewY,   0,   0, // skewY could also be tan(ySkewAngle)
      //   skewX,  scaleY,  0,   0, // skewX could also be tan(xSkewAngle)
      //   0,      0,       1,   0, //
      //   translateX, translateY, 0, 1,
      // )

      // out.x = (1-y)
      matrix.elements[0] = 0; //  0 * in.x
      matrix.elements[4] = -1; // -1 * in.y
      matrix.elements[12] = 1; // + 1
      // out.y = x
      matrix.elements[1] = 1; // 1 * in.x
      matrix.elements[5] = 0; // 0 * in.y
      break;
    }
    // Revit view was rotated 90 degrees counterclockwise => Rotate by 90 degrees cw
    case 2: {
      // Desired transform here is:
      //
      // (x, y) => (y, 1-x)
      // out.x = y
      matrix.elements[0] = 0; // 0 * in.x
      matrix.elements[4] = 1; // 1 * in.y        matrix.elements[0] = 0; // 0 * in.x
      // out.y = (1-x)
      matrix.elements[1] = -1; //    -in.x
      matrix.elements[5] = 0; // 0 * in.y
      matrix.elements[13] = 1; // + 1

      break;
    }
    default:
      /* eslint-disable-next-line no-console */
      console.warn(
        "Unexpected enum value for view rotation: ",
        viewRotationType
      );
  }

  return matrix;
};

export const compute2Dto3DTransform = (vp: any, sheetUnitScale: number) => {
  // Viewport bbox on sheet (Box2)
  const sheetRegion = getViewportBounds(vp, sheetUnitScale);
  if (!sheetRegion) {
    return;
  }

  // SectionBox (Box3 + Matrix4): Oriented box in 3D world coords. Represents the volume
  // that is mapped to the sheet.
  const sectionBox = vp.sectionBox;
  const sectionBoxTransform = readMatrixFromArray12(sectionBox.transform);
  //matrix transform from 2d to 3d world 3d coords
  // // ...from normalized viewport coords ...to the xy-extent of the untransformed SectionBox
  const viewportToSectionBox = remapRectangle(
    0,
    0,
    1,
    1,
    sectionBox.min.x,
    sectionBox.min.y,
    sectionBox.max.x,
    sectionBox.max.y
  );

  // Consider Revit viewport rotation. Result keeps within viewport coords ([0,1]^2)
  const vpRotationInv = getInverseViewportRotation(vp.viewportRotation);

  // ...from viewport rectangle in sheet coordinates  // ...to [0,1]
  const sheetToViewport = remapRectangle(
    sheetRegion.min.x,
    sheetRegion.min.y,
    sheetRegion.max.x,
    sheetRegion.max.y,
    0,
    0,
    1,
    1
  );

  // Finally, apply the sectionBoxTransform to obtain world coords. Note that matrix products must be done in reverse order.
  const matrix = sectionBoxTransform
    .multiply(viewportToSectionBox)
    .multiply(vpRotationInv)
    .multiply(sheetToViewport);

  return matrix;
};

export const supports2DTo3DTransform = (vp: any) => {
  // If AECModelData contains a modelToSheetTransform (only possible for models produced using latest RevitAPI changes), we get a matrix directly
  // and don't have to care for sectionBox or isCropBoxActive anymore.
  const canUseTransform = Boolean(vp.modelToSheetTransform);

  // Check if we can use the old code path to reverse-engineer the transform from the SectionBox
  const canUseWorkaround = Boolean(vp.sectionBox && vp.isCropBoxActive);

  // If neither of the two work, we can stop here.
  if (!canUseTransform && !canUseWorkaround) {
    return false;
  }
  const TopViewViewportTypes = ["FloorPlan", "CeilingPlan", "EngineeringPlan"];

  const supportedViewportTypes = [...TopViewViewportTypes, "Section"];

  return Boolean(
    supportedViewportTypes.includes(vp.viewType) &&
      vp.geometryViewportRegion && // We need the viewport outline that corresponds 1:1 with the view sectionBox (excluding labels etc.)
      !vp.hasBreaks && // A view in Revit may be split into separate parts using "View breaks". This is not supported yet.
      !vp.extensions.hasRegions
  ); // The 2D/3D transform may actually vary within a single view. We currently don't get the required data to support this.
};

export const get3DTo2DMatrix = (vp: any, sheetUnitScale: number) => {
  let matrix = get3DTo2DModelSheetTransform(vp, sheetUnitScale);

  if (matrix) {
    return matrix;
  }
  if (!supports2DTo3DTransform(vp)) {
    return;
  }
  matrix = compute2Dto3DTransform(vp, sheetUnitScale);

  return matrix?.invert();
};
