import { Icon, IconProps } from "@chakra-ui/icons";

const ImageIcon = (props: IconProps) => {
  return (
    <Icon width="16" height="16" viewBox="0 0 16 16" fill="inherit" {...props}>
      <path
        d="M1.86414 15.7916C1.40107 15.7916 1.00911 15.6312 0.688281 15.3103C0.367448 14.9895 0.207031 14.5976 0.207031 14.1345V1.86536C0.207031 1.40229 0.367448 1.01034 0.688281 0.689502C1.00911 0.368669 1.40107 0.208252 1.86414 0.208252H14.1333C14.5963 0.208252 14.9883 0.368669 15.3091 0.689502C15.6299 1.01034 15.7904 1.40229 15.7904 1.86536V14.1345C15.7904 14.5976 15.6299 14.9895 15.3091 15.3103C14.9883 15.6312 14.5963 15.7916 14.1333 15.7916H1.86414ZM1.86414 14.4166H14.1333C14.2038 14.4166 14.2685 14.3872 14.3271 14.3284C14.386 14.2697 14.4154 14.2051 14.4154 14.1345V1.86536C14.4154 1.79477 14.386 1.73015 14.3271 1.67148C14.2685 1.61266 14.2038 1.58325 14.1333 1.58325H1.86414C1.79355 1.58325 1.72893 1.61266 1.67026 1.67148C1.61144 1.73015 1.58203 1.79477 1.58203 1.86536V14.1345C1.58203 14.2051 1.61144 14.2697 1.67026 14.3284C1.72893 14.3872 1.79355 14.4166 1.86414 14.4166ZM4.01486 12.3541H12.0531C12.2189 12.3541 12.3417 12.2788 12.4216 12.1284C12.5015 11.978 12.4891 11.8323 12.3845 11.6913L10.1987 8.76327C10.1128 8.65281 10.0024 8.59758 9.86732 8.59758C9.73211 8.59758 9.62166 8.65281 9.53595 8.76327L7.29355 11.6842L5.79159 9.75923C5.70589 9.65458 5.59688 9.60225 5.46457 9.60225C5.33242 9.60225 5.22341 9.65756 5.13755 9.76817L3.6922 11.6913C3.58174 11.8323 3.56646 11.978 3.64636 12.1284C3.72627 12.2788 3.8491 12.3541 4.01486 12.3541ZM4.79036 5.93742C5.10768 5.93742 5.37795 5.82574 5.60116 5.60238C5.82452 5.37917 5.9362 5.1089 5.9362 4.79158C5.9362 4.47427 5.82452 4.204 5.60116 3.98079C5.37795 3.75743 5.10768 3.64575 4.79036 3.64575C4.47304 3.64575 4.20278 3.75743 3.97957 3.98079C3.75621 4.204 3.64453 4.47427 3.64453 4.79158C3.64453 5.1089 3.75621 5.37917 3.97957 5.60238C4.20278 5.82574 4.47304 5.93742 4.79036 5.93742Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export default ImageIcon;
