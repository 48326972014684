import { createContext, useContext, useMemo, useReducer } from "react";
import { Item, Partial } from "../type";
import LayoutFrameBuilder, {
  LayoutFrameBuilderProps,
} from "../containers/LayoutFrameBuilder";
import {
  initialLayoutFrameBuilder,
  LayoutFrameBuilderActions,
  layoutFrameBuilderReducer,
} from "./layoutFrameBuilderReducer";

interface LayoutFrameBuilderContextState
  extends LayoutFrameBuilderProviderProps {
  zoom: number;
  itemSelected: Item | null;
}

const LayoutFrameBuilderContext = createContext<LayoutFrameBuilderContextState>(
  {} as any
);

interface LayoutFrameBuilderProviderProps extends LayoutFrameBuilderProps {
  partials: Record<string, Record<string, Record<string, Partial>>>;
  isLoading?: boolean;
  items: Item[];
  itemSelectedId: string | null;
  onCreate(item: Item): void;
  onChange(item: Item): void;
  onDelete(id: string): void;
  onSelect(id: null | string): void;
}
const LayoutFrameBuilderDispatchContext = createContext(
  (() => {}) as React.Dispatch<LayoutFrameBuilderActions>
);

export const LayoutFrameBuilderProvider: React.FC<
  LayoutFrameBuilderProviderProps
> = ({
  partials,
  items,
  onCreate,
  onDelete,
  onChange,
  onSelect,
  itemSelectedId,
  ...other
}) => {
  const [state, dispatch] = useReducer(
    layoutFrameBuilderReducer,
    initialLayoutFrameBuilder
  );

  const itemSelected = useMemo(() => {
    return itemSelectedId
      ? items.find((item) => item.id === itemSelectedId) || null
      : null;
  }, [itemSelectedId, items]);

  return (
    <LayoutFrameBuilderContext.Provider
      value={{
        ...state,
        partials,
        items,
        onCreate,
        onDelete,
        onChange,
        onSelect,
        itemSelectedId,
        itemSelected,
      }}
    >
      <LayoutFrameBuilderDispatchContext.Provider value={dispatch}>
        <LayoutFrameBuilder {...other} />
      </LayoutFrameBuilderDispatchContext.Provider>
    </LayoutFrameBuilderContext.Provider>
  );
};

export const useLayoutFrameBuilderContext = () => {
  return useContext(LayoutFrameBuilderContext);
};

export const useLayoutFrameBuilderDispatchContext = () => {
  return useContext(LayoutFrameBuilderDispatchContext);
};
