import { RadioGroup, Flex, Radio } from "@chakra-ui/react";
import { memo, Fragment, useMemo } from "react";
import { FieldRawProps } from "../../types";

const CheckboxRaw = (props: FieldRawProps) => {
  const {
    containerRef,
    item,
    linkedData,
    style: pinComponentStyle,
    ...boxProps
  } = props;

  const additionInfoCheckbox = Object.values(
    item?.additionInfo?.checkbox?.mapValues || {}
  );
  const extraId = linkedData?.extraId;

  const textAlign = pinComponentStyle?.textAlign;
  const alignItems = useMemo(
    () =>
      !textAlign || textAlign === "left"
        ? "flex-start"
        : textAlign === "right"
        ? "flex-end"
        : "center",
    [textAlign]
  );

  if (isNaN(Number(extraId))) {
    return <></>;
  }

  return (
    <RadioGroup
      display="flex"
      alignItems={pinComponentStyle.alignItems || "center"}
      justifyContent={pinComponentStyle?.textAlign || "left"}
      {...(boxProps as any)}
      height="100%"
      sx={{
        ".chakra-radio__control": {
          borderRadius: 0,
          position: "relative",
          borderColor: "#A3A3A3",
          width: `${pinComponentStyle.fontSize}px`,
          height: `${pinComponentStyle.fontSize}px`,
          "&[data-checked]": {
            borderColor: "transparent",
            "&:before": {
              position: "absolute",
              left: 0,
              top: 0,
              content: "'\\2705'",
              backgroundColor: "transparent",
              width: "100%",
              height: "100%",
              fontSize: `${pinComponentStyle.fontSize}px`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
          },
        },
        ".chakra-radio__label": {
          ...pinComponentStyle,
          whiteSpace: "nowrap",
          width: `calc(100% - ${pinComponentStyle.fontSize}px)`,
          fontSize: `${pinComponentStyle.fontSize}px`,
        },
      }}
    >
      <Flex
        width="100%"
        flexDirection="column"
        gap="1rem"
        alignItems={alignItems}
      >
        {additionInfoCheckbox.map((data, index) => {
          if (index !== extraId) {
            return <Fragment key={index} />;
          }

          return (
            <Radio
              isReadOnly={true}
              width="fit-content"
              maxWidth="100%"
              key={index}
              value={String(index)}
            >
              {data.displayValue}
            </Radio>
          );
        })}
      </Flex>
    </RadioGroup>
  );
};

export default memo(CheckboxRaw);
