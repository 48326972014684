import {
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  useBoolean,
} from "@chakra-ui/react";
import { IconBase } from "components/base";
import OfflineModeTutorialModal from "components/modal/OfflineModeTutorialModal";
import { memo, useEffect, useMemo } from "react";
import { DATE_TIME_SLASH_WITHOUT_SECONDS_FORMAT, formatDate } from "utils/date";

let timeoutShowWarningToSyncData: NodeJS.Timeout;

interface Props {
  isOnline: boolean;
  actualNetworkStatus: boolean;
  cachingBimFileId: string;
  syncDataTime: Date | null;
  progress: number | undefined;
  isCaching: boolean;
  bimfileId: string;
  isPageForgeViewer?: boolean;

  stopCacheProject: () => void;
  syncNewProjectData: () => Promise<void>;
}

const SyncDataForOfflineIcon = (props: Props) => {
  const {
    isCaching,
    progress: _progress = 0,
    syncDataTime,
    actualNetworkStatus,
    cachingBimFileId,
    isOnline,
    bimfileId,
    stopCacheProject,
    syncNewProjectData,
  } = props;

  const [isOpenOfflineTutorial, setIsOpenOfflineTutorial] = useBoolean(false);
  const [isShowWarningSyncData, setIsShowWarningSyncData] = useBoolean();
  const [isSyncData, setIsSyncData] = useBoolean();

  const progress = isNaN(_progress) ? 0 : _progress;

  useEffect(() => {
    if (!syncDataTime) {
      setIsShowWarningSyncData.off();

      return;
    }

    clearTimeout(timeoutShowWarningToSyncData);
    const localTime = new Date(syncDataTime).getTime();
    const now = new Date().getTime();
    const timeout = localTime + 60 * 60 * 24 * 1_000 - now;
    if (timeout < 0) {
      setIsShowWarningSyncData.on();
    } else {
      timeoutShowWarningToSyncData = setTimeout(() => {
        setIsShowWarningSyncData.on();
      }, Math.floor(timeout));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncDataTime]);

  const isDisabled = useMemo(() => {
    return (
      (!!cachingBimFileId && cachingBimFileId !== bimfileId) ||
      !actualNetworkStatus ||
      !isOnline ||
      isSyncData
    );
  }, [bimfileId, actualNetworkStatus, cachingBimFileId, isSyncData, isOnline]);

  const color = useMemo(() => {
    if (isCaching) {
      return "var(--yellow)";
    }

    return "var(--gray)";
  }, [isCaching]);

  const iconDownloadProject = useMemo(
    () => (
      <Flex flexDirection="column" alignItems="center">
        <CircularProgress
          sx={{
            "& > svg": {
              width: "2.8rem",
              height: "2.8rem",
              fontSize: "2.8rem",
            },
          }}
          color={color}
          value={isCaching || progress !== 100 ? progress || 0 : 0}
        >
          <CircularProgressLabel
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {isCaching ? (
              <Tooltip placement="left" label={`${progress}%`}>
                <Image
                  src="/img/icon-pause.svg"
                  width="1.9rem"
                  height="1.9rem"
                />
              </Tooltip>
            ) : (
              <IconBase
                icon="/img/icon-download.svg"
                width="2.3rem"
                height="2.3rem"
                opacity={!isDisabled ? 1 : 0.5}
                color="#171717"
              />
            )}
          </CircularProgressLabel>
        </CircularProgress>

        {(isCaching || (progress > 0 && progress < 100)) &&
          !isNaN(progress) && (
            <Text fontSize="1.2rem" color={color} lineHeight={1}>
              {`${progress}%`}
            </Text>
          )}
      </Flex>
    ),
    [isDisabled, progress, isCaching, color]
  );

  const handleSyncData = async () => {
    setIsSyncData.on();
    await syncNewProjectData();
    setIsSyncData.off();
  };

  const handleClickMenuDownloadProject = (onCloseMenu: () => void) => {
    onCloseMenu();

    if (isCaching) {
      stopCacheProject();
    } else {
      handleSyncData();
    }
  };

  return (
    <>
      <Menu autoSelect={false} closeOnSelect={false}>
        {({ isOpen, onClose }) => (
          <>
            <MenuButton
              isActive={isOpen}
              as={Button}
              aria-label="Options"
              isDisabled={isDisabled}
              leftIcon={iconDownloadProject}
              border="none"
              background="transparent !important"
              boxShadow="unset !important"
              fontSize="1.2rem"
              color="font.gray"
              height="auto"
              variant="text"
              whiteSpace={{ base: "normal", xl: "nowrap" }}
              rightIcon={
                <IconBase
                  icon="/img/arrow-right3.svg"
                  transition="0.4s"
                  color="#737373"
                  transform={isOpen ? "rotate(270deg)" : "rotate(90deg)"}
                />
              }
              lineHeight="1.8rem"
              textAlign="left"
            >
              <Text>オフラインモード使用前準備 </Text>
              <Text ml={syncDataTime ? 0 : "-0.7rem"}>
                {syncDataTime
                  ? `最終DL ${formatDate(
                      syncDataTime,
                      DATE_TIME_SLASH_WITHOUT_SECONDS_FORMAT
                    )}`
                  : "（事前にダウンロードが必要です）"}
              </Text>

              {syncDataTime && (
                <Text color={isShowWarningSyncData ? "font.danger" : "#D97706"}>
                  {isShowWarningSyncData
                    ? "最新データ再ダウンロード推奨"
                    : "※出来るだけ最新のデータを活用推奨"}
                </Text>
              )}
            </MenuButton>

            <MenuList
              maxWidth="25rem"
              minWidth="25rem"
              padding="0px"
              margin="0px"
              background="#fff"
              borderRadius="6px"
              whiteSpace={"normal"}
              boxShadow="0px 1px 3px 0px #0000004D, 0px 4px 8px 3px #00000026"
            >
              <MenuItem
                p="0.4rem 1.2rem"
                fontSize="1.2rem"
                color="font.gray"
                _hover={{ bgColor: "#F0F9FF !important" }}
                onClick={() => handleClickMenuDownloadProject(onClose)}
                icon={iconDownloadProject}
                lineHeight="1.8rem"
                textAlign="left"
              >
                <Text>最新データをダウンロード</Text>
                <Text ml="-7px">（所要時間： 数分〜数十分）</Text>
              </MenuItem>

              <MenuItem
                p="0.4rem 1.2rem"
                fontSize="1.2rem"
                color="font.gray"
                _hover={{ bgColor: "#F0F9FF !important" }}
                onClick={setIsOpenOfflineTutorial.on}
                icon={
                  <IconBase
                    icon="/img/icon-support.svg"
                    width="2.8rem"
                    height="2.8rem"
                    color="#171717"
                  />
                }
                lineHeight="1.8rem"
                textAlign="left"
              >
                <Text>オフラインモードの</Text>
                <Text>使い方・注意点</Text>
              </MenuItem>
            </MenuList>
          </>
        )}
      </Menu>

      {isOpenOfflineTutorial && (
        <OfflineModeTutorialModal
          isOpen={isOpenOfflineTutorial}
          onClose={setIsOpenOfflineTutorial.off}
        />
      )}
    </>
  );
};

export default memo(SyncDataForOfflineIcon);
