import { ActionMap } from "interfaces/models/context";
import {
  DocumentFormat,
  DocumentFormatSettingStatus,
} from "interfaces/models/documentFormat";
import { DocumentPageLayout } from "interfaces/models/documentPageLayout";
import { InspectionType } from "interfaces/models/inspectionType";
import { PageComponent } from "interfaces/models/pageComponent";
import { PinLayout } from "interfaces/models/pinLayout";

export enum ReadFrameDisplayResultMode {
  UploadFile = 1,
  SelectArea = 2,
}

export type InspectionFormat = {
  inspectionType?: InspectionType;
  activeTab: InspectionFormatTab;
  fileUrl?: string;
  frameUrl?: string;
  readFrameDisplayResultMode?: ReadFrameDisplayResultMode;
  selectedDocumentPageLayout?: DocumentPageLayout;
  pageLayouts: DocumentPageLayout[];
  selectedPinLayouts: PinLayout[];
  activeMode: InspectionFormatMode;
  documentLayout: DocumentFormat | undefined;
  isLoadedPagePreview: boolean;
  imageSrc: string | null;
  currentPageIdx: number | null;
  disabledUpload: boolean;
  pageComponents: PageComponent[];
};

export enum InspectionFormatTab {
  ReadDocument,
  SettingRepeatPin,
  SettingComponentOnPinLayout,
  SettingCommonInfo,
  SettingFontSize,
}

export enum InspectionFormatMode {
  FlowSettingDocAndPin,
  SettingRepeatForPin,
  SettingFontSize,
  SettingComponentOnPinLayout,
  SettingCommonInfo,
}

// Define the initial state
export const initialInspectionFormat: InspectionFormat = {
  inspectionType: undefined,
  fileUrl: undefined,
  frameUrl: undefined,
  selectedDocumentPageLayout: undefined,
  isLoadedPagePreview: false,
  pageLayouts: [{ pageOrder: new Date().getTime() } as any],
  selectedPinLayouts: [],
  documentLayout: {} as any,
  readFrameDisplayResultMode: ReadFrameDisplayResultMode.UploadFile,
  activeTab: InspectionFormatTab.ReadDocument,
  activeMode: InspectionFormatMode.FlowSettingDocAndPin,
  imageSrc: null,
  currentPageIdx: null,
  disabledUpload: false,
  pageComponents: [],
};

export enum Types {
  SET_INSPECTION_TYPE = "SET_INSPECTION_TYPE",
  UPDATE_PARENT_TAB = "UPDATE_PARENT_TAB",
  UPDATE_CHILD_TAB = "UPDATE_CHILD_TAB",
  UPDATE_FILE_URL = "UPDATE_FILE_URL",
  UPDATE_FRAME_URL = "UPDATE_FRAME_URL",
  UPDATE_READ_FRAME_DISPLAY_RESULT_MODE = "UPDATE_READ_FRAME_DISPLAY_RESULT_MODE",
  UPDATE_SELECTED_DOCUMENT_PAGE_LAYOUT = "UPDATE_SELECTED_DOCUMENT_PAGE_LAYOUT",
  UPDATE_DOCUMENT_PAGE_LAYOUTS = "UPDATE_DOCUMENT_PAGE_LAYOUTS",
  UPDATE_SELECTED_PIN_LAYOUTS = "UPDATE_SELECTED_PIN_LAYOUTS",
  UPDATE_ACTIVE_TAB = "UPDATE_ACTIVE_TAB",
  ADD_DOCUMENT_PAGE_LAYOUT = "ADD_DOCUMENT_PAGE_LAYOUT",
  UPDATE_ACTIVE_MODE = "UPDATE_ACTIVE_MODE",
  SET_DOCUMENT_LAYOUT = "UPDATE_DOCUMENT_LAYOUT",
  UPDATE_IS_LOADED_PREVIEW_DOC_PAGE_LAYOUT = "UPDATE_IS_LOADED_PREVIEW_DOC_PAGE_LAYOUT",
  COMBINE_STATE = "COMBINE_STATE",
  SET_DISABLE_UPLOAD = "SET_DISABLE_UPLOAD",
  SET_DOCUMENT_LAYOUT_SETTING_STATUS = "SET_DOCUMENT_LAYOUT_SETTING_STATUS",
}

type InspectionFormatPayload = {
  [Types.SET_INSPECTION_TYPE]: InspectionType | undefined;
  [Types.UPDATE_FILE_URL]: string | undefined;
  [Types.UPDATE_FRAME_URL]: string | undefined;
  [Types.UPDATE_READ_FRAME_DISPLAY_RESULT_MODE]: ReadFrameDisplayResultMode;
  [Types.UPDATE_SELECTED_DOCUMENT_PAGE_LAYOUT]: DocumentPageLayout;
  [Types.UPDATE_DOCUMENT_PAGE_LAYOUTS]: DocumentPageLayout[];
  [Types.UPDATE_SELECTED_PIN_LAYOUTS]: PinLayout[];
  [Types.UPDATE_ACTIVE_TAB]: InspectionFormatTab;
  [Types.ADD_DOCUMENT_PAGE_LAYOUT]: DocumentPageLayout;
  [Types.UPDATE_ACTIVE_MODE]: InspectionFormatMode;
  [Types.SET_DOCUMENT_LAYOUT]: { id?: string };
  [Types.UPDATE_IS_LOADED_PREVIEW_DOC_PAGE_LAYOUT]: boolean;
  [Types.COMBINE_STATE]: Partial<InspectionFormat>;
  [Types.SET_DISABLE_UPLOAD]: boolean;
  [Types.SET_DOCUMENT_LAYOUT_SETTING_STATUS]: DocumentFormatSettingStatus;
};

export type InspectionFormatActions =
  ActionMap<InspectionFormatPayload>[keyof ActionMap<InspectionFormatPayload>];

// Define the reducer function
const InspectionFormatReducer = (
  state: InspectionFormat,
  action: InspectionFormatActions
): InspectionFormat => {
  switch (action.type) {
    case Types.SET_INSPECTION_TYPE: {
      return {
        ...state,
        inspectionType: action.payload,
      };
    }
    case Types.UPDATE_FILE_URL: {
      return {
        ...state,
        fileUrl: action.payload,
      };
    }
    case Types.UPDATE_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: action.payload,
      };
    }
    case Types.UPDATE_FRAME_URL: {
      return {
        ...state,
        frameUrl: action.payload,
      };
    }
    case Types.UPDATE_READ_FRAME_DISPLAY_RESULT_MODE: {
      return {
        ...state,
        readFrameDisplayResultMode: action.payload,
      };
    }
    case Types.UPDATE_SELECTED_DOCUMENT_PAGE_LAYOUT: {
      return {
        ...state,
        selectedDocumentPageLayout: action.payload,
      };
    }
    case Types.UPDATE_SELECTED_PIN_LAYOUTS: {
      return {
        ...state,
        selectedPinLayouts: action.payload,
      };
    }
    case Types.UPDATE_IS_LOADED_PREVIEW_DOC_PAGE_LAYOUT: {
      return {
        ...state,
        isLoadedPagePreview: action.payload,
      };
    }
    case Types.ADD_DOCUMENT_PAGE_LAYOUT: {
      return {
        ...state,
        pageLayouts: [...state.pageLayouts, action.payload],
      };
    }
    case Types.UPDATE_ACTIVE_MODE: {
      return {
        ...state,
        activeMode: action.payload,
      };
    }
    case Types.UPDATE_DOCUMENT_PAGE_LAYOUTS: {
      const settingStatus = state.documentLayout?.settingStatus ?? {};
      settingStatus.pageLayout = true;

      return {
        ...state,
        pageLayouts: action.payload,
        documentLayout: { ...state.documentLayout, settingStatus } as any,
      };
    }
    case Types.SET_DOCUMENT_LAYOUT: {
      return {
        ...state,
        documentLayout: action.payload as any,
      };
    }
    case Types.COMBINE_STATE: {
      return { ...state, ...action.payload };
    }
    case Types.SET_DISABLE_UPLOAD: {
      return { ...state, disabledUpload: action.payload };
    }
    case Types.SET_DOCUMENT_LAYOUT_SETTING_STATUS: {
      let settingStatus = state.documentLayout?.settingStatus ?? {};
      settingStatus = { ...settingStatus, ...action.payload };

      return {
        ...state,
        documentLayout: { ...state.documentLayout, settingStatus } as any,
      };
    }
    default:
      return state;
  }
};

export { InspectionFormatReducer };
