import { ActionMap } from "interfaces/models/context";

export enum Types {
  SET_FIELD_VALUE = "SET_FIELD_VALUE",
  SET_FIELD_ATTRIBUTES = "SET_FIELD_ATTRIBUTES",
  INIT_VALUE = "INIT_VALUE",
}

export type FormStatePayload = {
  [Types.INIT_VALUE]: Record<string, any>;
  [Types.SET_FIELD_VALUE]: { itemResultId: string; value: any };
  [Types.SET_FIELD_ATTRIBUTES]: Record<string, any>;
};

export type FormStateActions =
  ActionMap<FormStatePayload>[keyof ActionMap<FormStatePayload>];

export type FormStateState = {
  formValue: any;
  isDisabled?: boolean;
  fields: Record<string, FieldState>;
  pinId: string | null;
};

export interface FormStateContextType extends FormStateState {
  isReadOnly?: boolean;
  dispatch: React.Dispatch<FormStateActions>;
  saveFieldValue(itemResultId: string, value: any): Promise<any>;
}

export interface FieldState {
  isLoading?: boolean;
  isInvalid?: boolean;
}
