import DrawToolModal from "./DrawToolModal";
import useDrawImage from "./useDrawImage";
import { FileModel } from "interfaces/models";

interface DrawToolModalContainerProps {
  isOpen?: boolean;
  image?: any;
  imageCaptured?: any;
  setImage(image?: FileModel): void;
  handleOpenCamera(): void;
  onClose(): void;
}

const DrawToolModalContainer: React.FC<DrawToolModalContainerProps> = ({
  image,
  isOpen = false,
  setImage,
  handleOpenCamera,
  onClose,
  imageCaptured,
}) => {
  const {
    handleSelectDrawImage,
    handleRecaptureImage,
    isRecaptureImageRef,
    isDrawing,
  } = useDrawImage({
    setImage,
    handleOpenCamera,
    image,
  });

  return (
    <DrawToolModal
      isOpen={isOpen}
      fileRef={imageCaptured}
      imageUrl={imageCaptured.current ? "" : image?.src || ""}
      onSelect={handleSelectDrawImage}
      onClose={() => {
        isRecaptureImageRef.current = false;
        onClose();
      }}
      onCloseAfterSave={() => {
        imageCaptured.current = undefined;
        onClose();
      }}
      reCapture={handleRecaptureImage}
      isSaving={isDrawing}
    />
  );
};

export default DrawToolModalContainer;
