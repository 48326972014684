import { Tooltip, TooltipProps } from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import { useDeviceSelectors } from "react-device-detect";

type Props = PropsWithChildren<TooltipProps>;

const PCTooltip = ({ children, ...props }: Props) => {
  const [{ isMobile }] = useDeviceSelectors(window.navigator.userAgent);

  if (isMobile) {
    return <>{children}</>;
  }

  return <Tooltip {...props}>{children}</Tooltip>;
};

export { PCTooltip };
