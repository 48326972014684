import { Box } from "@chakra-ui/react";

interface RightSidebarProps {
  children: any;
}

const RightSidebar: React.FC<RightSidebarProps> = ({ children }) => {
  return (
    <>
      <Box flexGrow={1} pointerEvents={"none"} bg="transparent" />
      <Box
        flex={{ base: `0 0 300px`, xl: `0 0 400px` }}
        w={{ base: "300px", xl: "400px" }}
        pointerEvents={"auto"}
        position={"relative"}
        zIndex={1}
        pb="3rem"
        sx={{
          "&:before": {
            content: "''",
            position: "fixed",
            top: 0,
            width: { base: "300px", xl: "400px" },
            height: "100%",
            zIndex: -1,
            bg: "#fbfbfb",
            borderLeft: "1px solid #e6e6e6",
          },
        }}
      >
        {children}
      </Box>
    </>
  );
};

export default RightSidebar;
