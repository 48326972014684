import { DataTypeNumber } from "components/containers/inspectionSetting/containers/templateBuilder/setup/define";
import { calculateTextHeight, calculateTextWidth } from "helper/canvasHelper";
import { InspectionItemResult } from "interfaces/models/inspectionItemResult";
import {
  DEFAULT_PIN_COMPONENT_STYLE,
  PinComponentLinkedData,
  PinComponentStyle,
} from "interfaces/models/pinComponent";
import {
  LINKED_DATA_KEY_NO,
  SOURCE,
} from "../../SettingPinLayoutFrame/context/pinLayoutFrameReducer";
import { Component } from "../components/PreviewComponent";

export const getComponentStyle = (
  style?: Partial<PinComponentStyle>,
  isDataTypeNumber: boolean = false
) => {
  const result = {} as any;

  Object.keys(DEFAULT_PIN_COMPONENT_STYLE).forEach((k) => {
    const key = k as keyof PinComponentStyle;
    const isTextAlign = key === "textAlign";
    const isVerticalAlign = key === "alignItems";

    if (style?.[key]) {
      result[key] = style[key];
    } else {
      if (isTextAlign && isDataTypeNumber) {
        result[key] = "right";
      } else if (isVerticalAlign && isDataTypeNumber) {
        result[key] = "center";
      } else {
        result[key] = DEFAULT_PIN_COMPONENT_STYLE[key];
      }
    }
  });

  return result as PinComponentStyle;
};

export const inlineStyleFromComponent = (style: PinComponentStyle) => {
  let result = "";
  Object.keys(style).forEach((k) => {
    const key = k as keyof PinComponentStyle;
    switch (key) {
      case "fontSize":
        result += `font-size: ${style[key]}px; `;
        break;
      case "textAlign":
        result += `text-align: ${style[key]}; `;
        break;
      case "lineHeight":
        result += `line-height: ${style[key]}`;
        break;
      default:
        break;
    }
  });

  return result;
};

export const getDefaultValueByLinkedData = (
  linkedData?: PinComponentLinkedData
) => {
  if (!linkedData) {
    return "テキスト";
  }

  switch (linkedData.source) {
    case SOURCE.HEAD:
      switch (linkedData.key) {
        case "no":
          return "1234567890";

        default:
          return "テキスト";
      }

    default:
      return "テキスト";
  }
};

export const fillFakeContent = (params: {
  textEle: HTMLElement;
  parentElement: HTMLElement;
  defaultText: string;
}) => {
  const { textEle, parentElement, defaultText } = params;
  textEle.textContent = "";
  const parentHeight = parentElement.clientHeight;
  const parentWidth = parentElement.clientWidth;
  const parentStyle = getComputedStyle(parentElement);
  const fontSize = Number(parentStyle.fontSize.replace("px", "")) ?? 14;
  const lineHeight = Number(parentStyle.lineHeight.replace("px", ""));
  let text = defaultText;
  let textHeight = 0;
  const getTextHeight = (text: string) => {
    return calculateTextHeight({
      maxWidth: parentWidth,
      fontSize,
      lineHeight: lineHeight ?? fontSize * 1.2,
      text,
    });
  };

  const isOverflowHeight = (textHeight: number) => {
    return textHeight > parentHeight;
  };
  const isOverflowWidth = (text: string) => {
    const textWidth = calculateTextWidth({ text, fontSize });

    return textWidth > parentWidth;
  };

  do {
    textHeight = getTextHeight(text);

    // remove character is overflowed
    if (isOverflowHeight(textHeight)) {
      do {
        textHeight = getTextHeight(text);
        if (!isOverflowHeight(textHeight)) {
          break;
        }

        text = text.slice(0, -1);
      } while (isOverflowHeight(textHeight));

      break;
    }

    // fill text
    text = `${text}${defaultText}`;
  } while (true);

  // fill text in case text not fit container
  while (!isOverflowWidth(text)) {
    text = `${text}${defaultText}`;

    if (isOverflowWidth(text)) {
      do {
        text = text.slice(0, -1);
      } while (isOverflowWidth(text));

      break;
    }
  }

  textEle.textContent = text;
};

export const setComponentStyleDefault = <T extends Component>(params: {
  components: T[];
  itemResults?: InspectionItemResult[] | undefined;
}): T[] => {
  const { components, itemResults = [] } = params;

  return components.map((component) => {
    let isDataTypeNumber = false;
    const linkedData = component.linkedData;
    const source = linkedData?.source;
    if (source === SOURCE.INSPECTION_FORM) {
      const dataType = itemResults.find(
        (itemResult) => itemResult.id === linkedData?.key
      )?.dataType;
      isDataTypeNumber = DataTypeNumber.includes(dataType as any);
    }

    if (source === SOURCE.HEAD && linkedData?.key === LINKED_DATA_KEY_NO) {
      isDataTypeNumber = true;
    }

    const style = getComponentStyle(component.style, isDataTypeNumber);

    return { ...component, style };
  });
};
