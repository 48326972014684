import { Box, Flex, useBoolean } from "@chakra-ui/react";
import RightSidebar from "./rightSidebar";
import AreaSelection from "./areaSelection";
import { useEffect, useMemo, useRef } from "react";
import { ContainerContext } from "../context/container";
import { BoundContext } from "../context/boundContext";
import DocImage from "./DocImage";
import GrayArea from "./GrayArea";
import {
  useLayoutFrameBuilderContext,
  useLayoutFrameBuilderDispatchContext,
} from "../context/layoutFrameBuilderContext";
import FormChangeRatio from "components/containers/InspectionFormat/component/FormChangeRatio";
import { Types } from "../context/layoutFrameBuilderReducer";
import { useContextInspectionFormat } from "components/containers/InspectionFormat/context/inspectionFormat";
import useMouseInside from "../hooks/useMouseInside";
import useKeyboardEvent from "../hooks/useKeyboardEvent";
import { Position } from "../type";
import { DropableAreaContext } from "../context/dropableAreaContext";
import { Scrollbar } from "react-scrollbars-custom";
import Spinner from "components/ui/Spinner";
import useAutoSizePDFDocs from "hooks/useAutoSizePDFDocs";

// https://github.com/ArentInc/neptune/pull/3245#pullrequestreview-2527090928
export const FRAME_BORDER_WIDTH = 2;
export const BOX_PADDING = 30;

export interface LayoutFrameBuilderProps {
  selections?: any;
  extra?: any;
  header?: any;
  boundingPosition?: Position;
  content?: any;
  toolbar?: any;
}

const LayoutFrameBuilder: React.FC<LayoutFrameBuilderProps> = ({
  selections,
  extra,
  header,
  boundingPosition,
  content,
  toolbar,
}) => {
  const containerRef = useRef<any>();
  const boxRef = useRef<any>();
  const wrapperRef = useRef<any>();
  const { selectedDocumentPageLayout } = useContextInspectionFormat();
  const { ref, isInside } = useMouseInside();
  const { isLoading, zoom } = useLayoutFrameBuilderContext();
  const dispatch = useLayoutFrameBuilderDispatchContext();
  const rightSidebarRef = useRef<any>();
  const wrapperRightSidebarRef = useRef<any>();
  const [isOverflow, overflow] = useBoolean(true);
  // Event press delete/back_space[Mac] button
  useKeyboardEvent();

  const pageLayout = selectedDocumentPageLayout!;

  const position = useMemo(() => {
    if (!boundingPosition)
      return {
        width: "100%",
        top: 0,
        left: 0,
        height: "100%",
      };

    const widthPercent = boundingPosition.width;
    const topPercent = boundingPosition.top;
    const leftPercent = boundingPosition.left;
    const heightPercent = boundingPosition.height;

    return {
      width: `${widthPercent}%`,
      top: `${topPercent}%`,
      left: `${leftPercent}%`,
      height: `${heightPercent}%`,
    };
  }, [boundingPosition]);

  const { width, height } = pageLayout;

  const getRatio = useAutoSizePDFDocs({
    pageWidth: Number(width),
    pageOffsetWidth: 2 * BOX_PADDING,
  });

  useEffect(() => {
    if (ref.current) {
      dispatch({ type: Types.SET_ZOOM, payload: getRatio(ref.current) });
    }
  }, [ref, dispatch, getRatio]);

  return (
    <ContainerContext.Provider value={containerRef}>
      <Flex
        direction={"column"}
        h="100vh"
        position={"fixed"}
        top={0}
        left={0}
        w={"100%"}
        bg="#fff"
        zIndex={10}
        overflow={"hidden"}
      >
        {header}
        <Flex
          flexGrow={1}
          pr={{ base: `300px`, xl: `400px` }}
          position={"relative"}
          zIndex={1}
          maxH="calc(100% - 6rem)"
        >
          {(isLoading || isOverflow) && (
            <Flex
              alignItems={"center"}
              justifyContent={"center"}
              position={"absolute"}
              right={0}
              top={0}
              w="100%"
              h="100%"
              bg={"rgba(0,0,0,.3)"}
              zIndex={100}
            >
              <Spinner size={"xl"} />
            </Flex>
          )}
          <Flex
            position={"relative"}
            ref={ref}
            justifyContent={"center"}
            overflow={isOverflow ? "hidden" : "auto"}
            minW={"100%"}
            mt="5rem"
          >
            <Flex
              left="0"
              position="fixed"
              right={{
                base: `300px`,
                xl: `400px`,
              }}
              top="6.5rem"
              zIndex="99"
              px="1rem"
              gap="1rem"
              mr={"-2rem"}
            >
              {toolbar}
              <FormChangeRatio
                ratio={zoom}
                onChangeRatio={(zoom) =>
                  dispatch({ type: Types.SET_ZOOM, payload: zoom })
                }
              />
            </Flex>
            <Box ref={boxRef} minW="100%" minH="100%">
              <Flex
                mx="auto"
                justifyContent={"center"}
                alignItems={"center"}
                ref={wrapperRef}
                fontSize={`1rem`}
                position={"relative"}
                width={`calc(${width * zoom}px + 3rem)`}
                height={`calc(${height * zoom}px + 3rem)`}
              >
                <Box
                  transformOrigin={"center center"}
                  transform={`scale(${zoom})`}
                  position={"relative"}
                  flex={`0 0 ${width}px`}
                  width={`${width}px`}
                  height={`${height}px`}
                  border={`${FRAME_BORDER_WIDTH}px solid #808080`}
                  boxSizing="content-box"
                >
                  <DocImage
                    file={selectedDocumentPageLayout?.layoutUrl}
                    onLoad={() => overflow.off()}
                    maxH={"100%"}
                    objectFit={"contain"}
                    w="100%"
                    h="auto"
                  >
                    {content}
                    {boundingPosition && <GrayArea position={position} />}
                    <Box
                      position={"absolute"}
                      boxSizing="border-box"
                      zIndex={1}
                      outline={`${FRAME_BORDER_WIDTH}px solid #000`}
                      ref={containerRef}
                      {...position}
                    >
                      <BoundContext.Provider value={containerRef}>
                        <Box w="100%" h="100%">
                          <AreaSelection />
                        </Box>
                      </BoundContext.Provider>
                    </Box>
                  </DocImage>
                </Box>
              </Flex>
            </Box>
            {extra}
          </Flex>
          <Flex
            h={"calc(100vh - 6rem)"}
            flexGrow={1}
            direction={"row"}
            left="0"
            position={"absolute"}
            width={"100%"}
            overflowX={"hidden"}
            pointerEvents={isInside ? "none" : "auto"}
            ref={wrapperRightSidebarRef}
          >
            <DropableAreaContext.Provider value={{ isInside }}>
              <Scrollbar noScrollX={false}>
                <Flex
                  ref={rightSidebarRef as any}
                  height={"100%"}
                  width={"100%"}
                >
                  <RightSidebar>{selections}</RightSidebar>
                </Flex>
              </Scrollbar>
            </DropableAreaContext.Provider>
          </Flex>
        </Flex>
      </Flex>
    </ContainerContext.Provider>
  );
};

export default LayoutFrameBuilder;
