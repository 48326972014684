import { getGeneratedViewList } from "apiClient/v2/bims/bimViewApi";
import { View } from "apiClient/v2/forgeApi";
import { getForgeData } from "apiClient/v2/s3Api";
import { FORGE_DATA_FOLDER_PATH } from "constants/s3";
import { FamilyInstanceDTO } from "interfaces/dtos/familyInstance";
import { Level } from "interfaces/models";
import { Space } from "interfaces/models/area";
import { DataProjectModel } from "interfaces/models/dataProjectModel";
import { ungzip } from "pako";
import { downloadFileFromS3 } from "utils/download-multipart";
import { getPreSignUrl } from "utils/file";
import BimView from "interfaces/models/bimView";

export const getDataFamilyInstance = async ({
  bimFileId,
  version,
  shouldCache,
  level,
}: {
  bimFileId: string;
  version?: string;
  level?: string;
  shouldCache?: boolean;
}): Promise<
  { familyInstances: { [key: string]: FamilyInstanceDTO } } | undefined
> => {
  if (!version) {
    return;
  }
  const fileName = `f-data-${encodeURIComponent(bimFileId)}-v${version}.json`;

  return await handleFetchForgeData({
    fileName,
    level,
    shouldCache,
  });
};

export const getDataSpace = async ({
  bimFileId,
  version,
  shouldCache,
  level,
}: {
  bimFileId: string;
  version?: string;
  level?: string;
  shouldCache?: boolean;
}): Promise<{ spaces: Space[] } | undefined> => {
  if (!version) {
    return;
  }
  const fileName = `f-spaces-${encodeURIComponent(bimFileId)}-v${version}.json`;

  return await handleFetchForgeData({
    fileName,
    level,
    shouldCache,
  });
};

export const handleFetchForgeData = async ({
  fileName,
  level,
  shouldCache,
}: {
  fileName: string;
  level?: string;
  shouldCache?: boolean;
}) => {
  try {
    const forgeData = await getForgeData(
      {
        fileName,
        level,
        shouldCache,
      },
      {
        headers: {
          isIgnoreShowMessageError: true,
        },
      }
    );
    if (!forgeData?.data) {
      return;
    }
    const bufferDefault = new Uint8Array(0);
    //@ts-ignore
    const arrayBuffer = await fetch(forgeData.data)
      .then((res) => res.arrayBuffer())
      .catch(() => bufferDefault);
    const textData = ungzip(arrayBuffer, { to: "string" });

    return JSON.parse(textData);
  } catch (err) {
    return;
  }
};

export const getInstanceTableName = ({
  bimFileId,
  version,
}: {
  bimFileId: string;
  version: string;
}) => {
  return `${decodeURIComponent(bimFileId)
    .toLowerCase()
    .replace(/[:.,/-]/g, "_")}_v${version}`;
};

export const checkHaveViewData = async (
  bimId: string,
  version: string,
  views: View[]
) => {
  const urn = `${bimId}?version=${version}`;
  const bimFileId = encodeURIComponent(urn);
  const viewListResponse = await getGeneratedViewList(bimFileId);
  const viewList = viewListResponse?.data || [];

  return checkIsGenerateViewDone(views, viewList);
};

export const checkIsGenerateViewDone = (
  views: View[],
  bimViewList: BimView[]
) => {
  return views.every((v) => {
    return bimViewList.some(
      (vl) => vl.viewId === v.guid && vl.imageUrl && vl.viewData
    );
  });
};

export const getFamilyInstancesFromS3 = async ({
  bimFileId,
  version,
  shouldCache,
}: {
  bimFileId: string;
  version?: string;
  shouldCache?: boolean;
}): Promise<
  | { familyInstances: { [key: string]: FamilyInstanceDTO }; levels: Level[] }
  | undefined
> => {
  if (!version) {
    return;
  }

  const arrayBuffer = await downloadFileFromS3({
    filePath: FORGE_DATA_FOLDER_PATH,
    fileName: `f-data-${encodeURIComponent(bimFileId)}-v${version}.json`,
    shouldCache,
  });

  let textData = "";
  try {
    textData = ungzip(arrayBuffer, { to: "string" });
  } catch (err) {
    textData = new TextDecoder().decode(arrayBuffer);
  }

  // decompress data
  try {
    return JSON.parse(textData);
  } catch (err) {
    return;
  }
};

export const getMapCategoriesProps = async (bimFile: DataProjectModel) => {
  try {
    const encodeBimFile = encodeURIComponent(bimFile.id!);
    const categoryFileName = `f-categories-props-${encodeBimFile}-v${bimFile.version}.json`;
    const presignedUrl = await getPreSignUrl(
      FORGE_DATA_FOLDER_PATH,
      categoryFileName
    );
    if (!presignedUrl) {
      return;
    }
    const arrayBuffer = await fetch(presignedUrl)
      .then((res) => res.arrayBuffer())
      .catch(() => undefined);
    if (!arrayBuffer) {
      return;
    }
    const textData = ungzip(arrayBuffer, { to: "string" });

    return JSON.parse(textData);
  } catch (e) {
    return undefined;
  }
};
