import Document from "@tiptap/extension-document";
import { DEFAULT_DOCUMENT_PAGE_SIZE } from "constants/document";

export const CustomDocumentNode = Document.extend({
  addAttributes() {
    return {
      pageIndex: { default: 0 },
      pageWidth: { default: DEFAULT_DOCUMENT_PAGE_SIZE.width },
      pageHeight: { default: DEFAULT_DOCUMENT_PAGE_SIZE.height },
    };
  },
});
