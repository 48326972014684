import {
  Box,
  Checkbox,
  CheckboxGroup,
  Collapse,
  Img,
  Input,
  Radio,
  RadioGroup,
} from "@chakra-ui/react";
import { css } from "@emotion/react";
import { documentItemApi } from "apiClient/v2";
import IconDocument from "components/icon/IconDocument";
import SelectUserMulti from "components/input/SelectUserMulti";
import { OPTION_ALL_FLOOR } from "constants/area";
import { DocumentCategoryKey, DocumentTemplateType } from "constants/enum";
import { ALL_LEVEL_LABEL } from "constants/forge";
import useForm from "hooks/useForm";
import { useAuthorization } from "hooks/usePermission";
import useUserOfProject from "hooks/useUserOfProject";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { Level } from "interfaces/models";
import { NeptuneArea } from "interfaces/models/area";
import { DocumentCategory } from "interfaces/models/documentCategory";
import { DocumentGroup } from "interfaces/models/documentGroup";
import { DocumentTemplate } from "interfaces/models/documentTemplate";
import { User } from "interfaces/models/user";
import isEqual from "lodash/isEqual";
import groupBy from "lodash/groupBy";
import {
  doMapDocumentCategory,
  isPhotoLedgerTemplate,
  getCategoryFieldNecessaryForOffline,
  isSelfInspectionTemplate,
} from "models/documentCategory";
import { insertDataLogToIndexedDb, transformMapTitleKey } from "models/dataLog";
import useSupportSyncDataOffline, {
  TypeHandleInitData,
} from "pages/forge-viewer/hooks/useSupportSyncDataOffline";
import React, { memo, useCallback, useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { setIsCreateDocumentItem } from "redux/forgeViewerSlice";
import { RootState, useAppDispatch } from "redux/store";
import { uuid } from "utils/common";
import { getAreaExtension } from "utils/forge/extensions/area-extension";
import { transformBodyForCombineData } from "utils/offline";
import Select from "../../input/Select";
import ModalInput, {
  ModalInputProps,
  ModalInputSection,
} from "../../ui/ModalInput";
import { useForgeViewerContext } from "pages/forge-viewer/ForgeViewerContext";

const ModalAddDocumentCategory = memo<{
  isOpen: boolean;
  modalProps?: ModalInputProps;
  isLoading: boolean;
  documentTemplates: DocumentTemplate[];
  title?: string;
  okText?: string;
  users: {
    [key: string]: User;
  };
  currentUser?: User | null;
  neptuneAreas?: NeptuneArea[];
  levels?: Level[];
  defaultValue?: Partial<DocumentCategory>;
  levelDefault?: string;
  documentGroups: DocumentGroup[];
  documentItems: DocumentItemDTO[];
  onClose: () => void;
  onApply: (
    category: Partial<DocumentCategory>,
    template: DocumentTemplate
  ) => Promise<any>;
}>(
  ({
    currentUser,
    isLoading,
    modalProps,
    levels,
    neptuneAreas,
    isOpen,
    documentTemplates,
    levelDefault,
    defaultValue,
    documentGroups,
    documentItems,
    onClose,
    onApply,
  }) => {
    const autoTemplateName = useRef<string | null>(null);
    const fieldCategoryLogsRef = useRef<DocumentCategoryKey[]>([]);
    const roles = useAuthorization();
    const { listUserById } = useUserOfProject();
    const dispatch = useAppDispatch();
    const { isFetchingUserAssigned } = useSelector(
      (state: RootState) => state.user
    );
    const { socket } = useForgeViewerContext();
    const { isCreateDocumentItem } = useSelector(
      (state: RootState) => state.forgeViewer
    );

    const { setValue, getValue, formValue } = useForm<
      Partial<DocumentCategoryDTO>
    >(() => {
      if (!defaultValue) return { groupId: "-" };

      if (!Object.keys(defaultValue || {})?.length && levelDefault) {
        defaultValue.level = levelDefault;
      }

      const category = { ...defaultValue };

      if (!category.groupId) {
        category.groupId = "-";
      }

      if (category.level) {
        if (category.allFloor) {
          category.noArea = null as any;
          category.allFloor = "1";
        } else if (category?.noArea) {
          category.noArea = true as any;
          category.allFloor = "0";
        } else if (category.neptuneAreaIds?.length) {
          category.noArea = null as any;
          category.allFloor = "0";
        } else {
          category.allFloor = "1";
        }
      }

      return category;
    });

    const {
      fieldUpdateDataRef,
      initDataRef: initCategoryDataRef,
      clearFieldUpdateData,
      handleUpdateFieldsChangeData,
      handleInitData: handleInitCategoryData,
    } = useSupportSyncDataOffline<DocumentCategoryDTO>();

    useEffect(() => {
      if (formValue?.id && formValue?.id === defaultValue?.id) {
        handleInitCategoryData({
          data: defaultValue as DocumentCategoryDTO,
          type: TypeHandleInitData.DOCUMENT_CATEGORY,
        });
      } else {
        clearFieldUpdateData();
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue?.id, formValue?.id]);

    const handleBeforeSetValue = useCallback(
      (key: string, value: any) => {
        setValue(key, value);
        handleUpdateFieldsChangeData([key as keyof DocumentCategoryDTO]);
      },
      [setValue, handleUpdateFieldsChangeData]
    );

    const { documentTemplateOptions, mapTemplate } = useMemo(() => {
      const mapTemplate = new Map<string, DocumentTemplate>();

      const createOption = (template: DocumentTemplate) => {
        template.id && mapTemplate.set(template.id, template);

        return {
          name: template.templateName,
          value: template.id as any,
        };
      };

      const filterTemplate = (template: DocumentTemplate) => {
        return template.documentType !== DocumentTemplateType.PARENT_TEMPLATE;
      };

      const createOptions = (templates: DocumentTemplate[]) => {
        const templateOfProject: any[] = [];
        for (const template of templates) {
          if (filterTemplate(template)) {
            templateOfProject.push(createOption(template));
          }
        }

        return templateOfProject;
      };

      return {
        mapTemplate,
        documentTemplateOptions: createOptions(documentTemplates),
      };
    }, [documentTemplates]);

    const submit = useCallback(async () => {
      const category = { ...formValue };

      const template = mapTemplate.get(category.templateId!);
      if (!template) return;

      if (category.groupId === "-") {
        category.groupId = "";
      }

      category.documentType = template.documentType;
      let noArea = null as any;
      let allFloor = null as any;

      if (category?.allFloor === "1") {
        noArea = null as any;
        allFloor = category.level;
      } else if (category?.noArea) {
        noArea = category.level;
        allFloor = null as any;
      }

      const promiseLogs: Promise<any>[] = [];
      const requestId = uuid();
      const bodyCreate = {
        ...category,
        allFloor,
        noArea,
      } as DocumentCategoryDTO;
      const now = new Date();
      let bodyUpdate = {
        id: category.id,
        allFloor,
        noArea,
        requestId,
        // need to pass a necessary field to overwrite data when in offline mode
        ...getCategoryFieldNecessaryForOffline(category as DocumentCategoryDTO),
        updatedAt: now,
      } as DocumentCategoryDTO;

      // insert log
      if (currentUser?.id) {
        const keyAddLogs = [
          DocumentCategoryKey.TITLE,
          DocumentCategoryKey.USER_ASSIGNED,
        ];

        const fieldAddLogs = new Set();
        fieldCategoryLogsRef.current.forEach((field) => {
          if (keyAddLogs.includes(field)) {
            fieldAddLogs.add(field);
          }
        });

        const { mapLogs, mapTitleKey, mapDisplayValueKey } =
          transformMapTitleKey(
            [...fieldAddLogs].map((key) => ({
              field: key as any,
              value: bodyCreate[key as keyof typeof bodyCreate],
            }))
          );

        const logs = Object.values(mapLogs).map((params) =>
          insertDataLogToIndexedDb({
            contentLogParams: { ...params, requestId },
            createdBy: currentUser.id!,
            identifyValue: category.id!,
            identifyCol: "categoryId",
            type: "category",
          })
        );

        promiseLogs.push(...logs);

        bodyCreate.mapDisplayValueKey = mapDisplayValueKey;
        bodyCreate.mapTitleKey = mapTitleKey;
        bodyUpdate.mapTitleKey = mapTitleKey;
        bodyUpdate.mapDisplayValueKey = mapDisplayValueKey;
      }

      fieldCategoryLogsRef.current = [];

      if (formValue?.id) {
        fieldUpdateDataRef.current.forEach((key) => {
          bodyUpdate[key] = bodyCreate[key] as never;
        });

        bodyUpdate = transformBodyForCombineData<DocumentCategoryDTO>({
          body: bodyUpdate,
          bodyBefore: initCategoryDataRef.current || ({} as any),
          typeInitData: TypeHandleInitData.DOCUMENT_CATEGORY,
        });

        if (template) {
          const item = documentItems?.[0];
          let items = documentItems.filter(
            (i) => i.templateId === category.templateId
          );
          if (item?.level !== category.level) {
            items = await documentItemApi.handleGetItemList({
              bimFileId: category.bimFileId,
              templateId: category.templateId,
              level: category.level,
            });
          }

          const newCategory = doMapDocumentCategory({
            category: { ...category, ...bodyUpdate } as DocumentCategoryDTO,
            documentItems: items,
            template,
          });

          if (
            defaultValue?.allFloor !== bodyUpdate.allFloor ||
            defaultValue?.noArea !== bodyUpdate.noArea ||
            !isEqual(defaultValue?.neptuneAreaIds, bodyUpdate.neptuneAreaIds)
          ) {
            bodyUpdate.selectedExternalIds = bodyUpdate.itemIds =
              newCategory.itemIds;

            const mapIsHiddenDocumentItem: Record<string, boolean> = (
              newCategory.documentItems || []
            ).reduce((acc, item) => {
              const isHidden = (item.subItems || []).every(
                (sub) => sub.isHidden
              );

              return Object.assign(acc, { [item.id]: isHidden });
            }, {});

            if (isPhotoLedgerTemplate(category.documentType)) {
              bodyUpdate.selectedExternalIds = (
                bodyUpdate?.selectedExternalIds || []
              )?.filter((id) => !mapIsHiddenDocumentItem[id]);
            }

            if (isSelfInspectionTemplate(category.documentType)) {
              bodyUpdate.selectedExternalIds = bodyUpdate.itemIds =
                newCategory.documentItems?.map((item) => item.id);
            }
          }
        }
      }

      onApply(formValue?.id ? bodyUpdate : bodyCreate, template).then(
        async () => {
          const logs = await Promise.all(promiseLogs);
          const changeLogs = groupBy(logs, "categoryId");
          Object.keys(changeLogs).map((id) => {
            socket.addDocumentCategoryLog(id, changeLogs[id]);
          });
        }
      );

      getAreaExtension()?.clearSelection();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      formValue,
      mapTemplate,
      documentItems,
      currentUser,
      onApply,
      defaultValue,
    ]);

    const areas = useMemo(() => {
      return (
        neptuneAreas?.filter((level) => level.level === formValue?.level) ?? []
      );
    }, [formValue, neptuneAreas]);

    const levelOptions = useMemo(() => {
      const options =
        levels
          ?.filter((level) => level.label !== ALL_LEVEL_LABEL)
          ?.map((level) => ({ value: level.label, name: level.label })) ?? [];
      if (!formValue?.id) {
        options.push({ value: OPTION_ALL_FLOOR, name: "すべての階" });
      }

      return options;
    }, [levels, formValue?.id]);

    const onChangeNoArea = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
          handleBeforeSetValue(DocumentCategoryKey.NEPTUNE_AREA_IDS, []);
        }
        handleBeforeSetValue(DocumentCategoryKey.NO_AREA, e.target.checked);
        fieldCategoryLogsRef.current.push(DocumentCategoryKey.NO_AREA);
        fieldCategoryLogsRef.current = fieldCategoryLogsRef.current.filter(
          (key) =>
            ![
              DocumentCategoryKey.ALL_FLOOR,
              DocumentCategoryKey.NEPTUNE_AREA_IDS,
            ].includes(key)
        );
      },
      [handleBeforeSetValue]
    );

    const onChangeLevel = useCallback(
      (selected: any) => {
        handleBeforeSetValue(DocumentCategoryKey.NEPTUNE_AREA_IDS, []);
        handleBeforeSetValue(DocumentCategoryKey.LEVEL, selected);
        handleBeforeSetValue(DocumentCategoryKey.ALL_FLOOR, "1");
        fieldCategoryLogsRef.current.push(DocumentCategoryKey.LEVEL);
        fieldCategoryLogsRef.current = fieldCategoryLogsRef.current.filter(
          (key) =>
            ![
              DocumentCategoryKey.NO_AREA,
              DocumentCategoryKey.ALL_FLOOR,
              DocumentCategoryKey.NEPTUNE_AREA_IDS,
            ].includes(key)
        );
      },
      [handleBeforeSetValue]
    );

    const onChangeFloor = useCallback(
      (value: any) => {
        handleBeforeSetValue(DocumentCategoryKey.NEPTUNE_AREA_IDS, []);
        handleBeforeSetValue(DocumentCategoryKey.NO_AREA, false);
        handleBeforeSetValue(DocumentCategoryKey.ALL_FLOOR, value);
        fieldCategoryLogsRef.current.push(DocumentCategoryKey.ALL_FLOOR);
        fieldCategoryLogsRef.current = fieldCategoryLogsRef.current.filter(
          (key) =>
            ![
              DocumentCategoryKey.NO_AREA,
              DocumentCategoryKey.NEPTUNE_AREA_IDS,
            ].includes(key)
        );
      },
      [handleBeforeSetValue]
    );

    const documentOptions = useMemo(() => {
      return [
        {
          value: "-",
          name: "選択なし",
        },
        ...documentGroups.map((document) => ({
          value: document.id,
          name: document.name,
        })),
      ];
    }, [documentGroups]);

    const isDisabledSubmit = useMemo(() => {
      if (!formValue) return true;
      if (!formValue.title?.trim()) return true;
      if (!formValue.templateId) return true;
      if (!formValue.level) return true;
      if (!formValue.allFloor) return true;
      if (formValue.allFloor === "0") {
        if (!formValue.noArea) {
          return !formValue.neptuneAreaIds?.length;
        }
      }

      return false;
    }, [formValue]);

    const onChangeTemplate = useCallback(
      (selected: string) => {
        if (!formValue?.title || autoTemplateName.current) {
          const template = mapTemplate.get(selected)!;
          handleBeforeSetValue(
            DocumentCategoryKey.TITLE,
            template.templateName
          );
          autoTemplateName.current = template.templateName;
        }
        handleBeforeSetValue(DocumentCategoryKey.TEMPLATE_ID, selected);
        fieldCategoryLogsRef.current.push(DocumentCategoryKey.TEMPLATE_ID);
      },
      [handleBeforeSetValue, formValue, mapTemplate]
    );

    const onChangeTitle = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        autoTemplateName.current = null;
        handleBeforeSetValue(DocumentCategoryKey.TITLE, e?.target.value);
        fieldCategoryLogsRef.current.push(DocumentCategoryKey.TITLE);
      },
      [handleBeforeSetValue]
    );

    const handleChangeValue = useCallback(
      (key: DocumentCategoryKey) => (e: any) => {
        let value = e;
        if (
          [
            DocumentCategoryKey.NEPTUNE_AREA_IDS,
            DocumentCategoryKey.USER_ASSIGNED,
            DocumentCategoryKey.GROUP_ID,
          ].includes(key)
        ) {
          value = e;
        }
        fieldCategoryLogsRef.current.push(key);
        handleBeforeSetValue(key, value);

        if (key === DocumentCategoryKey.NEPTUNE_AREA_IDS) {
          fieldCategoryLogsRef.current = fieldCategoryLogsRef.current.filter(
            (key) =>
              ![
                DocumentCategoryKey.NO_AREA,
                DocumentCategoryKey.ALL_FLOOR,
              ].includes(key)
          );
        }
      },
      [handleBeforeSetValue]
    );

    const isUpdating = !!formValue?.id;

    const handleClose = () => {
      fieldCategoryLogsRef.current = [];
      getAreaExtension()?.clearSelection();
      if (isCreateDocumentItem) {
        dispatch(setIsCreateDocumentItem(false));
      }
      onClose();
    };

    return (
      <>
        <ModalInput
          isOpen={isOpen}
          {...modalProps}
          title={isUpdating ? "書類を更新" : "書類を追加"}
          onClose={handleClose}
          onCancel={handleClose}
          okLabel={isUpdating ? "更新" : "追加"}
          cancelLabel={"キャンセル"}
          onOk={submit}
          cancelDisabled={isLoading}
          okDisabled={isDisabledSubmit}
          btnPropsOk={{
            isLoading,
          }}
          btnPropsCancel={{
            style: {
              paddingInline: "var(--chakra-space-8)",
            },
          }}
          icon={<IconDocument fill="#171717" width="4rem" height="4rem" />}
          bodyProps={{
            style: {
              maxHeight: "60vh",
              overflowY: "auto",
            },
          }}
          modalHeaderProps={{
            style: {
              paddingInlineStart: "var(--chakra-space-12)",
              paddingInlineEnd: "var(--chakra-space-12)",
            },
          }}
          modalContentProps={{
            style: {
              background: "#FAFAFA",
            },
            minWidth: 400,
            maxWidth: 1200,
            width: "90%",
          }}
          modalFooterProps={{
            style: {
              borderTop: "unset",
              backgroundColor: "unset",
            },
          }}
        >
          <Box
            css={css`
              --primary-border-color: #a3a3a3;
              color: #737373;
              font-size: 1.5rem;
              font-weight: 600;
            `}
          >
            <ModalInputSection
              label="フォルダを選択"
              tag={"必須"}
              tagColor={"blue"}
              divider={true}
            >
              <Box
                sx={{
                  button: {
                    fontSize: "1.5rem !important",
                  },
                }}
              >
                <Select
                  css={css`
                    height: 4rem;
                    min-width: 70%;
                    padding-left: var(--chakra-space-4);
                    font-weight: 500;
                  `}
                  iconProps={{ w: null, h: null }}
                  icon={
                    <Img
                      w="2.5rem"
                      h="2.5rem"
                      src="/img/icon-chevron-down.svg"
                      alt=""
                    />
                  }
                  placeHolder={"選択してください"}
                  value={getValue(DocumentCategoryKey.GROUP_ID)}
                  onChange={handleChangeValue(DocumentCategoryKey.GROUP_ID)}
                  options={documentOptions}
                  isDisabled={isLoading}
                />
              </Box>
            </ModalInputSection>
            <ModalInputSection
              label={"書類テンプレート"}
              tag={"必須"}
              tagColor={"red"}
              divider={true}
            >
              <Box
                sx={{
                  button: {
                    fontSize: "1.5rem !important",
                  },
                }}
              >
                <Select
                  css={css`
                    height: 40px;
                    min-width: 70%;
                    padding-left: var(--chakra-space-4);
                  `}
                  icon={
                    <Img
                      w="2.5rem"
                      h="2.5rem"
                      src="/img/icon-chevron-down.svg"
                      alt=""
                    />
                  }
                  placeHolder={"選択してください"}
                  value={getValue(DocumentCategoryKey.TEMPLATE_ID)}
                  isDisabled={isUpdating}
                  onChange={onChangeTemplate}
                  options={documentTemplateOptions}
                />
              </Box>
            </ModalInputSection>
            <ModalInputSection label={"書類名"} tag={"必須"} tagColor={"red"}>
              <Input
                placeholder={"入力してください"}
                value={getValue(DocumentCategoryKey.TITLE, "")}
                onChange={onChangeTitle}
                isDisabled={isLoading}
                css={css`
                  border: 1px solid #a3a3a3;
                  height: 40px;
                  line-height: 2rem; /* 32px */
                  background-color: #fff;
                  color: initial;
                  font-size: 1.5rem;
                  font-weight: 500;
                `}
                sx={{
                  "::placeholder": {
                    opacity: 1,
                    color: "var(--chakra-colors-gray-400) !important",
                  },
                }}
              />
            </ModalInputSection>
            <ModalInputSection
              label={"書類担当者"}
              tag={"必須"}
              tagColor={"blue"}
              divider={true}
            >
              <Box
                css={css`
                  --primary-border-color: #d4d4d4;
                  width: 70%;
                  display: inline-block;
                `}
                sx={{
                  "&>div>div:first-of-type": {
                    width: "100%",
                  },
                }}
              >
                <SelectUserMulti
                  users={listUserById}
                  isDisabled={isLoading || !roles.canEditDocumentCategory}
                  value={getValue(DocumentCategoryKey.USER_ASSIGNED, [])}
                  onChange={handleChangeValue(
                    DocumentCategoryKey.USER_ASSIGNED
                  )}
                  placeHolder="選択してください"
                  placement="bottom"
                  styleContent={{
                    overflow: "auto",
                    maxHeight: "23rem",
                  }}
                  fullWidth
                  styleProps={
                    {
                      minHeight: "4rem",
                      p: 0,
                      borderColor: "#A3A3A3",
                      width: "auto",
                      display: "inline-flex",
                      fontSize: "1.5rem",
                      fontWeight: 500,
                      background: "#fff",
                    } as any
                  }
                  styleTag={{
                    height: "3.4rem",
                    marginTop: 0,
                    marginBottom: 0,
                    marginInline: "0.4rem var(--wrap-x-spacing)",
                  }}
                  iconProps={{
                    style: {
                      marginRight: "var(--chakra-space-4)",
                      fill: "transparent",
                    },
                  }}
                  icon={
                    <Img
                      mr="1rem"
                      w="2.5rem"
                      h="2.5rem"
                      src="/img/icon-chevron-down.svg"
                      alt=""
                    />
                  }
                  placeholderProps={{
                    color: "var(--chakra-colors-gray-400)",
                    opacity: 1,
                  }}
                  isLoading={isFetchingUserAssigned}
                />
              </Box>
            </ModalInputSection>

            <ModalInputSection label={"階"} tag={"必須"} tagColor={"red"}>
              <Box
                sx={{
                  button: {
                    fontSize: "1.5rem !important",
                  },
                }}
              >
                <Select
                  css={css`
                    height: 40px;
                    min-width: 70%;
                    padding-left: var(--chakra-space-4);
                  `}
                  isDisabled={isLoading}
                  placeHolder={"選択してください"}
                  value={getValue(DocumentCategoryKey.LEVEL)}
                  onChange={onChangeLevel}
                  options={levelOptions}
                  iconProps={{ w: null, h: null }}
                  icon={
                    <Img
                      w="2.5rem"
                      h="2.5rem"
                      src="/img/icon-chevron-down.svg"
                      alt=""
                    />
                  }
                />
              </Box>
            </ModalInputSection>
            <ModalInputSection
              label={"検査対象工区"}
              tag={"必須"}
              tagColor={"red"}
              alignItems={"start"}
            >
              <Box
                margin-bottom={{
                  base: "1.5rem",
                  md: "1.5rem",
                  lg: "1.5rem",
                  xl: "1.5rem",
                  "2xl": "1.5rem",
                }}
                sx={{
                  "*": {
                    fontWeight: "600 !important",
                    verticalAlign: "middle !important",
                  },
                  "*:not([data-checked])": {
                    color: "#737373",
                  },
                  ".chakra-radio__label": {
                    fontSize: "1.5rem",
                  },
                }}
              >
                <RadioGroup
                  display={"flex"}
                  columnGap={"5rem"}
                  isDisabled={
                    !getValue(DocumentCategoryKey.LEVEL) ||
                    getValue(DocumentCategoryKey.LEVEL) === OPTION_ALL_FLOOR ||
                    isLoading
                  }
                  value={getValue(DocumentCategoryKey.ALL_FLOOR)}
                  onChange={onChangeFloor}
                >
                  <Radio
                    sx={{
                      borderColor: "var(--primary-border-color) !important",
                      "&:before": {
                        background: "#009be0 !important",
                      },
                    }}
                    fontSize={{
                      base: "1.2rem",
                      md: "1.4rem",
                      lg: "1.4rem",
                      xl: "1.5rem",
                      "2xl": "1.5rem",
                    }}
                    value={"1"}
                  >
                    階の全体（すべてのスペース）
                  </Radio>
                  <Radio
                    sx={{
                      borderColor: "var(--primary-border-color) !important",
                      "&:before": {
                        background: "#009be0 !important",
                      },
                    }}
                    value={"0"}
                  >
                    階の一部（スペースを選択）
                  </Radio>
                </RadioGroup>
              </Box>
              <Collapse in={getValue(DocumentCategoryKey.ALL_FLOOR) === "0"}>
                <Box
                  css={css`
                    background: #fffbeb;
                    padding: 2rem;
                    margin-top: 2rem;
                    margin-bottom: 2rem;
                  `}
                >
                  <Box
                    display="flex"
                    flexDirection={{
                      base: "column",
                      xl: "row",
                    }}
                    borderBottom="1px solid #e7e6e0"
                    gap="1.2rem"
                    paddingBottom="1rem"
                  >
                    <Box flexGrow={1} fontWeight="bold" color="#000">
                      スペースを選択してください
                    </Box>
                    <Checkbox
                      isDisabled={isLoading}
                      defaultChecked={getValue(DocumentCategoryKey.NO_AREA)}
                      isChecked={getValue(DocumentCategoryKey.NO_AREA)}
                      onChange={onChangeNoArea}
                    >
                      どの書類にも含まれていないスペースを表示
                    </Checkbox>
                  </Box>
                  <Box
                    css={css`
                      display: flex;
                      flex-direction: row;
                      flex-wrap: wrap;
                      gap: 2rem;
                      column-gap: 4rem;
                      padding-top: 2rem;
                      max-height: 12.5rem;
                      overflow-y: auto;
                    `}
                  >
                    <CheckboxGroup
                      defaultValue={getValue(
                        DocumentCategoryKey.NEPTUNE_AREA_IDS
                      )}
                      isDisabled={
                        !!getValue(DocumentCategoryKey.NO_AREA) || isLoading
                      }
                      value={getValue(DocumentCategoryKey.NEPTUNE_AREA_IDS)}
                      onChange={handleChangeValue(
                        DocumentCategoryKey.NEPTUNE_AREA_IDS
                      )}
                    >
                      {areas.map((area) => {
                        return (
                          <Checkbox key={area.id} value={area.id}>
                            {area.name}
                          </Checkbox>
                        );
                      })}
                    </CheckboxGroup>
                  </Box>
                </Box>
              </Collapse>
            </ModalInputSection>
          </Box>
        </ModalInput>
      </>
    );
  }
);

export default ModalAddDocumentCategory;
