import { logDev } from "utils/logs";
import { GenerateStatus } from ".";

import { CURRENT_LEVEL_KEY, LEVEL_ALL } from "constants/forge";

import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setIsLoadingDocument } from "redux/documentSlice";
import {
  setIsGeneratingView,
  setIsLoadedExternalId,
  setLevelSelected,
  setSelectedView,
} from "redux/forgeViewerSlice";
import { RootState } from "redux/store";

import { getLocalStorage, setLocalStorage } from "utils/storage";
import { getNetworkStatus } from "utils/common";
import { View } from "apiClient/v2/forgeApi";
import GenView from "services/genView";
import { getAECData } from "utils/forge/forgeApiData";
import { DataGenerate } from "apiClient/v2/bims/bimFileApi";

interface Props {
  generatingStatusRef: React.MutableRefObject<GenerateStatus>;
  isSettingFloor?: boolean;
  dataGenerated?: DataGenerate;
  setTimeCheckGenerate: (val: number) => void;
}

export default function useGenerateView(props: Props) {
  const {
    generatingStatusRef,
    dataGenerated,
    isSettingFloor,
    setTimeCheckGenerate,
  } = props;

  const {
    isGeneratingSpaces,
    isGeneratingFamilyInstances,
    isLoadedViewerModelData,
    isGeneratingAllFamilyInstance,
    isLoadedViewer,
    selectedView,
    isGeneratingView,
    levelSelected,
    isLoadedLevels,
    isInitialized,
  } = useSelector((state: RootState) => state.forgeViewer);
  const [views, setViews] = useState<View[]>([]);

  const dispatch = useDispatch();
  const { bimFileId, version } = useParams();
  const genViewRef = useRef(new GenView(bimFileId!, version!));
  const { dataProjectDetail } = useSelector(
    (state: RootState) => state.project
  );
  const [generatedViews, setGeneratedViews] = useState<View[]>([]);

  useEffect(() => {
    genViewRef.current = new GenView(bimFileId!, version!);
  }, [bimFileId, version]);

  useEffect(() => {
    if (
      isSettingFloor ||
      !bimFileId ||
      !version ||
      !dataGenerated ||
      levelSelected?.guid === undefined ||
      !isInitialized ||
      !dataProjectDetail?.id ||
      !dataProjectDetail?.version ||
      generatingStatusRef.current.view ||
      isGeneratingView
    ) {
      return;
    }
    (async () => {
      const isOnline = getNetworkStatus();
      // because mapping category and all instance not necessary when offline mode => we can ignore generate if offline
      if (!isOnline) {
        return;
      }
      generatingStatusRef.current.view = false;
      // load family instances data
      dispatch(setIsLoadingDocument(true));
      // width 3d -> generate all,  width 2d , we will only generate view by sheet selected
      const hasData = !dataGenerated?.missingViews.length;
      if (hasData) {
        genViewRef.current.clearData();
        dispatch(setIsGeneratingView(false));
        generatingStatusRef.current.view = true;
        setTimeCheckGenerate(Date.now());
      } else {
        const viewGenerates = await genViewRef.current.getViewGenerate(
          dataProjectDetail
        );
        if (!viewGenerates) {
          return dispatch(setIsGeneratingView(false));
        }
        setViews(viewGenerates);
        dispatch(setIsGeneratingView(true));
        const missingViewGuidSet = new Set(dataGenerated.missingViews);
        setGeneratedViews(
          viewGenerates.filter((bv) => !missingViewGuidSet.has(bv.guid))
        );
        const urn = `${dataProjectDetail.id}?version=${dataProjectDetail.version}`;
        const aecData = await getAECData(urn);
        if (!aecData) {
          return;
        }
        logDev(viewGenerates, "all view generate");
        genViewRef.current.setAecData(aecData);
        if (levelSelected.guid) {
          const currentLevel = getLocalStorage(CURRENT_LEVEL_KEY) || {};
          currentLevel[String(bimFileId)] = "";
          dispatch(setIsLoadedExternalId(false));
          setLocalStorage(CURRENT_LEVEL_KEY, currentLevel);
          dispatch(setLevelSelected(LEVEL_ALL));
          currentLevel[String(bimFileId)] = "";
        }
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    bimFileId,
    version,
    isSettingFloor,
    isInitialized,
    dataGenerated,
    dataProjectDetail?.id,
    dataProjectDetail?.version,
  ]);

  useEffect(() => {
    if (
      isGeneratingSpaces ||
      isGeneratingFamilyInstances ||
      isGeneratingAllFamilyInstance
    ) {
      return;
    }
    // at first time we not need wait viewer load finished
    if (selectedView && (!isLoadedViewer || !isLoadedViewerModelData)) {
      return;
    }
    if (isGeneratingView && bimFileId && version && isLoadedLevels) {
      generatingView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    bimFileId,
    isGeneratingAllFamilyInstance,
    isGeneratingSpaces,
    isGeneratingFamilyInstances,
    isLoadedViewerModelData,
    isGeneratingView,
    isLoadedLevels,
    isLoadedViewer,
    version,
  ]);

  const generatingView = async () => {
    // const dataView = genViewRef.current.getDataView();
    const notGeneratingView = views.find((v) => {
      return (
        !generatedViews.some((bv) => bv.guid === v.guid) &&
        v.guid !== selectedView?.guid
      );
    });
    if (selectedView) {
      // handle capture and add data to view
      logDev(selectedView.name, selectedView.role);
      const result = await genViewRef.current.addDataView(selectedView);
      if (!result) {
        return;
      }
      setGeneratedViews((prev) => {
        return [...prev, selectedView];
      });
    }
    if (!notGeneratingView) {
      // handle upload data
      genViewRef.current.clearData();
      generatingStatusRef.current.view = true;
      dispatch(setIsGeneratingView(false));
    } else {
      logDev("generating view", notGeneratingView.name);
    }
    dispatch(setSelectedView(notGeneratingView));
  };

  return {
    totalViewGenerated: generatedViews.length,
    totalView: views.length,
  };
}
