import { ApiResponse } from "interfaces/models/api";
import { InspectionGroup } from "interfaces/models/inspectionGroup";
import { axiosECS } from "services/baseAxios";
import { sleep } from "utils/common";
import { pinApi } from "..";

const pathV2 = "/v1/inspections/groups";

interface Params {
  inspectionTypeId: string;
  shouldCache?: boolean;
}

export const getInspectionGroupList = async (
  params?: Params
): Promise<ApiResponse<InspectionGroup[]>> => {
  const { shouldCache, ...other } = params ?? {};

  const data = await axiosECS.get(pathV2, {
    params: { ...other, paging: "cursor" },
  });

  if (shouldCache) {
    await handleCachedInspectionGroups(data.data);
  }

  return data;
};

export const getInspectionGroupById = async (
  id: string
): Promise<ApiResponse> => {
  return axiosECS.get(`${pathV2}/${id}`);
};

export const createInspectionGroup = async (
  inspectionGroup: InspectionGroup
): Promise<ApiResponse> => {
  return axiosECS.post(pathV2, inspectionGroup);
};

export const updateInspectionGroup = async (
  inspectionGroup: InspectionGroup
): Promise<ApiResponse> => {
  return axiosECS.patch(pathV2, inspectionGroup);
};

export const deleteInspectionGroup = async (
  ids: string[]
): Promise<ApiResponse<string[]>> => {
  return axiosECS.delete(pathV2, {
    data: { ids },
  });
};

const handleCachedInspectionGroups = async (groups: InspectionGroup[]) => {
  let requests: Promise<any>[] = [];
  for (let i = 0; i < groups.length; i++) {
    const group = groups?.[i];
    if (!group?.id) {
      continue;
    }

    requests.push(getInspectionGroupById(group.id));
    requests.push(pinApi.getAllPins({ inspectionGroupId: group.id }));

    if (requests.length >= 20) {
      await Promise.all(requests);
      await sleep(50);
      requests = [];
    }
  }

  await Promise.all(requests);
};
