import { ForgeClient } from "./client";
import { parseSVF } from "./svf-parser";

const PROPERTY_DB_FILELIST: string[] = [
  "objects_ids.json.gz",
  "objects_attrs.json.gz",
  "objects_avs.json.gz",
  "objects_vals.json.gz",
  "objects_offs.json.gz",
  "objects_viewables.json.gz",
];

const F2D_RESOURCE_FILELIST: string[] = [
  "manifest.json.gz",
  "metadata.json.gz",
];

const extractUrnPath = (
  encodedURN: string
): { rootFileName: string; basePath: string } => {
  const urn = decodeURIComponent(encodedURN);
  const rootFileName = urn.slice(urn.lastIndexOf("/") + 1);
  const basePath = urn.slice(0, urn.lastIndexOf("/") + 1);

  return {
    rootFileName: rootFileName,
    basePath: basePath,
  };
};

export const getViewerResourceUrls = async (params: {
  urn: string;
  authToken: string;
  viewableIDList?: string[];
  viewable2DNameList?: string[];
  viewableNameList?: string[];
}) => {
  const {
    urn,
    authToken,
    viewableNameList = [],
    viewable2DNameList = [],
  } = params;
  const manifestOverviewList = await ForgeClient.manifest(urn, authToken);
  const fetchUrlList: string[] = [];

  for (const overview of manifestOverviewList) {
    if (overview.outputType !== "svf") {
      continue;
    }

    for (const manifest of overview.children) {
      // PropertyDatabase
      if (manifest.role === "Autodesk.CloudPlatform.PropertyDatabase") {
        const { basePath } = extractUrnPath(manifest.urn);
        for (const file of PROPERTY_DB_FILELIST) {
          fetchUrlList.push(ForgeClient.getResourceUrl(basePath + file));
        }
      } else if (manifest.role === "Autodesk.AEC.ModelData") {
        fetchUrlList.push(ForgeClient.getResourceUrl(manifest.urn));
      } else if (manifest.role === "2d") {
        if (!viewable2DNameList.includes(manifest.name)) {
          continue;
        }

        for (const subManifest of manifest.children) {
          const urn = (subManifest as any)?.urn;
          if (!urn) {
            continue;
          }

          const mime = (subManifest as any)?.mime;
          const isF2d = mime === "application/autodesk-f2d";
          if (isF2d || subManifest.role === "pdf-page") {
            fetchUrlList.push(ForgeClient.getResourceUrl(urn));
          }

          if (isF2d) {
            const { basePath } = extractUrnPath(urn);
            for (const file of F2D_RESOURCE_FILELIST) {
              fetchUrlList.push(ForgeClient.getResourceUrl(basePath + file));
            }
          }
        }
      } else if (manifest.role === "3d") {
        if (!viewableNameList.includes(manifest.name)) {
          continue;
        }
        for (const subManifest of manifest.children) {
          if (subManifest.role !== "graphics") {
            continue;
          }
          const svfFileData = await ForgeClient.getResource(
            subManifest.urn,
            authToken
          );
          const svfFileStructure = parseSVF(svfFileData);
          const selectedUriList = svfFileStructure.assets
            .filter(
              (asset) => asset.URI.indexOf("embed:/") === -1 && asset.size
            )
            .map((asset) => asset.URI);
          const { basePath } = extractUrnPath(subManifest.urn);
          for (const url of selectedUriList) {
            fetchUrlList.push(ForgeClient.getResourceUrl(basePath + url));
          }
        }
      }
    }
  }

  return fetchUrlList;
};
