import { FormBuilderState } from "components/containers/inspectionSetting/containers/templateBuilder/context/FormBuilderContext/types";
import { ActionMap } from "interfaces/models/context";
import { PinLayout } from "interfaces/models/pinLayout";
import uniqBy from "lodash/uniqBy";
import { Ctx } from "../containers/selections/setup";
import {
  Item,
  Partial,
} from "components/containers/InspectionFormat/containers/LayoutFrameBuilder/type";
import cloneDeep from "lodash/cloneDeep";

export const DEFAULT_EXTRA_ID = "default";
export const LINKED_DATA_KEY_NO = "no";

export enum LinkedDataHeadKey {
  NO = "no",
  NAME = "name",
}

export type PinLayoutFrame = {
  items: Item[];
  itemSelectedId: string | null;
  itemSelected: Item | null;
  partials: Record<string, Record<string, Record<string, Partial>>>;
  pinLayout: PinLayout;
  clonePinLayoutId?: string;
  src: string;
  sourceData: {
    formBuilderState?: FormBuilderState | null;
    categories?: string[];
  };
  ctx: Ctx;
  zoom: number;
};

export enum SOURCE {
  HEAD = "head",
  BIM = "bim",
  INSPECTION_FORM = "inspection_form",
  ASSET = "asset",
}

// Define the initial state
export const initialPinLayoutFrame: PinLayoutFrame = {
  items: [],
  partials: {},
  itemSelectedId: null,
  itemSelected: null,
  pinLayout: null as any,
  src: null as any,
  sourceData: {},
  ctx: {},
  zoom: 1,
};

export enum Types {
  ADD_ITEM = "ADD_ITEM",
  ADD_ITEMS = "ADD_ITEMS",
  DELETE_ITEM = "DELETE_ITEM",
  UPDATE_ITEM = "UPDATE_ITEM",
  SET_PARTIAL = "SET_PARTIAL",
  CLEAR_PARTIALS = "CLEAR_PARTIALS",
  SET_PARTIALS = "SET_PARTIALS",
  DELETE_PARTIAL = "DELETE_PARTIAL",
  SET_ITEM_SELECTED = "SET_ITEM_SELECTED",
  RESET_ITEMS = "RESET_ITEMS",
}

type PinLayoutFramePayload = {
  [Types.ADD_ITEM]: Item;
  [Types.ADD_ITEMS]: Item[];
  [Types.RESET_ITEMS]: Item[];
  [Types.UPDATE_ITEM]: Item;
  [Types.DELETE_ITEM]: string;
  [Types.SET_ITEM_SELECTED]: string | null;
  [Types.SET_PARTIAL]: Partial;
  [Types.CLEAR_PARTIALS]: undefined;
  [Types.SET_PARTIALS]: Partial[];
  [Types.DELETE_PARTIAL]: { key: string };
};

export type PinLayoutFrameActions =
  ActionMap<PinLayoutFramePayload>[keyof ActionMap<PinLayoutFramePayload>];

// Define the reducer function
const pinLayoutFrameReducer = (
  prevState: PinLayoutFrame,
  action: PinLayoutFrameActions
) => {
  const nextState = cloneDeep(prevState);

  switch (action.type) {
    case Types.RESET_ITEMS:
      nextState.items = action.payload;
      nextState.items = uniqBy(nextState.items, "id");
      break;
    case Types.ADD_ITEMS:
      nextState.items.push(...action.payload);
      nextState.items = uniqBy(nextState.items, "id");
      break;
    case Types.ADD_ITEM:
      nextState.items.push(action.payload);
      nextState.itemSelectedId = action.payload.id;
      break;
    case Types.UPDATE_ITEM:
      {
        const index = nextState.items.findIndex(
          (item) => item.id === action.payload.id
        );
        if (index !== -1) {
          nextState.items[index] = {
            ...nextState.items[index],
            ...action.payload,
          };
        }
      }
      break;
    case Types.DELETE_ITEM:
      const index = nextState.items.findIndex(
        (item) => item.id === action.payload
      );
      if (index !== -1) {
        nextState.items.splice(index, 1);
      }
      if (action.payload === nextState.itemSelectedId) {
        nextState.itemSelectedId = null;
      }
      break;
    case Types.SET_PARTIAL:
      {
        const { id, source, extraId } = action.payload;
        if (!nextState.partials[source]) nextState.partials[source] = {};
        if (!nextState.partials[source][id])
          nextState.partials[source][id] = {};
        nextState.partials[source][id][extraId ?? DEFAULT_EXTRA_ID] =
          action.payload;
      }
      break;
    case Types.SET_PARTIALS:
      action.payload.forEach((data) => {
        const { id, source, extraId } = data;
        if (!nextState.partials[source]) nextState.partials[source] = {};
        if (!nextState.partials[source][id])
          nextState.partials[source][id] = {};
        nextState.partials[source][id][extraId ?? DEFAULT_EXTRA_ID] = data;
      });
      break;
    case Types.CLEAR_PARTIALS:
      nextState.partials = {};
      break;
    case Types.DELETE_PARTIAL:
      {
        const { key } = action.payload;
        delete nextState.partials[key];
      }
      break;
    case Types.SET_ITEM_SELECTED:
      nextState.itemSelectedId = action.payload;
      break;
  }

  return nextState;
};

export { pinLayoutFrameReducer };
