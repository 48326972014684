import { message } from "components/base";
import { FORGE_DATA_FOLDER_PATH } from "constants/s3";
import { FamilyInstanceDTO } from "interfaces/dtos/familyInstance";
import { Level } from "interfaces/models";
import { ApiResponse } from "interfaces/models/api";
import { FamilyInstance } from "interfaces/models/familyInstance";
import { removeEmptyProp } from "utils/common";
import { getAllMappingExternalId } from "utils/forge/viewerData";
import {
  AreaMesh,
  getAreaExtension,
} from "utils/forge/extensions/area-extension";
import { find3DBounds } from "utils/forge/forge3d";
import { getLevelByAecAndDerivative } from "utils/forge/forgeApiData";
import { uploadMultipartToS3 } from "utils/upload-multipart";
import { axiosECS } from "./baseAxios";
import { getObjectMetadata } from "apiClient/v2/s3Api";

export default class GenFamilyInstance {
  private _bimFileId;
  private _version;
  constructor(bimFileId: string, version: string) {
    this._bimFileId = bimFileId;
    this._version = version;
  }

  async checkGenerated() {
    const response = await getObjectMetadata({
      filePath: FORGE_DATA_FOLDER_PATH,
      fileName: `f-data-${encodeURIComponent(this._bimFileId!)}-v${
        this._version
      }.json`,
    });

    return !!response.data.isExists;
  }

  getFamilyInstancesProperties = async ({
    viewer,
    projectId,
  }: {
    viewer: Autodesk.Viewing.GuiViewer3D;
    projectId: string;
  }) => {
    const urn = `${this._bimFileId}?version=${this._version}`;
    const versionId = decodeURIComponent(urn);
    const instanceTree = viewer.model.getData().instanceTree;
    const fragList = viewer.model.getFragmentList();
    const familyInstances: { [key: string]: FamilyInstanceDTO } = {};
    const { levels: levelsData, levelsAceFilter } =
      await getLevelByAecAndDerivative({
        projectId,
        versionId,
      });

    const transformNumberValue = (value?: string) => {
      const numberString = value?.replace(/[^0-9.]/g, "");
      if (!numberString) {
        return "";
      }

      return `${isNaN(Number(numberString)) ? "" : Number(numberString)}`;
    };
    const areaExension = getAreaExtension();
    const spaces = (await areaExension?.getSpaces()) || [];
    const neptuneAreas = areaExension?.getNeptuneArea() || [];
    const mapSpacesByLevel: { [level: string]: AreaMesh[] } = {};
    const mapAreaExternalIdByLevel: { [level: string]: Set<string> } = {};
    neptuneAreas.forEach((area) => {
      mapAreaExternalIdByLevel[area.level] = new Set([
        ...(mapAreaExternalIdByLevel[area.level] || []),
        ...area.externalIds,
      ]);
    });

    Object.keys(mapAreaExternalIdByLevel).forEach((level) => {
      mapSpacesByLevel[level] = spaces.filter((space) =>
        mapAreaExternalIdByLevel[level].has(space.externalId)
      );
    });

    const mappingExternalId = await getAllMappingExternalId();
    if (!mappingExternalId) {
      message.error("ExternalIdを取得できないです。");

      return;
    }

    await Promise.all(
      levelsAceFilter.map(async (level) => {
        const familyInstancePropertiesRes = await this.getViewableProperties({
          guid: level.guid,
          name: level.name,
        });
        const familyInstanceProperties = familyInstancePropertiesRes.data?.data;

        familyInstanceProperties.forEach((item) => {
          const properties = item.properties;
          const name = item?.name;
          if (Object.keys(properties)?.length && name && item.externalId) {
            const bounds = find3DBounds(
              fragList,
              instanceTree,
              (mappingExternalId || {})?.[item.externalId]
            );
            const size = bounds.size();

            if (!size.x && !size.y && !size.z) {
              return;
            }

            const position = bounds.getCenter();
            const spaceIds: string[] = [];
            const level =
              properties["Level"] ||
              properties["Reference Level"] ||
              levelsData.find(
                (level) =>
                  Number(level.zMin) <= position.z &&
                  position.z <= Number(level.zMax)
              )?.label ||
              "";

            const spacesByLevel = mapSpacesByLevel[level] || [];
            const point = new THREE.Vector3();
            const space = spacesByLevel.at(0);
            point.setX(
              position.x * (space?.scale?.x || 1) + (space?.position.x || 0)
            );
            point.setY(
              position.y * (space?.scale?.y || 1) + (space?.position.y || 0)
            );
            point.setZ(
              position.z * (space?.scale?.z || 1) + (space?.position.z || 0)
            );
            const raycaster = new THREE.Raycaster();
            raycaster.set(point, new THREE.Vector3(0, 0, -1));
            const intersects = raycaster.intersectObjects(spacesByLevel);
            const area = spacesByLevel.find(
              (o) => o.uuid === intersects?.[0]?.object?.uuid
            );
            if (area) {
              spaceIds.push(area.externalId);
            }

            familyInstances[item.externalId] = {
              bounds,
              externalId: item.externalId,
              name,
              position,
              typeName: properties["Type Name"] || properties["タイプ名"],
              symbol: (properties["記号"] || "").toString(),
              level,
              systemName: properties["System Name"],
              systemType: properties["System Type"],
              fanType: properties["ファンの種類"],
              designOption:
                properties["Design Option"] ||
                properties["デザイン オプション"],
              sign: properties["符号"],
              estimateConstructionCategory: properties["積算_施工区分"],
              form: properties["形式"],
              size: properties["Size"] || properties["サイズ"],
              diameterRadius: properties["ダクト径_半径"],
              airVolume: transformNumberValue(properties["風量"]),
              openingRate: transformNumberValue(properties["開口率"]),
              faceWindSpeed: transformNumberValue(properties["面風速"]),
              objectTypes: [],
              spaceIds,
            };
            removeEmptyProp(familyInstances[item.externalId]);
          }
        });
      })
    );

    return familyInstances;
  };

  async uploadFamilyInstancesToS3(
    levels: Level[],
    familyInstances: { [key: string]: FamilyInstance }
  ): Promise<boolean> {
    const uploaded = await uploadMultipartToS3({
      fileData: { levels, familyInstances },
      filePath: FORGE_DATA_FOLDER_PATH,
      fileName: `f-data-${encodeURIComponent(this._bimFileId)}-v${
        this._version
      }.json`,
    });

    return uploaded;
  }

  async getViewableProperties(
    level: { guid: string; name: string },
    keys?: string[]
  ): Promise<
    ApiResponse<{
      total: number;
      data: {
        objectid: string;
        name: string;
        externalId: string;
        dbId: number;
        properties: { [key: string]: any };
      }[];
    }>
  > {
    return axiosECS.post(
      `/v1/aps/projects/null/bims/version/${encodeURIComponent(
        `${this._bimFileId}?version=${this._version}`
      )}/properties`,
      {
        level,
        keys: keys || [
          "Reference Level",
          "Level",
          "System Name",
          "記号",
          "System Type",
          "Type Name",
          "タイプ名",
          "ファンの種類",
          "積算_施工区分",
          "符号",
          "形式",
          "Size",
          "サイズ",
          "Design Option",
          "ダクト径_半径",
          "風量",
          "デザイン オプション",
          "開口率",
          "面風速",
        ],
      }
    );
  }
}
