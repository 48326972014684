import { Box, Flex, useMediaQuery } from "@chakra-ui/react";
import BreadCrumbBimFile from "components/customBreadCrumb/BreadCrumbBimFile";
import DropDownLevel from "components/ui/ForgeViewer/DropDownLevel";
import { LEVEL_OTHER } from "constants/forge";
import useGetCurrentPath from "hooks/useGetCurrentPath";
import useLevel from "hooks/useLevel";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

interface Props {}

function ForgeHeaderItem(props: Props) {
  const { params } = useGetCurrentPath();
  const { dataProjectDetail } = useSelector(
    (state: RootState) => state.project
  );
  const { isOnline, syncDataOption } = useSelector(
    (state: RootState) => state.app
  );

  const [isMobileWithWidth] = useMediaQuery("(max-width: 667px)");
  const [isMobileWithHeight] = useMediaQuery("(max-height: 476px)");
  const [isWidthLessThan800] = useMediaQuery("(max-width: 800px)");

  const [isWidthLargeThan800] = useMediaQuery("(min-width: 800px)");
  const [isWidthLessThan1024] = useMediaQuery("(max-width: 1024px)");

  const isMobile = useMemo(
    () => isMobileWithWidth || isMobileWithHeight || isWidthLessThan800,
    [isMobileWithWidth, isMobileWithHeight, isWidthLessThan800]
  );

  const isLargeScreenForgeViewer = useMemo(() => {
    return isWidthLargeThan800 && isWidthLessThan1024;
  }, [isWidthLargeThan800, isWidthLessThan1024]);

  const { isLoadedViewerModelData } = useSelector(
    (state: RootState) => state.forgeViewer
  );

  const {
    levelSelected,
    levels,
    isWaittingCaptureKeynote,
    isGeneratingFamilyInstances,
    isGeneratingAllFamilyInstance,
  } = useSelector((state: RootState) => state.forgeViewer);

  const isDisabledLevelOther = useMemo(() => {
    if (!dataProjectDetail?.id || isOnline) {
      return false;
    }

    const modelCached =
      (syncDataOption?.mapModelCached || {})?.[dataProjectDetail?.id || ""]?.[
        LEVEL_OTHER.label || ""
      ] || {};

    return !modelCached.modelType?.length;
  }, [isOnline, syncDataOption, dataProjectDetail?.id]);

  const { handleChangeFloor } = useLevel(dataProjectDetail?.id);

  return (
    <>
      <Flex
        alignItems={"center"}
        flexDir={"row"}
        gap={{
          base: "0.5rem",
          lg: isLargeScreenForgeViewer ? "0.8rem" : "3.2rem",
        }}
      >
        <Box>
          <BreadCrumbBimFile />
        </Box>

        <DropDownLevel
          isDisabled={
            !params?.bimFileId ||
            isGeneratingFamilyInstances ||
            isGeneratingAllFamilyInstance ||
            isWaittingCaptureKeynote ||
            !isLoadedViewerModelData
          }
          isDisabledLevelOther={isDisabledLevelOther}
          levelSelected={levelSelected}
          levels={levels}
          isMobile={isMobile}
          handleChangeLevel={handleChangeFloor}
        />
      </Flex>
    </>
  );
}

export default ForgeHeaderItem;
