import { ApiResponse } from "interfaces/models/api";
import { ProjectDetail } from "interfaces/models/project";
import { axiosECS } from "services/baseAxios";
import { validateBodyReq } from "utils/common";

const path = "/v1/projects";

export const getProjectList = async (params?: {
  onlyGetListName?: string;
}): Promise<ApiResponse<ProjectDetail[]>> => {
  return axiosECS.get(path, { params });
};

export const getProject = async (
  projectId: string,
  shouldCache = false
): Promise<ApiResponse<ProjectDetail>> => {
  return axiosECS.get(`${path}/${projectId}`, {
    params: {
      shouldCache,
    },
  });
};

export const deleteProject = async (
  id: string
): Promise<ApiResponse<string[]>> => {
  return axiosECS.delete(path, { data: { id } });
};

export const createProject = async (
  project: ProjectDetail
): Promise<ApiResponse<ProjectDetail>> => {
  return axiosECS.post(path, validateBodyReq(project));
};
