import { ApiResponse, Pagination } from "interfaces/models/api";
import { DocumentCategory } from "interfaces/models/documentCategory";
import { DocumentItem } from "interfaces/models/documentItem";
import {
  DocumentTemplate,
  DocumentTemplateCommonData,
} from "interfaces/models/documentTemplate";
import { axiosECS } from "services/baseAxios";
import { validateBodyReq } from "utils/common";
import { transformComponentForOldTemplate } from "utils/document";
import { countTableItems } from "./commonApi";

interface GetDocumentTemplatesReq extends Pagination {
  isActive?: boolean;
  isDefaultTemplate?: boolean;
  notFullData?: boolean;
  bimFileId?: string;
  shouldCache?: boolean;
}
interface GetByBlackboardIdRes extends DocumentTemplateCommonData {
  projectName: string;
}

const path = "/v1/document-templates";
const DEFAULT_LIMIT = 100;

const getTemplateList = async (
  params: GetDocumentTemplatesReq
): Promise<ApiResponse<DocumentTemplate[]>> => {
  // const res = await axiosECS.get(path, {
  //   params: {
  //     ...params,
  //     limit: params.limit || DEFAULT_LIMIT,
  //   },
  // });
  // const templates: DocumentTemplate[] = res?.data || [];

  // return {
  //   ...res,
  //   data: templates.map((template) =>
  //     transformComponentForOldTemplate(template)
  //   ),
  // };
  // actual api temporary not in used
  return { data: [] };
};

export const getTemplate = async (
  templateId: string,
  shouldCache = false
): Promise<ApiResponse<DocumentTemplate>> => {
  const res = await axiosECS.get(`${path}/${templateId}`, {
    params: { shouldCache, useCache: "false" },
  });
  const template: DocumentTemplate = res?.data;

  return { data: transformComponentForOldTemplate(template) };
};

export const getByBlackboardTemplateId = async (
  blackboardTemplateId: string
): Promise<ApiResponse<GetByBlackboardIdRes[]>> => {
  return axiosECS.get(`${path}/blackboard-template/${blackboardTemplateId}`);
};

export const createTemplate = async (
  template: DocumentTemplate & { isDeleteAllData?: boolean }
): Promise<
  ApiResponse<DocumentTemplate> & {
    updateData: {
      update: {
        documentCategories: DocumentCategory[];
        documentItems: DocumentItem[];
      };
    };
  }
> => {
  return axiosECS.post(path, validateBodyReq(template));
};

export const updateTemplate = async (
  template: DocumentTemplate & { isDeleteAllData?: boolean }
): Promise<
  ApiResponse<DocumentTemplate> & {
    updateData: {
      update: {
        documentCategories: DocumentCategory[];
        documentItems: DocumentItem[];
      };
    };
  }
> => {
  return axiosECS.patch(path, validateBodyReq(template));
};

export const generateData = async (
  templateId: string
): Promise<
  ApiResponse<{
    documentCategories: DocumentCategory[];
    documentItems: DocumentItem[];
  }>
> => {
  return axiosECS.post(`${path}/generate-data`, {
    templateId,
  });
};

export const deleteTemplate = async (
  templateId: string
): Promise<ApiResponse<DocumentTemplate>> => {
  return axiosECS.delete(path, { data: { templateId } });
};

export const handleGetTemplateList = async (
  params: GetDocumentTemplatesReq
) => {
  params.paging = params.paging || "offset";
  const templates =
    params.paging === "cursor"
      ? getTemplateListWithCursor(params)
      : getTemplateListWithOffset(params);

  return templates;
};

const getTemplateListWithCursor = async (params: GetDocumentTemplatesReq) => {
  const templates: DocumentTemplate[] = [];
  let cursor: string | undefined;
  do {
    params.cursor = cursor;
    const { data, pagination } = await getTemplateList({
      ...params,
      paging: "cursor",
    });
    cursor = pagination?.cursor;
    if (data?.length) templates.push.apply(templates, data);
  } while (cursor);

  return templates;
};

const getTemplateListWithOffset = async (params: GetDocumentTemplatesReq) => {
  // const documentTemplate: DocumentTemplate[] = [];
  // const res = await countTableItems<GetDocumentTemplatesReq>({
  //   path,
  //   params: {
  //     shouldCache: params.shouldCache,
  //     bimFileId: params.bimFileId,
  //     isDefaultTemplate: params.isDefaultTemplate,
  //     limit: params?.limit ? +params.limit : DEFAULT_LIMIT,
  //   },
  // });
  // const totalItem = res?.data?.totalItem;
  // const totalPage = res?.data?.totalPage;

  // if (totalItem && totalPage) {
  //   await Promise.all(
  //     Array.from({ length: totalPage }, (_, i) => i + 1).map(async (page) => {
  //       const { data } = await getTemplateList({
  //         ...params,
  //         page,
  //         paging: "offset",
  //         total: totalPage,
  //       });
  //       if (data.length) documentTemplate.push.apply(documentTemplate, data);

  //       return page;
  //     })
  //   );
  // }
  // actual api temporary not in used

  return [];
};
