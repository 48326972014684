import { FormStateActions, FormStateState, Types } from "./types";

export const formStateReducer = (
  state: FormStateState,
  action: FormStateActions
) => {
  let next = structuredClone(state);

  switch (action.type) {
    case Types.SET_FIELD_VALUE: {
      const { itemResultId, value } = action.payload;
      next.formValue[itemResultId] = value;

      return next;
    }

    case Types.INIT_VALUE:
      return { ...next, formValue: action.payload };

    case Types.SET_FIELD_ATTRIBUTES: {
      const { itemResultId, ...other } = action.payload;
      next.fields[itemResultId] = next.fields[itemResultId]
        ? { ...next.fields[itemResultId], ...other }
        : other;

      return { ...next };
    }
  }

  return next;
};
