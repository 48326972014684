import { ComponentStyleConfig, defineStyle } from "@chakra-ui/react";

export const variants: ComponentStyleConfig["variants"] = {
  primary: {
    fontSize: "1.4rem",
    lineHeight: "2rem",
    color: "#FFFFFF",
    bgColor: "#009BE0",
    borderRadius: "6px",
    border: "none",
    p: {
      base: "1rem 1.6rem",
      sm: "0.5rem 1rem",
      md: "1rem 1rem",
      lg: "1rem 1.6rem",
    },
    height: {
      base: "fit-content",
      md: "3.6rem",
    },
    boxShadow: "inset 0px -1px 2px #00000029",
    whiteSpace: "pre-wrap",
  },
  filled: {
    backgroundColor: "#009BE0",
    color: "#FFFFFF",
    _disabled: {
      color: "#A3A3A3 !important",
      backgroundColor: "#E5E5E5 !important",
      boxShadow: "unset !important",
      border: "unset",
      "& .icon-base": {
        background: "#A3A3A3",
      },
      "& > span > svg > path": {
        stroke: "#A3A3A3",
      },
    },
    _hover: {
      backgroundColor: "#38BDF8",
    },
    _active: {
      backgroundColor: "#009BE0",
      boxShadow:
        "#FFFFFF 0px 0px 0px 2px, #009BE0 0px 0px 0px 5px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px",
    },
    _pressed: {
      backgroundColor: "#0284C7",
    },
  },
  danger: {
    backgroundColor: "#EF4444",
    color: "#FFFFFF",
    _disabled: {
      color: "#A3A3A3 !important",
      backgroundColor: "#E5E5E5 !important",
      boxShadow: "unset !important",
      border: "unset",
      "& .icon-base": {
        background: "#A3A3A3",
      },
      "& > span > svg > path": {
        stroke: "#A3A3A3",
      },
    },
    _hover: {
      backgroundColor: "#F87171",
    },
    _active: {
      backgroundColor: "#EF4444",
      boxShadow:
        "#FFFFFF 0px 0px 0px 2px, #009BE0 0px 0px 0px 5px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px",
    },
    _pressed: {
      backgroundColor: "#DC2626",
    },
  },
  dangerOutline: {
    color: "#EF4444",
    backgroundColor: "#FFFFFF",
    border: "1px solid",
    borderColor: "#EF4444",
    _disabled: {
      color: "#A3A3A3 !important",
      backgroundColor: "#E5E5E5 !important",
      boxShadow: "unset !important",
      border: "unset",
      "& .icon-base": {
        background: "#A3A3A3",
      },
      "& > span > svg > path": {
        stroke: "#A3A3A3",
      },
    },
    _hover: {
      color: "#ff7875",
      borderColor: "#ffa39e",
    },
    _active: {
      color: "#d9363e",
      borderColor: "#d9363e",
      boxShadow:
        "#FFFFFF 0px 0px 0px 2px, #009BE0 0px 0px 0px 5px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px",
    },
    _pressed: {
      color: "#d9363e",
      borderColor: "#d9363e",
    },
  },
  outline: {
    backgroundColor: "#FFFFFF",
    color: "#009BE0",
    border: "1px solid",
    borderColor: "#A3A3A3",
    _disabled: {
      color: "#A3A3A3 !important",
      backgroundColor: "#E5E5E5 !important",
      boxShadow: "unset !important",
      border: "unset",
      "& .icon-base": {
        background: "#A3A3A3",
      },
      "& > span > svg > path": {
        stroke: "#A3A3A3",
      },
    },
    _hover: {
      backgroundColor: "#F0F9FF",
    },
    _active: {
      backgroundColor: "#FFFFFF",
      boxShadow:
        "#FFFFFF 0px 0px 0px 2px, #009BE0 0px 0px 0px 5px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px",
    },
    _pressed: {
      backgroundColor: "#E0F2FE",
    },
  },
  text: {
    color: "#009BE0",
    _disabled: {
      color: "#A3A3A3 !important",
      fill: "#A3A3A3 !important",
      background: "transparent",
      boxShadow: "unset !important",
      "& .icon-base": {
        background: "#A3A3A3 !important",
      },
      "& > div > svg > path": {
        fill: "#A3A3A3 !important",
        stroke: "#A3A3A3 !important",
      },
    },
    _hover: {
      color: "#38BDF8",
      fill: "#38BDF8",
      background: "transparent",
      "& .icon-base": {
        background: "#38BDF8",
      },
      "& > div > svg > path": {
        fill: "#38BDF8",
        stroke: "#38BDF8",
      },
    },
    _active: {
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px inset, #009BE0 0px 0px 0px 2px inset, rgba(0, 0, 0, 0.05) 0px 1px 2px ",
      color: "#009BE0",
      fill: "#009BE0",
      "& .icon-base": {
        background: "#009BE0",
      },
      "& > div > svg > path": {
        fill: "#009BE0",
        stroke: "#009BE0",
      },
    },
    _pressed: {
      color: "#0284C7",
      fill: "#0284C7",
      "& .icon-base": {
        background: "#0284C7",
      },
      "& > div > svg > path": {
        fill: "#0284C7",
        stroke: "#0284C7",
      },
    },
  },
  dangerText: {
    color: "font.danger",
    _disabled: {
      color: "#A3A3A3 !important",
      fill: "#A3A3A3 !important",
      background: "transparent",
      boxShadow: "unset !important",
      "& .icon-base": {
        background: "#A3A3A3 !important",
      },
      "& > div > svg > path": {
        fill: "#A3A3A3 !important",
        stroke: "#A3A3A3 !important",
      },
    },
    _hover: {
      color: "#ff7875",
    },
    _active: {
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px inset, #009BE0 0px 0px 0px 2px inset, rgba(0, 0, 0, 0.05) 0px 1px 2px ",
      color: "#ff7875",
    },
    _pressed: {
      color: "#ff7875",
    },
  },
};

const baseStyleButton: ComponentStyleConfig["baseStyle"] = {
  paddingInline: "0.6rem",
};

const md = defineStyle({
  fontSize: "1.4rem",
  minHeight: "3.6rem",
  borderRadius: "6px",
  fontWeight: "medium",
});

export const buttonStyle: ComponentStyleConfig = {
  sizes: { md },
  defaultProps: {
    minWidth: "1rem",
    variant: "outline",
    sizes: "md",
  },
  baseStyle: {
    ...baseStyleButton,
  },
  variants: {
    ...variants,
    primaryOutline: {
      ...variants.primary,
      backgroundColor: "transparent",
      color: "primary.default",
      border: "1px solid",
      borderColor: "border.default",
    },
    solid: {
      height: "unset",
    },
    blue: {
      fontSize: "1.4rem",
      lineHeight: "2rem",
      color: "#FFFFFF",
      bgColor: "var(--blue)",
      borderRadius: "4px",
      p: "1.05rem 2.4rem",
      height: "unset",
      boxShadow: "inset 0px -1px 2px #00000029",
    },
    blueLite: {
      fontSize: "1.4rem",
      lineHeight: "2rem",
      color: "#FFFFFF",
      bgColor: "#1795FE",
      borderRadius: "4px",
      p: "1.2rem 2.4rem",
      height: "unset",
      boxShadow: "inset 0px -1px 2px #00000029",
      fontWeight: "medium",
    },
    white: {
      fontSize: "1.4rem",
      lineHeight: "2rem",
      color: "#3CA4FF",
      bgColor: "#FFFFFF",
      borderRadius: "4px",
      p: "1.05rem 2.4rem",
      height: "unset",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "border.default",
    },
    whiteSquare: {
      fontSize: "1.4rem",
      lineHeight: "2rem",
      bgColor: "#FFFFFF",
      borderRadius: "4px",
      p: "1rem",
      height: "unset",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "border.default",
      fontWeight: "bold",
    },
    whiteIcon: {
      fontSize: "1.4rem",
      fontWeight: "medium",
      lineHeight: "1.6rem",
      color: "#000000",
      bgColor: "#FFFFFF",
      borderRadius: "4px",
      p: "0.5rem 1rem",
      height: "4.8rem",
      boxShadow: "0px 3px 6px #00000029",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "border.default",
      _active: {
        boxShadow: "0px 3px 6px #00000029",
      },
    },
    fillWhiteIcon: {
      fontSize: "1.4rem",
      fontWeight: "medium",
      lineHeight: "1.6rem",
      color: "#000000",
      bgColor: "#FFFFFF",
      borderRadius: "4px",
      p: "0.5rem 1rem",
      height: "4.8rem",
      boxShadow: "0px 3px 6px #00000029",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "border.default",
      _active: {
        boxShadow:
          "rgb(255, 255, 255) 0px 0px 0px 0px inset, #009BE0 0px 0px 0px 2px inset, rgba(0, 0, 0, 0.05) 0px 1px 2px",
      },
    },
    red: {
      fontSize: "1.4rem",
      lineHeight: "2rem",
      color: "#FFFFFF",
      bgColor: "#B00020",
      borderRadius: "4px",
      p: "1.05rem 2.4rem",
      height: "unset",
      boxShadow: "inset 0px -1px 2px #00000029",
    },
    gray: {
      fontSize: "1.4rem",
      lineHeight: "2rem",
      color: "#00000099",
      bgColor: "#E0E1E2",
      borderRadius: "4px",
      p: "1.05rem 2.4rem",
      height: "unset",
      boxShadow: "inset 0px -1px 2px #0000003D",
    },
    black: {
      fontSize: "1.4rem",
      lineHeight: "2rem",
      color: "#FFFFFF",
      bgColor: "var(--black)",
      borderRadius: "4px",
      p: "1.05rem 2.4rem",
    },
    tab: {
      p: "1.4rem 3rem",
      fontSize: "1.6rem",
      color: "#000000CC",
      mt: "1.1rem",
      height: "unset",
    },
    back: {
      p: "0",
      fontSize: "1.6rem",
      color: "var(--blue)",
      height: "unset",
    },
  },
};
