import { Box, Flex, Img, Text } from "@chakra-ui/react";
import HeaderCommon from "components/editor-builder/component-preview/HeaderCommon";
import { withPresignedUrl } from "components/HOC/presignedUrl";
import {
  ContentType,
  TemplateComponentProps,
  TemplateComponentType,
} from "constants/enum";
import { TemplateComponent } from "interfaces/models/component";
import {
  DEFAULT_BORDER_COLOR_ACTIVE,
  DEFAULT_BORDER_COLOR_DEFAULT,
} from "pages/document/template-page/hooks";
import { memo, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setComponentSelected } from "redux/documentSlice";
import { RootState } from "redux/store";
import { hexToRgbA } from "utils/common";
import { COMPONENT_SELECTED_OPACITY } from "constants/documentTemplate";

const ImgPresigned = withPresignedUrl(Img);

interface Props {
  component: TemplateComponent;
  isResizable?: boolean;
  isOnlyView?: boolean;
  contentType?: ContentType;
  zoomRatio: number;

  isShowDummyOfTaskSheetTemplate?: boolean;
}

const ImageComponentPreview = ({
  component,
  isResizable,
  isOnlyView = false,
  isShowDummyOfTaskSheetTemplate = false,
  contentType = ContentType.EDITOR,
  zoomRatio,
}: Props) => {
  const dispatch = useDispatch();
  const { componentSelected, components } = useSelector(
    (state: RootState) => state.document
  );

  const isComponentImageUploaded = useMemo(
    () => component.type === TemplateComponentType.ImageUploaded,
    [component.type]
  );

  const imageUploadedSrc = useMemo(
    () => component?.detail?.imageUploaded?.src,
    [component?.detail?.imageUploaded?.src]
  );

  const isSelectComponent = useMemo(() => {
    return (
      !isOnlyView && componentSelected.componentId === component.componentId
    );
  }, [component.componentId, componentSelected.componentId, isOnlyView]);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    component.componentId !== componentSelected.componentId &&
      dispatch(setComponentSelected(component));
  };

  const renderText = useMemo(() => {
    let text = "キーノート";
    if (component.type) {
      text = TemplateComponentProps?.[component.type]?.name || "キーノート";
    }

    return (
      <Text fontSize="1.2rem" color="#707070">
        {text}
      </Text>
    );
  }, [component?.type]);

  const backgroundColor = useMemo(() => {
    const bgColor = "#fff";

    if (!isSelectComponent) {
      return bgColor;
    }

    return hexToRgbA(bgColor, COMPONENT_SELECTED_OPACITY);
  }, [isSelectComponent]);

  if (
    contentType === ContentType.EDITOR &&
    isShowDummyOfTaskSheetTemplate &&
    isOnlyView
  ) {
    return (
      <Box
        id="img-container"
        style={{
          width: "100%",
          height: "100%",
          position: "relative",
          border: "1px solid #ccc",
          background: backgroundColor,
        }}
      >
        <ImgPresigned
          isSkipPresigned={true}
          src={
            component.type === TemplateComponentType.Keyplan
              ? "/img/task-sheet-template/keyplan-placeholder.png"
              : component.type === TemplateComponentType.TasksImage
              ? "/img/task-sheet-template/tasks-image-placeholder.png"
              : component.type === TemplateComponentType.PhotoBooksInstruction
              ? "/img/task-sheet-template/photo-books-instructions-placeholder.png"
              : component.type === TemplateComponentType.PhotoBooksReport
              ? "/img/task-sheet-template/photo-books-report-placeholder.png"
              : ""
          }
          alt=""
          style={{
            width: "100%",
            maxWidth: "100%",
            maxHeight: "100%",
            margin: "auto",
            display: "block",
          }}
          crossOrigin="anonymous"
        />
      </Box>
    );
  }

  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        width: "100%",
        height: "100%",
        cursor: isOnlyView ? "initial" : "move",
        background: backgroundColor,
        border: `1px solid ${
          isSelectComponent
            ? DEFAULT_BORDER_COLOR_ACTIVE
            : DEFAULT_BORDER_COLOR_DEFAULT
        }`,
      }}
      {...(isOnlyView
        ? {}
        : {
            onClick: handleClick,
          })}
    >
      {isResizable && !isOnlyView && (
        <HeaderCommon
          components={components}
          isSelectComponentOnly={isSelectComponent}
          component={component}
          left="-1px"
        />
      )}
      {imageUploadedSrc && isComponentImageUploaded && (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            position: "relative",
          }}
        >
          <ImgPresigned
            src={imageUploadedSrc}
            alt=""
            style={{
              width: "100%",
              maxWidth: "98%",
              maxHeight: "98%",
              margin: "auto",
              display: "block",
              objectFit: "contain",
            }}
            crossOrigin="anonymous"
            boxLoadingWidth="100%"
            boxLoadingHeight={component?.realSize.height * zoomRatio}
          />
        </Box>
      )}

      {!imageUploadedSrc &&
        isComponentImageUploaded &&
        contentType !== ContentType.EDITOR && (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "98%",
              height: "98%",
              margin: "auto",
              backgroundColor,
              overflow: "hidden",
            }}
          >
            画像なし
          </Box>
        )}

      {!imageUploadedSrc && contentType === ContentType.EDITOR && (
        <Flex
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
          bgColor={backgroundColor}
          overflow="hidden"
        >
          {renderText}
        </Flex>
      )}
    </Box>
  );
};

export default memo(ImageComponentPreview);
