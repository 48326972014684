import {
  Box,
  BoxProps,
  Button,
  Center,
  Flex,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { ClearDatabaseCachedIcon } from "components/icon";
import ProfileMenu from "components/Layout/ProfileMenu";
import OfflineBar from "components/OfflineBar";
import { SvgIcon } from "components/SvgIcon";
import useGetCurrentPath from "hooks/useGetCurrentPath";
import { DataProjectModel } from "interfaces/models/dataProjectModel";
import { ReactNode, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setIsCreateProject } from "redux/bimSlice";
import { resetWidgetsMode } from "redux/forgeViewerSlice";
import { setDataProjectDetail } from "redux/projectSlice";
import { RootState } from "redux/store";
import { routePath } from "routes/path";
import { storageCookie } from "utils/storage";
import { LAST_ACCESS_PAGE_KEY } from "constants/app";

interface Props extends BoxProps {
  bg?: string;
  projectId?: string;
  headerProps?: {
    isShowSyncDataForOfflineIcon?: boolean;
    isShowClearDatabaseCached?: boolean;
    isShowButtonOldVersion?: boolean;
    isShowSetting?: boolean;
    leftMoreContent?: ReactNode;
  };
}

const Header = ({ bg, headerProps, ...rest }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isWidthLargeThan800] = useMediaQuery("(min-width: 800px)");
  const [isWidthLessThan1024] = useMediaQuery("(max-width: 1024px)");
  const { widgetsMode } = useSelector((state: RootState) => state.forgeViewer);
  const { currentUser } = useSelector((state: RootState) => state.user);
  const { pathPattern } = useGetCurrentPath();

  const {
    isShowSyncDataForOfflineIcon,
    isShowClearDatabaseCached,
    isShowButtonOldVersion,
    leftMoreContent,
  } = headerProps || {};

  const isWidthFrom800To1024 = useMemo(() => {
    return isWidthLargeThan800 && isWidthLessThan1024;
  }, [isWidthLargeThan800, isWidthLessThan1024]);

  const isLargeScreenForgeViewer = useMemo(() => {
    return isWidthFrom800To1024 && routePath.ForgeViewer === pathPattern;
  }, [isWidthFrom800To1024, pathPattern]);

  const isPageForgeViewer = useMemo(() => {
    return [routePath.ForgeViewer]?.includes(pathPattern);
  }, [pathPattern]);

  const updateStatusLastAccessPage = useCallback((page: string) => {
    const currentDomain = window.location.hostname;
    const parts = currentDomain.split(".");
    parts.reverse();
    const domain = parts.length > 1 ? `${parts[1]}.${parts[0]}` : currentDomain;
    storageCookie(LAST_ACCESS_PAGE_KEY, page, {
      domain,
      expires: 24 * 365 * 3600,
    });
  }, []);

  useEffect(() => {
    const url = new URL(window.location.href);
    // only redirect to old page if access to page by base path
    if (
      process.env.REACT_APP_OLD_DOMAIN_URL &&
      url.pathname === "/" &&
      document.cookie.includes(`${LAST_ACCESS_PAGE_KEY}=old`)
    ) {
      window.location.href = process.env.REACT_APP_OLD_DOMAIN_URL!;
    } else {
      updateStatusLastAccessPage("new");
    }
  }, []);

  const navigateOldVersion = useCallback(() => {
    updateStatusLastAccessPage("old");
    window.location.href = process.env.REACT_APP_OLD_DOMAIN_URL!;
  }, [updateStatusLastAccessPage]);

  const navigateToHome = useCallback(() => {
    navigate(routePath.Home);
    dispatch(setDataProjectDetail({} as DataProjectModel));
    dispatch(setIsCreateProject(false));
    widgetsMode && dispatch(resetWidgetsMode());
  }, [dispatch, navigate, widgetsMode]);

  return (
    <>
      <Box
        id="neptune-header"
        minH="var(--header-height)"
        bgColor={bg || "#fff"}
        zIndex="tooltip"
        display="flex"
        alignItems="center"
        borderBottom="1px solid #D4D4D4"
        pr={isLargeScreenForgeViewer ? "0.8rem" : "1.6rem"}
        gap="0.8rem"
      >
        <Flex
          overflow="visible"
          as="header"
          alignItems="center"
          flex="1"
          alignSelf="stretch"
          gap={{ base: "0.5rem", xl: "1.2rem" }}
          pr={{ base: 0, xl: "0.8rem" }}
        >
          {/* logo */}
          <Center
            height="100%"
            borderRight="1px solid #D4D4D4"
            flexShrink={0}
            cursor="pointer"
            onClick={navigateToHome}
          >
            <SvgIcon height="100%" src="/img/icon-home.svg" />
          </Center>

          {/* Name App */}
          <Box
            flex={{
              base: isPageForgeViewer ? "0 0 5rem" : "0 0 auto",
              xl: "0 0 10rem",
            }}
          >
            <Text
              fontSize="1.8rem"
              color="#215283"
              fontWeight="500"
              letterSpacing="1px"
              cursor="pointer"
              onClick={navigateToHome}
            >
              品質管理
            </Text>
          </Box>

          {isShowButtonOldVersion && process.env.REACT_APP_OLD_DOMAIN_URL && (
            <Button
              onClick={navigateOldVersion}
              variant={"outline"}
              height="4rem"
            >
              旧システムへ
            </Button>
          )}

          {/* Icons Setting and Clear Cache */}
          {isShowClearDatabaseCached && <ClearDatabaseCachedIcon />}
          {/* Left More Content */}
          {leftMoreContent}
        </Flex>

        <Flex
          gap={isLargeScreenForgeViewer ? "0.2rem" : "0.8rem"}
          alignItems="center"
          sx={{ gap: 0 }}
          width={"auto"}
        >
          {/* On right Side of Header */}
          <OfflineBar
            isShowSyncDataForOfflineIcon={!!isShowSyncDataForOfflineIcon}
          />
          {!!currentUser && (
            <ProfileMenu
              width="2.8rem"
              height="2.8rem"
              marginRight={isLargeScreenForgeViewer ? "1.2rem" : "0px"}
            />
          )}
        </Flex>
      </Box>
    </>
  );
};

export default Header;
