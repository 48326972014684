import { z } from "zod";

const ThumbnailManifestItem = z.object({
  guid: z.string(),
  mime: z.string(),
  role: z.literal("thumbnail"),
  status: z.string(),
  type: z.literal("resource"),
  urn: z.string(),
});

const PropertyDatabaseManifestItem = z.object({
  guid: z.string(),
  mime: z.string(),
  role: z.literal("Autodesk.CloudPlatform.PropertyDatabase"),
  status: z.string(),
  type: z.literal("resource"),
  urn: z.string(),
});

const AECModelDataManifestItem = z.object({
  guid: z.string(),
  mime: z.string(),
  role: z.literal("Autodesk.AEC.ModelData"),
  status: z.string(),
  type: z.literal("resource"),
  urn: z.string(),
});

const View3DManifestItem = z.object({
  camera: z.array(z.number()),
  guid: z.string(),
  name: z.string(),
  progress: z.string(),
  status: z.string(),
  role: z.literal("3d"),
  type: z.literal("view"),
});

const SVFGraphicsManifestItem = z.object({
  guid: z.string(),
  mime: z.string(),
  role: z.literal("graphics"),
  type: z.literal("resource"),
  urn: z.string(),
});

const Resource3DManifestItem = z.object({
  guid: z.string(),
  hasThumbnail: z.string(),
  name: z.string(),
  phaseNames: z.string(),
  progress: z.string(),
  role: z.literal("3d"),
  status: z.string(),
  type: z.literal("geometry"),
  viewableID: z.string(),
  isMasterView: z.boolean().optional(),
  children: z.array(
    z.discriminatedUnion("role", [
      View3DManifestItem,
      ThumbnailManifestItem,
      SVFGraphicsManifestItem,
    ])
  ),
});

const PDFManifestItem = z.object({
  guid: z.string(),
  mime: z.string(),
  role: z.literal("pdf-page"),
  status: z.string(),
  type: z.literal("resource"),
  urn: z.string(),
});

const View2DManifestItem = z.object({
  guid: z.string(),
  name: z.string(),
  role: z.literal("2d"),
  type: z.literal("view"),
  viewbox: z.array(z.number()),
});

const F2DManifestItem = z.object({
  guid: z.string(),
  mime: z.string(),
  role: z.literal("graphics"),
  status: z.string(),
  type: z.literal("resource"),
  urn: z.string(),
});

const ViewInfoItem = z.object({
  guid: z.string(),
  type: z.literal("resource"),
  role: z.literal("metadata"),
});

const Resource2DManifestItem = z.object({
  role: z.literal("2d"),
  guid: z.string(),
  hasThumbnail: z.string(),
  name: z.string(),
  progress: z.string(),
  status: z.string(),
  type: z.literal("geometry"),
  viewableID: z.string(),
  children: z.array(
    z.discriminatedUnion("role", [
      ThumbnailManifestItem,
      View2DManifestItem,
      PDFManifestItem,
      F2DManifestItem,
      ViewInfoItem,
    ])
  ),
});

const ManifestItem = z.discriminatedUnion("role", [
  ThumbnailManifestItem,
  PropertyDatabaseManifestItem,
  AECModelDataManifestItem,
  Resource3DManifestItem,
  Resource2DManifestItem,
]);

const SVFManifestOverview = z.object({
  outputType: z.literal("svf"),
  extractorVersion: z.string(),
  hasThumbnail: z.string(),
  name: z.string(),
  overrideOutputType: z.string(),
  progress: z.string(),
  status: z.string(),
  children: z.array(ManifestItem),
});

const ThumbnailManifestOverview = z.object({
  outputType: z.literal("thumbnail"),
  progress: z.string(),
  status: z.string(),
  children: z.array(ThumbnailManifestItem),
});

export const ManifestOverview = z.discriminatedUnion("outputType", [
  SVFManifestOverview,
  ThumbnailManifestOverview,
]);

export type ManifestOverviewType = z.infer<typeof ManifestOverview>;
