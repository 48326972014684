export const LabelClicked = "LabelClicked";

export enum ForgeLabelEvent {
  FIT_LABEL_TO_VIEW = "FitLabelToView",
}

export const MAX_INTERSECT_LABEL = 800;

export const COMMON_AREA_POSITION = {
  x: { xMin: 10, xMax: 340 },
  y: { yMin: -20, yMax: 275 },
  z: 0,
};

export const COMBINED_LABEL_SIZE = { x: 70, y: 54 };

export const DisplayTaskSettings = {
  displayTaskTitle: { label: "指摘タイトル", class: "containerTitle" },
  displayTaskId: { label: "ID", class: "displayId" },
  displayTaskPhoto: { label: "写真", class: "displayPhoto" },
};

export const DisplayDocumentSettings = {
  displayDocumentTitle: {
    label: "書類名称",
    class: "containerTitle",
    areaClass: "area-containerTitle",
  },
  displayDocumentSubTitle: {
    label: "ピンのタイトル",
    class: "containerSubTitle",
    areaClass: "area-containerSubTitle",
  },
};
