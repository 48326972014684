import { VIEW_ROLE } from "constants/forge";
import { ApiResponse } from "interfaces/models/api";
import { ForgeProject } from "interfaces/models/project";
import { axiosECS } from "services/baseAxios";

const pathV1 = "/v1/aps/projects";

export type View = {
  name: string;
  guid: string;
  isMasterView?: boolean;
  role: VIEW_ROLE;
  sheetGuid?: string;
};

interface ForgeReq {
  projectId: string;
  forceGet?: boolean;
}

export const getProjects = async (
  forceGet?: boolean
): Promise<ApiResponse<ForgeProject[]>> => {
  return axiosECS.get(pathV1, { params: { forceGet: forceGet || false } });
};

export const getProjectById = async ({
  projectId,
  forceGet,
}: ForgeReq): Promise<ApiResponse<ForgeProject>> => {
  return axiosECS.get(`${pathV1}/${projectId}`, {
    params: { forceGet: forceGet || false },
  });
};

export const getFolderById = async ({
  projectId,
  folderId,
  forceGet,
}: ForgeReq & { folderId: string }): Promise<ApiResponse<any[]>> => {
  return axiosECS.get(`${pathV1}/${projectId}/folders/${folderId}`, {
    params: { forceGet: forceGet || false },
  });
};

export const getTopFolder = async ({
  projectId,
  forceGet,
}: ForgeReq): Promise<ApiResponse<any[]>> => {
  return axiosECS.get(`${pathV1}/${projectId}/folders/top-folder`, {
    params: { forceGet: forceGet || false },
  });
};

export const getFirstBim = async ({
  projectId,
}: ForgeReq): Promise<ApiResponse<any>> => {
  return axiosECS.get(`${pathV1}/${projectId}/bims/first`);
};

export const getDerivativesByVersionId = async ({
  projectId,
  versionId,
  shouldCache,
}: ForgeReq & { versionId: string; shouldCache?: boolean }): Promise<
  ApiResponse<any>
> => {
  return axiosECS.get(
    `${pathV1}/${projectId}/bims/version/${versionId}/derivatives`,
    {
      params: {
        shouldCache,
      },
    }
  );
};

export const getVersionsByBimFileId = async ({
  projectId,
  bimFileId,
  shouldCache,
}: ForgeReq & { bimFileId: string; shouldCache?: boolean }): Promise<
  ApiResponse<any[]>
> => {
  return axiosECS.get(`${pathV1}/${projectId}/bims/${bimFileId}/versions`, {
    params: {
      shouldCache,
    },
  });
};
export const getExternalIdsByVersionId = async ({
  projectId,
  versionId,
  level,
  shouldCache,
}: ForgeReq & {
  versionId: string;
  level?: string;
  shouldCache?: boolean;
}): Promise<ApiResponse<any>> => {
  return axiosECS.post(`${pathV1}/${projectId}/bims/external-ids`, {
    params: {
      shouldCache,
      versionId,
      level,
    },
  });
};

export const getAECByVersionId = async ({
  projectId,
  versionId,
  shouldCache,
}: ForgeReq & { versionId: string; shouldCache?: boolean }): Promise<
  ApiResponse<any>
> => {
  return axiosECS.get(`${pathV1}/${projectId}/bims/version/${versionId}/aec`, {
    params: { shouldCache },
  });
};

export const getAllBimViewByVersionId = async (
  projectId: string,
  versionId: string
): Promise<ApiResponse<View[]>> => {
  return axiosECS.get(`${pathV1}/${projectId}/bims/version/${versionId}/views`);
};

// API NOT USE ANY MORE
export const getBimList = async ({
  projectId,
  searchValue,
  isAllVersion,
}: ForgeReq & { searchValue?: string; isAllVersion?: boolean }): Promise<
  ApiResponse<any[]>
> => {
  return axiosECS.get(`${pathV1}/${projectId}/bims`, {
    params: { searchValue, isAllVersion },
  });
};

export const getFolders = async ({
  projectId,
}: ForgeReq): Promise<ApiResponse<any[]>> => {
  return axiosECS.get(`${pathV1}/${projectId}/folders`);
};

export const getExternalIdsByLevel = async (params: {
  versionId: string;
  level: string;
  shouldCache?: boolean;
}): Promise<ApiResponse<any>> => {
  return axiosECS.get(`${pathV1}/null/bims/external-ids`, { params });
};
