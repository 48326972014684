import { Icon, IconProps } from "@chakra-ui/icons";

const LockIcon = (props: IconProps) => {
  return (
    <Icon width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M6.30775 21.5C5.80908 21.5 5.38308 21.3234 5.02975 20.9703C4.67658 20.6169 4.5 20.1909 4.5 19.6923V10.3077C4.5 9.80908 4.67658 9.38308 5.02975 9.02975C5.38308 8.67658 5.80908 8.5 6.30775 8.5H7.5V6.5C7.5 5.25133 7.93783 4.18917 8.8135 3.3135C9.68917 2.43783 10.7513 2 12 2C13.2487 2 14.3108 2.43783 15.1865 3.3135C16.0622 4.18917 16.5 5.25133 16.5 6.5V8.5H17.6923C18.1909 8.5 18.6169 8.67658 18.9703 9.02975C19.3234 9.38308 19.5 9.80908 19.5 10.3077V19.6923C19.5 20.1909 19.3234 20.6169 18.9703 20.9703C18.6169 21.3234 18.1909 21.5 17.6923 21.5H6.30775ZM6.30775 20H17.6923C17.7821 20 17.8558 19.9712 17.9135 19.9135C17.9712 19.8558 18 19.7821 18 19.6923V10.3077C18 10.2179 17.9712 10.1442 17.9135 10.0865C17.8558 10.0288 17.7821 10 17.6923 10H6.30775C6.21792 10 6.14417 10.0288 6.0865 10.0865C6.02883 10.1442 6 10.2179 6 10.3077V19.6923C6 19.7821 6.02883 19.8558 6.0865 19.9135C6.14417 19.9712 6.21792 20 6.30775 20ZM12 16.75C12.4858 16.75 12.899 16.5798 13.2395 16.2395C13.5798 15.899 13.75 15.4858 13.75 15C13.75 14.5142 13.5798 14.101 13.2395 13.7605C12.899 13.4202 12.4858 13.25 12 13.25C11.5142 13.25 11.101 13.4202 10.7605 13.7605C10.4202 14.101 10.25 14.5142 10.25 15C10.25 15.4858 10.4202 15.899 10.7605 16.2395C11.101 16.5798 11.5142 16.75 12 16.75ZM9 8.5H15V6.5C15 5.66667 14.7083 4.95833 14.125 4.375C13.5417 3.79167 12.8333 3.5 12 3.5C11.1667 3.5 10.4583 3.79167 9.875 4.375C9.29167 4.95833 9 5.66667 9 6.5V8.5Z"
        fill="currentcolor"
      />
    </Icon>
  );
};

export default LockIcon;
