import { useCallback, useMemo, useRef } from "react";
import { Flex, FlexProps, useBoolean, Text } from "@chakra-ui/react";
import Moveable, { OnDrag, OnDragEnd, OnDragStart } from "react-moveable";
import { uuid } from "utils/common";
import { useContainer } from "../context/container";
import { useWindowResize } from "hooks/useWindowResize";
import { isContainElement } from "utils/dom";
import { useLayoutFrameBuilderContext } from "../context/layoutFrameBuilderContext";
import { Position } from "../type";
import TyphographyIcon from "components/icon/TyphographyIcon";

export interface DropableBoxProps<SOURCE = any> extends FlexProps {
  children: any;
  icon?: React.ReactElement;
  name: string;
  id: string;
  extraId?: string | number;
  source: SOURCE;
}

const DropableBox: React.FC<DropableBoxProps> = ({
  children,
  icon = <TyphographyIcon w="2rem" h="2rem" />,
  name,
  id,
  source,
  extraId,
  ...other
}) => {
  const selectionBoxRef = useRef<any>();
  const moveableRef = useRef<any>();
  const dragOffset = useRef<any>();
  const [isDraggable, draggable] = useBoolean(false);
  const containerRef = useContainer();
  const { items, itemSelected, onCreate, isLoading } =
    useLayoutFrameBuilderContext();
  const elementGuidelines = useMemo(() => {
    return items.map((i) => `#item-${i.id}`);
  }, [items]);

  const isCurrentSelected =
    itemSelected?.linkedData?.source === source &&
    itemSelected?.linkedData?.key === id &&
    itemSelected?.linkedData?.extraId === extraId;

  const onFinish = useCallback(
    (position: Position) => {
      onCreate({
        id: uuid(),
        linkedData: {
          key: id,
          source,
          extraId,
        },
        ...position,
      });
    },
    [onCreate, id, source, extraId]
  );

  const onDrag = useCallback(
    (e: OnDrag) => {
      e.target.style.transform = e.transform;
      if (isContainElement(e.target as HTMLDivElement, containerRef.current)) {
        containerRef.current.style.borderColor = "#1a1a1a";
      } else {
        containerRef.current.style.borderColor = "red";
      }
    },
    [containerRef]
  );

  const onDragStart = useCallback(
    (e: OnDragStart) => {
      const itemRect = e.target.getBoundingClientRect();
      draggable.on();
      dragOffset.current = {
        x: e.clientX - itemRect.left,
        y: e.clientY - itemRect.top,
      };
    },
    [draggable]
  );

  const onDragEnd = useCallback(
    (e: OnDragEnd) => {
      const containerBounding = containerRef.current.getBoundingClientRect();
      if (isContainElement(e.target as HTMLDivElement, containerRef.current)) {
        // Calculate new position of item on the second list
        const left = e.clientX - containerBounding.left - dragOffset.current.x;
        const top = e.clientY - containerBounding.top - dragOffset.current.y;

        let leftPercent = (left / containerBounding.width) * 100;
        let topPercent = (top / containerBounding.height) * 100;

        const elementBounding = e.target.getBoundingClientRect();

        let widthPercent =
          (elementBounding.width / containerBounding.width) * 100;
        let heightPercent =
          (elementBounding.height / containerBounding.height) * 100;

        const rightPercent = leftPercent + widthPercent;
        const bottomPercent = topPercent + heightPercent;

        if (rightPercent > 100) {
          leftPercent = Math.max(0, leftPercent - (rightPercent - 100));
        }

        if (bottomPercent > 100) {
          topPercent = Math.max(0, topPercent - (bottomPercent - 100));
        }

        leftPercent = Math.max(0, leftPercent);
        topPercent = Math.max(0, topPercent);

        if (topPercent + heightPercent > 100) {
          heightPercent = 100 - topPercent;
        }

        if (leftPercent + widthPercent > 100) {
          widthPercent = 100 - leftPercent;
        }

        onFinish({
          left: leftPercent,
          top: topPercent,
          width: widthPercent,
          height: heightPercent,
        });
      }

      containerRef.current.style.borderColor = "#1a1a1a";

      // Reset position after user drop
      e.target.style.transform = "";
      draggable.off();
    },
    [containerRef, draggable, onFinish]
  );

  useWindowResize(() => {
    moveableRef.current?.updateRect();
  });

  return (
    <Flex
      position={"relative"}
      {...other}
      sx={{
        ".draggable-area": {
          bg: isCurrentSelected ? "#2d9bf0 !important" : "var(--field-bg)",
        },
      }}
    >
      {children}
      <Flex
        position={"absolute"}
        top={0}
        left={0}
        zIndex={1}
        ref={selectionBoxRef}
        fontWeight={"bold"}
        boxSizing="border-box"
        cursor={"move"}
        w="100%"
        h="100%"
        opacity={isDraggable ? 1 : 0}
        justifyContent={"center"}
        alignItems={"center"}
        bg={"#2d9bf042"}
        color="#4af"
        gap="0.5rem"
      >
        <Flex
          justifyContent={"center"}
          alignItems={"center"}
          width={"100%"}
          height="auto"
          maxH={"70%"}
          maxW={"50%"}
          filter="grayscale(100%)"
          opacity="0.3"
        >
          {icon}
        </Flex>
        <Flex
          position={"absolute"}
          top={0}
          left="0"
          p="0.5rem"
          alignItems={"center"}
          whiteSpace={"nowrap"}
          maxW="100%"
        >
          {icon && (
            <Flex
              sx={{
                svg: {
                  w: "100%",
                  h: "100%",
                },
              }}
              flex={"0 0 2rem"}
              w={"2rem"}
              h={"2rem"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              {icon}
            </Flex>
          )}
          <Text maxW="100%" overflow={"hidden"} textOverflow={"ellipsis"}>
            {name}
          </Text>
        </Flex>
      </Flex>
      <Moveable
        ref={moveableRef}
        target={selectionBoxRef}
        resizable={!isLoading}
        draggable={!isLoading}
        throttleDrag={1}
        edge={false}
        renderDirections={["nw", "ne", "sw", "se"]}
        origin={false}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onDrag={onDrag}
        elementGuidelines={elementGuidelines}
        isDisplaySnapDigit
        isDisplayInnerSnapDigit
        snapGap
        snappable
        snapThreshold={0}
        snapVerticalThreshold={0}
        snapHorizontalThreshold={0}
        onRender={(e) => {
          e.target.style.content = `'${e.cssText}'`;
        }}
      />
    </Flex>
  );
};

export default DropableBox;
