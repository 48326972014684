import { useCallback, useMemo } from "react";
import { logDev } from "utils/logs";
import useFormState from "./useFormState";

interface Option {
  isReadOnly?: boolean;
}

export const useFieldState = (itemResultId: string, option: Option = {}) => {
  const { value, isReadOnly, isDisabled, setValue, getField } = useFormState({
    itemResultId,
  });

  const attributes = useMemo(
    () => getField?.(itemResultId) ?? {},
    [itemResultId, getField]
  );

  const onChange = useCallback(
    (value: any) => {
      if (isReadOnly || isDisabled || option.isReadOnly) return;
      logDev("Change value", itemResultId, value);
      setValue?.(itemResultId, value);
    },
    [isReadOnly, isDisabled, itemResultId, option.isReadOnly, setValue]
  );

  const props = useMemo(() => {
    return {
      isDisabled,
      isReadOnly: isReadOnly || option.isReadOnly,
      ...attributes,
      value,
      onChange,
    };
  }, [value, attributes, isDisabled, isReadOnly, option.isReadOnly, onChange]);

  return props;
};
