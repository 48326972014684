import { Icon, IconProps } from "@chakra-ui/icons";

const PinOnMapIcon = (props: IconProps) => {
  return (
    <Icon width="1em" height="1em" viewBox="0 0 38 49" fill="none" {...props}>
      <g id="pin-on-map-content">
        <mask
          id="path-1-outside-1_389_40654"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="38"
          height="49"
          fill="black"
        >
          <rect fill="white" width="38" height="49" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19 3C10.1634 3 3 10.1634 3 19C3 26.5568 8.23883 32.89 15.2827 34.5659L18.9991 42L22.7167 34.5661C29.7609 32.8904 35 26.557 35 19C35 10.1634 27.8366 3 19 3Z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19 3C10.1634 3 3 10.1634 3 19C3 26.5568 8.23883 32.89 15.2827 34.5659L18.9991 42L22.7167 34.5661C29.7609 32.8904 35 26.557 35 19C35 10.1634 27.8366 3 19 3Z"
          fill="#009BE0"
        />

        {/*  border  */}
        <path
          d="M15.2827 34.5659L17.9661 33.2245L17.3398 31.9716L15.9771 31.6474L15.2827 34.5659ZM18.9991 42L16.3157 43.3414L18.9986 48.7082L21.6823 43.3418L18.9991 42ZM22.7167 34.5661L22.0225 31.6475L20.66 31.9716L20.0336 33.2242L22.7167 34.5661ZM6 19C6 11.8203 11.8203 6 19 6V0C8.50659 0 0 8.50659 0 19H6ZM15.9771 31.6474C10.254 30.2858 6 25.136 6 19H0C0 27.9776 6.22365 35.4943 14.5884 37.4845L15.9771 31.6474ZM21.6825 40.6586L17.9661 33.2245L12.5994 35.9074L16.3157 43.3414L21.6825 40.6586ZM20.0336 33.2242L16.3159 40.6582L21.6823 43.3418L25.3999 35.9079L20.0336 33.2242ZM32 19C32 25.1362 27.7458 30.286 22.0225 31.6475L23.411 37.4846C31.776 35.4947 38 27.9779 38 19H32ZM19 6C26.1797 6 32 11.8203 32 19H38C38 8.50659 29.4934 0 19 0V6Z"
          fill="#B7B7B7"
          mask="url(#path-1-outside-1_389_40654)"
        />
        <mask
          id="path-3-outside-2_389_40654"
          maskUnits="userSpaceOnUse"
          x="1"
          y="1"
          width="36"
          height="46"
          fill="black"
        >
          <rect fill="white" x="1" y="1" width="36" height="46" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19 3C10.1634 3 3 10.1634 3 19C3 26.5568 8.23883 32.89 15.2827 34.5659L18.9991 42L22.7167 34.5661C29.7609 32.8904 35 26.557 35 19C35 10.1634 27.8366 3 19 3Z"
          />
        </mask>

        {/*  background  */}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19 3C10.1634 3 3 10.1634 3 19C3 26.5568 8.23883 32.89 15.2827 34.5659L18.9991 42L22.7167 34.5661C29.7609 32.8904 35 26.557 35 19C35 10.1634 27.8366 3 19 3Z"
          fill="currentcolor"
        />
        <path
          d="M15.2827 34.5659L17.0717 33.6716L16.6541 32.8364L15.7457 32.6203L15.2827 34.5659ZM18.9991 42L17.2102 42.8943L18.9988 46.4721L20.7879 42.8946L18.9991 42ZM22.7167 34.5661L22.2539 32.6204L21.3456 32.8364L20.9279 33.6715L22.7167 34.5661ZM5 19C5 11.268 11.268 5 19 5V1C9.05888 1 1 9.05887 1 19H5ZM15.7457 32.6203C9.58228 31.1539 5 25.6096 5 19H1C1 27.504 6.89537 34.6262 14.8198 36.5116L15.7457 32.6203ZM20.788 41.1057L17.0717 33.6716L13.4938 35.4602L17.2102 42.8943L20.788 41.1057ZM20.9279 33.6715L17.2103 41.1054L20.7879 42.8946L24.5055 35.4606L20.9279 33.6715ZM33 19C33 25.6098 28.4175 31.1541 22.2539 32.6204L23.1796 36.5118C31.1043 34.6266 37 27.5042 37 19H33ZM19 5C26.732 5 33 11.268 33 19H37C37 9.05887 28.9411 1 19 1V5Z"
          fill="white"
          mask="url(#path-3-outside-2_389_40654)"
        />
      </g>
    </Icon>
  );
};

export default PinOnMapIcon;
