import {
  Box,
  BoxProps,
  ButtonProps,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemProps,
  MenuList,
  MenuListProps,
  Portal,
  Spinner,
} from "@chakra-ui/react";
import { SvgIcon } from "components/SvgIcon";
import { memo } from "react";

export interface MenuDropdownItem extends Omit<MenuItemProps, "title"> {
  title: React.ReactElement;
  icon?: any;
  onClick(event: any): void;
  isLoading?: boolean;
}

interface Props extends BoxProps {
  isDisabled?: boolean;
  menuButtonProps?: ButtonProps;
  menuContentContainerProps?: BoxProps;
  options: MenuDropdownItem[];
  menuListProps?: MenuListProps;
  closeOnSelect?: boolean;
}

const MenuDropdown = ({
  options,
  isDisabled,
  menuButtonProps,
  menuListProps,
  closeOnSelect,
  menuContentContainerProps,
  ...rest
}: Props) => {
  if (!options.length) return null;

  return (
    <Box {...rest}>
      <Menu
        autoSelect={false}
        placement="bottom-end"
        closeOnSelect={closeOnSelect}
      >
        {({ isOpen }) => (
          <>
            <MenuButton
              isActive={isOpen}
              as={IconButton}
              aria-label="Options"
              width={"4rem"}
              icon={
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  width="2.4rem"
                  height="2.4rem"
                >
                  <SvgIcon
                    src="/img/three-dots-outline.svg"
                    width="2.4rem"
                    height="2.4rem"
                    flexShrink="0"
                    sx={{
                      path: {
                        stroke: isOpen ? "var(--primary-color)" : "#A3A3A3",
                        fill: isOpen ? "var(--primary-color)" : "#A3A3A3",
                      },
                    }}
                  />
                </Flex>
              }
              variant="text"
              _active={{
                boxShadow:
                  "rgb(255, 255, 255) 0px 0px 0px 0px inset, #009BE0 0px 0px 0px 2px inset, rgba(0, 0, 0, 0.05) 0px 1px 2px ",
              }}
              backgroundColor="transparent !important"
              onClick={(e) => e.stopPropagation()}
              isDisabled={isDisabled}
              {...menuButtonProps}
            />
            {isOpen && (
              <Portal>
                <MenuList
                  minW="8rem"
                  maxW="180px"
                  zIndex={11}
                  style={{
                    padding: "0px",
                    margin: "0px",
                    background: "#fff",
                    borderRadius: "6px",
                    overflow: "hidden",
                    fontSize: "1.2rem",
                    boxShadow:
                      "0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)",
                  }}
                  {...menuListProps}
                >
                  {options.map(
                    ({ title, onClick, isLoading, ...rest }, key: number) => (
                      <MenuItem
                        key={key}
                        p="0.7rem 1.6rem"
                        minHeight="4rem"
                        _hover={{
                          bgColor: "#F0F9FF !important",
                        }}
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          onClick?.(event);
                        }}
                        isDisabled={isLoading}
                        {...rest}
                      >
                        <Flex
                          w={"100%"}
                          alignItems={"center"}
                          gap="1rem"
                          {...menuContentContainerProps}
                        >
                          {isLoading && <Spinner />}
                          {title}
                        </Flex>
                      </MenuItem>
                    )
                  )}
                </MenuList>
              </Portal>
            )}
          </>
        )}
      </Menu>
    </Box>
  );
};

export default memo(MenuDropdown);
