import { adjustBrightnessHexColor } from "utils/color";
import { logError } from "utils/logs";
import { getCurrentViewer, getLeafFragIds, grayScaleForgeViewer } from ".";

import { getLabelExtension } from "./extensions/custom-label";

const MATERIAL_DARKEN_HEX_PERCENTAGE = 10;

export let ___viewer3d: Autodesk.Viewing.GuiViewer3D | undefined;
export const setViewer3d = (viewer?: any) => {
  ___viewer3d = viewer;
};

const __db2ThemingColor: Record<number, any> = {};
const __mapMaterialByFragId: Record<number, any> = {};
let __contextMenuForge: any | undefined = undefined;

export const storeDb2ThemingColor = (dbId: number, color: any) => {
  __db2ThemingColor[dbId] = color;
};

export const getDb2ThemingColorByDbId = (dbId: number) => {
  return __db2ThemingColor[dbId];
};

export const clearDb2ThemingColorByDbId = (dbId: number) => {
  delete __db2ThemingColor?.[dbId];
};

const setMapmaterialByFragId = (material: any, fragId: number) => {
  if (!__mapMaterialByFragId[fragId]) {
    __mapMaterialByFragId[fragId] = material;
  }
};

const getMapmaterialByFragId = (fragId: number) => {
  return __mapMaterialByFragId[fragId];
};

const clearMapmaterialByFragId = (fragId: number) => {
  delete __mapMaterialByFragId?.[fragId];
};

export const hideContextMenuForge = () => {
  const viewer = getCurrentViewer();
  const ctx = (viewer as any)?.contextMenu;
  if (ctx) {
    __contextMenuForge = ctx;
    viewer?.setContextMenu(undefined);
  }
};

export const showContextMenuForge = () => {
  const viewer = getCurrentViewer();
  const ctx = (viewer as any)?.contextMenu;
  // store init context menu
  if (ctx) {
    __contextMenuForge = ctx;
  }
  // set context menu from global when it hided
  if (__contextMenuForge) {
    viewer?.setContextMenu(__contextMenuForge);
  }
};

export const find3DBounds = (
  fragList: any,
  instanceTree: any,
  dbId: number
) => {
  const bounds = new THREE.Box3();
  instanceTree.enumNodeFragments(
    dbId,
    (fragId: number) => {
      const box = new THREE.Box3();
      fragList.getWorldBounds(fragId, box);
      bounds.union(box);
    },
    true
  );

  return bounds;
};

export const find3dPosition = (dbId: number) => {
  const viewer = ___viewer3d;
  if (!viewer) {
    return;
  }
  const fragList = viewer.model.getFragmentList();
  const instanceTree = viewer.model.getInstanceTree();
  if (!fragList || !instanceTree) {
    return;
  }

  return find3DBounds(fragList, instanceTree, dbId).getCenter();
};

export const checkPointInsideMesh = (
  point: THREE.Vector3,
  mesh: THREE.Mesh
) => {
  const raycaster = new THREE.Raycaster();
  raycaster.set(point, new THREE.Vector3(0, 0, -1));
  const intersects = raycaster.intersectObject(mesh);

  return intersects.length % 2 === 1;
};

export const getComponentGeometryInfo = (dbId: number) => {
  const viewer = ___viewer3d!;
  const viewerImpl = viewer.impl;
  const model = viewer.model;
  const fragIds = getLeafFragIds(model, dbId);
  let matrixWorld: any = null;

  const meshes = fragIds.map((fragId) => {
    const renderProxy = viewerImpl.getRenderProxy(model, fragId);

    const geometry = renderProxy.geometry;
    const attributes = geometry.attributes;
    const positions = geometry.vb ? geometry.vb : attributes.position.array;

    const indices = attributes.index.array || geometry.ib;
    const stride = geometry.vb ? geometry.vbstride : 3;
    const offsets = geometry.offsets;

    matrixWorld = matrixWorld || renderProxy.matrixWorld.elements;

    return {
      positions,
      indices,
      offsets,
      stride,
    };
  });

  return {
    matrixWorld,
    meshes,
  };
};

export const getComponentGeometry = (data: any, vertexArray: any) => {
  const offsets = [
    {
      count: data.indices.length,
      index: 0,
      start: 0,
    },
  ];

  for (let oi = 0, ol = offsets.length; oi < ol; ++oi) {
    const start = offsets[oi].start;
    const count = offsets[oi].count;
    const index = offsets[oi].index;

    for (let i = start, il = start + count; i < il; i += 3) {
      const a = index + data.indices[i];
      const b = index + data.indices[i + 1];
      const c = index + data.indices[i + 2];

      const vA = new THREE.Vector3();
      const vB = new THREE.Vector3();
      const vC = new THREE.Vector3();

      vA.fromArray(data.positions, a * data.stride);
      vB.fromArray(data.positions, b * data.stride);
      vC.fromArray(data.positions, c * data.stride);

      vertexArray.push(vA);
      vertexArray.push(vB);
      vertexArray.push(vC);
    }
  }
};

export const buildComponentMesh = (data: any) => {
  const vertexArray: any[] = [];

  for (let idx = 0; idx < data.nbMeshes; ++idx) {
    const meshData = {
      positions: data[`positions${idx}`],
      indices: data[`indices${idx}`],
      stride: data[`stride${idx}`],
    };

    getComponentGeometry(meshData, vertexArray);
  }

  const geometry = new THREE.Geometry();

  for (let i = 0; i < vertexArray.length; i += 3) {
    geometry.vertices.push(vertexArray[i]);
    geometry.vertices.push(vertexArray[i + 1]);
    geometry.vertices.push(vertexArray[i + 2]);

    const face = new THREE.Face3(i, i + 1, i + 2);
    geometry.faces.push(face);
  }

  const matrixWorld = new THREE.Matrix4();
  (matrixWorld as any).fromArray(data.matrixWorld);

  const mesh = new THREE.Mesh(geometry) as any;
  mesh.applyMatrix(matrixWorld);
  mesh.boundingBox = data.boundingBox;
  mesh.dbId = data.dbId;

  return mesh;
};

export const buildMesh = (dbId: number, model: Autodesk.Viewing.Model) => {
  const geometry = getComponentGeometryInfo(dbId);
  const data: any = {
    boundingBox: find3DBounds(
      model.getFragmentList(),
      model.getInstanceTree(),
      dbId
    ),
    matrixWorld: geometry.matrixWorld,
    nbMeshes: geometry.meshes.length,
    dbId,
  };

  geometry.meshes.forEach((mesh, idx) => {
    data[`positions${idx}`] = mesh.positions;
    data[`indices${idx}`] = mesh.indices;
    data[`stride${idx}`] = mesh.stride;
  });

  return buildComponentMesh(data);
};

function addMaterial(
  color: string,
  overlayName: string,
  viewer: Autodesk.Viewing.GuiViewer3D
) {
  const newColor = adjustBrightnessHexColor({
    hex: color,
    percentage: MATERIAL_DARKEN_HEX_PERCENTAGE,
    action: "darken",
  });
  const material = new THREE.MeshPhongMaterial({
    color: newColor,
    emissive: newColor as any,
    specular: newColor as any,
    shininess: 0,
    opacity: 1,
  });
  viewer!.impl.createOverlayScene(overlayName, material, material);

  return material;
}

function updateMaterial(
  dbId: number,
  color: string,
  instanceTree: any,
  viewer: Autodesk.Viewing.GuiViewer3D
) {
  if (Number.isNaN(dbId)) {
    return;
  }

  const overlayName = `overlay_${dbId}`;
  const material = new THREE.MeshBasicMaterial({
    color,
    // remove the environment map affects the surface
    combine: 0,
    reflectivity: 0,
  } as any);

  const fragList = viewer.model.getFragmentList() as any;
  (viewer.model as any).unconsolidate();
  instanceTree?.enumNodeFragments(dbId, function (fragId: number) {
    const currentMaterial = fragList.getMaterial(fragId);
    setMapmaterialByFragId(currentMaterial, fragId);
    viewer.impl.matman().addMaterial(overlayName, material, true);
    fragList.setMaterial(fragId, material);
  });
}

function clearMaterial(
  dbId: number,
  instanceTree: any,
  viewer: Autodesk.Viewing.GuiViewer3D
) {
  if (Number.isNaN(dbId)) {
    return;
  }

  const fragList = viewer.model.getFragmentList() as any;
  instanceTree?.enumNodeFragments(dbId, function (fragId: number) {
    const originalMaterial = getMapmaterialByFragId(fragId);
    if (originalMaterial) {
      clearMapmaterialByFragId(fragId);
      fragList.setMaterial(fragId, originalMaterial);
    }
  });
}

export const clearMaterialMultipleDbIds = (dbIds: number[]) => {
  const viewer = getCurrentViewer();
  if (!viewer) {
    return;
  }
  const instanceTree = viewer!.model?.getInstanceTree();
  if (!instanceTree) {
    return;
  }

  dbIds.forEach((dbId) => {
    clearMaterial(dbId, instanceTree, viewer);

    // revert original color
    const originalColor = getDb2ThemingColorByDbId(dbId);
    if (originalColor) {
      viewer?.model?.setThemingColor(dbId, originalColor);
      clearDb2ThemingColorByDbId(dbId);
    }
  });

  viewer.impl.invalidate(true);
};

export const changeMaterialMultipleDbIds = (params: {
  dbIds: number[];
  color: string | Record<number, string>; // hex or mapHexWithDbId
}) => {
  const { dbIds, color } = params;

  const viewer = getCurrentViewer();
  if (!viewer || !viewer?.model) {
    return;
  }
  const fragList = viewer.model.getFragmentList();
  const colorMap = fragList.db2ThemingColor;
  const instanceTree = viewer!.model?.getInstanceTree();
  let sameColor = typeof color === "string" ? color : undefined;

  dbIds.forEach((dbId) => {
    // remove impact highlight object from setThemingColor
    if (colorMap[dbId]) {
      storeDb2ThemingColor(dbId, colorMap[dbId]);
    }
    delete colorMap[dbId];
    if (!sameColor) {
      sameColor = color[dbId];
    }

    updateMaterial(dbId, sameColor, instanceTree, viewer);
  });
  viewer.impl.invalidate(true);
};

function createMeshHighlightObject(
  dbId: number,
  color: string,
  instanceTree: any,
  viewer: Autodesk.Viewing.GuiViewer3D
) {
  if (Number.isNaN(dbId)) {
    return;
  }

  const overlayName = `overlay_${dbId}`;
  addMaterial(color, overlayName, viewer);

  instanceTree?.enumNodeFragments(
    dbId,
    function (fragId: number) {
      const renderProxy = viewer.impl.getRenderProxy(viewer.model, fragId);
      if (renderProxy?.material) {
        renderProxy.meshProxy = new THREE.Mesh(
          renderProxy.geometry,
          renderProxy.material
        );
        renderProxy.meshProxy.matrix.copy(renderProxy.matrixWorld);
        renderProxy.meshProxy.matrixWorldNeedsUpdate = true;
        renderProxy.meshProxy.matrixAutoUpdate = false;
        renderProxy.meshProxy.frustumCulled = false;
        viewer.impl.addOverlay(overlayName, renderProxy.meshProxy);
      }
    },
    false
  );
}

export const highlightMultipleDbIds = (
  dbIds: number[],
  color: string | Record<number, string>, // hex or mapHexWithDbId
  isGrayScale = false
) => {
  const viewer = getCurrentViewer();
  if (!viewer) {
    return;
  }
  isGrayScale && grayScaleForgeViewer(viewer);
  const instanceTree = viewer!.model?.getInstanceTree();
  let sameColor = typeof color === "string" ? color : undefined;
  dbIds.forEach((dbId) => {
    if (!sameColor) {
      sameColor = color[dbId];
    }

    createMeshHighlightObject(dbId, sameColor, instanceTree, viewer);
  });
};

export const highlightMultipleObject = (
  objects: { dbId: number; color: string }[],
  isGrayScale = true
) => {
  const viewer = getCurrentViewer();
  if (!viewer) {
    return;
  }
  try {
    isGrayScale && grayScaleForgeViewer(viewer);
    getLabelExtension()?.updateLabels();
    const instanceTree = viewer!.model?.getInstanceTree();
    objects.forEach((object) => {
      createMeshHighlightObject(
        object.dbId,
        object.color,
        instanceTree,
        viewer
      );
    });
    viewer.impl.invalidate(true, true, true);
  } catch (err) {
    logError(err);
  }
};

export const clearHighlightMultipleObject = (dbIds: number[]) => {
  const viewer = getCurrentViewer();
  if (!viewer) {
    return;
  }
  const instanceTree = viewer!.model?.getInstanceTree();
  if (!instanceTree) {
    return;
  }
  dbIds.forEach((dbId) => {
    const overlayName = `overlay_${dbId}`;
    viewer.impl.clearOverlay(overlayName);
    instanceTree?.enumNodeFragments(
      dbId,
      function (fragId) {
        const renderProxy = viewer.impl.getRenderProxy(viewer.model, fragId);
        if (renderProxy.meshProxy) {
          delete renderProxy.meshProxy;
        }
      },
      true
    );
  });
  viewer.impl.invalidate(true);
};
