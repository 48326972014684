import { bimFileApi } from "apiClient/v2";
import { getAllBimViewByVersionId } from "apiClient/v2/forgeApi";
import { PROJECT_RESOURCE, SYNC_PROJECT_BIM_FILES } from "constants/cache";
import { DataProjectModel } from "interfaces/models/dataProjectModel";
import { ForgeClient } from "libs/forge-viewer/client";
import { getViewerResourceUrls } from "libs/forge-viewer/fetch-forge-files";
import { getForgeToken } from "../forge";
import { getLocalStorage, setLocalStorage } from "../storage";

export const getUrlsOfModel = async (project: DataProjectModel) => {
  const credentials = await getForgeToken();
  const bimFileId = project.defaultBimPathId?.split("/").pop() || "";
  if (!bimFileId) {
    return [] as string[];
  }

  const token = credentials.accessToken;
  const base64BimUrn = ForgeClient.extractBase64UrnFromBimFileId(
    project?.defaultBimPathId || ""
  );
  if (!base64BimUrn) {
    return [] as string[];
  }

  const urn = `${project.id}?version=${project.version}`;
  const versionId = encodeURIComponent(urn);
  const response = await getAllBimViewByVersionId(project.projectId, versionId);
  const views = response.data;
  const viewable2DNameSet: Set<string> = new Set();
  Object.values(project.levelData || {}).forEach((item) => {
    if (!item?.guid) {
      return;
    }

    item?.sheets?.forEach((sheet) => viewable2DNameSet.add(sheet.name));
  });
  const viewableNameList = views.map((view) => view.name);

  await ForgeClient.cdnManifest(base64BimUrn, token);
  const urls = await getViewerResourceUrls({
    urn: base64BimUrn,
    authToken: token,
    viewableNameList,
    viewable2DNameList: [...viewable2DNameSet],
  });
  const uniqueUrls = Array.from(new Set(urls));

  return uniqueUrls;
};

export const setSyncProjectBimfile = (bimFileId: string) => {
  const currentSyncProjectBimFiles = JSON.parse(
    getLocalStorage(SYNC_PROJECT_BIM_FILES, null) || "[]"
  );
  const syncProjectBimFiles = new Set([
    ...currentSyncProjectBimFiles,
    bimFileId,
  ]);
  setLocalStorage(SYNC_PROJECT_BIM_FILES, Array.from(syncProjectBimFiles));
};

export const getResourceCachedOfProject = async (
  currentBimFileId: string,
  syncDataTime: Date
) => {
  const res = await bimFileApi.getLastUpdatedResource({
    bimFileId: currentBimFileId,
    localTime: new Date(syncDataTime).toISOString(),
  });

  if (!res?.data?.length) {
    return;
  }

  let excludeCachedUrls: string[] = [];

  res.data.forEach((item) => {
    const resource = item.resource;

    switch (resource) {
      case "sub_items":
      case "document_items":
        break;

      case "families":
        excludeCachedUrls.push(PROJECT_RESOURCE.families);
        break;

      case "task_comments":
      case "tasks":
        break;

      case "blackboards":
      case "document_categories":
        break;

      case "task_sheet_templates":
        break;

      case "document_templates":
        break;

      case "document_groups":
        break;

      case "task_types":
        break;

      case "users":
        excludeCachedUrls.push(PROJECT_RESOURCE.listUser);
        excludeCachedUrls.push(PROJECT_RESOURCE.userSetting);
        break;

      case "inspection_contents":
      case "target_objects":
      case "pins":
      case "inspections":
      case "inspection_types":
        excludeCachedUrls.push(PROJECT_RESOURCE.inspectionTypeList);
        break;

      case "project_bim_file":
        excludeCachedUrls.push(PROJECT_RESOURCE.project);
        excludeCachedUrls.push(PROJECT_RESOURCE.projectDetail);
        excludeCachedUrls.push(PROJECT_RESOURCE.projectBimFileDetail);
        excludeCachedUrls.push(PROJECT_RESOURCE.partnerCompanies);
        break;

      default:
        if (PROJECT_RESOURCE?.[resource as keyof typeof PROJECT_RESOURCE]) {
          excludeCachedUrls.push(
            PROJECT_RESOURCE?.[resource as keyof typeof PROJECT_RESOURCE]
          );
        }
        break;
    }
  });

  excludeCachedUrls = [...new Set(excludeCachedUrls)];
  const cachedUrls = Object.values(PROJECT_RESOURCE).filter(
    (res) => !excludeCachedUrls.includes(res)
  );

  return cachedUrls;
};
