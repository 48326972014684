import { useOffline, useRecomputeDataSize } from "contexts/OfflineContext";
import { UpdateInspectionContent } from "interfaces/models/inspectionContent";
import React, {
  createContext,
  useContext,
  useEffect,
  useImperativeHandle,
  useReducer,
} from "react";
import useSaveInspectionContent from "../../hooks/useSaveInspectionContent";
import { initInspectionData } from "./actions";
import { formBuilderReducer } from "./reducer";
import {
  FormBuilderActions,
  FormBuilderContextType,
  FormBuilderState,
} from "./types";

export const FormBuilderContext = createContext<FormBuilderContextType>(
  {} as any
);

export interface FormBuilderContextRef {
  isDirty: boolean;
  dispatch(action: FormBuilderActions): void;
  validate: () => Promise<any>;
  onSaveContent: () => Promise<UpdateInspectionContent>;
}

export const useFormBuilder = () => {
  return useContext(FormBuilderContext);
};

export interface FormBuilderContextProviderProps {
  children?: any;
  defaultValue?: FormBuilderState;
}

const initState: FormBuilderState = {
  contentItems: [],
  itemResults: [],
  inspectionContent: {} as any,
};

export const FormBuilderContextProvider = React.forwardRef<
  FormBuilderContextRef,
  FormBuilderContextProviderProps
>(({ children, defaultValue }, ref) => {
  const [state, dispatch] = useReducer(
    formBuilderReducer,
    defaultValue ? Object.assign(initState, defaultValue) : initState
  );

  useRecomputeDataSize([state]);

  const { isDirty, isSavingContent, errors, validate, onSaveContent } =
    useSaveInspectionContent({
      state,
      dispatch,
    });

  useImperativeHandle(
    ref,
    () => ({
      dispatch,
      validate,
      isDirty,
      onSaveContent,
    }),
    [isDirty, validate, onSaveContent]
  );

  useEffect(() => {
    if (defaultValue) {
      dispatch(initInspectionData(Object.assign(initState, defaultValue)));
    }
  }, [defaultValue]);

  return (
    <FormBuilderContext.Provider
      value={{ isSavingContent, state, errors, dispatch, onSaveContent }}
    >
      {children}
    </FormBuilderContext.Provider>
  );
});
