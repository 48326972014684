import { Icon, IconProps } from "@chakra-ui/icons";

const PinGroupIcon = (props: IconProps) => {
  return (
    <Icon width="1em" height="1em" viewBox="0 0 33 32" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.9231 13.5871C28.1668 13.0448 29.8333 11.0238 29.8333 8.61359C29.8333 5.78782 27.5426 3.49707 24.7168 3.49707C21.891 3.49707 19.6003 5.78782 19.6003 8.61359C19.6003 11.0237 21.2667 13.0447 23.5102 13.587L24.7165 16L25.9231 13.5871ZM27.3698 8.61352C27.3698 10.0787 26.182 11.2665 24.7168 11.2665C23.2516 11.2665 22.0638 10.0787 22.0638 8.61352C22.0638 7.1483 23.2516 5.96051 24.7168 5.96051C26.182 5.96051 27.3698 7.1483 27.3698 8.61352ZM14.9186 25.9593C18.6156 25.0658 21.3617 21.7357 21.3617 17.7642C21.3617 13.108 17.5871 9.33333 12.9308 9.33333C8.27462 9.33333 4.5 13.108 4.5 17.7642C4.5 21.7356 7.24591 25.0656 10.9427 25.9593L12.9303 29.9352L14.9186 25.9593ZM17.3024 17.7643C17.3024 20.1786 15.3452 22.1359 12.9308 22.1359C10.5165 22.1359 8.5593 20.1786 8.5593 17.7643C8.5593 15.35 10.5165 13.3928 12.9308 13.3928C15.3452 13.3928 17.3024 15.35 17.3024 17.7643Z"
        fill="currentcolor"
      />
    </Icon>
  );
};

export default PinGroupIcon;
