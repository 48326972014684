import { Text, BoxProps, Flex, Box } from "@chakra-ui/react";
import { memo } from "react";

interface Props {
  title?: string;
  children?: React.ReactNode;
  isLoading?: boolean;
}

const FieldNumberPreviewWrapper = (props: Props & BoxProps) => {
  const { title, children, ...rest } = props;

  return (
    <Flex flexDirection="column" gap="0.5rem" {...rest}>
      <Flex
        fontWeight="bold"
        fontSize={"1.6rem"}
        className="field-title"
        position={"relative"}
        alignItems={"center"}
        gap="0.5rem"
      >
        <Box flex="0 0 8px" w="8px" h="8px" bg="#525252" />
        <Text>{title}</Text>
      </Flex>
      <Box className="field-wrapper">{children}</Box>
    </Flex>
  );
};

export default memo(FieldNumberPreviewWrapper);
