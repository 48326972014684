import { Button, Flex } from "@chakra-ui/react";
import isArray from "lodash/isArray";

interface Props {
  selected?: string | string[];
  onSelect?(e: React.MouseEvent<HTMLButtonElement>): void;
  isDisabled?: boolean;
  onBack?(e: React.MouseEvent<HTMLButtonElement>): void;
}

const Footer: React.FC<Props> = (props: Props) => {
  const { selected, onSelect, isDisabled, onBack } = props;

  return (
    <Flex w="100%" px="2rem" py="1rem" gap="2rem">
      <Button
        ml="auto"
        borderColor={"#009BE0"}
        borderWidth={"2px"}
        px="1.5rem"
        onClick={onBack}
      >
        キャンセル
      </Button>
      <Button
        variant="filled"
        minW="25rem"
        fontWeight={"bold"}
        fontSize={"1.75rem"}
        isDisabled={
          isDisabled || (isArray(selected) ? !selected?.length : !selected)
        }
        onClick={onSelect}
      >
        この写真を使う
      </Button>
    </Flex>
  );
};

export default Footer;
