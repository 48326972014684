import { ApiResponse } from "interfaces/models/api";
import { GetInstancePropertiesReq } from "interfaces/models/inspectionInstance";
import { QueryExecution } from "models/inspection";
import { axiosECS } from "services/baseAxios";

const path = "/v1/inspections/instances";

export const GET_INSTANCE_PROPS_PATH = `${path}/get-instance-props`;

export const createInstanceTable = async (bimFileId: string) => {
  return axiosECS.post(`${path}/create-table`, { bimFileId });
};

export const getInstanceList = async (
  body: any
): Promise<ApiResponse<QueryExecution>> => {
  return axiosECS.post(`${path}/get-instance`, body);
};

export const getExecutionIds = async (
  body: GetInstancePropertiesReq
): Promise<ApiResponse<QueryExecution[]>> => {
  return axiosECS.post(GET_INSTANCE_PROPS_PATH, body);
};

export const getQueryExecutionList = async (
  executionIds: string[]
): Promise<ApiResponse<QueryExecution[]>> => {
  return axiosECS.post(`${path}/get-query-executions`, { executionIds });
};

// remove soon
export const getQueryExecution = async (
  executionId: string
): Promise<ApiResponse<QueryExecution>> => {
  return axiosECS.post(`${path}/get-query-execution`, { executionId });
};
