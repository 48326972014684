import { Icon, IconProps } from "@chakra-ui/icons";

const InspectionDocumentsIcon = (props: IconProps) => {
  return (
    <Icon width="1em" height="1em" viewBox="0 0 28 29" fill="none" {...props}>
      <path
        d="M10.5 21.2082H17.5C17.7483 21.2082 17.9562 21.1244 18.1236 20.9568C18.2912 20.7893 18.375 20.5815 18.375 20.3332C18.375 20.0849 18.2912 19.877 18.1236 19.7096C17.9562 19.542 17.7483 19.4582 17.5 19.4582H10.5C10.2517 19.4582 10.0438 19.542 9.87642 19.7096C9.70881 19.877 9.625 20.0849 9.625 20.3332C9.625 20.5815 9.70881 20.7893 9.87642 20.9568C10.0438 21.1244 10.2517 21.2082 10.5 21.2082ZM10.5 16.5415H17.5C17.7483 16.5415 17.9562 16.4577 18.1236 16.2901C18.2912 16.1227 18.375 15.9148 18.375 15.6665C18.375 15.4182 18.2912 15.2103 18.1236 15.0429C17.9562 14.8753 17.7483 14.7915 17.5 14.7915H10.5C10.2517 14.7915 10.0438 14.8753 9.87642 15.0429C9.70881 15.2103 9.625 15.4182 9.625 15.6665C9.625 15.9148 9.70881 16.1227 9.87642 16.2901C10.0438 16.4577 10.2517 16.5415 10.5 16.5415ZM7.35904 25.5832C6.76968 25.5832 6.27083 25.379 5.8625 24.9707C5.45417 24.5623 5.25 24.0635 5.25 23.4741V5.52555C5.25 4.93618 5.45417 4.43734 5.8625 4.029C6.27083 3.62067 6.76968 3.4165 7.35904 3.4165H15.7523C16.0335 3.4165 16.3039 3.47114 16.5635 3.58042C16.8228 3.6895 17.0483 3.83981 17.2398 4.03134L22.1352 8.92667C22.3267 9.1182 22.477 9.34366 22.5861 9.60305C22.6954 9.86263 22.75 10.133 22.75 10.4142V23.4741C22.75 24.0635 22.5458 24.5623 22.1375 24.9707C21.7292 25.379 21.2303 25.5832 20.641 25.5832H7.35904ZM15.75 9.36184V5.1665H7.35904C7.26921 5.1665 7.18696 5.20393 7.11229 5.2788C7.03743 5.35346 7 5.43571 7 5.52555V23.4741C7 23.564 7.03743 23.6462 7.11229 23.7209C7.18696 23.7957 7.26921 23.8332 7.35904 23.8332H20.641C20.7308 23.8332 20.813 23.7957 20.8877 23.7209C20.9626 23.6462 21 23.564 21 23.4741V10.4165H16.8047C16.5039 10.4165 16.2529 10.3159 16.0519 10.1146C15.8506 9.91357 15.75 9.66264 15.75 9.36184Z"
        fill="currentcolor"
      />
    </Icon>
  );
};

export default InspectionDocumentsIcon;
