import { forgeApi } from "apiClient/v2";
import { ItemBIMType, StatusType } from "constants/enum";
import { LEVEL_ALL, LEVEL_OTHER } from "constants/forge";
import { Level, Sheet } from "interfaces/models";
import { DerivativesReq } from "interfaces/models/derivatives";
import { axiosECS } from "services/baseAxios";
import { wait } from "utils/common";
import { logDev } from "utils/logs";

const BATCH_DBIDS_SIZE = 4000;

export const getDbIdProperties = async (
  dbIds: number[],
  bimFileId: string,
  version: string,
  bimGuid?: string,
  keys?: string[]
) => {
  const length = Math.ceil(dbIds.length / BATCH_DBIDS_SIZE);
  const promiseArray = [];
  for (let i = 0; i < length; i++) {
    const itemPuts = dbIds.slice(
      i * BATCH_DBIDS_SIZE,
      Math.min(i * BATCH_DBIDS_SIZE + BATCH_DBIDS_SIZE, dbIds.length)
    );
    promiseArray.push(
      postRetry(
        `/v1/aps/projects/null/bims/version/${encodeURIComponent(
          `${bimFileId}?version=${version}`
        )}/properties`,
        {
          keys: keys || [
            "Reference Level",
            "Level",
            "System Name",
            "記号",
            "System Type",
            "Type Name",
            "ファンの種類",
            "積算_施工区分",
            "符号",
            "形式",
            "Size",
            "Design Option",
            "ダクト径_半径",
            "風量",
            "デザイン オプション",
          ],
          dbIds: itemPuts,
          bimGuid: bimGuid || "all",
        },
        500,
        3
      )
    );
  }

  return (await Promise.all(promiseArray))
    .map((item) => item?.data?.data)
    .flat(1);
};

async function postRetry(
  url: string,
  options: any,
  delay: number,
  tries: number
): Promise<any> {
  const result = await axiosECS.post(url, options);
  if (!result) {
    const triesLeft = tries - 1;
    if (!triesLeft) {
      return;
    }
    await wait(delay);
    logDev(triesLeft);

    return await postRetry(url, options, delay, triesLeft);
  }

  return result;
}

export const getAECData = async (urn: string, shouldCache = false) => {
  const { data } = await forgeApi.getAECByVersionId({
    projectId: "null",
    versionId: encodeURIComponent(urn),
    shouldCache,
  });

  return data;
};

export const getLevelByAecAndDerivative = async ({
  projectId,
  versionId,
}: {
  projectId: string;
  versionId: string;
}) => {
  const { data: derivativeData } = await forgeApi.getDerivativesByVersionId({
    projectId: projectId || "",
    versionId: encodeURIComponent(versionId),
  });
  const aecData: any = await getAECData(versionId);

  return transformLevelByAecDataAndDerivative(aecData, derivativeData);
};

export const transformLevelByAecDataAndDerivative = (
  aecData: any,
  derivativeData: any
) => {
  const levels: Level[] = [];
  const levelsAceFilter: any[] = [];

  const derivatives = derivativeData.children || [];
  let derivativesLevels3D = derivatives.filter(
    (item: any) =>
      item.hasThumbnail === "true" &&
      item.role === "3d" &&
      item.type === ItemBIMType.GEOMETRY
  );

  aecData?.levels?.forEach((level: any) => {
    const derivative = derivativesLevels3D.find((item: any) => {
      const name: string = item.name;

      const arrName = name.split("_");
      arrName.shift();
      const levelName = arrName.map((n) => n.replaceAll(" ", "")).join("");

      const currentLevelName = level.name.replace(" ", "").replace("_", "");
      const otherCondition = arrName.some(
        (name) => name.replace(/L$/, "") === currentLevelName.replace(/L$/, "")
      );

      const isIncludeName =
        levelName.includes(currentLevelName) || otherCondition;
      if (isIncludeName) {
        derivativesLevels3D = derivativesLevels3D.filter(
          (i: any) => i.guid !== item.guid
        );
      }

      return isIncludeName;
    });
    if (derivative) {
      levelsAceFilter.push(level);
      levels.push({
        guid: String(derivative.guid),
        label: String(level.name),
        sheets: [],
        zMin: level.elevation,
        zMax: level.elevation + level.height,
      });
    }
  });

  return { levels, levelsAceFilter };
};

export const getLevelsData = async ({
  projectId,
  versionId,
}: DerivativesReq) => {
  const optionAll: Level = { ...LEVEL_ALL };
  if (!projectId || !versionId) return [optionAll];
  const { levels } = await getLevelByAecAndDerivative({
    projectId,
    versionId,
  });
  const optionOther: Level = { ...LEVEL_OTHER };

  return [optionAll, ...levels, optionOther];
};

export const getSheetsData = async ({
  projectId,
  versionId,
}: DerivativesReq) => {
  let sheets: Sheet[] = [];
  if (!projectId || !versionId) return sheets;
  const { data: res } = await forgeApi.getDerivativesByVersionId({
    projectId: projectId || "",
    versionId: encodeURIComponent(versionId),
  });
  const aecData: any = await getAECData(decodeURIComponent(versionId));

  if (res.children?.length) {
    sheets = res.children
      .filter(
        (item: any) =>
          item.status === StatusType.SUCCESS &&
          item.hasThumbnail === "true" &&
          item.role === "2d" &&
          item.type === ItemBIMType.GEOMETRY
      )
      .map(
        (item: any) =>
          ({
            guid: item.guid,
            name: item.name,
            isMissingViewport: !aecData.viewports.find(
              (viewport: any) => viewport.sheetGuid === item.guid
            ),
          } as Sheet)
      );
  }

  return sheets;
};
