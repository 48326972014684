import { message } from "components/base";
import { InspectionType } from "interfaces/models/inspectionType";
import { useEffect } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { routePath } from "routes/path";

export default function useCheckInspectionType(
  inspectionType?: InspectionType
) {
  const navigate = useNavigate();
  const { projectId, bimId } = useParams();

  useEffect(() => {
    if (!inspectionType) {
      return;
    }
    const { isProcessing, isError } = inspectionType;
    if (isProcessing || isError) {
      const url = generatePath(routePath.InspectionList, {
        bimId,
        projectId,
      });
      const msgError = isProcessing
        ? "この検査種別はまだ作成中なので、操作できないです。"
        : "この検査種別は作成中にエラーが発生しましたので、操作できないです。";
      message.warning(msgError);
      navigate(url);
    }
  }, [inspectionType]);
}
