import { DataProjectModel } from "interfaces/models/dataProjectModel";
import { ProjectDetail } from "interfaces/models/project";
import { useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  fetchDataProjectDetail,
  fetchProjectDetail,
  resetLoadDataProjectDetail,
  resetLoadProjectDetail,
} from "redux/projectSlice";
import { RootState } from "redux/store";
import { routePath } from "routes/path";

interface Props {
  bimId?: string;
  projectId?: string;
  onLoadedDataProjectDetail?: (data: DataProjectModel) => void;
  onLoadedProjectDetail?: (data: ProjectDetail) => void;
}

export default function useProjectBimFile(props: Props) {
  const { bimId, projectId, onLoadedDataProjectDetail, onLoadedProjectDetail } =
    props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { projectDetail, dataProjectDetail } = useSelector(
    (state: RootState) => state.project
  );

  useEffect(() => {
    if (!projectId) {
      return navigate(routePath.NotFound);
    }
    if (projectDetail?.id === projectId) {
      onLoadedProjectDetail?.(projectDetail);

      return;
    }
    dispatch(resetLoadProjectDetail());
    (async () => {
      const { payload }: any = await dispatch(
        fetchProjectDetail({ projectId })
      );
      if (!payload?.id) {
        return navigate(routePath.NotFound);
      }
      onLoadedProjectDetail?.(payload);
    })();
  }, [projectId, projectDetail?.id]);

  useEffect(() => {
    if (!bimId) {
      return navigate(routePath.NotFound);
    }
    if (dataProjectDetail?.id === decodeURIComponent(bimId)) {
      onLoadedDataProjectDetail?.(dataProjectDetail);

      return;
    }
    dispatch(resetLoadDataProjectDetail());
    (async () => {
      const { payload }: any = await dispatch(
        fetchDataProjectDetail(decodeURIComponent(bimId))
      );
      if (!payload?.id) {
        return navigate(routePath.NotFound);
      }
      onLoadedDataProjectDetail?.(payload);
    })();
  }, [bimId, dataProjectDetail?.id]);
}
