import { ActionMap } from "interfaces/models/context";
import { Item, Partial } from "../type";

export const DEFAULT_EXTRA_ID = "default";
export const LINKED_DATA_KEY_NO = "no";

export enum LinkedDataHeadKey {
  NO = "no",
  NAME = "name",
}

export type LayoutFrameBuilderState = {
  items: Item[];
  itemSelectedId: string | null;
  partials: Record<string, Record<string, Record<string, Partial>>>;
  zoom: number;
};

// Define the initial state
export const initialLayoutFrameBuilder: LayoutFrameBuilderState = {
  items: [],
  partials: {},
  itemSelectedId: null,
  zoom: 1,
};

export enum Types {
  SET_ZOOM = "SET_ZOOM",
}

type PinLayoutFramePayload = {
  [Types.SET_ZOOM]: number;
};

export type LayoutFrameBuilderActions =
  ActionMap<PinLayoutFramePayload>[keyof ActionMap<PinLayoutFramePayload>];

// Define the reducer function
const layoutFrameBuilderReducer = (
  prevState: LayoutFrameBuilderState,
  action: LayoutFrameBuilderActions
) => {
  const nextState = structuredClone(prevState);

  switch (action.type) {
    case Types.SET_ZOOM:
      nextState.zoom = action.payload;
      break;
  }

  return nextState;
};

export { layoutFrameBuilderReducer };
