import { ApiResponse, Pagination } from "interfaces/models/api";
import { DocumentPageLayout } from "interfaces/models/documentPageLayout";
import { axiosECS } from "services/baseAxios";

interface GetPageLayoutReq extends Pagination {
  documentFormatId: string;
}

const pathV2 = "/v1/documents/page-layouts";

export const getPageLayoutList = async (
  params: GetPageLayoutReq
): Promise<ApiResponse<DocumentPageLayout[]>> => {
  return axiosECS.get(pathV2, {
    params,
  });
};

export const getPageLayout = async (id: string): Promise<ApiResponse> => {
  return axiosECS.get(`${pathV2}/${id}`);
};

export const deletePageLayout = async (
  id: string
): Promise<ApiResponse<string[]>> => {
  return axiosECS.delete(pathV2, { data: { id } });
};

export const createUpdateMultiPageLayout = async (body: {
  pageLayouts: DocumentPageLayout[];
  documentFormatId: string;
}): Promise<ApiResponse<DocumentPageLayout[]>> => {
  return axiosECS.post(`${pathV2}/bulk`, body);
};
