import { Icon } from "@chakra-ui/icons";
import { IconProps } from "@chakra-ui/react";

export default function MapFloorIcon(props: IconProps) {
  return (
    <Icon
      width="1em"
      height="1em"
      fill="currentcolor"
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="m11.409 21.436-8.777-3.777a1.5 1.5 0 0 1 0-2.76l1.443-.619-1.443-.621a1.5 1.5 0 0 1 0-2.758l1.443-.621-1.443-.621a1.5 1.5 0 0 1 0-2.758l8.777-3.779a1.52 1.52 0 0 1 1.186 0l8.779 3.779a1.5 1.5 0 0 1 .467.316 1.5 1.5 0 0 1-.467 2.441l-1.441.619 1.441.623a1.4 1.4 0 0 1 .467.316 1.48 1.48 0 0 1 .441 1.061 1.5 1.5 0 0 1-.908 1.381l-1.439.619 1.439.621a1.4 1.4 0 0 1 .467.316 1.48 1.48 0 0 1 .441 1.061 1.5 1.5 0 0 1-.908 1.383l-8.773 3.777a1.5 1.5 0 0 1-.6.121 1.6 1.6 0 0 1-.592-.12m-8.184-5.16 8.777 3.779 8.777-3.779-2.742-1.182-5.437 2.342a1.5 1.5 0 0 1-.6.121 1.6 1.6 0 0 1-.594-.121l-5.439-2.34Zm8.777-.221 4.824-2.076 1.205-.521 2.746-1.182-2.746-1.184-5.439 2.338a1.47 1.47 0 0 1-1.186 0l-5.437-2.338-2.746 1.184 4.2 1.807.449.191Zm0-4 4.824-2.076 1.205-.521 2.746-1.182L12 4.497 3.223 8.276l4.2 1.807.449.193Z" />
    </Icon>
  );
}
