import {
  documentFormatApi,
  inspectionTypeApi,
  pageLayoutApi,
} from "apiClient/v2";
import useProjectBimFile from "hooks/useBimFile";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { routePath } from "routes/path";
import {
  useContextInspectionFormat,
  useContextInspectionFormatDispatch,
} from "../context/inspectionFormat";
import { Types } from "../context/inspectionFormatReducer";
import useCheckInspectionType from "components/containers/hooks/useCheckInspectionType";

export default function useInspectionLayout() {
  const { bimId, id: documentFormatId, projectId } = useParams();
  const navigate = useNavigate();
  const [loadingStatus, setLoadingStatus] = useState({
    inspection: true,
    dataProjectDetail: true,
  });

  const { inspectionType } = useContextInspectionFormat();

  const dispatchContext = useContextInspectionFormatDispatch();

  useProjectBimFile({
    projectId,
    bimId,
    onLoadedDataProjectDetail: () => {
      setLoadingStatus((prev) => ({ ...prev, dataProjectDetail: false }));
    },
  });

  useEffect(() => {
    if (!bimId) {
      return navigate(routePath.NotFound);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bimId]);

  useEffect(() => {
    (async () => {
      if (!documentFormatId) {
        return navigate(routePath.NotFound);
      }
      const result = await documentFormatApi.getDocumentFormatById({
        id: documentFormatId,
      });
      const documentLayout = result?.data;
      if (!documentLayout) {
        return navigate(routePath.NotFound);
      }

      const inspectionTypeId = documentLayout.inspectionTypeId;
      const [inspectionTypeResult, pageLayoutsResult] = await Promise.all([
        inspectionTypeApi.getInspectionTypeById(inspectionTypeId),
        pageLayoutApi.getPageLayoutList({
          documentFormatId,
        }),
      ]);
      const inspectionType = inspectionTypeResult?.data;
      if (!inspectionType) {
        return navigate(routePath.NotFound);
      }
      const pageList = pageLayoutsResult?.data;

      dispatchContext({
        type: Types.SET_INSPECTION_TYPE,
        payload: inspectionType,
      });
      dispatchContext({
        type: Types.UPDATE_DOCUMENT_PAGE_LAYOUTS,
        payload: pageList,
      });

      dispatchContext({
        type: Types.SET_DOCUMENT_LAYOUT,
        payload: documentLayout,
      });
      setLoadingStatus((prev) => ({ ...prev, inspection: false }));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentFormatId]);

  useCheckInspectionType(inspectionType);

  const isLoading = useMemo(() => {
    return Object.values(loadingStatus).some((val) => val);
  }, [loadingStatus]);

  return {
    isLoading,
  };
}
