import { Icon, IconProps } from "@chakra-ui/icons";

const ArrowRightIcon = (props: IconProps) => {
  return (
    <Icon width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <mask
        id="mask0_528_38972"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_528_38972)">
        <path
          d="M12.9467 11.9995L8.8735 7.92652C8.73517 7.78802 8.66433 7.61394 8.661 7.40427C8.65783 7.19477 8.72867 7.01752 8.8735 6.87252C9.0185 6.72769 9.19417 6.65527 9.4005 6.65527C9.60683 6.65527 9.7825 6.72769 9.9275 6.87252L14.4217 11.3668C14.5152 11.4604 14.5813 11.5592 14.6198 11.663C14.6583 11.7669 14.6775 11.879 14.6775 11.9995C14.6775 12.12 14.6583 12.2322 14.6198 12.336C14.5813 12.4399 14.5152 12.5386 14.4217 12.6323L9.9275 17.1265C9.789 17.2649 9.61492 17.3357 9.40525 17.339C9.19575 17.3422 9.0185 17.2714 8.8735 17.1265C8.72867 16.9815 8.65625 16.8059 8.65625 16.5995C8.65625 16.3932 8.72867 16.2175 8.8735 16.0725L12.9467 11.9995Z"
          fill="currentcolor"
        />
      </g>
    </Icon>
  );
};

export default ArrowRightIcon;
