import {
  API_GET_CACHE,
  API_HOST_PREFIX,
  DATA_STORE,
  Operation,
  PATH_API,
  StoreName,
  UpdateToOnlineStatus,
} from "constants/serviceWorker";
import { ApiResponse } from "interfaces/models/api";
import {
  CreatePinList,
  GetPinReq,
  Pin,
  PinContent,
  PinDetail,
  UpdatePinGroup,
  UpdatePinGroupAction,
} from "interfaces/models/pin";
import { iCachedItem } from "interfaces/models/serviceWorker";
import isUndefined from "lodash/isUndefined";
import { arrayToObject } from "utils/object";
import RequestServiceWorker from "../RequestServiceWorker";

enum KeyPush {
  DETAIL = "detail",
  LIST_BY_TYPE = "list-by-type",
  LIST_BY_GROUP = "list-by-group",
}

class RequestGetInspectionPin extends RequestServiceWorker {
  async updateKeyPushed(args: {
    key: string;
    cachedItem: iCachedItem;

    // numberRequestRelative is the number of requests that need to sync data
    // If it is exceeded, the cache will be cleared.
    numberRequestRelative?: number;
  }) {
    const { key, cachedItem, numberRequestRelative = 2 } = args;
    const indexedDb = this.indexedDb!;

    const isStatusSuccess = cachedItem.status === UpdateToOnlineStatus.Success;
    const isPushed = cachedItem.isPushed || {};
    isPushed[key] = true;
    const keyPushs = Object.keys(isPushed);
    await indexedDb?.put(cachedItem.id, { ...cachedItem, isPushed });

    if (isStatusSuccess && keyPushs.length > numberRequestRelative) {
      return cachedItem.id;
    }

    return;
  }

  async getCachedItemByPins() {
    const params: GetPinReq = this.params as any;
    const indexedDb = this.indexedDb!;

    const [cachedContentItems, _cachedItems, cachedGroupItems] =
      await Promise.all([
        indexedDb?.getAll(
          DATA_STORE,
          StoreName.INSPECTION_PIN_CONTENT
        ) as Promise<iCachedItem<PinContent>[]>,
        indexedDb?.getAll(DATA_STORE, StoreName.INSPECTION_PIN),
        indexedDb?.getAll(
          DATA_STORE,
          StoreName.INSPECTION_PIN_GROUP
        ) as Promise<iCachedItem<UpdatePinGroup>[]>,
      ]);

    const cachedCreateItems: iCachedItem<CreatePinList>[] = [];
    const cachedItems: iCachedItem<any>[] = [];
    _cachedItems.forEach((item) => {
      if (item.operation === Operation.Post) {
        const _item = item as iCachedItem<CreatePinList>;
        if (_item.data.inspectionTypeId === params?.inspectionTypeId) {
          cachedCreateItems.push(item);
        } else if (_item.data.pinGroupId === params?.inspectionGroupId) {
          cachedCreateItems.push(item);
        }
      } else {
        cachedItems.push(item);
      }
    });

    return {
      cachedContentItems,
      cachedItems,
      cachedGroupItems,
      cachedCreateItems,
    };
  }

  async getCachedItemByPin() {
    const indexedDb = this.indexedDb!;

    const [cachedEditItems, cachedContentItems, cachedCreateItems] =
      await Promise.all([
        indexedDb
          ?.getAll(DATA_STORE, StoreName.INSPECTION_PIN)
          .then((res: iCachedItem<Pin>[]) =>
            res.filter((item) => item.operation === Operation.Patch)
          ),
        indexedDb?.getAll(DATA_STORE, StoreName.INSPECTION_PIN_CONTENT),
        indexedDb
          ?.getAll(DATA_STORE, StoreName.INSPECTION_PIN)
          .then((res: iCachedItem<CreatePinList>[]) =>
            res.filter((item) => item.operation === Operation.Post)
          ),
      ]);

    return { cachedEditItems, cachedContentItems, cachedCreateItems };
  }

  /**
   *
   *   --- Flow sync data for getPinList  ---
   *  1. when remove, delete pin group
   *  - update pinGroups of pin in pinList
   *  2. when add new pin
   *  - add pin into pinList
   *  3. when delete pin
   *  - remove pin in pinList and pin added, pinContent in IndexedDb
   *  - when edit in right sidebar
   */
  async getDataResponseInspectionPins(args: { apiResponse: ApiResponse }) {
    const { apiResponse } = args;
    let data: Pin[] = apiResponse.data;
    const deletes: Set<string> = new Set();
    const params: GetPinReq = this.params as any;
    const indexedDb = this.indexedDb!;
    const isQueryByInspectionType = params?.inspectionTypeId;
    const keyPush = isQueryByInspectionType
      ? KeyPush.LIST_BY_TYPE
      : KeyPush.LIST_BY_GROUP;

    const {
      cachedContentItems,
      cachedItems,
      cachedGroupItems,
      cachedCreateItems,
    } = await this.getCachedItemByPins();

    const cachedDeleteItems: iCachedItem[] = [];
    cachedItems.forEach((item) => {
      if (item.operation === Operation.Delete) {
        cachedDeleteItems.push(item);
      }
    });

    const isLastPage =
      !apiResponse?.pagination && params?.["paging"] === "cursor";
    const deleteItemIdsSet = new Set(
      cachedDeleteItems.map((item) => item.data) as string[]
    );

    // case add new pin
    const mapRemovePinByGroupId: Record<string, iCachedItem<UpdatePinGroup>[]> =
      {};
    cachedGroupItems.forEach((item) => {
      const { operation, data } = item;
      if (
        operation !== Operation.Patch ||
        data.action !== UpdatePinGroupAction.REMOVE
      ) {
        return;
      }

      const pinGroupId = item.data.pinGroupId;

      if (!mapRemovePinByGroupId?.[pinGroupId]) {
        mapRemovePinByGroupId[pinGroupId] = [];
      }

      mapRemovePinByGroupId[pinGroupId].push(item);
    });
    for await (const _cachedItem of cachedCreateItems) {
      const cachedItem = _cachedItem;
      const isPushed = cachedItem?.isPushed[keyPush];
      const pins = cachedItem.data.pins as Pin[];
      let isSyncData = false;

      for (const pin of pins) {
        if (deleteItemIdsSet.has(pin.id)) {
          const filterPins = pins.filter((item) => item.id !== pin.id);
          cachedItem.data.pins = filterPins;

          continue;
        }

        let clonePin = pin;
        // filter pinGroup for case request type get pin list by group
        // Find list of pinIDs to delete based on current pinGroup
        const currentPinGroupId = clonePin?.pinGroups?.at(0)?.pinGroupId || "";
        const removePinIds = mapRemovePinByGroupId?.[currentPinGroupId]
          ?.map((item) => item.data.pinIds.filter((id) => id === clonePin.id))
          ?.flat(1);

        // If pin belongs to pinGroup and needs to be deleted
        if (clonePin?.pinGroups?.length && removePinIds?.length) {
          clonePin = structuredClone(pin);
          clonePin.pinGroups = [];
        }

        const editIndex = data.findIndex((item) => item.id === clonePin.id);
        const isEditData = editIndex !== -1 && !isPushed;
        const isAddNewData =
          !isPushed && !isEditData && isLastPage && !this.swOnlineStatus;

        if (isEditData) {
          data[editIndex] = this.overrideCachedData({
            cachedItem: clonePin as any,
            currentItem: data[editIndex] as any,
            status: cachedItem.status,
          });
        } else if (isAddNewData) {
          data.push(clonePin);
        }

        isSyncData = editIndex !== -1 || isAddNewData;
      }

      if (isLastPage && cachedItem.status === UpdateToOnlineStatus.Success) {
        isSyncData = true;
      }

      const pinLength = cachedItem.data.pins.length;

      // Only clear cache when sync is successful for
      // both api get pin list and get pin detail
      if (isSyncData) {
        const deleteId = await this.updateKeyPushed({
          key: keyPush,
          cachedItem,
          numberRequestRelative: pinLength + 1, // all pinDetail, list-by-type, list-by-group
        });
        if (deleteId) {
          deletes.add(deleteId);
        }
      }

      // Or no pins
      if (!pinLength) {
        deletes.add(cachedItem.id);
      }
    }

    const mapData = arrayToObject(data, "id");
    for await (const cachedItem of cachedItems) {
      if (cachedItem.operation === Operation.Patch) {
        if (deleteItemIdsSet.has(cachedItem.data.id)) {
          deletes.add(cachedItem.id);
        } else {
          // case edit pin
          const { data } = cachedItem;
          if (!mapData?.[data?.id]) {
            continue;
          }

          mapData[data.id] = this.overrideCachedData({
            cachedItem: cachedItem.data,
            currentItem: mapData[data.id],
            status: cachedItem.status,
          });

          const deleteId = await this.updateKeyPushed({
            key: keyPush,
            cachedItem,
          });

          if (deleteId) {
            deletes.add(deleteId);
          }
        }
      }

      // case delete pin
      if (cachedItem.operation === Operation.Delete) {
        const existed = !!mapData?.[cachedItem.data];

        // remove pin
        if (existed || isLastPage) {
          if (!this.swOnlineStatus) {
            Array.from(deleteItemIdsSet).forEach((id) => {
              delete mapData?.[id];
            });
          }

          if (cachedItem.status === UpdateToOnlineStatus.Success) {
            deletes.add(cachedItem.id);
          }
        }
      }
    }

    data = Object.values(mapData);

    // case remove pin content
    cachedContentItems.forEach((item) => {
      if (deleteItemIdsSet.has(item.data.pinId)) {
        deletes.add(item.id);
      }
    });

    // case edit pin group
    if (cachedGroupItems.length) {
      const mapData = arrayToObject(data, "id");
      for await (const cachedItem of cachedGroupItems) {
        const isPushed = cachedItem?.isPushed[keyPush];
        if (isPushed) {
          continue;
        }

        const { data, operation, id: cachedId } = cachedItem;
        data?.pinIds?.forEach((pinId) => {
          if (!mapData?.[pinId] || operation !== Operation.Patch) {
            return;
          }

          let pinGroups = mapData[pinId].pinGroups;
          if (!pinGroups) {
            mapData[pinId].pinGroups = [];
            pinGroups = [];
          }

          switch (data.action) {
            case UpdatePinGroupAction.REMOVE: {
              if (isQueryByInspectionType) {
                mapData[pinId].pinGroups = pinGroups?.filter(
                  (i) => data.pinGroupId !== i.pinGroupId
                );
              }
              break;
            }
            case UpdatePinGroupAction.ADD: {
              if (isQueryByInspectionType) {
                mapData[pinId].pinGroups?.push({
                  order: Date.now(),
                  pinGroupId: data.pinGroupId,
                });
              }
              break;
            }

            case UpdatePinGroupAction.CHANGE_ORDER: {
              const indexGroup = pinGroups?.findIndex(
                (item) => item.pinGroupId === data.pinGroupId
              );
              if (indexGroup === -1 || isUndefined(data.order)) {
                break;
              }

              mapData[pinId].pinGroups![indexGroup].order = data.order;
              break;
            }
          }
        });

        // when edit pinGroup by action add
        // then sync data will using func 'updateCacheAfterUpdatePinGroup'
        // numberRequestRelative is the number of requests that need to sync data
        // If it is exceeded, the cache will be cleared.
        const deleteId = await this.updateKeyPushed({
          key: keyPush,
          cachedItem,
          numberRequestRelative:
            isQueryByInspectionType && data.action === UpdatePinGroupAction.ADD
              ? 0
              : 1,
        });

        if (deleteId) {
          deletes.add(cachedId);
        }
      }
      data = Object.values(mapData);
    }

    if (deletes.size) {
      await indexedDb?.deleteList(Array.from(deletes));
    }

    return data;
  }

  /**
   *
   *   --- Flow sync data for getPinDetail  ---
   *  1. when edit pin content in right sidebar
   *  - update data from cached
   *  2. when add new pin
   *  - add new data from pin added
   *  3. when edit pinContent
   *  - update pinContents of pin
   *  4. when delete pin
   *  - pinContent will deleted when sync data for getPinList
   */
  async getDataResponseInspectionPin(args: {
    pinId: string;
    apiResponse: ApiResponse;
  }) {
    const { pinId, apiResponse } = args;
    const deletes: string[] = [];
    let data: PinDetail = Array.isArray(apiResponse.data)
      ? {}
      : apiResponse.data;
    const indexedDb = this.indexedDb!;

    if (!data?.pinContents) {
      data.pinContents = [];
    }

    const { cachedEditItems, cachedContentItems, cachedCreateItems } =
      await this.getCachedItemByPin();

    // case sync get pin detail from add new pin
    for await (const cachedItem of cachedCreateItems) {
      const keyPush = `${KeyPush.DETAIL}/${pinId}`;
      const pins = cachedItem.data.pins as Pin[];
      let isSyncData = false;

      // eslint-disable-next-line
      pins.forEach((pin) => {
        if (pin.id === pinId && !cachedItem?.isPushed?.[keyPush]) {
          isSyncData = true;
          data = this.overrideCachedData({
            cachedItem: pin as any,
            currentItem: data as any,
            status: cachedItem.status,
          });

          return;
        }
      });

      // Only clear cache when sync is successful for
      // both api get pin list and get pin detail
      if (isSyncData) {
        const deleteId = await this.updateKeyPushed({
          key: keyPush,
          cachedItem,
          numberRequestRelative: pins.length + 1,
        });
        if (deleteId) {
          deletes.push(deleteId);
        }
      }
    }

    // case sync get pin detail from edit pin
    for await (const cachedItem of cachedEditItems) {
      if (cachedItem.data.id === data.id) {
        data = this.overrideCachedData({
          cachedItem: cachedItem.data,
          currentItem: data,
          status: cachedItem.status,
        });

        const deleteId = await this.updateKeyPushed({
          key: KeyPush.DETAIL,
          cachedItem,
        });
        if (deleteId) {
          deletes.push(deleteId);
        }
      }
    }

    // case sync get pin contents of pin detail from edit pin
    cachedContentItems.forEach((cached) => {
      const item = cached.data as PinContent;
      const { itemResultId, pinId } = item;

      if (pinId !== data.id) return cached;

      const index = (data?.pinContents || [])?.findIndex(
        (pinContent: PinContent) => pinContent.itemResultId === itemResultId
      );

      if (index === -1) {
        data.pinContents!.push(item);
      } else {
        data.pinContents![index] = item;
      }

      if (cached.status === UpdateToOnlineStatus.Success) {
        deletes.push(cached.id);
      }
    });

    if (deletes.length) {
      await indexedDb?.deleteList(deletes);
    }

    return data;
  }

  async changeResponseData(args: {
    apiResponse: ApiResponse;
    response: Response | undefined;
  }): Promise<Response> {
    const isArray = !!this.params?.paging;
    const response = args.response;
    const apiResponse = structuredClone(args.apiResponse);
    if (isArray) {
      // Change response data for get list
      apiResponse.data = await this.getDataResponseInspectionPins({
        apiResponse,
      });
    } else {
      // Change response data for get detail
      const pinId =
        new URL(this.event.request.clone().url).pathname.split("/").pop() || "";
      apiResponse.data = await this.getDataResponseInspectionPin({
        pinId,
        apiResponse,
      });
    }

    return await this.response({
      data: apiResponse,
      response,
    });
  }
}

export const updateCacheAfterUpdatePinGroup = async (
  params: {
    pin?: Pin;
  } & UpdatePinGroup
) => {
  const { pinGroupId, action, pin, pinIds, order } = params;
  const url = `/${API_HOST_PREFIX}/v1/${PATH_API.INSPECTION_PIN}`;
  const cache = await caches.open(API_GET_CACHE);
  const cacheKeys = await cache.keys();
  const requests = cacheKeys.filter(
    (req) =>
      req.url.includes(url) &&
      req.url.includes(`inspectionGroupId=${pinGroupId}`)
  );

  await Promise.all(
    requests.map(async (req) => {
      const res = await cache.match(req);
      if (!res) {
        return;
      }

      const pinsRes: ApiResponse<Pin[]> = await res.json();
      switch (action) {
        case UpdatePinGroupAction.ADD: {
          if (!pin || pinsRes.pagination) {
            break;
          }

          const newPin = structuredClone(pin);
          newPin.pinGroups = newPin?.pinGroups ?? [];
          newPin.pinGroups.push({ pinGroupId, order: Date.now() });
          pinsRes.data.push(newPin);

          break;
        }

        case UpdatePinGroupAction.REMOVE: {
          pinsRes.data = pinsRes.data.filter(
            (item) => !pinIds.includes(item.id)
          );

          break;
        }

        case UpdatePinGroupAction.CHANGE_ORDER: {
          pinsRes.data.forEach((pin) => {
            if (!pinIds.includes(pin.id) || isUndefined(order)) {
              return;
            }

            pin.pinGroups = (pin.pinGroups ?? []).map((pinGroup) => {
              if (pinGroup.pinGroupId === pinGroupId) {
                return { ...pinGroup, order };
              }

              return pinGroup;
            });
          });
        }
      }
      const newResponse = new Response(JSON.stringify(pinsRes), {
        headers: res?.headers,
        status: res?.status || 202,
        statusText: res?.statusText,
      });
      await cache.put(req, newResponse.clone());
    })
  );
};

export default RequestGetInspectionPin;
