import { TextSelection } from "@tiptap/pm/state";
import { Extension } from "@tiptap/react";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    selection: {
      unSelection: () => ReturnType;
      setCustomSelection: (pos: number) => ReturnType;
      selectAllCurrentText: () => ReturnType;
    };
  }
}

export const Selection = Extension.create({
  name: "selection",

  addCommands() {
    return {
      unSelection:
        () =>
        ({ editor, tr }) => {
          const transaction = tr.setSelection(
            TextSelection.create(editor.state.doc, 0)
          );
          editor.view.dispatch(transaction);

          return true;
        },
      setCustomSelection:
        (pos) =>
        ({ editor, tr }) => {
          const transaction = tr.setSelection(
            TextSelection.create(editor.state.doc, pos)
          );
          editor.view.dispatch(transaction);

          return true;
        },
      selectAllCurrentText:
        () =>
        ({ editor, tr }) => {
          const sel = editor.state.selection;

          const transaction = tr.setSelection(
            TextSelection.create(
              editor.state.doc,
              sel.$from.start(),
              sel.$from.end()
            )
          );
          editor.view.dispatch(transaction);

          return true;
        },
    };
  },
});
