import { PATH_API } from "constants/serviceWorker";
import { ApiResponse } from "interfaces/models/api";
import { DataProjectModel } from "interfaces/models/dataProjectModel";
import { axiosECS } from "services/baseAxios";
import { View } from "../forgeApi";

const path = `/v1/${PATH_API.BIM_FILES}`;

export interface DataGenerate {
  isGeneratedInstance: boolean;
  isGeneratedCategory: boolean;
  isGeneratedFamilyInstance: boolean;
  isGeneratedSpace: boolean;
  // List guid of view that do not exist in the DB
  missingViews: string[];
}

export const getProjectList = async (params?: {
  onlyGetListName?: string;
}): Promise<ApiResponse<any[]>> => {
  return axiosECS.get(path, { params });
};

export const getUsingBimFiles = async (): Promise<ApiResponse<string[]>> => {
  return axiosECS.get(`${path}/ids`);
};

export const getLastUpdatedResource = async (body: {
  bimFileId: string;
  localTime: string;
}): Promise<ApiResponse<{ lastUpdated: string; resource: string }[]>> => {
  return axiosECS.post(`${path}/last-updated-resources`, body);
};

export const createProject = async (body: any) => {
  return axiosECS.post(path, body);
};

export const updateProject = async (body: any) => {
  return axiosECS.patch(path, body);
};

export const getProject = async (
  bimFileId: string,
  useCache = false
): Promise<ApiResponse<DataProjectModel>> => {
  return axiosECS.get(`${path}/${bimFileId}`, {
    params: {
      useCache,
    },
  });
};

export const deleteBimFileList = async (
  ids: string[]
): Promise<ApiResponse<string[]>> => {
  return axiosECS.delete(path, { data: { ids } });
};

export const getDataGenerate = async (
  projectId: string,
  bimFileId: string // bim file id with version (encodeURIComponent format)
): Promise<ApiResponse<DataGenerate>> => {
  return axiosECS.get(`${path}/data-generate`, {
    params: { projectId, bimFileId },
  });
};

export const updateSheetLevel = async (body: any) => {
  return axiosECS.patch(`${path}/sheet-level`, body);
};

export const updateDataGenerate = async (body: any) => {
  return axiosECS.patch(`${path}/data-generate`, body);
};
