import { Img } from "@chakra-ui/react";
import { Attribute, Node } from "@tiptap/core";
import { NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react";
import {
  PREFIX_CACHE_PATH,
  withPresignedUrl,
} from "components/HOC/presignedUrl";
import { DEFAULT_DOCUMENT_PAGE_SIZE } from "constants/document";
import { CustomNodeComponentProps, NodeType } from "../type";

const ImgPresigned = withPresignedUrl(Img);

export interface FrameOriginNodeAttrs {
  src: string;
  pageWidth: number;
  pageHeight: number;
}

export const FrameOriginNode = Node.create({
  name: NodeType.FRAME_ORIGIN,
  group: "block",
  isolating: true,
  selectable: false,

  addAttributes() {
    const attrs: {
      [key in keyof FrameOriginNodeAttrs]: Attribute;
    } = {
      src: { default: null },
      pageWidth: { default: DEFAULT_DOCUMENT_PAGE_SIZE.width },
      pageHeight: { default: DEFAULT_DOCUMENT_PAGE_SIZE.height },
    };

    return attrs;
  },
  parseHTML() {
    return [
      {
        tag: "frame-origin",
      },
    ];
  },
  renderHTML() {
    return ["frame-origin", {}, 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(Component);
  },
});

const Component = (props: CustomNodeComponentProps<FrameOriginNodeAttrs>) => {
  const { src, pageWidth, pageHeight } = props.node.attrs;
  const ratio = pageWidth / pageHeight;

  return (
    <NodeViewWrapper
      style={{
        cursor: "default",
        pointerEvents: "none",
      }}
    >
      <ImgPresigned
        alt=""
        src={src}
        width="100%"
        height="auto"
        maxHeight="100%"
        objectFit="contain"
        objectPosition="top"
        isConvertToBase64={true}
        boxLoadingBackgroundColor="white"
        cachePath={PREFIX_CACHE_PATH.DOCS}
        boxLoadingWidth={pageWidth}
        boxLoadingHeight={pageHeight}
        aspectRatio={ratio}
        crossOrigin="anonymous"
      />
    </NodeViewWrapper>
  );
};
