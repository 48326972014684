import { Flex, Checkbox, Text, Img } from "@chakra-ui/react";

const MAX_ZOOM = 6;
const MIN_ZOOM = 2;

interface NavbarProps {
  navBarTitle?: string;
  zoom: number;
  onZoom(zoom: number): void;
}

const Navbar: React.FC<NavbarProps> = ({ navBarTitle, zoom, onZoom }) => {
  return (
    <Flex
      px="2rem"
      py="2rem"
      gap={{ base: "2rem", "2xl": "5rem" }}
      alignItems={"center"}
      flexWrap={"wrap"}
    >
      <Flex
        pl="2rem"
        alignItems={"center"}
        flexWrap={"wrap"}
        gap={{ base: "2rem", "2xl": "5rem" }}
      >
        <Text fontWeight={600} fontSize={"2rem"}>
          {navBarTitle}
        </Text>
      </Flex>
      <Flex gap="1rem" ml="auto">
        <Img
          onClick={() => zoom < MAX_ZOOM && onZoom(zoom + 1)}
          cursor="pointer"
          w="3rem"
          src="/img/icon-zoom-out.png"
        />
        <Img
          cursor="pointer"
          w="3rem"
          onClick={() => zoom > MIN_ZOOM && onZoom(zoom - 1)}
          src="/img/icon-zoom-in.png"
        />
      </Flex>
    </Flex>
  );
};

export default Navbar;
