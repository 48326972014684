import { InspectionItem } from "interfaces/models/inspectionItem";
import { InspectionItemResult } from "interfaces/models/inspectionItemResult";
import { uuid } from "utils/common";
import { DataType } from "../../setup/define";
import { FormBuilderActions, FormBuilderState, Types } from "./types";

export const PLUS_ORDER = 5;

export const formBuilderReducer = (
  state: FormBuilderState,
  action: FormBuilderActions
) => {
  let next = structuredClone(state);
  const id = uuid();

  switch (action.type) {
    case Types.ADD_ITEM:
      if (!next.contentItems) next.contentItems = [];
      const item: InspectionItem = {
        id,
        offlineId: id,
        inspectionContentId: state.inspectionContent.id,
        name: "",
        updatedAt: new Date(),
        order: String(
          next.contentItems.length
            ? +next.contentItems.at(-1)!.order! + PLUS_ORDER
            : PLUS_ORDER
        ),
      };

      next.contentItems.push(item);

      break;

    case Types.DELETE_ITEM:
      if (next.contentItems) {
        next.contentItems = next.contentItems.filter(
          (inspectionItem) => inspectionItem.id !== action.payload.id
        );
        next.itemResults = next.itemResults.filter(
          (inspectionItemResult) =>
            inspectionItemResult.contentItemId !== action.payload.id
        );
      }
      break;

    case Types.UPDATE_ITEM:
      next.contentItems = next.contentItems
        .map((item) => {
          if (item.id !== action.payload.id) return item;

          return { ...item, ...action.payload, updatedAt: new Date() };
        })
        .sort((a, b) => Number(a.order) - Number(b.order));
      break;

    case Types.ADD_ITEM_RESULT:
      if (!next.itemResults) next.itemResults = [];
      const filterItemResults = next.itemResults
        .filter((i) => i.contentItemId === action.payload.contentItemId)
        .sort((a, b) => Number(a.order) - Number(b.order));
      const lastItem = filterItemResults?.at(-1);
      const lastIndex = next.itemResults.findIndex(
        (i) => i.id === lastItem?.id
      );

      const newItemResult = {
        id,
        name: "",
        contentItemId: action.payload.contentItemId,
        dataType: DataType.TEXT,
        updatedAt: new Date(),
        offlineId: id,
      } as InspectionItemResult;
      // insert item result to item results related
      const lastOrder = lastItem?.order || 0;
      next.itemResults.splice(lastIndex + 1, 0, {
        ...newItemResult,
        order: String(Number(lastOrder) + PLUS_ORDER),
      });

      break;

    case Types.DELETE_ITEM_RESULT:
      next.itemResults = next.itemResults?.filter(
        (inspectionItemResult) => inspectionItemResult.id !== action.payload.id
      );
      break;

    case Types.UPDATE_ITEM_RESULT:
      next.itemResults = next.itemResults.map((item) => {
        if (item.id !== action.payload.id) return item;

        return { ...item, ...action.payload, updatedAt: new Date() };
      });
      break;

    case Types.UPDATE_CONTENT:
      next.inspectionContent = {
        ...next.inspectionContent,
        ...action.payload,
        updatedAt: new Date(),
      };
      break;

    case Types.COPY_ITEM:
      const inspectionItem = next.contentItems.find(
        (node) => node.id === action.payload.id
      );
      if (inspectionItem) {
        const copyInspectionItem = structuredClone(inspectionItem);
        const copyInspectionItemId = uuid();

        copyInspectionItem.id = copyInspectionItemId;
        copyInspectionItem.offlineId = copyInspectionItemId;

        const maxItemOrder = Math.max(
          ...next.contentItems.map((i) => Number(i.order))
        );
        const maxItemResultOrder = Math.max(
          ...next.itemResults.map((i) => Number(i.order))
        );
        next.contentItems.push({
          ...copyInspectionItem,
          order: String(maxItemOrder + PLUS_ORDER),
          updatedAt: new Date(),
        });
        next.contentItems.sort((a, b) => Number(a.order) - Number(b.order));
        const contentItemId = inspectionItem.id;
        next.itemResults.forEach((item) => {
          if (item.contentItemId === contentItemId) {
            const inspectionItemResult = structuredClone(item);
            const inspectionItemResultId = uuid();

            inspectionItemResult.offlineId = inspectionItemResultId;
            inspectionItemResult.updatedAt = new Date();
            inspectionItemResult.id = inspectionItemResultId;
            inspectionItemResult.contentItemId = copyInspectionItemId;
            inspectionItemResult.order = String(
              maxItemResultOrder + Number(inspectionItemResult.order)
            );

            return next.itemResults.push(inspectionItemResult);
          }
        });
      }
      break;

    case Types.INIT_DATA:
      next = action.payload;
      break;
  }

  return next;
};
