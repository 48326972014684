import { DeleteIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  BoxProps,
  Button,
  Flex,
  FlexProps,
  Input,
  InputProps,
} from "@chakra-ui/react";
import React, { useEffect, useImperativeHandle } from "react";
import { useState } from "react";

interface SearchProps extends BoxProps {
  searchVal?: string;
  onSearch?(value: string): void;
  isShowSearchIcon?: boolean;
  inputProps?: InputProps;
  onClick?(): void;
  searchIconWrapperProps?: FlexProps;
}

const Search = React.forwardRef<any, SearchProps>(
  (
    {
      onSearch,
      searchVal,
      searchIconWrapperProps,
      isShowSearchIcon,
      inputProps,
      onClick,
      ...rest
    },
    ref
  ) => {
    const [searchValue, setSearchValue] = useState(searchVal ?? "");

    useEffect(() => {
      setSearchValue(searchVal ?? "");
    }, [searchVal]);

    useEffect(() => {
      const timeout = setTimeout(() => {
        onSearch?.(searchValue?.toLowerCase().trim());
      }, 300);

      return () => {
        clearTimeout(timeout);
      };
    }, [onSearch, searchValue]);

    useImperativeHandle(
      ref,
      () => {
        return {
          reset: () => {
            setSearchValue("");
          },
        };
      },
      []
    );

    return (
      <Box position={"relative"} onClick={onClick} {...rest}>
        {isShowSearchIcon && (
          <Flex
            position={"absolute"}
            left={0}
            width={"4rem"}
            top={0}
            height={"100%"}
            zIndex={4}
            justifyContent={"center"}
            alignItems={"center"}
            className="search-icon"
            {...searchIconWrapperProps}
          >
            <SearchIcon fontSize={"2rem"} />
          </Flex>
        )}
        <Input
          borderRadius="4px"
          bgColor="#FFFFFF"
          fontSize="1.4rem"
          _placeholder={{ color: "#A3A3A3", fontSize: "1.4rem" }}
          height="4rem"
          value={searchValue}
          pr={"4rem"}
          position={"relative"}
          zIndex={0}
          pl={isShowSearchIcon ? "4rem" : "1rem"}
          onChange={(e) => setSearchValue(e.target.value)}
          {...inputProps}
        />
        {searchValue && (
          <Box
            position={"absolute"}
            right={"3px"}
            top={0}
            height={"100%"}
            zIndex={1}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Button
              backgroundColor={"transparent"}
              borderColor={"transparent"}
              onClick={() => setSearchValue("")}
              borderRadius={"50%"}
              aspectRatio={1}
              color="#525252"
            >
              <DeleteIcon />
            </Button>
          </Box>
        )}
      </Box>
    );
  }
);

export default Search;
