import isEqual from "lodash/isEqual";
import isObject from "lodash/isObject";
import transform from "lodash/transform";

export function checkDiffTwoObject({
  object,
  base,
}: {
  object: Object;
  base: Object;
}): { isDifference: boolean; dataChanges: { [key: string]: any } } {
  function changes(object: Object, base: Object) {
    return transform(object, function (result: any, value, key) {
      if (!isEqual(value, base[key])) {
        result[key] =
          isObject(value) && isObject(base[key])
            ? changes(value, base[key])
            : value;
      }
    });
  }

  const dataChanges = changes(object, base);

  return { isDifference: !!Object.keys(dataChanges).length, dataChanges };
}

export function arrayToObject<T>(array: T[], key: keyof T): Record<string, T> {
  return (array || []).reduce(
    (prev, item) => Object.assign(prev, { [item[key] as any]: item }),
    {} as Record<string, T>
  );
}

export function isFunction(value: any) {
  return value instanceof Function;
}
