import { NeptuneArea, Space } from "interfaces/models/area";
import { buildMesh, ___viewer3d } from "utils/forge/forge3d";
import { uploadMultipartToS3 } from "utils/upload-multipart";
import { FORGE_DATA_FOLDER_PATH } from "constants/s3";
import { areaApi } from "apiClient/v2";
import { getObjectMetadata } from "apiClient/v2/s3Api";

export default class GenSpace {
  private _bimFileId;
  private _version;
  constructor(bimFileId: string, version: string) {
    this._bimFileId = bimFileId;
    this._version = version;
  }

  async checkGenerated() {
    const response = await getObjectMetadata({
      filePath: FORGE_DATA_FOLDER_PATH,
      fileName: `f-spaces-${encodeURIComponent(this._bimFileId!)}-v${
        this._version
      }.json`,
    });

    return !!response.data.isExists;
  }

  getSpaces = async (model?: Autodesk.Viewing.Model) => {
    if (!model) model = ___viewer3d?.model;
    if (!model) return [];
    const instanceTree = model.getData().instanceTree;
    if (!instanceTree) {
      return [];
    }
    const allDbIds = Object.keys(
      instanceTree.nodeAccess?.dbIdToIndex || {}
    ).map(function (id) {
      return parseInt(id);
    });

    const spaces: Promise<Space>[] = [];
    allDbIds.forEach((dbId) => {
      const nodeName = instanceTree.getNodeName(dbId);

      if (["Spaces", "スペース"].includes(nodeName)) {
        instanceTree.enumNodeChildren(dbId, (id: any) => {
          if (typeof id === "number") {
            spaces.push(
              new Promise((resolve, reject) => {
                model!.getProperties(
                  id,
                  (data) => {
                    const title = data.name?.slice(0, data.name.indexOf("["));
                    const level = data.properties.find(
                      (prop) =>
                        ["Level", "レベル"].includes(prop.displayName) &&
                        ["拘束", "Constraints"].includes(prop.displayCategory)
                    )?.displayValue;

                    const mesh = (buildMesh(id, model!) as THREE.Mesh).toJSON();
                    mesh.dbId = id;
                    resolve({
                      externalId: data.externalId,
                      title,
                      level: level,
                      mesh,
                    } as Space);
                  },
                  (err) => reject(err)
                );
              })
            );
          }
        });
      }
    });

    return await Promise.all(spaces).then((res) =>
      res.filter((res) => !!res.level)
    );
  };

  async uploadSpaceData(spaces: Space[]) {
    let newAreas: NeptuneArea[] = [];
    let newSpaces: Space[] = [];
    if (spaces) {
      const uploaded = await uploadMultipartToS3({
        filePath: FORGE_DATA_FOLDER_PATH,
        fileName: `f-spaces-${encodeURIComponent(this._bimFileId!)}-v${
          this._version
        }.json`,
        fileData: { spaces },
      });
      if (uploaded) {
        await areaApi.createNeptuneArea({
          bimFileId: this._bimFileId!,
          version: +this._version!,
        });
        newAreas = (await areaApi.getNeptuneAreaList(this._bimFileId!)).data;
        newSpaces = spaces;
      }
    }

    return { newAreas, newSpaces };
  }
}
