import { Icon, IconProps } from "@chakra-ui/icons";

const PinMiniIcon = (props: IconProps) => {
  return (
    <Icon width="1em" height="1em" viewBox="0 0 23 22" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8346 9.80111C18.8346 13.2556 16.446 16.1522 13.2303 16.9294L11.5009 20.3877L9.77201 16.9294C6.55644 16.152 4.16797 13.2555 4.16797 9.80111C4.16797 5.75102 7.45121 2.46777 11.5013 2.46777C15.5514 2.46777 18.8346 5.75102 18.8346 9.80111ZM11.5013 13.6036C13.6014 13.6036 15.3038 11.9011 15.3038 9.80109C15.3038 7.70105 13.6014 5.99862 11.5013 5.99862C9.40126 5.99862 7.69884 7.70105 7.69884 9.80109C7.69884 11.9011 9.40126 13.6036 11.5013 13.6036Z"
        fill="currentcolor"
      />
    </Icon>
  );
};

export default PinMiniIcon;
