import { Box, Flex, Input, Spinner } from "@chakra-ui/react";
import FieldPreviewWrapper from "../../../components/FieldPreviewWrapper";
import { FieldPreviewProps } from "../../types";
import { useInputValue } from "../../../hooks/useInputValue";
import { SOURCE } from "components/containers/InspectionFormat/Content/SettingPinLayoutFrame/context/pinLayoutFrameReducer";
import { DroppableBox } from "components/containers/InspectionFormat/containers/LayoutFrameBuilder";
import TyphographyIcon from "components/icon/TyphographyIcon";

interface InputPreviewerProps extends FieldPreviewProps<string> {}

const InputPreviewer: React.FC<InputPreviewerProps> = ({
  item,
  value,
  isReadOnly,
  onChange,
  isLoading,
  isDraggable,
}) => {
  const controller = useInputValue({ value, onChange, isReadOnly });

  if (isDraggable) {
    return (
      <FieldPreviewWrapper title={item.name} data-type={item.dataType}>
        <DroppableBox
          flex={1}
          name={item.name}
          icon={<TyphographyIcon w="3rem" h="3rem" />}
          id={item.id}
          source={SOURCE.INSPECTION_FORM}
        >
          <Input
            className="draggable-area"
            isReadOnly={isReadOnly}
            backgroundColor="white"
            height="4rem"
            maxWidth="100%"
          />
        </DroppableBox>
      </FieldPreviewWrapper>
    );
  }

  return (
    <FieldPreviewWrapper title={item.name}>
      <Box position={"relative"}>
        <Input
          isReadOnly={isReadOnly}
          backgroundColor="white"
          height="4rem"
          maxWidth="100%"
          width="100%"
          placeholder="テキスト"
          fontSize={"1.6rem"}
          color="#171717"
          _readOnly={{
            cursor: "pointer",
          }}
          {...controller}
          onChange={(e) => controller.onChange(e.target.value)}
          paddingRight={isLoading ? "3rem" : "1rem"}
        />
        {isLoading && (
          <Flex
            justifyContent={"center"}
            alignItems={"center"}
            position={"absolute"}
            right={0}
            top={0}
            w={"3rem"}
            h="100%"
            zIndex={10}
          >
            <Spinner />
          </Flex>
        )}
      </Box>
    </FieldPreviewWrapper>
  );
};

export default InputPreviewer;
