import { useCallback, useContext } from "react";
import { FormStateContext } from "../context/FormStateContext/context";

interface Props {
  itemResultId?: string;
}

export const useFormState = (props?: Props) => {
  const { itemResultId } = props || {};
  const ctx = useContext(FormStateContext);

  const value = ctx?.formValue?.[itemResultId || ""];

  const getField = useCallback(
    (itemResultId: string) => ctx?.fields?.[itemResultId],
    [ctx?.fields]
  );

  const getValue = useCallback(
    (itemResultId: string) => ctx?.formValue?.[itemResultId],
    [ctx?.formValue]
  );

  return {
    ...ctx,
    value,
    setValue: ctx?.saveFieldValue,
    getValue,
    getField,
  };
};

export default useFormState;
