import { FamilyInstanceDTO } from "interfaces/dtos/familyInstance";
import { Vector3 } from "interfaces/models";
import { DataProjectModel } from "interfaces/models/dataProjectModel";

import store from "redux/store";
import { getBimFileInfo } from "utils/bim";
import { logDev, logError } from "utils/logs";
import { getCurrentViewer } from ".";

export let ___mapExternalId: { [key: string]: number };

export let _allowClearSelectWhenClick: boolean = true;
export let _allow_select_object: boolean = true;

export const setAllowClearSelectWhenClick = (val: boolean) => {
  _allowClearSelectWhenClick = val;
};

export const setAllowSelectObject = (val: boolean) => {
  _allow_select_object = val;
};

export let __familyInstances: {
  [key: string]: FamilyInstanceDTO;
} = {};

export const setMapExternalId = (value: any) => {
  ___mapExternalId = value;
};

export const clearAllData = () => {
  setMapExternalId({});
  setFamilyInstances({});
};

export const setFamilyInstances = (value: any) => {
  __familyInstances = value;
};

const getDbIdToIndex = () => {
  const viewer = getCurrentViewer();
  if (!viewer) {
    return {};
  }
  const instanceTree = viewer?.model?.getData()?.instanceTree;

  return instanceTree?.nodeAccess?.dbIdToIndex || {};
};

export const covertToDbIds = (
  ids?: number | string | number[] | string[] | undefined
) => {
  if (typeof ids === "undefined") {
    return [];
  }
  if (typeof ids === "number") {
    return [ids];
  }
  if (typeof ids === "string") {
    const dbId = getDbIdByExternalId(ids);

    return [dbId];
  }
  if (Array.isArray(ids)) {
    return ids.map((id) => {
      if (typeof id === "number") {
        return id;
      }

      return getDbIdByExternalId(id);
    });
  }

  return [] as number[];
};

export const getSelectMapExternalId2DbId = async (
  externalIds: string[]
): Promise<{ [key: string]: number }> => {
  const viewer = getCurrentViewer();
  if (!viewer) {
    return {};
  }
  try {
    const filter = externalIds.reduce((prev, cr) => {
      //@ts-ignore
      prev[cr] = true;

      return prev;
    }, {});
    const result = await viewer.model
      .getPropertyDb()
      .executeUserFunction(function userFunction(pdb, filter) {
        return pdb.getExternalIdMapping(filter);
      }, filter);

    return result;
  } catch (err) {
    return {};
  }
};

export const traverseDbId = (cb: (dbId: number) => void) => {
  const dbIdToIndex = getDbIdToIndex();
  for (const dbId in dbIdToIndex) {
    const id = Number(dbId);
    if (id === 0) {
      continue;
    }
    cb(id);
  }
};

export const getDbIdByExternalId = (externalId?: string) => {
  if (!___mapExternalId || !externalId) {
    return NaN;
  }

  return Number(___mapExternalId[externalId]);
};

export const checkIsDiffDataProjectVersion = (
  dataProjectDetail: DataProjectModel
) => {
  const urn = decodeURIComponent(dataProjectDetail?.defaultBimPathId || "")
    ?.split("/")
    .pop();
  const prevUrn = decodeURIComponent(
    dataProjectDetail?.prevDefaultBimPathId || ""
  )
    ?.split("/")
    .pop();

  if (!prevUrn || !urn) {
    return false;
  }

  const { version: currentForgeVersion, bimFileId: currentBimFileId } =
    getBimFileInfo(urn || "");
  const { version: prevForgeVersion, bimFileId: prevBimFileId } =
    getBimFileInfo(prevUrn || "");

  return (
    currentForgeVersion !== prevForgeVersion &&
    currentBimFileId === prevBimFileId
  );
};

export const getAllDbIds = (model?: Autodesk.Viewing.Model) => {
  if (!model) model = getCurrentViewer()?.model;
  if (!model) return [];
  const instanceTree = model.getData().instanceTree!;

  return Object.keys(instanceTree.nodeAccess?.dbIdToIndex || {}).map(function (
    id
  ) {
    return parseInt(id);
  });
};

export const getPropertiesByDbIds = async (
  dbIds: number[],
  filter: { [key: string]: any } = {
    propFilter: ["externalId"],
    ignoreHidden: true,
  }
): Promise<Autodesk.Viewing.PropertyResult[]> => {
  const viewer = getCurrentViewer();
  if (!viewer) {
    return [];
  }

  return new Promise((resolve, reject) => {
    viewer.model.getBulkProperties2(
      dbIds,
      filter,
      (data) => {
        resolve(data);
      },
      () => {
        reject("cannot load properties");
      }
    );
  });
};

export const getLevelOfPosition = (position: Vector3 | THREE.Vector3) => {
  const levels = store.getState().forgeViewer.levels || [];
  const level = levels.find(
    (level: any) => level.zMin <= position.z && position.z <= level.zMax
  );

  return level?.label;
};

export const handleSetMapDbIdAndExternalId = async () => {
  const x = performance.now();
  let allDbIds = getAllDbIds();
  try {
    const instancesProperties = await getPropertiesByDbIds(allDbIds);
    console.log(performance.now() - x, `Load ${allDbIds.length} externalId`);
    allDbIds = [];
    const mapExternalId: { [key: string]: number } = {};
    for (const instance of instancesProperties) {
      mapExternalId[instance.externalId!] = instance.dbId;
    }
    setMapExternalId(mapExternalId);

    return true;
  } catch (err) {
    logError(err);

    return false;
  }
};

export const getAllMappingExternalId = async (): Promise<
  { [key: string]: number } | undefined
> => {
  const viewer = getCurrentViewer();
  if (!viewer) {
    return;
  }

  return new Promise((res) => {
    viewer?.model?.getExternalIdMapping(
      (data) => {
        res(data as any);
      },
      () => {
        logDev("fetch mapExternalId failed");
        res(undefined);
      }
    );
  });
};

export const getExternalIdByDbId = async (
  dbId: number
): Promise<string | undefined> => {
  const viewer = getCurrentViewer();
  if (!viewer) {
    return;
  }

  return new Promise((resolve) => {
    viewer.model.getProperties(
      dbId,
      (data) => {
        resolve(data?.externalId);
      },
      () => {
        resolve(undefined);
      }
    );
  });
};

export const getTreeNode = (includeName = false) => {
  const model = getCurrentViewer()?.model!; // current viewer = NOP_VIEWER
  if (!model) {
    return;
  }
  const it = model.getData().instanceTree;
  const rootId = it?.getRootId();
  const rootNode: any = {
    dbId: rootId,
  };
  if (includeName) {
    rootNode.name = it?.getNodeName(rootId);
  }
  // get tree object though traversal tree object viewer -> Here we can get categories = first level of tree
  const _buildModelTreeRec = function (node: any) {
    it?.enumNodeChildren(node.dbId, function (childId: any) {
      node.children = node.children || [];
      const childNode: any = {
        dbId: childId,
      };
      if (includeName) {
        childNode.name = it.getNodeName(childId);
      }
      node.children.push(childNode);
      _buildModelTreeRec(childNode);
    });
  };

  _buildModelTreeRec(rootNode);

  return rootNode;
};

export const getCategoryNameOfObject = (dbId: number) => {
  const rootNode = getTreeNode(true);
  let foundCategoryName = "";
  const objectTraversal = (node: any, categoryName?: string) => {
    if (categoryName && node.dbId === dbId) {
      foundCategoryName = categoryName;

      return;
    }
    // recursive all child of object
    (node.children || []).forEach((child: any) => {
      objectTraversal(child, categoryName ?? child.name);
    });
  };
  objectTraversal(rootNode);

  return foundCategoryName;
};

export const getAllItems = async () => {
  console.time("Time get all item");
  const allDbIds = getAllDbIds();
  const BATCH_SIZE = 40000;
  const batchNumber = Math.ceil(allDbIds.length / BATCH_SIZE);
  console.log("total items", allDbIds.length);
  const promises = [];
  for (let i = 1; i <= batchNumber; i++) {
    const start = (i - 1) * BATCH_SIZE;
    const end = start + BATCH_SIZE;
    const dbIds = allDbIds.slice(start, end);
    const result = getPropertiesByDbIds(dbIds, {
      ignoreHidden: true,
    });
    promises.push(result);
  }

  const items = await Promise.all(promises);
  console.timeEnd("Time get all item");

  return items.flat();
};

export const getPropertyByDbId = (
  dbId: number
): Promise<{
  dbId: number;
  externalId: string;
  name: string;
  properties: any[];
}> => {
  return new Promise((resolve, reject) => {
    const viewer = getCurrentViewer();
    if (!viewer) return reject(null);
    viewer.getProperties(dbId, resolve, reject);
  }) as any;
};
