import { DataType } from "components/containers/inspectionSetting/containers/templateBuilder/setup/define";
import { Pagination } from "./api";

export interface PinGroup {
  id: string;
  inspectionTypeId: string;
  name: string;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}

export interface Pin {
  id: string;
  inspectionTypeId: string;
  label: string;
  level?: string;
  position?: Position;
  objectInfo?: object;
  objects?: string[];
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
  status: PinGroupStatusType;
  pinGroups?: PinGroupOrder[];
  viewId?: string;

  // offline
  offlineId?: string;
}

export interface PinGroupOrder {
  pinGroupId: string;
  order: string | number;
}
export interface PinDTO extends Omit<Pin, "pinGroups">, PinGroupOrder {}

export interface PinDetail extends Pin {
  index: number;
  isPinOtherGroup?: boolean;
  pinContents?: PinContent[];
  no?: string;
}

export type CreatePinList = {
  pins: Partial<Pin & { order: number }>[];
  pinGroupId: string;
  inspectionTypeId: string;
};

export type CreatePinListResponse = {
  pins: Pin[];
  pinGroupId: string;
  inspectionTypeId: string;
};

export interface PinContent {
  additionInfo: any;
  dataType: DataType;
  id: string;
  itemResultId: string;
  name: string;
  order: number;
  pinId: string;
  value?: { data: any };
  updatedAt?: Date;
}

export enum PinGroupStatusType {
  NotStarted,
  Underway,
  SendBack,
  WaitConfirm,
  Approved,
}

export const MapPinGroupStatusType = {
  [PinGroupStatusType.NotStarted]: "未着手",
  [PinGroupStatusType.Underway]: "着手中",
  [PinGroupStatusType.WaitConfirm]: "承認待ち",
  [PinGroupStatusType.Approved]: "承認済み",
  [PinGroupStatusType.SendBack]: "指摘あり",
};

export const PinGroupStatusOpts = [
  PinGroupStatusType.NotStarted,
  PinGroupStatusType.Underway,
  PinGroupStatusType.WaitConfirm,
  PinGroupStatusType.Approved,
  PinGroupStatusType.SendBack,
];

export const MapPinGroupStatusTypeColor = {
  [PinGroupStatusType.NotStarted]: "#A0A8C6",
  [PinGroupStatusType.Underway]: "#D97706",
  [PinGroupStatusType.WaitConfirm]: "#009BE0",
  [PinGroupStatusType.Approved]: "#338D88",
  [PinGroupStatusType.SendBack]: "#BA0F0F",
};

export const MapPinGroupStatusTypeColorText = {
  [PinGroupStatusType.NotStarted]: "#FFFFFF",
  [PinGroupStatusType.Underway]: "#FFFFFF",
  [PinGroupStatusType.WaitConfirm]: "#FFFFFF",
  [PinGroupStatusType.Approved]: "#FFFFFF",
  [PinGroupStatusType.SendBack]: "#FFFFFF",
};

export type TargetObject = {
  name: string;
  externalId: string;
};

export interface Position {
  x: number;
  y: number;
  z: number;
}

export interface FileHistory {
  src: string;
  createdAt: number;
  userUpload: string;
}

export enum UpdatePinGroupAction {
  ADD = "ADD",
  REMOVE = "REMOVE",
  CHANGE_ORDER = "CHANGE_ORDER",
}

export interface UpdatePinGroup {
  pinIds: string[];
  pinGroupId: string;
  order?: string | number;
  action: UpdatePinGroupAction;
  isFromModeAddPinToGroup?: boolean;
}

export interface GetPinReq extends Pagination {
  inspectionTypeId?: string;
  inspectionGroupId?: string;
  isFullData?: boolean;
  shouldCache?: boolean;
}

export interface UpdatePinContentReq {
  pinId: string;
  itemResultId: string;
  value: { data: any };
  inspectionTypeId: string;
}
