import { ApiResponse } from "interfaces/models/api";
import { axiosECS } from "services/baseAxios";
import { presignedAndDownloadFileS3 } from "utils/file";
import { View } from "../forgeApi";

const path = "/v1/bims/views";

// Get a list of views that have been generated on the database
export const getGeneratedViewList = async (
  bimFileId: string, // bim file with version
  shouldCache?: boolean
): Promise<ApiResponse<any[]>> => {
  const response = await axiosECS.get(path, {
    params: { bimFileId, shouldCache },
  });
  if (shouldCache && response.data) {
    const requests = [];
    const bimViews = response.data || [];
    for (let i = 0; i < bimViews.length; i++) {
      requests.push(presignedAndDownloadFileS3(bimViews[i].imageUrl, true));
    }
    await Promise.all(requests);
  }

  return response;
};

// Get a list of combined views: data from aps + data bim file (levelData)
export const getCombinedViewList = async (
  projectId: string,
  bimFileId: string // bim file id with version (encodeURIComponent format)
): Promise<ApiResponse<View[]>> => {
  return axiosECS.get(`${path}/combined-views`, {
    params: { projectId, bimFileId },
  });
};

// Get a list of inspection views: data from aps + view by inspection
export const getInspectionTypeViewList = async (
  projectId: string,
  inspectionTypeId: string,
  bimFileId: string, // bim file id with version (encodeURIComponent format)
  shouldCache?: boolean
): Promise<ApiResponse<View[]>> => {
  return axiosECS.get(`${path}/inspection-type-views`, {
    params: { inspectionTypeId, bimFileId, projectId, shouldCache },
  });
};

export const createBimViewList = async (body: {
  bimFileId: string; // bim file with version
  views: any[];
}): Promise<ApiResponse<any[]>> => {
  return axiosECS.post(path, body);
};

export const deleteBimViewByBimFile = async (
  bimFileId: string
): Promise<ApiResponse<any[]>> => {
  return axiosECS.delete(path, {
    data: { bimFileId },
  });
};
