import { Flex, Image } from "@chakra-ui/react";
import { withPresignedUrl } from "components/HOC/presignedUrl";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { FileUploadInfo } from "interfaces/models";
import { TaskComment } from "interfaces/models/task";
import { memo } from "react";
import { isDoc, isVideo } from "utils/file";
import { PreviewFileComponent } from "./PreviewFileComponent";

const ImagePresigned = withPresignedUrl(Image);

interface Props {
  log: TaskComment;
  hasContainer?: boolean;
}

const FIELDS_TYPE_MEDIA: (keyof TaskDTO)[] = [
  "images",
  "confirmedImages",
  "attaches",
];

const Media = memo(({ media }: { media: FileUploadInfo }) => {
  const fileName = new URL(media.src).pathname.substring(1);

  if (isDoc(fileName) || isVideo(fileName)) {
    return (
      <PreviewFileComponent
        onDelete={() => {}}
        readonly={true}
        file={{ src: media.src }}
      />
    );
  }

  return (
    <ImagePresigned
      maxH="62px"
      minW="60px"
      objectFit="cover"
      borderRadius="5px"
      cursor="pointer"
      src={media.src}
      isPreviewImageOnNewTab={true}
    />
  );
});

const MediasByLog = (props: Props) => {
  const { log, hasContainer = false } = props;
  const medias = (log?.value?.data as FileUploadInfo[]) || [];

  if (!medias.length || !FIELDS_TYPE_MEDIA.includes((log.field || "") as any)) {
    return <></>;
  }

  return (
    <Flex
      gap="0.5rem"
      flexWrap="wrap"
      {...(hasContainer && {
        mt: "1rem",
        ml: "3.4rem",
      })}
    >
      {medias.map((item) => (
        <Media key={item.src} media={item} />
      ))}
    </Flex>
  );
};

export default memo(MediasByLog);
