import { ApiResponse } from "interfaces/models/api";
import { DocumentTask, MainImageData } from "interfaces/models/documentTask";
import { axiosECS } from "services/baseAxios";
import { removeFalsyProps, validateBodyReq } from "utils/common";
import { presignedAndDownloadFileS3 } from "utils/file";

interface GetDocumentTasksReq {
  bimFileId?: string;
  title?: string;
  cursor?: string;
  level?: string;
  id?: string;
  shouldCache?: boolean;
  ids?: string[];
  limit?: number;
}
const path = "/v1/document-tasks";

const DEFAULT_LIMIT = 500;

const getDocumentTaskList = async (
  params: GetDocumentTasksReq
): Promise<ApiResponse<DocumentTask[]>> => {
  return axiosECS.get(path, {
    params: removeFalsyProps(params),
    headers: {
      isIgnoreShowMessageError: true,
    },
  });
};

const createDocumentTask = async (
  documentTask: Partial<DocumentTask>
): Promise<ApiResponse<DocumentTask>> => {
  return axiosECS.post(path, validateBodyReq(documentTask));
};

const updateDocumentTask = async (
  documentTask: Partial<DocumentTask>
): Promise<ApiResponse<DocumentTask>> => {
  return axiosECS.patch(path, validateBodyReq(documentTask));
};

export const getDocumentTask = async (
  documentTaskId: string,
  shouldCache = false
): Promise<DocumentTask> => {
  const { data } = await getDocumentTaskList({
    id: documentTaskId,
    shouldCache,
  });

  return data[0];
};

export const getDocumentTaskByIds = async (
  documentTaskIds: string[],
  shouldCache = false
): Promise<DocumentTask[]> => {
  const list: DocumentTask[] = [];
  let cursor;
  while (true) {
    const { data, pagination } = (await getDocumentTaskList({
      ids: documentTaskIds,
      shouldCache,
      limit: DEFAULT_LIMIT,
      cursor,
    })) as any;
    list.push(...data);
    if (!pagination) break;
    cursor = pagination.cursor;
  }

  return list;
};

export const deleteDocumentTaskList = async (ids: string[]) => {
  return await axiosECS.delete(path, {
    data: { ids },
  });
};

export const createUpdateDocumentTask = async (
  documentTask: Partial<DocumentTask>
): Promise<DocumentTask> => {
  const isUpdate = !!documentTask.id;
  const { data } = isUpdate
    ? await updateDocumentTask(documentTask)
    : await createDocumentTask(documentTask);

  return data;
};

export const getDocumentTasksByTitle = async ({
  title,
  bimFileId,
}: {
  title: string;
  bimFileId: string;
}) => {
  const { data } = await getDocumentTaskList({
    title: encodeURIComponent(title || ""),
    bimFileId: encodeURIComponent(bimFileId || ""),
  });

  return data;
};

export const getDocumentTasksByBimFileId = async (
  params: GetDocumentTasksReq
) => {
  const { bimFileId, cursor, shouldCache, level } = params;
  const res = await getDocumentTaskList({
    level,
    bimFileId: encodeURIComponent(bimFileId || ""),
    cursor,
  });

  const docs = res?.data || [];
  if (shouldCache) {
    const requests: Promise<any>[] = [];

    docs?.forEach((doc) => {
      if (doc?.detailDataUrl) {
        requests.push(presignedAndDownloadFileS3(doc.detailDataUrl, true));
      }
      if (doc?.taskListUrl) {
        requests.push(presignedAndDownloadFileS3(doc.taskListUrl, true));
      }
      if (doc?.mainImageData) {
        const mainImageData: MainImageData = doc.mainImageData || {};
        Object.values(mainImageData?.mapTaskLabelsByGuid || {}).forEach(
          (item) => {
            requests.push(presignedAndDownloadFileS3(item.imageUrl, true));
          }
        );
      }

      requests.push(
        getDocumentTaskList({
          title: doc.title,
          bimFileId: doc.bimFileId,
          shouldCache: true,
        })
      );

      requests.push(getDocumentTask(doc.id, true));
    });

    await Promise.all(requests);
  }

  return res;
};

export const getAllDocumentTasksByBimFileId = async (
  params: GetDocumentTasksReq
) => {
  const documentTasks: DocumentTask[] = [];
  let cursor: string | undefined;
  do {
    const { data, pagination } = await getDocumentTasksByBimFileId({
      ...params,
      cursor,
    });
    cursor = pagination?.cursor;
    if (data?.length) {
      documentTasks.push.apply(documentTasks, data);
    }
  } while (cursor);

  return documentTasks;
};
