import { mergeAttributes, Node } from "@tiptap/core";
import {
  CheckedSVG,
  UnCheckedSVG,
} from "components/modal/PreviewDocumentCategory/PreviewComponent/Table/Checkbox";
import { PinComponentStyle } from "interfaces/models/pinComponent";
import { renderToStaticMarkup } from "react-dom/server";
import { NodeType } from "../type";
import { onChangeCheckbox } from "../utils";

export interface TaskItemNodeAttrs {
  checked: boolean;
  groupId?: string;
  key?: string;
  style?: PinComponentStyle;
}

export const TaskItem = Node.create({
  name: NodeType.TASK_ITEM,
  // fence the cursor for regular editing operations
  // [https://tiptap.dev/docs/editor/core-concepts/schema#isolating]
  isolating: true,
  content: "inline*",
  defining: true,

  addAttributes() {
    return {
      key: { default: null },
      groupId: { default: null },
      checked: {
        default: false,
        keepOnSplit: false,
        parseHTML: (element) => {
          const dataChecked = element.getAttribute("data-checked");

          return dataChecked === "" || dataChecked === "true";
        },
        renderHTML: (attributes) => ({
          "data-checked": attributes.checked,
        }),
      },
      style: {
        default: {},
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `li[data-type="${this.name}"]`,
        priority: 51,
      },
    ];
  },

  renderHTML({ node }) {
    return [
      "li",
      mergeAttributes({
        "data-type": this.name,
      }),
      [
        "label",
        [
          "input",
          {
            type: "checkbox",
            checked: node.attrs.checked ? "checked" : null,
          },
        ],
        ["span"],
      ],
      ["div", 0],
    ];
  },

  addNodeView() {
    return ({ HTMLAttributes, getPos, editor }) => {
      const { groupId, key } = HTMLAttributes;
      const style = HTMLAttributes.style as PinComponentStyle;
      const listItem = document.createElement("li");
      listItem.style.width = "100%";
      listItem.style.display = "flex";
      listItem.style.alignItems = "center";
      listItem.style.justifyContent = style?.textAlign || "center";
      listItem.style.gap = "0.1rem";

      const checkboxWrapper = document.createElement("label");
      const content = document.createElement("span");
      content.style.textAlign = "left";
      content.style.whiteSpace = "nowrap";
      content.contentEditable = "false";
      content.style.lineHeight = "1";
      const checked = HTMLAttributes["data-checked"];
      const staticCheckboxEle = renderToStaticMarkup(
        checked ? CheckedSVG : UnCheckedSVG
      );
      const checkbox = $(staticCheckboxEle).get(0)!;
      const fontSize = style.fontSize || 14;
      checkbox.style.width = `${fontSize}px`;
      checkbox.style.height = `${fontSize}px`;
      checkboxWrapper.contentEditable = "false";
      content.style.maxWidth = `calc(100% - ${fontSize}px)`;

      checkbox.addEventListener("click", () => {
        if (!editor.isEditable) {
          return;
        }

        if (editor.isEditable && typeof getPos === "function") {
          onChangeCheckbox({ editor, getPos, groupId, key });
        }
      });

      checkboxWrapper.append(checkbox);
      listItem.append(checkboxWrapper, content);
      listItem.setAttribute("data-type", this.name);
      listItem.setAttribute("data-group-id", groupId);
      listItem.setAttribute("data-key", key);

      return {
        dom: listItem,
        contentDOM: content,
        update: (updatedNode) => {
          if (updatedNode.type !== this.type) {
            return false;
          }
          const checked = updatedNode.attrs.checked;
          listItem.dataset.checked = checked;
          const staticCheckboxEle = renderToStaticMarkup(
            checked ? CheckedSVG : UnCheckedSVG
          );
          const checkboxElement = $(staticCheckboxEle).get(0)!;
          checkbox.innerHTML = checkboxElement.innerHTML;

          return true;
        },
      };
    };
  },
});
