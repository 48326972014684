import { Icon, IconProps } from "@chakra-ui/icons";

export default function VerticalTopIcon(props: IconProps) {
  return (
    <Icon
      width="1rem"
      height="1em"
      viewBox="0 0 48 48"
      fill="currentcolor"
      {...props}
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="invisible_box" data-name="invisible box">
          <rect width="48" height="48" fill="none" />
        </g>
        <g id="Q3_icons" data-name="Q3 icons">
          <path d="M6,4H42a2,2,0,0,1,0,4H6A2,2,0,0,1,6,4Z" />
          <path d="M31.4,18.5l-6-5.9a1.9,1.9,0,0,0-2.8,0l-6,5.9a2.1,2.1,0,0,0-.2,2.7,1.9,1.9,0,0,0,3,.2L22,18.8V42a2,2,0,0,0,4,0V18.8l2.6,2.6a1.9,1.9,0,0,0,3-.2A2.1,2.1,0,0,0,31.4,18.5Z" />
        </g>
      </g>
    </Icon>
  );
}
