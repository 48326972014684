import {
  API_GET_CACHE,
  AVAILABLE_STORES,
  UpdateToOnlineStatus,
} from "constants/serviceWorker";
import { getIndexedDb, IndexedDBType } from "utils/indexedDb";
import { ServiceWorker } from "./common";

export interface RequestServiceWorkerProps {
  event: FetchEvent;
  swOnlineStatus: boolean;
}

export const ErrorResponse = new Response("", {
  status: 503,
  statusText: "Service Unavailable",
});

class RequestServiceWorker {
  event: FetchEvent;
  swOnlineStatus: boolean;

  protected storeNameOfRequest: string = "";
  protected params: Record<string, any> = {};
  protected indexedDb: IndexedDBType | undefined = undefined;

  constructor(params: RequestServiceWorkerProps) {
    this.event = params.event;
    this.swOnlineStatus = params.swOnlineStatus;
    const result = this.parseParams(this.event.request.clone());
    this.storeNameOfRequest = result.storeNameOfRequest;
    this.params = result.params;

    this.init();
  }

  async init() {
    this.indexedDb = await getIndexedDb();
  }

  async response(params: {
    response: Response | undefined;
    data: any;
    isPutToCache?: boolean;
  }) {
    const { response, data, isPutToCache = true } = params;
    const request = this.event.request.clone();

    const cache = await caches.open(API_GET_CACHE);
    const newResponse = new Response(JSON.stringify(data), {
      headers: response?.headers,
      status: response?.status || 202,
      statusText: response?.statusText,
    });
    if (newResponse.ok && isPutToCache) {
      await cache.put(request, newResponse.clone());
    }

    return newResponse;
  }

  overrideCachedData(params: {
    cachedItem: any;
    currentItem: any;
    status: UpdateToOnlineStatus;
  }) {
    const { cachedItem, currentItem, status } = params;
    const isStatusSuccess = status === UpdateToOnlineStatus.Success;
    let result = structuredClone(currentItem) as any;
    // when online or after sync data success
    // we can remove offline id of record
    if ((this.swOnlineStatus || isStatusSuccess) && "offlineId" in cachedItem) {
      delete cachedItem?.offlineId;
    }
    result = this.swOnlineStatus
      ? { ...cachedItem, ...currentItem }
      : { ...currentItem, ...cachedItem };

    return result;
  }

  parseParams(request: Request) {
    const uri = request.url.replace(
      `${process.env.REACT_APP_API_HOST_URL || ""}/`,
      ""
    );
    if (uri.includes("count-items")) {
      return { storeNameOfRequest: "count-items", params: {} };
    }
    const params: any = {};
    const queryString = uri.split("?").pop();
    const paths = uri.split("?")[0].split("/");
    let storeNameOfRequest =
      request.method !== "GET"
        ? paths.pop() || ""
        : paths
            .filter((path) => AVAILABLE_STORES.includes(path as any))
            .pop() ||
          paths.pop() ||
          "";

    const storeName = ServiceWorker.convertPathAPIToStoreName(uri);
    if (storeName) {
      storeNameOfRequest = storeName;
    }

    if (queryString) {
      const getParams = ({
        result,
        key,
        value,
      }: {
        result: any;
        key: string;
        value: any;
      }) => {
        if (result[key]) {
          if (Array.isArray(result[key])) {
            result[key].push(value);
          } else {
            result[key] = [result[key], value];
          }
        } else {
          result[key] = value;
        }
      };

      queryString.split("&").forEach(function (pair) {
        const keyValue = pair.split("=");
        const key = decodeURIComponent(keyValue[0]);
        const value = decodeURIComponent(keyValue[1] || "");
        if (["cursor", "limit", "shouldCache"].includes(key)) {
          return;
        }
        getParams({ result: params, key, value });
      });
    }

    return { storeNameOfRequest, params };
  }

  isRequestSyncData(url: string) {
    return (
      new URLSearchParams(new URL(url).search).get("syncOffline") === "true"
    );
  }
}

export default RequestServiceWorker;
