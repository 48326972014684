import { Icon, IconProps } from "@chakra-ui/icons";

const InspectionFormatSettingIcon = (props: IconProps) => {
  return (
    <Icon width="1em" height="1em" viewBox="0 0 22 22" fill="none" {...props}>
      <path
        d="M8.25 16.2707H13.75C13.9451 16.2707 14.1084 16.2048 14.24 16.0731C14.3717 15.9416 14.4375 15.7783 14.4375 15.5832C14.4375 15.3881 14.3717 15.2248 14.24 15.0932C14.1084 14.9615 13.9451 14.8957 13.75 14.8957H8.25C8.0549 14.8957 7.89158 14.9615 7.76004 15.0932C7.62835 15.2248 7.5625 15.3881 7.5625 15.5832C7.5625 15.7783 7.62835 15.9416 7.76004 16.0731C7.89158 16.2048 8.0549 16.2707 8.25 16.2707ZM8.25 12.604H13.75C13.9451 12.604 14.1084 12.5382 14.24 12.4065C14.3717 12.2749 14.4375 12.1116 14.4375 11.9165C14.4375 11.7214 14.3717 11.5581 14.24 11.4265C14.1084 11.2949 13.9451 11.229 13.75 11.229H8.25C8.0549 11.229 7.89158 11.2949 7.76004 11.4265C7.62835 11.5581 7.5625 11.7214 7.5625 11.9165C7.5625 12.1116 7.62835 12.2749 7.76004 12.4065C7.89158 12.5382 8.0549 12.604 8.25 12.604ZM5.7821 19.7082C5.31903 19.7082 4.92708 19.5478 4.60625 19.2269C4.28542 18.9061 4.125 18.5141 4.125 18.0511V3.94861C4.125 3.48554 4.28542 3.09359 4.60625 2.77275C4.92708 2.45192 5.31903 2.2915 5.7821 2.2915H12.3768C12.5978 2.2915 12.8102 2.33443 13.0141 2.4203C13.218 2.506 13.3951 2.6241 13.5456 2.77459L17.3919 6.62092C17.5424 6.77141 17.6605 6.94855 17.7462 7.15236C17.8321 7.35632 17.875 7.56875 17.875 7.78967V18.0511C17.875 18.5141 17.7146 18.9061 17.3938 19.2269C17.0729 19.5478 16.681 19.7082 16.2179 19.7082H5.7821ZM12.375 6.96284V3.6665H5.7821C5.71152 3.6665 5.6469 3.69591 5.58823 3.75473C5.52941 3.8134 5.5 3.87802 5.5 3.94861V18.0511C5.5 18.1217 5.52941 18.1863 5.58823 18.2449C5.6469 18.3038 5.71152 18.3332 5.7821 18.3332H16.2179C16.2885 18.3332 16.3531 18.3038 16.4118 18.2449C16.4706 18.1863 16.5 18.1217 16.5 18.0511V7.7915H13.2037C12.9673 7.7915 12.7702 7.71244 12.6122 7.55432C12.4541 7.39635 12.375 7.19919 12.375 6.96284Z"
        fill="currentcolor"
      />
    </Icon>
  );
};

export default InspectionFormatSettingIcon;
