import { ApiResponse, Pagination } from "interfaces/models/api";
import {
  DocumentFormat,
  DocumentFormatDTO,
} from "interfaces/models/documentFormat";
import { axiosECS } from "services/baseAxios";

const pathV2 = "/v1/documents/formats";
const DEFAULT_LIMIT = 100;

interface GetDocumentFormatReq extends Pagination {
  inspectionTypeId: string;
  name?: string;
  cursor?: string;
  shouldCache?: boolean;
  documentFormatId?: string;
}

export const getDocumentFormatList = async (
  params: GetDocumentFormatReq
): Promise<ApiResponse<DocumentFormat[]>> => {
  const { limit, ...rest } = params;

  return axiosECS.get(pathV2, {
    params: { limit: limit || DEFAULT_LIMIT, ...rest },
  });
};

export const getAllDocumentFormats = async (params: GetDocumentFormatReq) => {
  const formats: DocumentFormat[] = [];
  let cursor: string | undefined;
  const limit = 500;
  do {
    const { data, pagination } = await getDocumentFormatList({
      ...params,
      paging: "cursor",
      cursor,
      limit,
    });
    cursor = pagination?.cursor;
    if (data.length) {
      formats.push.apply(formats, data);
    }
  } while (cursor);

  return formats;
};

export const getDocumentFormatById = async (params: {
  id: string;
  isFullData?: boolean;
}): Promise<ApiResponse<DocumentFormatDTO>> => {
  return axiosECS.get(`${pathV2}/${params.id}`, {
    params: { isFullData: params.isFullData },
  });
};

export const createDocumentFormat = async (
  documentFormat: Partial<DocumentFormat>
): Promise<ApiResponse<DocumentFormat>> => {
  return axiosECS.post(pathV2, documentFormat);
};

export const updateDocumentFormat = async (
  documentFormat: Partial<DocumentFormat>
): Promise<ApiResponse<DocumentFormat>> => {
  return axiosECS.patch(pathV2, documentFormat);
};

export const deleteDocumentFormat = async (
  id: string
): Promise<ApiResponse<string[]>> => {
  return axiosECS.delete(pathV2, { data: { id } });
};
