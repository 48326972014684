import { saveAs } from "file-saver";
import axios from "axios";
import {
  FONT_SIZE_MIN,
  FONT_SIZE_OFFSET,
  LINE_HEIGHT_DEFAULT,
} from "components/modal/PreviewDocumentCategory/hooks/useHandleTextOverflow";
import { message } from "components/base";

let timeout: NodeJS.Timeout;
const MAX_LOOP_CHECK_TEXT_OVERFLOW = 5_000;

export const moveCaretToEnd = (element?: HTMLElement) => {
  if (!element) {
    return;
  }

  const range = document.createRange();
  range.selectNodeContents(element);
  range.collapse(false);

  const selection = window.getSelection();
  if (selection) {
    selection.removeAllRanges();
    selection.addRange(range);
  }
  element.focus();
};

export const transformSizeForTextElement = ({
  element,
  elementCheckOverflow: _elementCheckOverflow,
  elementContainsText: _elementContainsText,
  elementChangeStyle: _elementChangeStyle,
  isShowMessage = true,
  lastTextRef,
  sizeDefault,
  isTransformForParent = false,
  otherConditionCheckTextOverflow,
}: {
  isShowMessage?: boolean;
  element: HTMLElement;
  elementCheckOverflow?: HTMLDivElement;
  elementContainsText?: HTMLDivElement;
  elementChangeStyle?: HTMLDivElement;
  lastTextRef: React.MutableRefObject<string | undefined>;
  isTransformForParent?: boolean;
  sizeDefault: {
    fontSize: number;
    lineHeight: number;
  };
  otherConditionCheckTextOverflow?: () => void;
}) => {
  const elementCheckOverflow = _elementCheckOverflow ?? element;
  const elementChangeStyle = _elementChangeStyle ?? element;
  const elementContainsText = _elementContainsText ?? element;

  const parentElement = elementCheckOverflow.parentElement;
  if (!parentElement) {
    return {
      shouldUpdate: false,
      text: elementContainsText.innerHTML,
    };
  }

  const checkIsOverflow = (element: HTMLElement) => {
    const parentElement = element.parentElement;
    if (!parentElement) {
      return false;
    }

    const isParentHasScroll =
      parentElement.scrollHeight > parentElement.clientHeight;

    let res =
      otherConditionCheckTextOverflow?.() ||
      element.clientHeight > parentElement.clientHeight ||
      isParentHasScroll;

    if (isTransformForParent) {
      res = otherConditionCheckTextOverflow?.() || isParentHasScroll;
    }

    return res;
  };

  const fontSizeNumber = Number(
    getComputedStyle(
      isTransformForParent ? parentElement : elementChangeStyle
    ).fontSize.replace("px", "")
  );

  const isLimitExceedFontSize = fontSizeNumber === FONT_SIZE_MIN;
  let isOverflow = checkIsOverflow(elementCheckOverflow);
  let text = elementContainsText?.innerHTML || "";

  let shouldUpdate = false;
  const isLimitLineHeight = (fs: number) =>
    elementCheckOverflow.clientHeight <= fs * LINE_HEIGHT_DEFAULT;

  if (isOverflow && isLimitExceedFontSize) {
    if (isShowMessage) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        message.warning("テキストが長すぎるため入力できません。");
      }, 300);
    }
  }

  const eleToSizeStyle = isTransformForParent
    ? parentElement
    : elementChangeStyle;

  if (!lastTextRef?.current || !isOverflow || !isLimitExceedFontSize) {
    if (lastTextRef) {
      lastTextRef.current = text;
    }
  }

  if (isOverflow && isLimitExceedFontSize && lastTextRef.current) {
    shouldUpdate = !isLimitLineHeight(fontSizeNumber);
    const lastTextLength = lastTextRef.current.length;
    const textLegnth = text.length;
    const isUpdate = textLegnth >= lastTextLength;

    return {
      text: isUpdate ? lastTextRef.current : text,
      shouldUpdate: isUpdate && shouldUpdate,
    };
  }

  // increase size
  if (!isOverflow) {
    let isOverflowToMuchAfterChangeFontsize =
      checkIsOverflow(elementCheckOverflow);
    let fs = fontSizeNumber;
    let maxLoop = MAX_LOOP_CHECK_TEXT_OVERFLOW;
    while (
      !isOverflowToMuchAfterChangeFontsize &&
      fs !== sizeDefault.fontSize &&
      maxLoop >= 0
    ) {
      maxLoop--;
      fs = fs + FONT_SIZE_OFFSET;
      fs = fs > sizeDefault.fontSize ? sizeDefault.fontSize : fs;
      const lh = fs * LINE_HEIGHT_DEFAULT;
      eleToSizeStyle.style.fontSize = `${fs}px`;
      eleToSizeStyle.style.lineHeight = `${lh}px`;
      isOverflowToMuchAfterChangeFontsize =
        checkIsOverflow(elementCheckOverflow);
      if (isOverflowToMuchAfterChangeFontsize) {
        fs = fs - FONT_SIZE_OFFSET;
        eleToSizeStyle.style.fontSize = `${fs}px`;
        eleToSizeStyle.style.lineHeight = `${fs * LINE_HEIGHT_DEFAULT}px`;
        break;
      }
    }
  }

  // reduce size
  let maxLoop = MAX_LOOP_CHECK_TEXT_OVERFLOW;
  let fs = fontSizeNumber;
  while (isOverflow && maxLoop >= 0) {
    maxLoop--;

    if (isOverflow && fs !== FONT_SIZE_MIN) {
      fs = fs - FONT_SIZE_OFFSET;

      eleToSizeStyle.style.fontSize = `${fs}px`;
      eleToSizeStyle.style.lineHeight = `${fs * LINE_HEIGHT_DEFAULT}px`;
    }

    isOverflow = checkIsOverflow(elementCheckOverflow);
    if (isOverflow && fs === FONT_SIZE_MIN) {
      shouldUpdate = true;
      text = text.slice(0, -1);
      elementContainsText.innerHTML = text;
    }
  }

  return {
    text,
    shouldUpdate,
  };
};

export const downloadPdfByUrl = async (url: string, fileName: string) => {
  const arraybuffer = await axios(url, {
    responseType: "arraybuffer",
  }).then((res) => res.data);
  const newPdfBlob = new Blob([arraybuffer], {
    type: "application/octet-stream",
  });

  saveAs(newPdfBlob, fileName);
};
