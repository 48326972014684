import { Box } from "@chakra-ui/react";

const GrayArea: React.FC<{ position: any }> = ({ position }) => {
  return (
    <>
      <Box
        position={"absolute"}
        top={0}
        left={0}
        width={"100%"}
        height={position.top}
        backgroundColor={"var(--chakra-colors-white)"}
        opacity={0.7}
      ></Box>
      <Box
        position={"absolute"}
        bottom={0}
        left={0}
        width={"100%"}
        top={`calc(${position.top} + ${position.height})`}
        backgroundColor={"var(--chakra-colors-white)"}
        opacity={0.7}
      ></Box>
      <Box
        position={"absolute"}
        bottom={0}
        left={0}
        height={"100%"}
        width={position.left}
        backgroundColor={"var(--chakra-colors-white)"}
        opacity={0.7}
      ></Box>
      <Box
        position={"absolute"}
        bottom={0}
        left={`calc(${position.left} + ${position.width})`}
        right={0}
        height={"100%"}
        backgroundColor={"var(--chakra-colors-white)"}
        opacity={0.7}
      ></Box>
    </>
  );
};

export default GrayArea;
