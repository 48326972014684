import { BoxProps, FormControl } from "@chakra-ui/react";
import { error } from "console";
import {
  AdditionInfo,
  CheckboxAdditionalInfo,
  MapValuesAdditionalInfo,
} from "interfaces/models/inspectionItemResult";
import debounce from "lodash/debounce";
import get from "lodash/get";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useFormBuilder } from "../context/FormBuilderContext/context";
import { ElementType, TypeSchema } from "../context/FormBuilderContext/types";

interface Props<T = any> {
  onChange(value: T): void;
  value: T;
  path: (
    | keyof TypeSchema
    | number
    | keyof TypeSchema["inspectionContent"]
    | keyof ElementType<TypeSchema["itemResults"]>
    | keyof AdditionInfo
    | keyof MapValuesAdditionalInfo
    | keyof CheckboxAdditionalInfo
  )[];
  isInvalid: boolean;
  formControlStyle?: BoxProps["style"];
  useDebounce?: boolean;
}

function withField<ComponentProps>(
  Component: React.ComponentType<ComponentProps>,
  change: (e: any) => any
) {
  return (props: Props & ComponentProps) => {
    const {
      useDebounce = true,
      isInvalid = true,
      path,
      formControlStyle = {},
      ...rest
    } = props;
    const [state, setState] = useState(props.value);

    const { errors } = useFormBuilder();

    const debounceChangeRef = useRef(debounce(props.onChange, 300));
    const changeRef = useRef(props.onChange);

    const hasError = useMemo(
      () => !!get(errors, path.join(".")),
      [errors, path]
    );

    useEffect(() => {
      setState(props.value);
    }, [props.value]);

    const onChange = useCallback(
      (value: any) => {
        setState(value);
        if (useDebounce) {
          debounceChangeRef.current(value);
        } else {
          changeRef.current(value);
        }
      },
      [useDebounce]
    );

    return (
      <FormControl
        isInvalid={hasError && isInvalid}
        style={formControlStyle}
        scrollMarginTop="60px"
      >
        <Component
          {...(rest as ComponentProps)}
          value={state}
          onChange={(e: any) => onChange(change(e))}
        />
      </FormControl>
    );
  };
}

export default withField;
