import { Icon, IconProps } from "@chakra-ui/icons";

const CursorIcon = (props: IconProps) => {
  return (
    <Icon width="1em" height="1em" viewBox="0 0 33 32" fill="none" {...props}>
      <path
        d="M19.3559 17.9889L23.6721 16.6761C23.9388 16.595 24.1385 16.3727 24.1906 16.0988C24.2428 15.825 24.1387 15.5448 23.9205 15.3714L11.0344 5.13111C10.8064 4.94996 10.4942 4.91712 10.2335 5.04687C9.97286 5.17663 9.81086 5.44553 9.81798 5.73662L10.2254 22.3962C10.2323 22.6764 10.3949 22.9295 10.6469 23.0522C10.899 23.1749 11.1984 23.1467 11.4233 22.9793L15.4341 19.9914L18.5708 26.0888C18.7603 26.4572 19.2125 26.6022 19.5808 26.4127L22.1626 25.0846C22.5309 24.8951 22.6759 24.4429 22.4864 24.0746L19.3559 17.9889Z"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

export default CursorIcon;
