import { useCallback, useEffect, useState } from "react";

interface UseInputValue<T = any> {
  value?: T;
  onChange?(v?: T): void;
  isReadOnly?: boolean;
}

export const useInputValue = ({
  value,
  onChange,
  isReadOnly,
}: UseInputValue) => {
  const [state, setState] = useState(value ?? "");

  useEffect(() => {
    setState(value ?? "");
  }, [value]);

  return {
    value: state,
    onChange: (value: any) => {
      if (isReadOnly) return;
      setState(value);
    },
    onBlur: useCallback(() => {
      onChange?.(state);
    }, [onChange, state]),
  };
};
