import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Spinner,
  Text,
  Textarea,
  useBoolean,
} from "@chakra-ui/react";
import { IconBase } from "components/base";
import FileUpload from "components/FileUpload";
import FormUploadFile from "components/FormUploadFile";
import SelectUserSingle from "components/input/SelectUserSingle";
import { SvgIcon } from "components/SvgIcon";
import DayPicker from "components/ui/DayPicker";
import Slider from "components/ui/Slider";
import { SelectUserSingleSx } from "constants/dropdown";
import { TypeOfFile } from "constants/file";
import { TaskLogTypeKey } from "constants/task";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { UserDTO } from "interfaces/dtos/UserDTO";
import { FileModel } from "interfaces/models";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";

import { MapTitleForTask } from "utils/data-logs";
import { getUrlDisplayOfFileUpload } from "utils/image";
import { selectStyles } from "../";
import CameraModal from "../../CameraModal";
import DrawToolModal, { HandleSelectDrawImageProps } from "../../DrawToolModal";
import { SelectedImages } from "../hooks";
import useDownloadOriginImage from "../useDownloadOriginImage";
import useDropdownDirection from "./useDropdownDirection";

interface Props {
  canEditTaskModal: any;
  partnerCompanyOptions: {
    name: string;
    value: string;
  }[];
  taskSelected: TaskDTO | undefined;
  taskModalInfo: TaskDTO | undefined;
  selectedImages: FileModel[];
  isFetchingPartnerCompanies?: boolean;
  mapPartnerCompany: { [id: string]: string };
  listUserById: { [key: string]: UserDTO };
  isFetchingUserAssigned: boolean;
  isDisableUploadImage?: boolean;
  isCurrentTask: boolean;
  onChangeInput: (field: string) => (event: any) => void;
  addChatMessage: (
    content: string,
    type: TaskLogTypeKey,
    files?: any,
    taskId?: string | undefined,
    statusChange?: string | undefined
  ) => Promise<void>;
  handleChangePartnerCompanies: (id: string | string[]) => void;
  onDeleteImage: (
    index: number,
    addCommentFunc: () => void,
    field?: keyof SelectedImages | undefined
  ) => Promise<void>;
  handleSaveDrawImage: (
    field: keyof SelectedImages
  ) => (
    newListImage: FileModel[],
    requestId?: string | undefined
  ) => Promise<void>;
  handleSelectedDay: (field: keyof TaskDTO) => (day: string | Date) => void;
  setIsDisableUploadImage: (
    loading: boolean,
    task?: TaskDTO | undefined
  ) => void;
  onChangeUserSingle: (params: { field: keyof TaskDTO; user: any }) => void;
  updateDataOnBlurInput: (field: keyof TaskDTO) => void;
  onChangeFile: (field?: keyof SelectedImages) => any;
  handleSaveCaptureCamera: (
    field: keyof SelectedImages
  ) => (params: HandleSelectDrawImageProps) => Promise<void>;
}

export interface ContentBeforeFixedRefType {
  onScrollModal: (e: any) => void;
}

const ContentBeforeFixed = forwardRef((props: Props, ref) => {
  const {
    canEditTaskModal,
    partnerCompanyOptions,
    taskModalInfo,
    selectedImages,
    isFetchingPartnerCompanies,
    mapPartnerCompany,
    listUserById,
    isFetchingUserAssigned,
    isDisableUploadImage,
    isCurrentTask,
    taskSelected,
    handleSelectedDay,
    handleChangePartnerCompanies,
    onChangeInput,
    onDeleteImage,
    addChatMessage,
    handleSaveDrawImage,
    setIsDisableUploadImage,
    onChangeUserSingle,
    updateDataOnBlurInput,
    onChangeFile,
    handleSaveCaptureCamera,
  } = props;
  const [originImageLinks, setOriginImageLinks] = useState<string[]>([]);
  const [isOpenCameraModal, setOpenCameraModal] = useBoolean();
  const [isOpenDraw, setOpenDraw] = useBoolean();
  const imgDrawFile = useRef<File>();

  const {
    partnerCompanyDirection,
    assignUserDirection,
    endDateScheduledDirection,
    taskDateDirection,
    formRef,
    calculateScrollTop,
    checkDropdownDirection,
  } = useDropdownDirection();

  useImperativeHandle(ref, () => ({
    onScrollModal: (event: any) => {
      calculateScrollTop(event);
    },
  }));

  const isExistImages = useMemo(() => selectedImages?.length, [selectedImages]);
  // self custom
  const { isDownloadOriginImage, handleDownloadOriginImage } =
    useDownloadOriginImage();

  useEffect(() => {
    (async () => {
      const originImageLinks = await getUrlDisplayOfFileUpload(
        taskSelected?.images || []
      );
      setOriginImageLinks(originImageLinks);
    })();
  }, [taskSelected?.images]);

  const onClickBtnSelectFile = (event: React.MouseEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    target.value = "";
  };

  const renderUploadFormImage = useCallback(
    (activeThumb: number) => {
      if (!canEditTaskModal?.canEditImagesField) {
        return <></>;
      }

      return (
        <FormUploadFile
          onClick={onClickBtnSelectFile}
          onChange={onChangeFile("images")}
          type={TypeOfFile.IMAGE}
          style={{
            marginLeft: isExistImages ? "0" : "inherit",
          }}
        >
          <Flex
            alignItems="center"
            flexWrap="wrap"
            justifyContent="flex-end"
            gap="1rem"
          >
            <FileUpload width="auto" multiple accept="image/*">
              <Button isDisabled={isDisableUploadImage && isCurrentTask}>
                選択
              </Button>
            </FileUpload>
            <Button
              variant="filled"
              onClick={setOpenCameraModal.on}
              isDisabled={isDisableUploadImage && isCurrentTask}
            >
              撮影
            </Button>

            {originImageLinks?.[activeThumb] && (
              <Button
                isLoading={isDownloadOriginImage}
                leftIcon={
                  <IconBase
                    w="2rem"
                    h="2rem"
                    icon="/img/icon-download.svg"
                    color="font.blue"
                  />
                }
                onClick={() =>
                  handleDownloadOriginImage(originImageLinks?.[activeThumb])
                }
              >
                元の写真をダウンロード
              </Button>
            )}

            {isOpenCameraModal && (
              <CameraModal
                isOpen={isOpenCameraModal}
                onClose={setOpenCameraModal.off}
                onCapture={(file) => {
                  imgDrawFile.current = file;
                  setOpenDraw.on();
                }}
              />
            )}

            {isOpenDraw && (
              <DrawToolModal
                isOpen={isOpenDraw}
                onSelect={handleSaveCaptureCamera("images")}
                onClose={setOpenDraw.off}
                fileRef={imgDrawFile}
                reCapture={() => {
                  setOpenDraw.off();
                  setOpenCameraModal.on();
                }}
              />
            )}
          </Flex>
        </FormUploadFile>
      );
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isOpenCameraModal,
      isExistImages,
      isDisableUploadImage,
      isCurrentTask,
      isOpenDraw,
      imgDrawFile,
      canEditTaskModal?.canEditImagesField,
      onClickBtnSelectFile,
      onChangeFile,
      addChatMessage,
      handleSaveCaptureCamera,
    ]
  );

  return (
    <>
      <Text
        px="16px"
        color="#171717"
        fontWeight="700"
        mb="1rem"
        fontSize="1.8rem"
        alignSelf="start"
      >
        指摘内容
      </Text>

      <Flex
        w="100%"
        color="#171717"
        bg="#E5E5E5"
        fontSize="1.4rem"
        alignItems={isExistImages ? "center" : "unset"}
        mb="0.4rem"
        p="1rem 16px"
        flexDir={isExistImages ? "column" : "unset"}
      >
        <Text
          flexBasis={isExistImages ? "unset" : "6rem"}
          w="6rem"
          mr="3rem"
          mb="0.5rem"
          alignSelf="start"
          whiteSpace="nowrap"
        >
          {MapTitleForTask.images?.title}
        </Text>
        <Slider
          className="slider-image"
          originImageLinks={originImageLinks}
          keyImageName={"images" as keyof TaskDTO}
          currentTask={taskModalInfo}
          onDeleteImage={(idx: number) =>
            onDeleteImage(
              idx,
              () => addChatMessage("", TaskLogTypeKey.DELETE_POINTED_OUT_PHOTO),
              "images"
            )
          }
          listImageSelected={selectedImages || []}
          setIsDisableUploadImage={setIsDisableUploadImage}
          onSaveDrawImage={handleSaveDrawImage("images")}
          readonly={!canEditTaskModal?.canEditImagesField}
          uploadForm={renderUploadFormImage}
        />
      </Flex>

      <Flex
        w="100%"
        color="#171717"
        fontSize="1.4rem"
        mb="1rem"
        my="16px"
        px="16px"
        flexDir="column"
      >
        <Text w="6rem" mr="1rem" mb="0.5rem">
          {MapTitleForTask.memo?.title}
        </Text>
        <Textarea
          fontSize="1.4rem"
          minH="9rem"
          maxH="12rem"
          value={taskModalInfo?.memo || ""}
          onChange={onChangeInput("memo")}
          onBlur={() => {
            updateDataOnBlurInput("memo");
          }}
          borderRadius={4}
          w="100%"
          border="1px solid var(--primary-border-color) !important"
          placeholder={`指摘の説明内容です。`}
          readOnly={!canEditTaskModal?.canEditMemoField}
          cursor={!canEditTaskModal?.canEditMemoField ? "not-allowed" : "unset"}
          bg={!canEditTaskModal?.canEditMemoField ? "#f1f1f1" : "#FAFAFA"}
        />
      </Flex>

      <Flex
        w="100%"
        h="4.4rem"
        color="#171717"
        fontSize="1.4rem"
        alignItems="center"
        mb="0.4rem"
        my="16px"
        pl="16px"
        pr="16px"
        ref={(el) => {
          Object.assign(formRef?.current, { assignUserEl: el });
        }}
      >
        <Text flexBasis="8.5rem">{MapTitleForTask.userAssigned?.title}</Text>
        <Flex w="calc(100% - 8.5rem)" alignItems="center" flex="1">
          <SelectUserSingle
            users={listUserById}
            isLoading={isFetchingUserAssigned}
            value={taskModalInfo?.userAssigned}
            menuPlacement={assignUserDirection}
            components={{
              DropdownIndicator: () => (
                <SvgIcon
                  src="/img/icon-react-select-custom-dropdown.svg"
                  mx=".8rem"
                />
              ),
            }}
            styles={selectStyles}
            onChange={(user) =>
              onChangeUserSingle({
                field: "userAssigned",
                user,
              })
            }
            sx={{
              ...SelectUserSingleSx,
              "div[class*='option']": {
                borderBottom: "1px solid var(--primary-border-color)",
                p: "0.5rem 1.5rem",
              },
              "div[class*='option']:last-child": {
                borderBottom: "none",
              },
              ".containerSelectInput > div": {
                background: !canEditTaskModal?.canEditAssignedUserField
                  ? "#f1f1f1"
                  : "#FAFAFA",
                borderColor: "var(--primary-border-color)",
              },
            }}
            isDisabled={!canEditTaskModal?.canEditAssignedUserField}
          />
        </Flex>
      </Flex>
      <Flex
        w="100%"
        color="#171717"
        fontSize="1.4rem"
        alignItems="center"
        mb="0.4rem"
        my="16px"
        pl="16px"
        pr="16px"
        ref={(el) => {
          Object.assign(formRef.current, { taskDate: el });
        }}
      >
        <Text flexBasis="8.5rem">指摘日</Text>
        <Box flex="1">
          <DayPicker
            name="taskDate"
            value={taskModalInfo?.taskDate as Date}
            onSelectedDay={handleSelectedDay("taskDate")}
            shouldCloseOnSelect
            popperPlacement={`${taskDateDirection}-start`}
            canDeleteDate={false}
            onCalendarOpen={checkDropdownDirection}
            popperModifiers={[
              {
                name: "flip",
                enabled: false,
              },
            ]}
            readonly={!canEditTaskModal?.canEditTaskDateField}
          />
        </Box>
      </Flex>

      <Flex
        w="100%"
        color="#171717"
        fontSize="1.4rem"
        alignItems="center"
        mb="0.4rem"
        my="16px"
        pl="16px"
        pr="16px"
        ref={(el) => {
          Object.assign(formRef.current, { endDateScheduledEl: el });
        }}
      >
        <Text flexBasis="8.5rem">{MapTitleForTask.deadline?.title}</Text>
        <Box flex="1">
          <DayPicker
            name="deadline"
            value={taskModalInfo?.deadline}
            onSelectedDay={handleSelectedDay("deadline")}
            shouldCloseOnSelect
            popperPlacement={`${endDateScheduledDirection}-start`}
            onCalendarOpen={checkDropdownDirection}
            popperModifiers={[
              {
                name: "flip",
                enabled: false,
              },
            ]}
            readonly={!canEditTaskModal?.canEditTaskDateField}
          />
        </Box>
      </Flex>

      <Flex
        w="100%"
        color="#171717"
        fontSize="1.4rem"
        px="16px"
        alignItems="center"
        borderBottom="1px solid #cfcfcf"
        pb="2rem"
        ref={(el) => {
          Object.assign(formRef.current, { partnerCompanyEl: el });
        }}
      >
        <Text flexBasis="8.5rem">協力会社</Text>

        <Flex flex="1">
          <Menu
            matchWidth
            closeOnSelect
            placement={partnerCompanyDirection}
            onOpen={checkDropdownDirection}
            flip={false}
          >
            {({ isOpen }) => (
              <>
                <MenuButton
                  w="100%"
                  sx={{
                    span: {
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      paddingLeft: "1rem",
                      paddingRight: ".5rem",
                      borderRadius: "4px",
                      border: "1px solid #ccc",
                    },
                  }}
                  cursor={
                    !canEditTaskModal?.canEditPartnerCompaniesField
                      ? "not-allowed"
                      : "unset"
                  }
                  bg={
                    !canEditTaskModal?.canEditPartnerCompaniesField
                      ? "#f1f1f1"
                      : "#fafafa"
                  }
                >
                  <Flex alignItems="center">
                    <Text display="flex" h="4rem" alignItems="center">
                      {
                        mapPartnerCompany?.[
                          taskModalInfo?.partnerCompanyId as keyof typeof mapPartnerCompany
                        ]
                      }
                    </Text>
                  </Flex>
                  {isFetchingPartnerCompanies ? (
                    <Spinner />
                  ) : (
                    <SvgIcon src="/img/icon-navigation-arrow_drop_down.svg" />
                  )}
                </MenuButton>

                {canEditTaskModal?.canEditPartnerCompaniesField && (
                  <MenuList
                    zIndex={11}
                    display={isOpen ? "block" : "none"}
                    maxH="160px"
                    overflowY="auto"
                  >
                    <MenuOptionGroup
                      value={taskModalInfo?.partnerCompanyId || ""}
                      title=""
                      type="radio"
                      onChange={handleChangePartnerCompanies}
                    >
                      {partnerCompanyOptions?.map((item) => (
                        <MenuItemOption
                          key={item?.value}
                          value={item?.value}
                          _hover={{ bg: "#F0F9FF" }}
                          p=".5rem 1.5rem"
                          borderBottom="1px solid var(--primary-border-color)"
                          _last={{ borderBottom: "none" }}
                          icon={
                            <SvgIcon src="/img/icon-action-check_circle.svg" />
                          }
                        >
                          <Text
                            px="1rem"
                            borderRadius="4px"
                            display="flex"
                            alignItems="center"
                          >
                            {item?.name}
                          </Text>
                        </MenuItemOption>
                      ))}
                    </MenuOptionGroup>
                  </MenuList>
                )}
              </>
            )}
          </Menu>
        </Flex>
      </Flex>
    </>
  );
});

export default ContentBeforeFixed;
