import { ApiResponse } from "interfaces/models/api";
import {
  DocumentKeyNote,
  KeynoteImageData,
} from "interfaces/models/documentKeynote";
import { axiosECS } from "services/baseAxios";
import { validateBodyReq } from "utils/common";
import { presignedAndDownloadFileS3 } from "utils/file";

interface GetDocumentKeyNotesReq {
  documentCategoryId?: string;
  shouldCache?: boolean;
}

const path = "/v1/document-keynotes";

const getKeynoteList = async ({
  documentCategoryId,
  shouldCache,
}: GetDocumentKeyNotesReq): Promise<ApiResponse<DocumentKeyNote[]>> => {
  return axiosECS.get(path, {
    params: {
      documentCategoryId,
      shouldCache,
    },
  });
};

export const createKeynote = async (
  keynote: Partial<DocumentKeyNote>
): Promise<ApiResponse<DocumentKeyNote>> => {
  return axiosECS.post(path, validateBodyReq(keynote));
};

export const updateKeynote = async (
  keynote: DocumentKeyNote
): Promise<ApiResponse<DocumentKeyNote>> => {
  return axiosECS.patch(path, validateBodyReq(keynote));
};

export const handleGetKeynoteList = async (
  documentCategoryId: string,
  shouldCache?: boolean
) => {
  const { data } = await getKeynoteList({ documentCategoryId, shouldCache });
  const keynote = data[0];
  const keynoteImageData: KeynoteImageData =
    keynote?.keynoteImageData || ({} as any);

  const guid = keynoteImageData?.guid;
  const imageUrl = keynoteImageData?.mapLabelsByGuid?.[guid]?.imageUrl;
  const imageUrls = Object.values(keynoteImageData?.mapLabelsByGuid || {})
    .map((item) => item.imageUrl)
    .filter((i) => !!i);
  if (shouldCache && imageUrl) {
    await Promise.all(
      imageUrls.map((imageUrl) =>
        presignedAndDownloadFileS3(imageUrl, shouldCache)
      )
    );
  }

  return keynote;
};
