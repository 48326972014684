import { Box, BoxProps, Flex } from "@chakra-ui/react";
import { CONTAINER_LAYOUT_ID } from "constants/styleProps";
import React, { memo, ReactNode } from "react";
import Header from "./Header";

interface Props {
  style?: BoxProps;
  children?: React.ReactNode;
  bg?: string;
  isShowHeader?: boolean;
  headerProps?: {
    isShowSyncDataForOfflineIcon?: boolean;
    isShowClearDatabaseCached?: boolean;
    isShowButtonOldVersion?: boolean;
    isShowSetting?: boolean;
    leftMoreContent?: ReactNode;
  };
}

const Layout = ({
  children,
  isShowHeader = true,
  headerProps,
  bg,
  style,
}: Props) => {
  return (
    <Box
      id={CONTAINER_LAYOUT_ID}
      w="100%"
      h="100%"
      top={0}
      position={{ base: "absolute", md: "fixed" }}
      {...style}
    >
      <Flex height="100%" flexDirection="column">
        {isShowHeader && <Header headerProps={headerProps} bg={bg} />}
        {children}
      </Flex>
    </Box>
  );
};

export default memo(Layout);
