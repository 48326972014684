import { Box, Flex, Image } from "@chakra-ui/react";
import { withPresignedUrl } from "components/HOC/presignedUrl";
import { useState } from "react";

export interface ItemData {
  id: any;
  src: string;
  title: any;
}

const Presigned = withPresignedUrl(Image);

interface ItemProps {
  aspectRatio?: number | string;
  onClick(e: React.MouseEvent<HTMLDivElement>): void;
  item: ItemData;
  isActive?: boolean;
}

const Item: React.FC<ItemProps> = ({
  onClick,
  aspectRatio,
  item,
  isActive,
}) => {
  const [isLoadedImage, setIsLoadedImage] = useState(false);

  const onLoadImage = (e: any) => {
    setIsLoadedImage(true);
  };

  return (
    <Box
      onClick={onClick}
      borderWidth={"5px"}
      borderStyle={"solid"}
      borderColor={isActive ? "var(--blue-100)" : "transparent"}
      p="1rem"
      cursor={"pointer"}
      transition={"all .3s ease-in-out"}
      h="100%"
    >
      <Flex>
        <Presigned
          bg="var(--table-alternate-background)"
          objectFit={"contain"}
          objectPosition={"center"}
          aspectRatio={aspectRatio}
          w="100%"
          h="auto"
          src={item.src}
          onLoad={onLoadImage}
        />
      </Flex>
      <Flex pt="1rem" gap="1rem">
        <Box
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          flexGrow={1}
          fontSize={"1.25rem"}
        >
          {item.title}
        </Box>
      </Flex>
    </Box>
  );
};

export default Item;
