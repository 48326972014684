import { ApiResponse, Pagination } from "interfaces/models/api";
import {
  CountTaskTypesResponse,
  TASK_TYPE_INITIAL,
  TaskType,
} from "interfaces/models/taskType";
import { axiosECS } from "services/baseAxios";
import { getNetworkStatus, validateBodyReq } from "utils/common";
import { presignedAndDownloadFileS3 } from "utils/file";
import { countTableItems } from "./commonApi";

const path = "/v1/task-types";
const DEFAULT_LIMIT = 500;

interface GetTaskTypesReq extends Pagination {
  paging?: "cursor" | "offset" | undefined;
  title?: string;
  deleted?: boolean;
  exact?: boolean;
  shouldCache?: boolean;
}

export const getTaskTypesList = async (
  params: GetTaskTypesReq
): Promise<ApiResponse<TaskType[]>> => {
  const { limit } = params;

  return axiosECS.get(path, {
    params: { ...params, limit: limit || DEFAULT_LIMIT },
  });
};

export const countTaskTypeList = async (
  params: GetTaskTypesReq
): Promise<ApiResponse<CountTaskTypesResponse>> => {
  const { limit } = params;

  return axiosECS.get(`${path}/count-items`, {
    params: { ...params, limit: limit || DEFAULT_LIMIT },
  });
};

export const getTaskType = async (
  id: string
): Promise<ApiResponse<TaskType>> => {
  const result = await axiosECS.get(`${path}/${id}`, {
    params: {
      // not use cache's data in service worker
      useCache: false,
    },
  });

  // case service worker return response type array
  if (Array.isArray(result.data)) {
    result.data = result.data?.[0];
  }

  return result;
};

export const createTaskType = async (
  taskType: Partial<TaskType>
): Promise<ApiResponse<TaskType>> => {
  return axiosECS.post(path, validateBodyReq(taskType));
};

export const updateTaskType = async (
  taskType: TaskType
): Promise<ApiResponse<TaskType>> => {
  const updatedAt = taskType.updatedAt || new Date();
  const nullFields = [
    "goodImageUrl",
    "badImageUrl",
    "goodDescription",
    "badDescription",
  ];
  const bodyClone = structuredClone(taskType) as { [key: string]: any };
  for (const key in bodyClone) {
    if (bodyClone[key] === "" && nullFields.includes(key)) {
      bodyClone[key] = bodyClone[key] || null;
    }
  }

  return axiosECS.patch(path, { ...bodyClone, updatedAt });
};

export const deleteTaskTypeList = async (
  ids: string[]
): Promise<ApiResponse<string[]>> => {
  return await axiosECS.delete(path, { data: { ids } });
};

export const getTaskTypeByTitle = async (title: string) => {
  return await getTaskTypesList({ title, exact: true });
};

export const handleGetTaskTypes = async (params: GetTaskTypesReq) => {
  params.paging = params.paging || "offset";
  const taskTypes: TaskType[] =
    params.paging === "cursor"
      ? await getTaskTypeWithCursor(params)
      : await getTaskTypeWithOffset(params);

  if (params.shouldCache) {
    await Promise.all(
      taskTypes.map(async (type) => {
        if (type.badImageUrl) {
          await presignedAndDownloadFileS3(
            type.badImageUrl,
            params.shouldCache
          );
        }
        if (type.goodImageUrl) {
          await presignedAndDownloadFileS3(
            type.goodImageUrl,
            params.shouldCache
          );
        }
      })
    );
  }

  return taskTypes;
};

export const getTaskTypeWithCursor = async (params: GetTaskTypesReq) => {
  const taskTypes: TaskType[] = [];
  let cursor: string | undefined;
  do {
    const { data, pagination } = await getTaskTypesList({
      deleted: params.deleted,
      cursor,
      paging: "cursor",
      shouldCache: params.shouldCache,
    });
    cursor = pagination?.cursor;
    if (data.length) {
      taskTypes.push.apply(taskTypes, data);
    }
  } while (cursor);

  return taskTypes;
};

const getTaskTypeWithOffset = async (params: GetTaskTypesReq) => {
  const taskTypes: TaskType[] = [];
  const {
    data: { totalItem, totalPage },
  } = await countTableItems<GetTaskTypesReq>({
    path,
    params: {
      limit: params.limit ? +params.limit : DEFAULT_LIMIT,
      deleted: params.deleted,
      shouldCache: params.shouldCache,
    },
  });

  const isOnline = getNetworkStatus();

  const promise = async (page: number) => {
    const { data } = await getTaskTypesList({
      ...params,
      page,
      paging: "offset",
      total: totalPage,
    });
    if (data.length) taskTypes.push.apply(taskTypes, data);
  };

  if (totalItem && totalPage) {
    if (!isOnline) {
      const pages = Array.from({ length: totalPage }, (_, i) => i + 1);
      for await (const page of pages) {
        await promise(page);
      }
    } else {
      await Promise.all(
        Array.from({ length: totalPage }, (_, i) => i + 1).map(async (page) =>
          promise(page)
        )
      );
    }
  }

  return taskTypes;
};

export const upsertTaskType = async (taskType: TaskType) => {
  const { id } = taskType;

  if (id && id !== TASK_TYPE_INITIAL.id) {
    return await updateTaskType(taskType);
  }

  return await createTaskType(taskType);
};

export const createListTaskType = async (taskTypes: TaskType[]) => {
  const {
    data,
  }: {
    data: {
      success: number;
      errorTitles: string[];
    };
  } = await axiosECS.post(
    `${path}/list`,
    validateBodyReq({ items: taskTypes })
  );

  return data;
};
