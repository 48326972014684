import { useCallback } from "react";

const DEFAULT_PADDING_PERCENT = 5; // unit percent
const MIN_RATIO = 0.25;
const DEFAULT_RATIO = 1;

interface UseAutoSizePDFDocs {
  pageWidth: number;
  pageOffsetWidth: number;
}

const useAutoSizePDFDocs = ({
  pageWidth,
  pageOffsetWidth,
}: UseAutoSizePDFDocs) => {
  const getRatio = useCallback(
    (element: HTMLElement) => {
      const { offsetWidth } = element;
      const fullWidth = pageWidth + pageOffsetWidth;
      const widthPercent = +Number(offsetWidth / fullWidth) * 100;
      const ratio = Math.floor(widthPercent - DEFAULT_PADDING_PERCENT) / 100;

      return Math.min(Math.max(MIN_RATIO, ratio), DEFAULT_RATIO);
    },
    [pageOffsetWidth, pageWidth]
  );

  return getRatio;
};

export default useAutoSizePDFDocs;
