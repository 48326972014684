import { createContext, useContext, useImperativeHandle } from "react";

import React from "react";
import useInspectionLayout from "../hooks/useInspectionLayout";
import { Flex, Spinner } from "@chakra-ui/react";

export interface IInspectionLayoutHandlerContext {}

export type InspectionFormatHandlerContextRef = {};

export const InspectionLayoutHandlerContext =
  createContext<IInspectionLayoutHandlerContext>({} as any);

function useInspectionLayoutHandlerContext() {
  return {};
}

export const InspectionFormatHandler = React.forwardRef<
  InspectionFormatHandlerContextRef,
  { children: any }
>(({ children }: any, ref) => {
  const handlers = useInspectionLayoutHandlerContext();
  const { isLoading } = useInspectionLayout();

  useImperativeHandle(
    ref,
    () => {
      return {};
    },
    []
  );

  return (
    <InspectionLayoutHandlerContext.Provider value={handlers}>
      {isLoading ? (
        <Flex mt="2rem" justifyContent="center">
          <Spinner />
        </Flex>
      ) : (
        children
      )}
    </InspectionLayoutHandlerContext.Provider>
  );
});

export const useInspectionLayoutHandler = () => {
  return useContext(InspectionLayoutHandlerContext);
};
