import { UnzipFileInfo, unzipSync } from "fflate";
import { z } from "zod";

const AdskIDType = z.object({
  id: z.string(),
  sourceSystem: z.string(),
  type: z.string(),
  version: z.string(),
});

const ManifestAssetType = z.object({
  URI: z.string(),
  id: z.string(),
  size: z.number(),
  type: z.string(),
  usize: z.number(),
});

export const SVFManifestJsonSchema = z.object({
  adskID: AdskIDType,
  assets: z.array(ManifestAssetType),
  manifestversion: z.number(),
  name: z.string(),
  toolkitversion: z.string(),
});

export type SVFManifestJsonType = z.infer<typeof SVFManifestJsonSchema>;

// svf の本体は zip ファイルなので、中のフォルダ構造を解析するための manifest.json を取り出す
export const parseSVF = (data: ArrayBuffer): SVFManifestJsonType => {
  const uint8Data = new Uint8Array(data);
  const filter = (fileInfo: UnzipFileInfo) => {
    return fileInfo.name === "manifest.json";
  };
  const unzipped = unzipSync(uint8Data, { filter });
  for (const [, data] of Object.entries(unzipped)) {
    const obj = JSON.parse(new TextDecoder().decode(data));
    const result = SVFManifestJsonSchema.safeParse(obj);
    if (result.success) {
      return result.data;
    }
  }
  throw new Error("cannot find appropriate manifest.json");
};
