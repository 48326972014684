import { isEqual } from "lodash";
import cloneDeep from "lodash/cloneDeep";

export const chunkArray = (arr: any[], chunkSize: number) => {
  const cloneArr = cloneDeep(arr);
  const res = [];
  while (cloneArr.length > 0) {
    const chunk = cloneArr.splice(0, chunkSize);
    res.push(chunk);
  }

  return res;
};

export const mergeArray = (curr: any[]) => (newArr: any[]) => {
  newArr.forEach((b, i) => {
    curr[i] = { ...curr[i], ...b };
  });

  return [...curr];
};

export const paginate = <T = any>(
  array: T[] | undefined,
  pageSize: number,
  currentPage: number
) => {
  return array?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  ) as T[];
};

export const sortArrayByField = <T>(
  arr: any[],
  field: T extends never ? string : keyof T,
  isAsc: boolean = true
) => {
  return arr?.slice()?.sort((a, b) => {
    const aField = a[field as any]! || "";
    const bField = b[field as any]! || "";

    if (aField > bField) {
      return isAsc ? 1 : -1;
    }
    if (aField < bField || !aField) {
      return isAsc ? -1 : 1;
    }

    return 0;
  });
};

export const isSubArray = (parent: any[], sub: any[]) => {
  for (let i = 0; i < sub.length; i++) {
    if (!parent.includes(sub[i])) {
      return false;
    }
  }

  return true;
};

export const sortDocumentTemplateTree = (arr: any[]) => {
  return arr?.slice()?.sort((a, b) => {
    const aTitle = a?.parentName?.trim() || "";
    const bTitle = b?.parentName?.trim() || "";
    const aUpdateDate = new Date(a?.updatedAt || "");
    const bUpdateDate = new Date(b?.updatedAt || "");
    const aCreateDate = new Date(a?.createdAt || "");
    const bCreateDate = new Date(b?.createdAt || "");

    if (aTitle !== bTitle) {
      return aTitle.localeCompare(bTitle);
    } else if (aUpdateDate.getTime() !== bUpdateDate.getTime()) {
      return aUpdateDate.getTime() - bUpdateDate.getTime();
    } else {
      return aCreateDate.getTime() - bCreateDate.getTime();
    }
  });
};

export const sortDocumentList = (arr: any[], isSortByLevel = false) => {
  return arr?.slice()?.sort((a, b) => {
    const aTitle = a?.title?.trim() || a?.templateName?.trim() || "";
    const bTitle = b?.title?.trim() || b?.templateName?.trim() || "";
    const aUpdateDate = new Date(a?.updatedAt || "");
    const bUpdateDate = new Date(b?.updatedAt || "");
    const aCreateDate = new Date(a?.createdAt || "");
    const bCreateDate = new Date(b?.createdAt || "");
    const aLevel = a?.level?.trim() || "";
    const bLevel = b?.level?.trim() || "";

    if (aTitle !== bTitle) {
      return aTitle.localeCompare(bTitle);
    } else if (aLevel !== bLevel && isSortByLevel) {
      return aLevel.localeCompare(bLevel);
    } else if (aUpdateDate.getTime() !== bUpdateDate.getTime()) {
      return aUpdateDate.getTime() - bUpdateDate.getTime();
    } else {
      return aCreateDate.getTime() - bCreateDate.getTime();
    }
  });
};

export const compareArray = (
  firstArray: Array<any>,
  secondArray: Array<any>
) => {
  if (!firstArray || !secondArray || firstArray.length !== secondArray.length) {
    return false;
  }

  return firstArray.every((itemInFirstArray) => {
    return secondArray.some((itemInSecondArray) =>
      isEqual(itemInSecondArray, itemInFirstArray)
    );
  });
};

/**
 * This function check if the first array contains the second array
 * @param firstArray - An array of any type.
 * @param secondArray - An array of any type.
 */
export const containsArray = (
  firstArray: Array<any>,
  secondArray: Array<any>
) => {
  if (!firstArray || !secondArray) {
    return false;
  }

  return secondArray.every((itemInSecondArray) => {
    return firstArray?.some((itemInFirstArray) =>
      isEqual(itemInSecondArray, itemInFirstArray)
    );
  });
};

/**
 * This function sorts an array of objects based on multiple sorting criteria and orders.
 * @param list - An array of elements to be sorted.
 * @param callbacks - An optional array of callback functions that define the sorting logic for each
 * column.
 */
export const multiSort = <T>(
  list: Array<T>,
  callbacks?: Array<(a: T, b: T) => number>
): T[] => {
  const sortedList = cloneDeep(list);

  if (!callbacks) {
    return sortedList;
  }

  callbacks?.forEach((callback) => {
    sortedList?.sort(callback);
  });

  return sortedList;
};

/**
 * This function takes an array as input and returns a new array with only unique
 * elements.
 * @param array - The `array` parameter is an array of any type `T`.
 */
export const uniqueArray = <T = any>(array: Array<T>) => {
  return array.reduce((acc: Array<T>, item: T) => {
    const isAccumulatorContainsItem = acc?.some((accItem) =>
      isEqual(accItem, item)
    );

    if (!isAccumulatorContainsItem) {
      acc?.push(item);
    }

    return acc;
  }, []);
};

export const ignoreArrayOrderCompare = (a: any[], b: any[]) => {
  if (a.length !== b.length) return false;
  const elements = new Set([...a, ...b]);
  for (const x of elements) {
    const count1 = a.filter((e) => e === x).length;
    const count2 = b.filter((e) => e === x).length;
    if (count1 !== count2) return false;
  }

  return true;
};

export const sortArrayByTitle = (arr: any[], title: string) => {
  if (!arr?.length) return [];

  const arrNoTitle = arr.filter((item) => !item?.[title]);
  const arrHasTitle = arr
    .filter((item) => item?.[title])
    .slice()
    .filter((item) => !!item)
    .sort((item1, item2) => {
      const title1 = String(item1?.[title]!)?.split("-");
      const title2 = String(item2?.[title]!)?.split("-");

      const length = Math.min(title1.length, title2.length);

      for (let i = 0; i < length; i++) {
        if (title1[i] === title2[i]) continue;
        if (!isNaN(Number(title1[i])) && !isNaN(Number(title2[i]))) {
          return Number(title1[i]) - Number(title2[i]);
        }

        return title1[i] < title2[i] ? -1 : 1;
      }

      return title1.length - title2.length;
    });

  return [...arrNoTitle, ...arrHasTitle];
};

export const updateElementInArray = <T>({
  array,
  element,
  keyIndex,
}: {
  array: T[];
  element: T | (() => T);
  keyIndex: keyof T | ((item: T, dataUpdate: T) => boolean);
}) => {
  const dataUpdate = element instanceof Function ? element() : element;
  if (!dataUpdate || !array?.length) {
    return array;
  }

  const index = array.findIndex((item) =>
    keyIndex instanceof Function
      ? keyIndex(item, dataUpdate)
      : item[keyIndex] === dataUpdate[keyIndex]
  );

  if (index === -1) {
    return array;
  }

  array[index] = { ...array[index], ...dataUpdate };

  return array;
};

export const toggleElementInArray = (
  array: string[] | number[],
  val: string | number,
  toggleVal: boolean
) => {
  const index = array.findIndex((id) => id === val);
  if (toggleVal && index < 0) {
    //@ts-ignore
    array.push(val);
  } else if (!toggleVal && index >= 0) {
    array.splice(index, 1);
  }
};

export const groupByKey = <T>(
  list: T[],
  key: keyof T,
  defaultGroup?: string
): { [key: string]: T[] } =>
  list.reduce((hash: { [key: string]: T[] }, obj) => {
    const k = obj[key] as any;

    return {
      ...hash,
      [k || defaultGroup]: (hash?.[k || defaultGroup] || []).concat(obj),
    };
  }, {});
