import { ApiResponse } from "interfaces/models/api";
import { PageComponent } from "interfaces/models/pageComponent";
import { axiosECS } from "services/baseAxios";

interface GetPageComponentReq {
  pageLayoutId: string;
}

const pathV2 = "/v1/documents/page-components";

export const getPageComponentList = async (
  params: GetPageComponentReq
): Promise<ApiResponse<PageComponent[]>> => {
  return axiosECS.get(pathV2, {
    params,
  });
};

export const createPageComponentList = async (body: {
  pageLayoutId: string;
  components: PageComponent[];
}): Promise<ApiResponse> => {
  return axiosECS.post(pathV2, body);
};
