import { TypeOfFile } from "constants/file";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { TaskLogDTO } from "interfaces/models/task";
import { getTaskContentLog } from "models/commentLog";
import { useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { setIsProcessingTaskModal, setTask } from "redux/taskSlice";
import { SaveTaskModalData } from "./hooks";

interface Props {
  taskModalInfo: TaskDTO | undefined;
  handleUpdateFieldsChangeData: (keys: (keyof TaskDTO)[]) => void;
  saveTaskModalData: (params: SaveTaskModalData) => Promise<any>;
}

const useRevertTask = (props: Props) => {
  const { taskModalInfo, handleUpdateFieldsChangeData, saveTaskModalData } =
    props;

  const dispatch = useDispatch();
  const taskModalInfoRef = useRef(taskModalInfo);
  taskModalInfoRef.current = taskModalInfo;

  const handleRevertTaskByLog = useCallback(
    (log: TaskLogDTO) => {
      if (!log.field || !taskModalInfoRef.current?.id) {
        return;
      }
      const field = log.field as keyof TaskDTO;
      const logData = log?.value?.data;
      const newTaskModalData = {
        ...taskModalInfoRef.current,
      } as TaskDTO;

      newTaskModalData[field] = logData as never;
      // field position goes with externalId so when reverting, revert both fields
      if (field === "position" && "externalId" in logData) {
        const { externalId, ...position } = logData;

        newTaskModalData.externalId = logData?.externalId;
        newTaskModalData.position = position;
        handleUpdateFieldsChangeData(["externalId"]);
      }

      const isFieldImage = ["images", "confirmedImages"].includes(
        log.field || ""
      );
      const isFieldAttach = log.field === "attaches";
      const taskLogItem = getTaskContentLog({
        taskId: taskModalInfoRef.current.id,
        type: log.type,
        field,
        isRevert: true,
        value: logData,
      });

      const params: SaveTaskModalData = {
        newTaskModalData,
        isInsertMediaLog: false,
        taskLogs: [taskLogItem],
        isRevertDataByLog: true,
      };
      if (isFieldImage || isFieldAttach) {
        params.fileData = {
          [isFieldImage ? TypeOfFile.IMAGE : TypeOfFile.ATTACH]: logData,
        };
        if (isFieldImage) {
          params.field = log.field as any;
        }
      }
      dispatch(setIsProcessingTaskModal(true));
      dispatch(setTask(taskModalInfoRef.current));
      handleUpdateFieldsChangeData([
        log.field as keyof typeof taskModalInfoRef.current,
      ]);

      saveTaskModalData(params);
    },
    [handleUpdateFieldsChangeData, saveTaskModalData, dispatch]
  );

  return { handleRevertTaskByLog };
};

export default useRevertTask;
