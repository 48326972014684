import { DocumentTaskDataFieldPhotoBooks } from "interfaces/models/documentTask";
import { TaskType } from "interfaces/models/taskType";
import { User } from "interfaces/models/user";
import { useMemo } from "react";
import { DEFAULT_FORMAT_DATE_DISPLAY, formatDate } from "utils/date";
import { FIELD_NAME_INSTRUCTIONS } from "../ContentPhotoBooksInstructions";
import { TaskPhotoBookInstruction } from "../PhotoBooksInstructionsItem";

interface iProps {
  taskTypes: TaskType[];
  task: TaskPhotoBookInstruction;
  listUserById: { [key: string]: User };
  photoBookContentEdit?: DocumentTaskDataFieldPhotoBooks;
}

const useGetPhotoBookContentInstruction = ({
  taskTypes,
  task,
  listUserById,
  photoBookContentEdit,
}: iProps) => {
  const content = useMemo(() => {
    const name =
      taskTypes.find((item) => item.id === task.taskTypeId)?.title ||
      "-";

    const title =
      photoBookContentEdit?.title?.editValue ??
      `${FIELD_NAME_INSTRUCTIONS.TITLE}: ${name}`;
    const memo =
      photoBookContentEdit?.memo?.editValue ??
      `${FIELD_NAME_INSTRUCTIONS.CONTENT}: ${task?.memo || "-"}`;
    const partnerCompany =
      photoBookContentEdit?.partnerCompanyId?.editValue ??
      `${FIELD_NAME_INSTRUCTIONS.PARTNER_COMPANY}: ${
        task?.partnerCompanyName || "-"
      }`;
    const deadline =
      photoBookContentEdit?.deadline?.editValue ??
      `${FIELD_NAME_INSTRUCTIONS.DEADLINE}: ${
        task?.deadline
          ? formatDate(task?.deadline, DEFAULT_FORMAT_DATE_DISPLAY)
          : "-"
      }`;

    const userAssigned =
      photoBookContentEdit?.userAssigned?.editValue ??
      `${FIELD_NAME_INSTRUCTIONS.USER_ASSIGNED}: ${
        listUserById?.[(task as any)?.userAssigned || ""]?.name || "-"
      }`;

    return {
      task,
      title,
      memo,
      partnerCompany,
      deadline,
      userAssigned,
    };
  }, [task, taskTypes, listUserById, photoBookContentEdit]);

  return content;
};

export default useGetPhotoBookContentInstruction;
