import { DataGenerate } from "apiClient/v2/bims/bimFileApi";
import { GenerateStatus } from "./index";

import { LEVEL_ALL, LEVEL_OTHER } from "constants/forge";

import { Level } from "interfaces/models";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchNeptuneAreasByBimFile,
  setIsGeneratingSpaces,
  setIsLoadedExternalId,
  setIsLoadedNeptuneAreas,
  setIsLoadedSpaces,
  setLevelSelected,
  setNeptuneAreas,
  setSpaces,
} from "redux/forgeViewerSlice";
import { RootState } from "redux/store";
import GenSpace from "services/genSpace";

import { ___viewer3d } from "utils/forge/forge3d";
import { getDataSpace } from "utils/forge/s3Data";

import { getLocalStorage, setLocalStorage } from "utils/storage";

interface Props {
  generatingStatusRef: React.MutableRefObject<GenerateStatus>;
  setTimeCheckGenerate: (val: number) => void;
}

export default function useGenerateSpace(props: Props) {
  const { generatingStatusRef, setTimeCheckGenerate } = props;
  const lastFetchSpacesArgs = useRef<{
    bimFileId: string | null;
    version: string | null;
    levelSelected: Level | null;
  }>({
    bimFileId: null,
    levelSelected: null,
    version: "",
  });
  const [isSpaceDataAllLevel, setIsSpaceDataAllLevel] = useState(false);

  const { bimFileId, version } = useParams();
  const { dataProjectDetail } = useSelector(
    (state: RootState) => state.project
  );

  const dispatch = useDispatch();
  const {
    isGeneratingSpaces,
    isLoadedViewerModelData,
    isLoadedViewer,
    levels,
    levelSelected,
    isLoadedLevels,
    isInitialized,
  } = useSelector((state: RootState) => state.forgeViewer);

  // #region generate spaces
  useEffect(() => {
    if (
      !bimFileId ||
      !version ||
      levelSelected?.guid === undefined ||
      isSpaceDataAllLevel ||
      !isInitialized ||
      !dataProjectDetail?.id
    ) {
      return;
    }
    const lastLevelSelected = lastFetchSpacesArgs.current.levelSelected;
    if (
      lastFetchSpacesArgs.current.bimFileId === bimFileId &&
      lastLevelSelected?.guid === levelSelected?.guid &&
      lastFetchSpacesArgs.current.version === version
    ) {
      // if same level -> not need refetch
      return;
    }
    generatingStatusRef.current.space = false;
    lastFetchSpacesArgs.current = {
      bimFileId,
      version,
      levelSelected,
    };
    dispatch(setIsLoadedSpaces(false));
    dispatch(setIsLoadedNeptuneAreas(false));
    dispatch(setSpaces([]));
    (async () => {
      const selectedGuid = levelSelected.guid;
      if (selectedGuid !== lastFetchSpacesArgs.current.levelSelected?.guid) {
        return;
      }
      const spacesData = await getDataSpace({
        bimFileId,
        version,
        level: levelSelected?.guid ? levelSelected?.label : LEVEL_OTHER.label,
      });

      if (spacesData) {
        setIsSpaceDataAllLevel(!levelSelected.guid);
        dispatch(setIsGeneratingSpaces(false));
        const spaces = spacesData?.spaces || [];
        dispatch(setSpaces(spaces));
        dispatch(setIsLoadedSpaces(true));
        setTimeCheckGenerate(Date.now());
        generatingStatusRef.current.space = true;
        const timeout = setTimeout(() => {
          dispatch(fetchNeptuneAreasByBimFile({ bimFileId }));
        }, 500);

        return () => {
          timeout && clearTimeout(timeout);
        };
      } else {
        dispatch(setIsGeneratingSpaces(true));
        if (levelSelected.guid) {
          const level = levels.find((f) => !f.guid) || LEVEL_ALL;
          lastFetchSpacesArgs.current.levelSelected = level;
          dispatch(setLevelSelected(level));
          // set false for waiting refetch external id all level
          dispatch(setIsLoadedExternalId(false));
          const currentLevel = getLocalStorage("currentLevel") || {};
          currentLevel[String(bimFileId)] = "";
          setLocalStorage("currentLevel", currentLevel);

          return;
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    bimFileId,
    bimFileId,
    version,
    levelSelected,
    isInitialized,
    dataProjectDetail?.id,
  ]);

  useEffect(() => {
    if (
      isGeneratingSpaces &&
      bimFileId &&
      version &&
      isLoadedViewer &&
      isLoadedViewerModelData &&
      !!___viewer3d
    ) {
      generateSpaceData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    bimFileId,
    isGeneratingSpaces,
    isLoadedViewerModelData,
    isLoadedLevels,
    isLoadedViewer,
    version,
  ]);

  const generateSpaceData = useCallback(async () => {
    const genSpace = new GenSpace(bimFileId!, version!);
    const spaces = (await genSpace.getSpaces()).filter((e) => e.level);
    const { newAreas, newSpaces } = await genSpace.uploadSpaceData(spaces);

    dispatch(setIsGeneratingSpaces(false));
    dispatch(setIsLoadedNeptuneAreas(true));
    dispatch(setNeptuneAreas(newAreas));
    setIsSpaceDataAllLevel(true);
    dispatch(setSpaces(newSpaces));
    dispatch(setIsLoadedSpaces(true));
    generatingStatusRef.current.space = true;
  }, [bimFileId, version, generatingStatusRef, dispatch]);

  return {
    isSpaceDataAllLevel,
  };
}
