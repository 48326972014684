import { CSSProperties } from "@emotion/serialize";
import { Attribute, Node } from "@tiptap/core";
import { CheckboxDirectionOptionsEnum } from "constants/enum";
import { NodeType } from "../type";

export interface TaskListNodeAttrs {
  direction: CheckboxDirectionOptionsEnum;
  type: "item" | "opt";
  alignItems?: CSSProperties["alignItems"];
}

export const TaskList = Node.create({
  name: NodeType.TASK_LIST,
  group: "block list",
  content: `(${NodeType.TASK_ITEM} | ${NodeType.TASK_OPT})+`,
  // fence the cursor for regular editing operations
  // [https://tiptap.dev/docs/editor/core-concepts/schema#isolating]
  isolating: true,
  selectable: false,
  allowGapCursor: false,

  addAttributes() {
    const attrs: { [key in keyof TaskListNodeAttrs]: Attribute } = {
      direction: { default: "horizontal" },
      type: { default: "item" },
      alignItems: { default: "flex-start" },
    };

    return attrs;
  },

  parseHTML() {
    return [
      {
        tag: `ul[data-type="${this.name}"]`,
        priority: 51,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const { direction, type, alignItems } = HTMLAttributes as TaskListNodeAttrs;

    return [
      "ul",
      {
        "data-type": this.name,
        style:
          "display: flex; gap: 0.1rem; " +
          `flex-direction: column; justify-content: ${alignItems}; ` +
          "width: 100%; height: 100%; ",
      },
      0,
    ];
  },
});
