import { Avatar, AvatarProps, ImageProps } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { withPresignedUrl } from "./HOC/presignedUrl";
import { defaultAvatarPath } from "constants/file";

const AvatarPresigned = withPresignedUrl(Avatar);

export default function AvatarUser(props: AvatarProps | ImageProps) {
  const { ...rest } = props;
  const [src, setSrc] = useState<string | null | undefined>(props.src);
  useEffect(() => {
    setSrc(props.src);
  }, [props.src]);

  const onError = () => {
    setSrc(null);
  };

  return (
    <AvatarPresigned
      fallbackSrc={defaultAvatarPath}
      {...rest}
      src={src || ""}
      onError={onError}
    />
  );
}
