import { CloseIcon } from "@chakra-ui/icons";
import { Box, Center, Flex, Text } from "@chakra-ui/react";
import { useCallback, useMemo } from "react";
import { JointContent, NoticeType } from "./interface";

type CustomComponentProps = {
  onClose: () => void;
  onCloseCallback?: () => void;
  content: JointContent;
  status?: NoticeType;
};

const COLORS = {
  success: "#CEE741",
  error: "#ef4444",
  warning: "#f59e0b",
  info: "#009BE0",
  loading: "#009BE0",
};

const CustomComponent: React.FC<CustomComponentProps> = ({
  content,
  onClose,
  onCloseCallback,
  status = "success",
}) => {
  const renderContent = useMemo(() => {
    const typeofContent = typeof content;
    const isArray = typeofContent === "object" && Array.isArray(content);
    const color = status === "success" ? "black" : "white";

    if (typeofContent === "string") {
      return (
        <Text fontWeight="500" fontSize="1.6rem" color={color}>
          {content}
        </Text>
      );
    }

    if (typeofContent === "object") {
      if (isArray && content.length <= 1) {
        return (
          <Text fontWeight="500" fontSize="1.6rem" color={color}>
            {content[0] ?? ""}
          </Text>
        );
      }

      if (isArray && content.length > 1) {
        return (
          <>
            <Text fontWeight="700" fontSize="1.6rem" color={color}>
              {content[0]}
            </Text>
            <Text fontWeight="400" fontSize="1.6rem" color={color}>
              {content[1]}
            </Text>
          </>
        );
      }

      return content;
    }
  }, [content, status]);

  const onHandleClose = useCallback(() => {
    onClose();
    onCloseCallback && onCloseCallback();
  }, [onCloseCallback, onClose]);

  return (
    <Flex
      background={COLORS[status]}
      minWidth="30rem"
      maxWidth="465px"
      position="relative"
      gap="1rem"
      padding="1rem"
      alignItems="center"
    >
      <Center
        cursor="pointer"
        width="5rem"
        height="2rem"
        onClick={onHandleClose}
      >
        <CloseIcon color={status === "success" ? "black" : "white"} />
      </Center>

      <Box>{renderContent}</Box>
    </Flex>
  );
};

export default CustomComponent;
