import { ApiResponse } from "interfaces/models/api";
import { BlackboardTemplate } from "interfaces/models/blackboardTemplate";
import { axiosECS } from "services/baseAxios";
import { validateBodyReq, validateListString } from "utils/common";

const path = "/v1/blackboard-templates";

export const getTemplateList = async (
  shouldCache = false
): Promise<ApiResponse<BlackboardTemplate[]>> => {
  // actual api temporary not in used
  // return axiosECS.get(path, {
  //   params: {
  //     shouldCache,
  //   },
  // });
  return { data: [] };
};

export const getTemplate = async (
  blackboardTemplateId: string
): Promise<ApiResponse<BlackboardTemplate>> => {
  // actual api temporary not in used
  // return axiosECS.get(`${path}/${blackboardTemplateId}`);
  return { data: undefined as any };
};

export const createTemplate = async (
  template: BlackboardTemplate
): Promise<ApiResponse<BlackboardTemplate>> => {
  return axiosECS.post(path, validateBodyReq(template));
};

export const updateTemplate = async (
  template: BlackboardTemplate
): Promise<ApiResponse<BlackboardTemplate>> => {
  return axiosECS.patch(path, validateBodyReq(template));
};

export const deleteTemplateList = async (
  ids: string[]
): Promise<ApiResponse<string[]>> => {
  return axiosECS.delete(path, { data: { ids: validateListString(ids) } });
};
