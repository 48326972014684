import { Attribute, Node } from "@tiptap/core";
import { NodeType } from "../type";

export interface PinLayoutNodeAttrs {
  top: number;
  left: number;
  width: number;
  height: number;
}

export const PinLayoutNode = Node.create({
  name: NodeType.PIN_LAYOUT,
  content: `(${NodeType.PIN_COMPONENT})*`,
  group: "block",
  // fence the cursor for regular editing operations
  // [https://tiptap.dev/docs/editor/core-concepts/schema#isolating]
  isolating: true,
  selectable: false,

  addAttributes() {
    const attrs: {
      [key in keyof PinLayoutNodeAttrs]: Attribute;
    } = {
      top: { default: 0 },
      left: { default: 0 },
      width: { default: 0 },
      height: { default: 0 },
    };

    return attrs;
  },
  parseHTML() {
    return [
      {
        tag: "div",
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    const { top, left, width, height } = HTMLAttributes as PinLayoutNodeAttrs;

    return [
      "div",
      {
        style: `position: absolute; pointer-events: none; height: ${height}%; width: ${width}%; top: ${top}%; left: ${left}%`,
      },
      0,
    ];
  },
});
