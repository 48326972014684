import { useLayoutFrameBuilderContext } from "../../context/layoutFrameBuilderContext";
import DraggableBox from "../DraggableBox";

interface AreaSelectionProps {}

const AreaSelection: React.FC<AreaSelectionProps> = ({}) => {
  const { items, itemSelectedId } = useLayoutFrameBuilderContext();

  return (
    <>
      {items.map((item) => {
        return (
          <DraggableBox
            key={item.id}
            item={item}
            isActive={item.id === itemSelectedId}
          />
        );
      })}
    </>
  );
};

export default AreaSelection;
