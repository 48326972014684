import { Box } from "@chakra-ui/react";
import React, { useCallback } from "react";
import { IconBase } from "components/base";
import { useLayoutFrameBuilderContext } from "../context/layoutFrameBuilderContext";

interface DeleteButtonProps {
  isActive?: boolean;
  itemSelectedId: string;
}

const DeleteButton = React.forwardRef(
  ({ isActive, itemSelectedId }: DeleteButtonProps, ref: any) => {
    const { isLoading, onDelete } = useLayoutFrameBuilderContext();

    const removeItem = useCallback(() => {
      if (!itemSelectedId || isLoading) return;
      onDelete(itemSelectedId);
    }, [itemSelectedId, isLoading, onDelete]);

    return (
      <Box
        as="button"
        ref={ref}
        borderRadius={"4px"}
        bg={isActive ? "#f37054" : "#fff"}
        border="1px solid #c1c0c0"
        width={"2.6rem"}
        height={"2.4rem"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        cursor={isLoading ? "not-allowed" : "pointer"}
        opacity={isLoading ? 0.5 : itemSelectedId ? 1 : 0}
        transition={"all .3s ease-in-out"}
        boxShadow={isActive ? "0 0 0 3px #f3705499" : ""}
        _hover={{
          bg: "#f37054",
          boxShadow: "0 0 0 3px #f3705499",
          borderColor: "transparent",
          color: "#fff",
        }}
        onClick={removeItem}
      >
        <IconBase w={"1.5rem"} aspectRatio={1} icon="/img/icon-delete-1.svg" />
      </Box>
    );
  }
);

export default DeleteButton;
