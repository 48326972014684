import { Box, BoxProps, Text } from "@chakra-ui/react";
import { memo } from "react";
import { SvgIcon } from "./SvgIcon";

interface EmptyDataProps extends BoxProps {
  message?: string;
  icon?: string;
  iconSize?: string;
}

const EmptyData = (props: EmptyDataProps) => {
  const {
    message = "データなし",
    icon = "/img/no-data-holder.svg",
    iconSize = "15rem",
    ...rest
  } = props;

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      h="100%"
      w="100%"
      {...rest}
    >
      <Box w={iconSize} h={iconSize}>
        <SvgIcon w={iconSize} h={iconSize} src={icon} />
      </Box>
      <Text>{message}</Text>
    </Box>
  );
};

export default memo(EmptyData);
