import { DEFAULT_FONT_SIZE } from "pages/document/template-page/hooks";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useRef, useState } from "react";

interface iProps {
  element?: HTMLElement;
  parentLoop: number | undefined;
  autoReduceSize: boolean;
  isAutoReduceSizeWhenTyping?: boolean;
}

export const FONT_SIZE_OFFSET = 1;
const MAX_LOOP_FIND_PARENT_COMPONENT = 5;

export const FONT_SIZE_MIN = 5;
export const LINE_HEIGHT_DEFAULT = 1.5;

const useHandleTextOverflow = ({
  element,
  parentLoop,
  autoReduceSize,
  isAutoReduceSizeWhenTyping = false,
}: iProps) => {
  const [lineClamp, setLineClamp] = useState(1);
  const [isCalculatedLineClamp, setIsCalcalatedLineClamp] =
    useState<boolean>(false);
  const [isAutoReduceSize, setIsAutoReduceSize] = useState(autoReduceSize);

  const sizeDefaultRef = useRef<{
    fontSizeNumber: number;
    lineHeightNumber: number;
  }>();
  const sizeParentRef = useRef<{
    clientHeight: number;
  }>({
    clientHeight: 0,
  });

  const getParentComponent = useCallback(
    (e: HTMLElement) => {
      let parentElement: HTMLElement = e;
      const tagName = "TD";

      if (parentLoop === 0) {
        return;
      }

      if (parentLoop) {
        let loopCount = parentLoop;
        while (parentElement && loopCount) {
          loopCount--;
          parentElement = parentElement.parentElement!;
        }
      } else {
        let loopCount = MAX_LOOP_FIND_PARENT_COMPONENT;

        while (
          parentElement &&
          parentElement.tagName !== tagName &&
          loopCount
        ) {
          loopCount--;
          parentElement = parentElement.parentElement!;
        }
      }

      return parentElement?.tagName === tagName || parentLoop
        ? parentElement
        : null;
    },
    [parentLoop]
  );

  useEffect(() => {
    setIsAutoReduceSize(autoReduceSize);
  }, [autoReduceSize]);

  const checkContentOverflowTooMuch = useCallback((target: HTMLElement) => {
    if (!target) {
      return false;
    }

    return target.clientHeight < target.scrollHeight;
  }, []);

  const calculateLineClamp = useCallback(
    (target: HTMLElement) => {
      if (target) {
        const parentElement = getParentComponent(target);

        if (parentElement) {
          const lineHeightNumber = Number(
            getComputedStyle(target).lineHeight.replace("px", "")
          );
          const parentHeight =
            parentElement.clientHeight -
            Number(
              getComputedStyle(parentElement).paddingTop.replace("px", "")
            );

          const _lineClamp = Math.ceil(parentHeight / lineHeightNumber);
          setLineClamp(_lineClamp || 1);
        }
      }
    },
    [getParentComponent]
  );

  useEffect(() => {
    if (!element || !isAutoReduceSizeWhenTyping || !isAutoReduceSize) {
      return;
    }

    calculateLineClamp(element);
    setIsCalcalatedLineClamp(true);
  }, [
    parentLoop,
    element,
    element?.innerText,
    getParentComponent,
    calculateLineClamp,
    isAutoReduceSize,
    isAutoReduceSizeWhenTyping,
  ]);

  // check element resize
  useEffect(() => {
    if (isAutoReduceSizeWhenTyping) {
      return;
    }

    if (element) {
      const targetNode = getParentComponent(element);
      if (!targetNode) {
        return;
      }

      const observer = new ResizeObserver(
        debounce(() => {
          const isNotSameHeight =
            sizeParentRef.current?.clientHeight !== targetNode.clientHeight;

          if (isNotSameHeight) {
            calculateLineClamp(element);

            if (isAutoReduceSize) {
              setIsCalcalatedLineClamp(true);
            }

            sizeParentRef.current = {
              clientHeight: targetNode.clientHeight,
            };
          }
        }, 30)
      );

      observer.observe(targetNode);

      return () => {
        observer.unobserve(targetNode);
      };
    }
  }, [
    parentLoop,
    element,
    getParentComponent,
    calculateLineClamp,
    isAutoReduceSize,
    isAutoReduceSizeWhenTyping,
  ]);

  const handleLoopCalculateLineClamp = useCallback(
    async (target: HTMLElement) => {
      let fontSizeNumber = Number(
        getComputedStyle(target).fontSize.replace("px", "")
      );
      let lineHeightNumber = Number(
        getComputedStyle(target).lineHeight.replace("px", "")
      );
      let isOverflowToMuch = checkContentOverflowTooMuch(target);
      let isBreak = false;

      do {
        if (!isOverflowToMuch) {
          return;
        }

        fontSizeNumber = fontSizeNumber - FONT_SIZE_OFFSET;
        lineHeightNumber = lineHeightNumber - FONT_SIZE_OFFSET;
        if (fontSizeNumber <= FONT_SIZE_MIN) {
          fontSizeNumber = FONT_SIZE_MIN;
          lineHeightNumber = lineHeightNumber + FONT_SIZE_OFFSET;
          isBreak = true;
        }

        target.style.fontSize = `${fontSizeNumber}px`;
        target.style.lineHeight = `${lineHeightNumber}px`;

        calculateLineClamp(target);
        isOverflowToMuch = checkContentOverflowTooMuch(target);
      } while (isOverflowToMuch && !isBreak && isAutoReduceSizeWhenTyping);
    },
    [
      calculateLineClamp,
      checkContentOverflowTooMuch,
      isAutoReduceSizeWhenTyping,
    ]
  );

  // auto reduce size text
  useEffect(() => {
    if (
      isAutoReduceSize &&
      isCalculatedLineClamp &&
      element &&
      !!element.innerText &&
      sizeDefaultRef.current
    ) {
      const target = element;
      const fontSizeNumber = Number(
        getComputedStyle(target).fontSize.replace("px", "")
      );
      const lineHeightNumber = Number(
        getComputedStyle(target).lineHeight.replace("px", "")
      );

      const isOverflowToMuch = checkContentOverflowTooMuch(target);
      if (isOverflowToMuch) {
        handleLoopCalculateLineClamp(target);

        return;
      }

      let fs = fontSizeNumber + FONT_SIZE_OFFSET;
      fs =
        fs > sizeDefaultRef.current.fontSizeNumber
          ? sizeDefaultRef.current.fontSizeNumber
          : fs;

      let lh = lineHeightNumber + FONT_SIZE_OFFSET;
      lh =
        lh > sizeDefaultRef.current.lineHeightNumber
          ? sizeDefaultRef.current.lineHeightNumber
          : lh;
      target.style.fontSize = `${fs}px`;
      target.style.lineHeight = `${lh}px`;

      const isOverflowToMuchAfterChangeFontsize =
        checkContentOverflowTooMuch(target);
      if (isOverflowToMuchAfterChangeFontsize) {
        target.style.fontSize = `${fs - FONT_SIZE_OFFSET}px`;
        target.style.lineHeight = `${lh - FONT_SIZE_OFFSET}px`;
      }

      calculateLineClamp(target);

      setIsCalcalatedLineClamp(false);
    }
  }, [
    handleLoopCalculateLineClamp,
    autoReduceSize,
    isAutoReduceSize,
    isCalculatedLineClamp,
    calculateLineClamp,
    checkContentOverflowTooMuch,
    element,
    element?.innerText,
  ]);

  // reset size text
  useEffect(() => {
    if (!isAutoReduceSize && !!element) {
      const target = element;
      target.style.fontSize = `${sizeDefaultRef?.current?.fontSizeNumber}px`;
      target.style.lineHeight = `${sizeDefaultRef?.current?.lineHeightNumber}px`;

      calculateLineClamp(element);
      setIsCalcalatedLineClamp(true);
    }
  }, [isAutoReduceSize, calculateLineClamp, element]);

  // store size default
  useEffect(() => {
    if (!!element) {
      const fontSizeNumber = Number(
        element.getAttribute("data-font-size") || DEFAULT_FONT_SIZE
      );

      sizeDefaultRef.current = {
        fontSizeNumber: fontSizeNumber,
        lineHeightNumber: fontSizeNumber * LINE_HEIGHT_DEFAULT,
      };
    }
  }, [element]);

  return {
    lineClamp,
    sizeDefaultRef,
  };
};

export default useHandleTextOverflow;
