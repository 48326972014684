import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import Moveable, { OnDrag, OnResize } from "react-moveable";
import { IconBase } from "components/base";
import { useWindowResize } from "hooks/useWindowResize";
import useOnClickOutside from "hooks/useOnClickOutside";
import { useContainer } from "../context/container";
import DeleteButton from "./DeleteButton";
import { isContainElement } from "utils/dom";
import { Item, Position } from "../type";
import { useBoundContext } from "../context/boundContext";
import { useLayoutFrameBuilderContext } from "../context/layoutFrameBuilderContext";
import { DEFAULT_EXTRA_ID } from "../context/layoutFrameBuilderReducer";
import { FRAME_BORDER_WIDTH } from "./LayoutFrameBuilder";

interface DraggableBoxProps {
  item: Item;
  isActive?: boolean;
}

const DraggableBox: React.FC<DraggableBoxProps> = ({ item, isActive }) => {
  const selectionBoxRef = useRef<any>();
  const moveableRef = useRef<any>();
  const timeoutRef = useRef<any>();
  const funcRef = useRef<any>();
  const { items, partials, zoom, onChange, isLoading, onSelect } =
    useLayoutFrameBuilderContext();
  const boundRef = useBoundContext();
  const containerRef = useContainer();

  const partial = useMemo(() => {
    return (partials as any)[item.linkedData?.source!]?.[
      item.linkedData?.key!
    ]?.[item.linkedData?.extraId ?? DEFAULT_EXTRA_ID];
  }, [item.linkedData, partials]);

  const elementGuidelines = useMemo(() => {
    return items.filter((i) => i.id !== item.id).map((i) => `#item-${i.id}`);
  }, [item.id, items]);

  const onFinish = useCallback(
    (position: Partial<Position>) => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      funcRef.current = () => {
        onChange({ ...item, ...position });
      };
      timeoutRef.current = setTimeout(funcRef.current, 300);
    },
    [item, onChange]
  );

  const highlightElementContaint = useCallback(
    (e: any) => {
      if (isContainElement(e.target as HTMLDivElement, containerRef.current)) {
        containerRef.current.style.borderColor = "#1a1a1a";
      } else {
        containerRef.current.style.borderColor = "red";
      }
    },
    [containerRef]
  );

  const onDrag = useCallback(
    (e: OnDrag) => {
      const parent = e.target.parentElement!;
      const leftPercent = (e.left / parent.offsetWidth) * 100;
      const topPercent = (e.top / parent.offsetHeight) * 100;
      e.target.style.top = `${topPercent}%`;
      e.target.style.left = `${leftPercent}%`;
      onFinish({ left: leftPercent, top: topPercent });
      highlightElementContaint(e);
    },
    [onFinish, highlightElementContaint]
  );

  const onResize = useCallback(
    (e: OnResize) => {
      const parent = e.target.parentElement!;
      const leftPercent = (e.drag.left / parent.offsetWidth) * 100;
      const topPercent = (e.drag.top / parent.offsetHeight) * 100;
      const widthPercent = (e.width / parent.offsetWidth) * 100;
      const heightPercent = (e.height / parent.offsetHeight) * 100;
      e.target.style.top = `${topPercent}%`;
      e.target.style.left = `${leftPercent}%`;
      e.target.style.width = `${widthPercent}%`;
      e.target.style.height = `${heightPercent}%`;

      onFinish({
        width: widthPercent,
        height: heightPercent,
        left: leftPercent,
        top: topPercent,
      });
      highlightElementContaint(e);
    },
    [onFinish, highlightElementContaint]
  );

  const onMouseDown = useCallback(() => {
    onSelect(item.id);
  }, [onSelect, item.id]);

  const onFinishDrag = useCallback(() => {
    containerRef.current.style.borderColor = "#1a1a1a";
    setTimeout(() => {
      moveableRef.current?.updateRect();
    }, 500);
  }, [containerRef]);

  useWindowResize(() => {
    moveableRef.current?.updateRect();
  });

  useLayoutEffect(() => {
    moveableRef.current?.updateRect();
  }, [zoom]);

  useEffect(() => {
    return () => {
      if (funcRef.current) {
        funcRef.current();
      }
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  useOnClickOutside(selectionBoxRef, () => {
    if (isActive) {
      setTimeout(() => {
        onSelect(null);
      }, 200);
    }
  });

  useEffect(() => {
    if (!selectionBoxRef.current) return;
    const newStyles = {
      width: `${item.width}%`,
      height: `${item.height}%`,
      left: `${item.left}%`,
      top: `${item.top}%`,
    };
    Object.assign(selectionBoxRef.current.style, newStyles);
    moveableRef.current?.updateRect();
  }, [item]);

  if (!partial) return null;

  const dragInfo = (
    <Flex
      alignItems={"center"}
      whiteSpace={"nowrap"}
      p="0.25rem"
      fontSize={isActive ? "1.125rem" : "0.75em"}
      fontWeight={500}
      maxW={"100%"}
      gap="0.25rem"
      transition={"all .3s linear"}
    >
      {partial.icon && (
        <Flex
          sx={{
            svg: {
              w: "100%",
              h: "100%",
            },
          }}
          alignItems={"center"}
          justifyContent={"center"}
          flex={`${"0 0 2em"}`}
          w={`${"2em"}`}
          h={`${"2em"}`}
        >
          {partial.icon}
        </Flex>
      )}
      <Text
        title={partial.name}
        maxW="100%"
        overflow={"hidden"}
        textOverflow={"ellipsis"}
      >
        {partial.name}
      </Text>
    </Flex>
  );

  return (
    <>
      <Box
        ref={selectionBoxRef}
        position={"absolute"}
        bg="#2d9bf042"
        color="#4af"
        border="1px solid #2d9bf042"
        width={`${item.width}%`}
        height={`${item.height}%`}
        top={`${item.top}%`}
        left={`${item.left}%`}
        id={`item-${item.id}`}
        cursor={"move"}
        onMouseDown={onMouseDown}
        onTouchStart={onMouseDown}
        zIndex={isActive ? 11 : 10}
      >
        <Flex position={"relative"} w="100%" h="100%">
          <Flex
            position={"absolute"}
            left={0}
            top={0}
            width={"100%"}
            h="100%"
            alignItems={"center"}
            justifyContent={"center"}
            maxW={"100%"}
            p="0.5rem"
          >
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              width={"100%"}
              h={"70%"}
              maxW={"50%"}
              filter="grayscale(100%)"
              opacity="0.3"
              sx={{
                "&>*": {
                  w: "100%",
                  h: "100%",
                },
              }}
            >
              {partial.icon}
            </Flex>
          </Flex>
          <Flex
            position={"absolute"}
            top={0}
            left={0}
            maxW={"100%"}
            p="0.25rem"
            transition={"all .3s ease"}
          >
            {dragInfo}
          </Flex>
          <Flex
            position={"absolute"}
            top={"100%"}
            left={"50%"}
            transform={isActive ? `translate(-50%, 0)` : "translate(-50%, 50%)"}
            maxW={"100%"}
            paddingTop="0.5rem"
            opacity={isActive ? 1 : 0}
            pointerEvents={isActive ? "auto" : "none"}
            transition={"all .3s ease-in-out"}
          >
            <DeleteButton itemSelectedId={item.id} />
          </Flex>
        </Flex>
      </Box>
      <Moveable
        ref={moveableRef}
        target={selectionBoxRef}
        draggable={!isLoading}
        throttleDrag={1}
        edgeDraggable={false}
        startDragRotate={0}
        throttleDragRotate={0}
        throttleResize={1}
        resizable={!isLoading}
        edge={false}
        className={`draggable-controls ${isActive ? "active" : ""}`}
        renderDirections={["nw", "ne", "sw", "se"]}
        snappable={true}
        bounds={{
          left: 0,
          top: 0,
          right: -FRAME_BORDER_WIDTH,
          bottom: -FRAME_BORDER_WIDTH,
          position: "css",
        }}
        snapContainer={boundRef.current}
        onDrag={onDrag}
        onResize={onResize}
        onDragEnd={onFinishDrag}
        origin={false}
        isDisplaySnapDigit
        isDisplayInnerSnapDigit
        snapGap
        snapDirections={{
          top: true,
          left: true,
          bottom: true,
          right: true,
          center: true,
          middle: true,
        }}
        elementSnapDirections={{
          top: true,
          left: true,
          bottom: true,
          right: true,
          center: true,
          middle: true,
        }}
        snapHorizontalThreshold={0}
        snapVerticalThreshold={0}
        snapThreshold={0}
        elementGuidelines={elementGuidelines}
        onRender={(e) => {
          e.target.style.content = `'${e.cssText}'`;
        }}
      />
    </>
  );
};

export default DraggableBox;
