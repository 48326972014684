import { SystemModeType } from "constants/enum";
import { Vector3 } from "interfaces/models";
import store from "redux/store";
import { logDev } from "utils/logs";
import { clearForgeSelection, pointerToRaycaster } from "..";
import { calculatePositionFromSheet } from "../forge2d";
import { AreaMesh, getAreaExtension } from "./area-extension";
import { debounce, isEmpty } from "lodash";
import {
  getExternalIdByDbId,
  getPropertyByDbId,
  _allowClearSelectWhenClick,
  __familyInstances,
  _allow_select_object,
} from "../viewerData";

export enum ClickExtensionEvents {
  SingleClickEvent = "SingleClickEvent",
  IsClickProgressing = "IsClickProgressing",
}

export interface ClickInfo {
  originalEvent?: any;
  forgeData?: {
    dbId?: number;
    name?: string;
    externalId?: string;
    position: Vector3 | THREE.Vector3;
    level?: string;
    fragId?: number;
    distance?: number;
    rotation?: Vector3 | THREE.Vector3;
    type?: string;
    fov?: string;
    direction?: string;
    mesh?: THREE.Mesh;
    uranusStepId?: string;
    uranus_url?: string;
    positionZOfSheet?: number;
  };
  areaData?: AreaMesh;
}

export class ClickTool {
  private viewer: Autodesk.Viewing.GuiViewer3D;
  private name: string;

  private isClickProgressing = false;
  private isClickProgressingLatestStatus = false;

  constructor(viewer: Autodesk.Viewing.GuiViewer3D) {
    this.viewer = viewer;
    this.name = ClickTool.name;
  }

  getLevelHeight() {
    const levelSelected = store.getState().forgeViewer.levelSelected;
    if (!levelSelected.guid || (!levelSelected.zMax && !levelSelected.zMin)) {
      return 0;
    }

    return !!levelSelected?.zMin
      ? levelSelected?.zMin
      : levelSelected?.zMax || 0;
  }

  canSelectPointOnForge = () => {
    const state = store.getState();

    return !(
      (state.forgeViewer.systemMode === SystemModeType.Document &&
        (state.document.documentCategorySelected ||
          state.document.documentItemSelected)) ||
      ((state.forgeViewer.isCreateTask || !isEmpty(state.task.taskSelected)) &&
        state.forgeViewer.systemMode === SystemModeType.Task)
    );
  };

  getObjectName = (object: any) => {
    const elementIdProperty = object?.properties.find(
      (property: any) =>
        property.attributeName === "ElementId" &&
        property.displayCategory === "__revit__"
    );
    const elementId = elementIdProperty?.displayValue;
    let name = object?.name || "";
    if (elementId && !name?.endsWith(`[${elementId}]`)) {
      name = `${name} [${elementId}]`;
    }

    return name;
  };

  async handleSingle3dClick(event: any, button: number): Promise<boolean> {
    if (button !== 0) {
      return true;
    }

    const canSelectOnForge = this.canSelectPointOnForge();
    if (canSelectOnForge && _allowClearSelectWhenClick) {
      clearForgeSelection(this.viewer);
    }
    const clickInfo: ClickInfo = {
      originalEvent: event,
    };

    const position = this.viewer.impl.intersectGround(
      event.canvasX,
      event.canvasY
    ) || { x: 0, y: 0, z: 0 };

    const levelSelected = store.getState().forgeViewer.levelSelected;
    clickInfo.forgeData = {
      level: levelSelected.label,
      position: { x: position.x, y: position.y, z: this.getLevelHeight() },
    };

    const hitTest = this.viewer.impl.hitTest(event.canvasX, event.canvasY);
    const dbId = hitTest?.dbId;

    if (dbId) {
      const object: any = await getPropertyByDbId(hitTest?.dbId);
      const name = this.getObjectName(object);
      const externalId = object?.externalId;
      const instance = __familyInstances[externalId];
      logDev(instance);
      const isIncludeDwg =
        !!instance?.systemType?.includes(".dwg") ||
        !!instance?.typeName?.includes(".dwg");

      if (hitTest && !isIncludeDwg) {
        const position = hitTest.intersectPoint || hitTest.point;
        if (event.target?.closest("canvas") && _allow_select_object) {
          this.viewer.select([hitTest.dbId]);
        }
        clickInfo.forgeData = {
          level: levelSelected.label,
          position,
          dbId: hitTest.dbId,
          externalId,
          name,
          fragId: hitTest.fragId,
          distance: hitTest.distance,
          mesh: hitTest.object,
        };
      }
    } else {
      this.viewer.select([]);
    }

    // check area
    const raycaster = pointerToRaycaster(
      this.viewer.impl.canvas,
      this.viewer.impl.camera,
      event
    );
    const areaExtension = getAreaExtension();
    if (areaExtension) {
      const objects = (await areaExtension.getSpaces()) || [];
      const intersects = raycaster.intersectObjects(objects);
      if (intersects.length > 0) {
        const area = objects.find((o) => o.uuid === intersects[0].object.uuid);
        clickInfo.areaData = area;
      }
    }

    this.viewer.dispatchEvent({
      type: ClickExtensionEvents.SingleClickEvent,
      payload: clickInfo,
    });
    logDev("clicked", clickInfo);

    return false;
  }

  async handleSingle2dClick(event: any, button: number): Promise<boolean> {
    if (button !== 0) {
      return true;
    }

    const canSelectOnForge = this.canSelectPointOnForge();
    if (canSelectOnForge && _allowClearSelectWhenClick) {
      clearForgeSelection(this.viewer);
    }
    const clickInfo: ClickInfo = {
      originalEvent: event,
    };

    const position = calculatePositionFromSheet(
      this.viewer.impl.intersectGround(event.canvasX, event.canvasY)
    );
    const levelSelected = store.getState().forgeViewer.levelSelected;
    clickInfo.forgeData = {
      level: levelSelected.label,
      position: new THREE.Vector3(
        position.x,
        position.y,
        this.getLevelHeight()
      ),
      positionZOfSheet: position.z,
    };
    const hitTest = this.viewer.impl.hitTest(event.canvasX, event.canvasY);
    const dbId = hitTest?.dbId;
    if (dbId) {
      const externalId = (await getExternalIdByDbId(hitTest?.dbId)) || "";
      const instance = __familyInstances?.[externalId];
      logDev(instance);
      const isIncludeDwg =
        !!instance?.systemType?.includes(".dwg") ||
        !!instance?.typeName?.includes(".dwg");

      if (hitTest && !isIncludeDwg) {
        const position = calculatePositionFromSheet(
          hitTest.intersectPoint || hitTest.point
        );
        if (event.target?.closest("canvas") && _allow_select_object) {
          this.viewer.select([hitTest.dbId]);
        }
        const positionZOfInstance =
          instance?.position.z || instance?.bounds.max.z;
        const positionZ =
          typeof positionZOfInstance !== "undefined"
            ? positionZOfInstance
            : this.getLevelHeight();

        clickInfo.forgeData = {
          level: levelSelected.label,
          position: new THREE.Vector3(position.x, position.y, positionZ),
          dbId: hitTest.dbId,
          externalId,
          fragId: hitTest.fragId,
          positionZOfSheet: position.z,
        };
      }
    }

    // check area
    const raycaster = pointerToRaycaster(
      this.viewer.impl.canvas,
      this.viewer.impl.camera,
      event
    );
    const areaExtension = getAreaExtension();
    if (areaExtension) {
      const objects = (await areaExtension.getSpaces()) || [];
      const intersects = raycaster.intersectObjects(objects);
      if (intersects.length > 0) {
        const area = objects.find((o) => o.uuid === intersects[0].object.uuid);
        clickInfo.areaData = area;
      }
    }

    this.viewer.dispatchEvent({
      type: ClickExtensionEvents.SingleClickEvent,
      payload: clickInfo,
    });

    logDev("clicked", clickInfo);

    return false;
  }

  async handleClick(event: any, button: number) {
    if (this.isClickProgressing) return true;

    // Dispatch start loading event
    this.isClickProgressing = true;
    this.dispatchLoadingProperty();

    const result = this.viewer.model.is2d()
      ? this.handleSingle2dClick(event, button)
      : this.handleSingle3dClick(event, button);

    // Dispatch stop loading event
    this.isClickProgressing = false;
    this.dispatchLoadingProperty();

    return result;
  }

  async handleSingleClick(event: any, button: number): Promise<boolean> {
    if (!this.viewer.model) return false;

    return this.handleClick(event, button);
  }

  async handleDoubleClick(event: any, button: number): Promise<boolean> {
    if (!this.viewer.model) return false;

    return this.handleClick(event, button);
  }

  handleSingleTap = async (event: any) => {
    return await this.handleSingleClick(event, 0);
  };

  handleDoubleTap = async (event: any) => {
    return await this.handleDoubleClick(event, 0);
  };

  activate(name: string, viewer: Autodesk.Viewing.GuiViewer3D) {
    this.viewer = viewer;
    this.name = name;
  }

  deactivate(name: string) {
    this.name = ClickTool.name;
  }

  getNames(): string[] {
    return [this.name];
  }

  getName(): string | undefined {
    return this.name;
  }

  protected dispatchLoadingProperty = debounce(() => {
    /** Ignore dispatch event if status not change since the latest time */
    if (this.isClickProgressing === this.isClickProgressingLatestStatus) return;
    this.isClickProgressingLatestStatus = this.isClickProgressing;

    this.viewer.dispatchEvent({
      type: ClickExtensionEvents.IsClickProgressing,
      payload: this.isClickProgressing,
    });
  }, 100);
}

export class ClickExtension extends Autodesk.Viewing.Extension {
  private tool: ClickTool;
  constructor(viewer: Autodesk.Viewing.GuiViewer3D, options: any) {
    super(viewer, options);
    this.tool = new ClickTool(viewer);
  }
  load() {
    this.viewer.toolController.registerTool(this.tool);
    this.viewer.toolController.activateTool(ClickTool.name);

    return true;
  }

  unload() {
    this.viewer.toolController.deactivateTool(ClickTool.name);
    this.viewer.toolController.deregisterTool(this.tool);
    logDev("ClickExtension unloaded.");

    return true;
  }

  static register = () => {
    Autodesk.Viewing.theExtensionManager.registerExtension(
      "ClickExtension",
      ClickExtension
    );
  };
}
