import { RadioGroup, Flex, Box, Checkbox } from "@chakra-ui/react";
import { DroppableBox } from "components/containers/InspectionFormat/containers/LayoutFrameBuilder";
import { SOURCE } from "components/containers/InspectionFormat/Content/SettingPinLayoutFrame/context/pinLayoutFrameReducer";
import FieldCheckboxPreviewWrapper from "../../../components/FieldCheckboxPreviewWrapper";
import { CheckboxPreviewerProps } from "../previewer";
import CheckboxIcon from "components/icon/CheckboxIcon";

interface DraggableProps extends CheckboxPreviewerProps {
  additionInfoCheckbox: any[];
}

const Draggable: React.FC<DraggableProps> = ({
  item,
  value,
  isReadOnly,
  onChange,
  additionInfoCheckbox,
}) => {
  return (
    <FieldCheckboxPreviewWrapper title={item.name} data-type={item.dataType}>
      <RadioGroup value={value} onChange={onChange}>
        <Flex display={"inline-flex"} flexDirection="column" gap="1rem">
          {additionInfoCheckbox.map((data, index) => (
            <DroppableBox
              name={data.displayValue}
              icon={<CheckboxIcon w="3rem" h="3rem" />}
              key={index}
              id={item.id}
              extraId={index}
              source={SOURCE.INSPECTION_FORM}
            >
              <Box className="draggable-area" w="100%" p="0.5rem 1rem">
                <Checkbox
                  isReadOnly={isReadOnly}
                  width="fit-content"
                  value={data.displayValue}
                >
                  {data.displayValue}
                </Checkbox>
              </Box>
            </DroppableBox>
          ))}
        </Flex>
      </RadioGroup>
    </FieldCheckboxPreviewWrapper>
  );
};

export default Draggable;
