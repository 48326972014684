import React, { createContext, useMemo, useReducer } from "react";
import { formStateReducer } from "./reducer";
import { FormStateContextType, FormStateState } from "./types";
import useFormHandler from "../../hooks/useFormhandler";
import { PinDetail } from "interfaces/models/pin";

export const FormStateContext = createContext<FormStateContextType>({} as any);

export interface FormStateContextRef {
  isDirty: boolean;
}

export interface FormStateContextProviderProps {
  children?: any;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  pinDetail: PinDetail;
}

const initState: FormStateState = {
  formValue: {} as any,
  isDisabled: false,
  fields: {},
  pinId: null,
};

export const FormStateContextProvider = React.forwardRef<
  FormStateContextRef,
  FormStateContextProviderProps
>(({ children, isDisabled = false, isReadOnly = false, pinDetail }, ref) => {
  const { id, pinContents, inspectionTypeId } = pinDetail;
  const formValue = useMemo(() => {
    if (!pinContents) return initState.formValue;
    const formValue: any = {};
    pinContents.map(
      (pinContent) =>
        (formValue[pinContent.itemResultId] = pinContent.value?.data)
    );

    return formValue;
  }, [pinContents]);

  const [state, dispatch] = useReducer(formStateReducer, {
    ...initState,
    formValue,
    isDisabled,
    pinId: id,
  });

  const handlers = useFormHandler({ dispatch, state, inspectionTypeId });

  return (
    <FormStateContext.Provider
      value={{ ...state, ...handlers, dispatch, isReadOnly }}
    >
      {children}
    </FormStateContext.Provider>
  );
});
