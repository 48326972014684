import { useResize } from "hooks/useResize";
import { useLayoutEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

const useForgeViewerResize = () => {
  const headerRef = useRef(document.querySelector("#neptune-header>div"));
  const resize = useResize();
  const { projectDetail, dataProjectDetail } = useSelector(
    (state: RootState) => state.project
  );

  useLayoutEffect(() => {
    const timeout = setTimeout(() => {
      let height = "var(--default-header-height-forge-viewer)";

      if (
        headerRef.current?.clientHeight &&
        headerRef.current?.clientHeight >= 64
      ) {
        height = `${headerRef.current?.clientHeight}px`;
      }

      document.body.style.setProperty("--header-height", height);
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [resize, projectDetail?.id, dataProjectDetail?.id]);

  useLayoutEffect(() => {
    return () => {
      document.body.style.setProperty(
        "--header-height",
        "var(--default-header-height-forge-viewer)"
      );
    };
  }, []);
};

export default useForgeViewerResize;
