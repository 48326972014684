import { ApiResponse } from "interfaces/models/api";
import { PinLayout } from "interfaces/models/pinLayout";
import { axiosECS } from "services/baseAxios";

interface GetPinLayoutReq {
  pageLayoutId: string;
}

const pathV2 = "/v1/documents/pin-layouts";

export const getPinLayoutList = async (
  params: GetPinLayoutReq
): Promise<ApiResponse<PinLayout[]>> => {
  return axiosECS.get(pathV2, {
    params,
  });
};

export const getPinLayout = async (id: string): Promise<ApiResponse> => {
  return axiosECS.get(`${pathV2}/${id}`);
};

export const createPinLayout = async (body: any): Promise<ApiResponse> => {
  return axiosECS.post(pathV2, body);
};

export const updatePinLayout = async (body: any): Promise<ApiResponse> => {
  return axiosECS.patch(pathV2, body);
};
