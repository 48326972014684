import { Box, Flex } from "@chakra-ui/react";
import React from "react";

interface HeaderProps {
  title?: any;
}

const Header: React.FC<HeaderProps> = ({ title }) => {
  return (
    <Flex
      w="100%"
      alignItems={"center"}
      p="1rem 2rem"
      bg="#1a1a1a"
      justifyContent={"space-between"}
      color="#fff"
    >
      <Box mx="auto" fontSize={"2rem"} fontWeight={"bold"}>
        {title}
      </Box>
    </Flex>
  );
};

export default Header;
