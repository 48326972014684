import { ActionMap } from "interfaces/models/context";
import {
  InspectionContent,
  UpdateInspectionContent,
} from "interfaces/models/inspectionContent";
import { InspectionItem } from "interfaces/models/inspectionItem";
import {
  CheckboxAdditionalInfo,
  InspectionItemResult,
} from "interfaces/models/inspectionItemResult";
import { z, ZodType } from "zod";
import { DataType } from "../../setup/define";

export type ElementType<T extends ReadonlyArray<unknown>> =
  T extends ReadonlyArray<infer ElementType> ? ElementType : never;

export const DEFAULT_CHECKBOX_ADDITIONAL_INFO: CheckboxAdditionalInfo = {
  mapValues: {
    "0": { label: "合格チェックボックスのラベル", displayValue: "良" },
    "1": { label: "不合格チェックボックスのラベル", displayValue: "不良" },
  },
};

export enum Types {
  ADD_ITEM = "ADD_ITEM",
  DELETE_ITEM = "DELETE_ITEM",
  UPDATE_ITEM = "UPDATE_ITEM",
  ADD_ITEM_RESULT = "ADD_ITEM_RESULT",
  DELETE_ITEM_RESULT = "DELETE_ITEM_RESULT",
  UPDATE_ITEM_RESULT = "UPDATE_ITEM_RESULT",
  UPDATE_CONTENT = "UPDATE_CONTENT",
  COPY_ITEM = "COPY_ITEM",
  INIT_DATA = "INIT_DATA",
}

export type FormBuilderPayload = {
  [Types.ADD_ITEM]: null;
  [Types.ADD_ITEM_RESULT]: { contentItemId: string };
  [Types.DELETE_ITEM]: { id: string };
  [Types.UPDATE_ITEM]: { id: string } & Partial<InspectionItem>;
  [Types.DELETE_ITEM_RESULT]: {
    id: string;
  };
  [Types.UPDATE_ITEM_RESULT]: { id: string } & Partial<InspectionItemResult> & {
      initData?: Partial<InspectionItemResult>;
    };
  [Types.UPDATE_CONTENT]: Partial<InspectionContent>;
  [Types.COPY_ITEM]: { id: string };
  [Types.INIT_DATA]: FormBuilderState;
};

export type FormBuilderActions =
  ActionMap<FormBuilderPayload>[keyof ActionMap<FormBuilderPayload>];

export type FormBuilderState = {
  inspectionContent: InspectionContent;
  contentItems: InspectionItem[];
  itemResults: InspectionItemResult[];
  defaultState?: Omit<FormBuilderState, "defaultState">;
};

export interface FormBuilderContextType {
  state: FormBuilderState;
  isSavingContent: boolean;
  errors: { [key: string]: string };
  dispatch: React.Dispatch<FormBuilderActions>;
  onSaveContent: () => Promise<UpdateInspectionContent | undefined>;
}

const isStringNotEmpty = z.string().refine((s) => !!s.trim(), "Not Empty!");

export const schema = z.object({
  inspectionContent: z.object({
    additionInfo: z.string().optional(),
  }) satisfies ZodType<Partial<InspectionContent>>,
  contentItems: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
      order: z.string(),
      inspectionContentId: z.string(),
    }) satisfies ZodType<InspectionItem>
  ),
  itemResults: z.array(
    z.object({
      id: z.string(),
      name: isStringNotEmpty,
      dataType: z.lazy(() => z.nativeEnum(DataType)),
      additionInfo: z
        .object({
          checkbox: z
            .object({
              mapValues: z.record(
                z.string(),
                z.object({
                  label: z.string(),
                  displayValue: isStringNotEmpty,
                })
              ),
            })
            .optional() satisfies ZodType<CheckboxAdditionalInfo | undefined>,
        })
        .nullish(),
      order: z.string(),
      contentItemId: z.string(),
    }) satisfies ZodType<InspectionItemResult>
  ),
});

export type TypeSchema = z.infer<typeof schema>;

export const validatePartialSchemaContent = (
  path: Partial<TypeSchema["inspectionContent"]>
) => schema.shape.inspectionContent.partial().safeParse(path)?.success;

export const validatePartialSchemaItems = (
  path: Partial<ElementType<TypeSchema["contentItems"]>>
) => schema.shape.contentItems.element.partial().safeParse(path)?.success;

export const validatePartialSchemaResults = (
  path: Partial<ElementType<TypeSchema["itemResults"]>>
) => schema.shape.itemResults.element.partial().safeParse(path)?.success;
