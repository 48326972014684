import { RGBColor } from "react-color";

const HEXADECIMAL_COLOR_100 = "FF";

export const getHighlightColor = (
  color: THREE.Color | string,
  percent: number = 5
) => {
  const result = typeof color === "string" ? new THREE.Color(color) : color;
  (result as any).offsetHSL(0, 0, percent / 100);

  return result;
};

const componentToHex = (c: number) => {
  const hex = c.toString(16);

  return hex.length == 1 ? `0${hex}` : hex;
};

const convertStringGRBToRGBColor = (color: string): RGBColor => {
  const rgbaValues = color.substring(5, color.length - 1).split(",");
  const r = parseInt(rgbaValues[0].trim());
  const g = parseInt(rgbaValues[1].trim());
  const b = parseInt(rgbaValues[2].trim());
  const a = Number(rgbaValues[3].trim());

  return { r, g, b, a };
};

export const convertRGBAToHex = (color: RGBColor | string) => {
  let colorClone: RGBColor = { r: 0, g: 0, b: 0 };
  switch (typeof color) {
    case "object": {
      colorClone = color;
      break;
    }
    case "string": {
      colorClone = convertStringGRBToRGBColor(color);
      break;
    }
    default: {
      break;
    }
  }

  const r = colorClone.r;
  const g = colorClone.g;
  const b = colorClone.b;
  const a = Math.round(colorClone.a! * 255);

  return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(
    b
  )}${componentToHex(a)}`;
};

export const getOpacityFromHex = (hex: string) => {
  const opacityHex = hex.slice(-2);
  if (opacityHex.toLocaleUpperCase() === HEXADECIMAL_COLOR_100) {
    return 1;
  }
  const opacityDecimal = parseInt(opacityHex, 16);

  const opacityPercentage = opacityDecimal / 255;

  return opacityPercentage.toFixed(2);
};
export const hexToRgba = (hex: string, opacity: number) => {
  hex = hex.replace("#", "");
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const getIsBrightColorFromHex = (hex: string) => {
  const colorWithoutHash = hex.substring(1);

  const r: number = parseInt(colorWithoutHash.substring(0, 2), 16);
  const g: number = parseInt(colorWithoutHash.substring(2, 4), 16);
  const b: number = parseInt(colorWithoutHash.substring(4, 6), 16);

  // The constants 299, 587, and 114 are used to calculate the perceived brightness of a color.
  // These values are based on the luminance contribution of each color channel (red, green, and blue)
  // to human vision. The green channel has the highest contribution, followed by red, and then blue
  const brightness: number = (r * 299 + g * 587 + b * 114) / 1000;

  // Return true if the brightness is greater than 125, indicating a light color.
  // This threshold is commonly used to determine if a color is light or dark.
  // If the brightness is greater than 125, the color is considered light.
  return brightness > 125;
};

export const adjustBrightnessHexColor = (params: {
  hex: string;
  percentage: number;
  action: "lighten" | "darken";
}) => {
  const { hex: _hex, percentage, action } = params;
  const hex = _hex.replace("#", "");

  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  if (action === "lighten") {
    r = Math.min(255, Math.floor(r + (255 - r) * (percentage / 100)));
    g = Math.min(255, Math.floor(g + (255 - g) * (percentage / 100)));
    b = Math.min(255, Math.floor(b + (255 - b) * (percentage / 100)));
  } else if (action === "darken") {
    r = Math.max(0, Math.min(255, Math.floor(r * (1 - percentage / 100))));
    g = Math.max(0, Math.min(255, Math.floor(g * (1 - percentage / 100))));
    b = Math.max(0, Math.min(255, Math.floor(b * (1 - percentage / 100))));
  }

  const adjustedHex = `#${r.toString(16).padStart(2, "0")}${g
    .toString(16)
    .padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;

  return adjustedHex;
};
