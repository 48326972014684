import { DATA_STORE, PATH_API, StoreName } from "constants/serviceWorker";
import { iCachedItem } from "interfaces/models/serviceWorker";
import { getIndexedDb } from "utils/indexedDb";

function convertStoreNameToPathAPI(storeName: string) {
  switch (storeName) {
    case StoreName.INSPECTION_PIN_GROUP:
      return PATH_API.INSPECTION_PIN_GROUP;

    case StoreName.INSPECTION_PIN_CONTENT:
      return PATH_API.INSPECTION_PIN_CONTENT;

    case StoreName.INSPECTION_CONTENT:
      return PATH_API.INSPECTION_CONTENT;

    case StoreName.INSPECTION_TYPE:
      return PATH_API.INSPECTION_TYPE;

    case StoreName.INSPECTION_ITEM_RESULT:
      return PATH_API.INSPECTION_ITEM_RESULT;

    case StoreName.INSPECTION_ITEM:
      return PATH_API.INSPECTION_ITEM;

    case StoreName.INSPECTION_PIN:
      return PATH_API.INSPECTION_PIN;

    case StoreName.INSPECTION_GROUP:
      return PATH_API.INSPECTION_GROUP;

    case StoreName.SUB_ITEMS:
      return PATH_API.SUB_ITEMS;

    case StoreName.USER_SETTINGS:
      return PATH_API.USER_SETTINGS;

    default:
      return storeName;
  }
}

function convertPathAPIToStoreName(pathAPI: string) {
  if (pathAPI.includes(PATH_API.INSPECTION_PIN_GROUP)) {
    return StoreName.INSPECTION_PIN_GROUP;
  }

  if (pathAPI.includes(PATH_API.INSPECTION_PIN_CONTENT)) {
    return StoreName.INSPECTION_PIN_CONTENT;
  }

  if (pathAPI.includes(PATH_API.INSPECTION_CONTENT)) {
    return StoreName.INSPECTION_CONTENT;
  }

  if (pathAPI.includes(PATH_API.SUB_ITEMS)) {
    return StoreName.SUB_ITEMS;
  }

  if (pathAPI.includes(PATH_API.INSPECTION_ITEM_RESULT)) {
    return StoreName.INSPECTION_ITEM_RESULT;
  }

  if (pathAPI.includes(PATH_API.INSPECTION_ITEM)) {
    return StoreName.INSPECTION_ITEM;
  }

  if (pathAPI.includes(PATH_API.INSPECTION_TYPE)) {
    return StoreName.INSPECTION_TYPE;
  }

  if (pathAPI.includes(PATH_API.INSPECTION_PIN)) {
    return StoreName.INSPECTION_PIN;
  }

  if (pathAPI.includes(PATH_API.INSPECTION_GROUP)) {
    return StoreName.INSPECTION_GROUP;
  }

  if (pathAPI.includes(PATH_API.USER_SETTINGS)) {
    return StoreName.USER_SETTINGS;
  }

  return;
}

const removeItemAndRelative = async (args: {
  cachedItemId: string;
  storeNameOfRequest: string;
}) => {
  const { cachedItemId, storeNameOfRequest } = args;

  const indexedDb = await getIndexedDb();
  const cachedItems =
    (await indexedDb?.getAll(DATA_STORE, storeNameOfRequest)) || [];
  const promises = cachedItems.map((item) => {
    if (item.data?.id === cachedItemId) {
      return indexedDb.delete(item.id, DATA_STORE);
    }

    return Promise.resolve(true);
  });

  await Promise.all(promises);
  // clear logs and comments
  if (storeNameOfRequest === StoreName.TASKS) {
    // clear comments
    const cacheTaskComments = ((await indexedDb?.getAll(
      DATA_STORE,
      StoreName.TASK_COMMENTS
    )) || []) as iCachedItem[];
    const promises = cacheTaskComments.map((item) => {
      if (item.data?.taskId === cachedItemId) {
        return indexedDb.delete(item.id);
      }

      return Promise.resolve(true);
    });
    await Promise.all(promises);
  }
  // now we only care doc items not care  (offline mode not allow delete doc group and doc category)
  if (
    [StoreName.DOCUMENT_ITEMS, StoreName.SUB_ITEMS].includes(
      storeNameOfRequest as any
    )
  ) {
    const isStoreDocItem = storeNameOfRequest === StoreName.DOCUMENT_ITEMS;
    const setDeleteSubItemId: Set<string> = new Set([]);
    if (isStoreDocItem) {
      const cacheSubItems = ((await indexedDb?.getAll(
        DATA_STORE,
        StoreName.SUB_ITEMS
      )) || []) as iCachedItem[];
      const promiseDeleteSubItems = cacheSubItems.map((item) => {
        if (item.data?.itemId === cachedItemId) {
          setDeleteSubItemId.add(item.data.id);

          return indexedDb.delete(item.id);
        }

        return Promise.resolve(true);
      });
      await Promise.all(promiseDeleteSubItems);
    }
    const isStoreSubItem = storeNameOfRequest === StoreName.SUB_ITEMS;
    // clear comments
    const cacheLogs = ((await indexedDb?.getAll(
      DATA_STORE,
      StoreName.DATA_LOGS
    )) || []) as iCachedItem[];
    const promises = cacheLogs.map((item) => {
      if (
        (item.data?.itemId === cachedItemId && isStoreDocItem) ||
        (setDeleteSubItemId.has(item.data?.subItemId) && isStoreDocItem) ||
        (item.data?.subItemId === cachedItemId && isStoreSubItem)
      ) {
        return indexedDb.delete(item.id);
      }

      return Promise.resolve(true);
    });
    await Promise.all(promises);
    const cacheBlackBoards = ((await indexedDb?.getAll(
      DATA_STORE,
      StoreName.BLACKBOARDS
    )) || []) as iCachedItem[];

    const promiseBlackboards = cacheBlackBoards.map((item) => {
      if (
        (setDeleteSubItemId.has(item.data?.subItemId) && isStoreDocItem) ||
        (item.data?.subItemId === cachedItemId && isStoreSubItem)
      ) {
        return indexedDb.delete(item.id);
      }

      return Promise.resolve(true);
    });
    await Promise.all(promiseBlackboards);
  }
};

export const ServiceWorker = {
  convertStoreNameToPathAPI,
  convertPathAPIToStoreName,
  removeItemAndRelative,
};
