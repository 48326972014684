import { Icon, IconProps } from "@chakra-ui/icons";

const PlusThinIcon = (props: IconProps) => {
  return (
    <Icon width="1em" height="1em" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.83149 1.49935C9.83149 1.03911 9.45839 0.666016 8.99815 0.666016C8.53791 0.666016 8.16482 1.03911 8.16482 1.49935V8.16678H1.4974C1.03716 8.16678 0.664063 8.53987 0.664063 9.00011C0.664062 9.46035 1.03716 9.83345 1.4974 9.83345H8.16482V16.5008C8.16482 16.9611 8.53791 17.3342 8.99815 17.3342C9.45839 17.3342 9.83149 16.9611 9.83149 16.5008V9.83345H16.4989C16.9591 9.83345 17.3322 9.46035 17.3322 9.00011C17.3322 8.53987 16.9591 8.16678 16.4989 8.16678H9.83149V1.49935Z"
        fill="currentcolor"
      />
    </Icon>
  );
};

export default PlusThinIcon;
