import { getInspectionGroupList } from "apiClient/v2/inspection/inspectionGroupApi";
import { getAllInspectionTypeWithCursor } from "apiClient/v2/inspection/inspectionTypeApi";
import { Operation, StoreName } from "constants/serviceWorker";
import { iCachedItem } from "interfaces/models/serviceWorker";

export const refreshCachedData = (item: iCachedItem): Promise<any> => {
  switch (item.operation) {
    case Operation.Patch: {
      switch (item.store) {
        case StoreName.INSPECTION_TYPE: {
          if (!item?.data?.bimFileId) {
            return Promise.resolve();
          }

          return getAllInspectionTypeWithCursor({
            bimFileId: encodeURIComponent(item.data.bimFileId),
          });
        }
        default:
          return Promise.resolve();
      }
    }

    case Operation.Post:
      switch (item.store) {
        case StoreName.INSPECTION_GROUP: {
          if (!item?.data?.inspectionTypeId) {
            return Promise.resolve();
          }

          return getInspectionGroupList({
            inspectionTypeId: item?.data?.inspectionTypeId,
          });
        }
        default:
          return Promise.resolve();
      }

    default:
      return Promise.resolve();
  }
};
