import { IconProps, Icon } from "@chakra-ui/react";

const TyphographyIcon: React.FC<IconProps> = (props) => {
  return (
    <Icon width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M7.81841 17.875C7.54875 17.875 7.31844 17.7807 7.12747 17.5922C6.9365 17.4037 6.84101 17.1747 6.84101 16.9054V6.07292H3.27128C2.99949 6.07292 2.76849 5.97797 2.57828 5.78806C2.38807 5.59816 2.29297 5.36754 2.29297 5.09621C2.29297 4.82488 2.38723 4.59517 2.57576 4.4071C2.76429 4.21903 2.99323 4.125 3.26257 4.125H12.3587C12.6304 4.125 12.8614 4.21934 13.0517 4.40802C13.2419 4.59685 13.337 4.82602 13.337 5.09552C13.337 5.36517 13.2427 5.59549 13.0542 5.78646C12.8657 5.97743 12.6368 6.07292 12.3676 6.07292H8.78893V16.8967C8.78893 17.1685 8.69459 17.3995 8.50591 17.5897C8.31723 17.7799 8.08806 17.875 7.81841 17.875ZM16.0068 17.875C15.7371 17.875 15.5082 17.7807 15.3202 17.5922C15.1323 17.4037 15.0383 17.1747 15.0383 16.9054V10.6122H13.2843C13.0125 10.6122 12.7815 10.5172 12.5913 10.3272C12.4009 10.1373 12.3057 9.90665 12.3057 9.63531C12.3057 9.36398 12.4 9.13435 12.5885 8.94644C12.777 8.75837 13.006 8.66433 13.2753 8.66433H18.7313C19.0031 8.66433 19.2341 8.75867 19.4243 8.94735C19.6145 9.13603 19.7096 9.3652 19.7096 9.63485C19.7096 9.90451 19.6154 10.1348 19.4268 10.3258C19.2383 10.5168 19.0094 10.6122 18.74 10.6122H16.9773V16.9054C16.9773 17.1747 16.8829 17.4037 16.6943 17.5922C16.5054 17.7807 16.2763 17.875 16.0068 17.875Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export default TyphographyIcon;
