import dayjs from "dayjs";
import { Box, Text } from "@chakra-ui/react";
import { TASK_PRINT_MODE } from "constants/enum";
import { TaskLogTypeKey } from "constants/task";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import {
  DocumentItemDTO,
  DocumentSubItemDTO,
} from "interfaces/dtos/documentItemDTO";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { DocumentGroup } from "interfaces/models/documentGroup";
import { ReactNode } from "react";
import { DATE_FORMAT, formatDate } from "utils/date";
import { Blackboard } from "interfaces/models/blackboard";
import { MODERN_SCREENSHOT_WORKER_URL } from "constants/downloadPDF";
import { domToJpeg } from "modern-screenshot";

export const DEFAULT_FIELD_COLOR = "font.blue";
export const DEFAULT_VALUE_COLOR = "green";

interface PropsTitleCommon {
  title: string;
  fieldColor?: string;
  valueColor?: string;
}

export const MapTitleForPrintMode = {
  [TASK_PRINT_MODE.PRINTABLE]: "印刷対象",
  [TASK_PRINT_MODE.NOT_PRINTABLE]: "印刷対象外",
};

export enum ENTITY_NAME {
  FOLDER = "フォルダ",
  TASK = "指摘",
  DOCUMENT = "書類",
  PIN = "ピン",
}

export enum ACTION_CHANGE_DATA {
  REGISTER = "登録",
  ADD_MORE = "追加",
  CREATE = "作成",
  EDIT = "変更",
  CLEAR = "クリア",
  CHANGE = "編集",
  DELETE = "削除",
  MOVE = "移動",
  DISPLAY = "表示",
  COMMENT = "コメント",
  CHECK = "チェック",
}

export enum DocumentLogTypeKey {
  DELETE_SUB_ITEM = "deleteSubItem",
  CREATE_SUB_ITEM = "createSubItem",
  CREATE_TASK = "createTask",
  BLACKBOARD = "blackboard",
  // memo of sub item self inspection
  TREATMENT_CONTENT = "treatmentContent",
  // status of sub item self inspection
  // group name
  NAME = "name",
}

export const MapTitleCommentForTaskLog: {
  [k in keyof Partial<TaskDTO> | string]: PropsTitleCommon;
} = {
  image: {
    title: "写真",
  },
  audio: {
    title: "録音",
  },
  attach: {
    title: "資料",
  },
  position: {
    title: "指摘の位置",
  },
};

export const MapTitleForTask: {
  [k in keyof Partial<TaskDTO> | string]: PropsTitleCommon;
} = {
  task: {
    title: "指摘",
  },
  taskDate: {
    title: "指摘日",
  },
  position: {
    title: "位置",
  },
  status: {
    title: "ステータス",
  },
  taskTypeId: {
    title: "タイトル",
  },
  tags: {
    title: "タグ",
  },
  printMode: {
    title: "印刷モード",
  },
  images: {
    title: "指摘写真",
  },
  memo: {
    title: "指摘内容",
  },
  userAssigned: {
    title: "高砂担当者",
  },
  deadline: { title: "完了期限" },
  partnerCompanyId: {
    title: "協力会社",
  },
  confirmedImages: { title: "是正写真" },
  confirmedMemo: { title: "是正内容" },
  endDateScheduled: {
    title: "是正完了日",
  },
  confirmedDateTime: { title: "高砂確認日" },
  userConfirmed: { title: "高砂確認者" },
  attaches: {
    title: "添付資料",
  },
};

export const MapCommonTaskLogType = {
  [TaskLogTypeKey.CREATE_TASK]: {
    title: MapTitleForTask.task.title,
    action: ACTION_CHANGE_DATA.CREATE,
  },
  [TaskLogTypeKey.ADD_COMMENT]: {
    title: "",
    action: ACTION_CHANGE_DATA.COMMENT,
  },
  [TaskLogTypeKey.ADD_COMMENT_IMAGE]: {
    title: MapTitleCommentForTaskLog.image.title,
    action: ACTION_CHANGE_DATA.REGISTER,
  },
  [TaskLogTypeKey.ADD_COMMENT_AUDIO]: {
    title: MapTitleCommentForTaskLog.audio.title,
    action: ACTION_CHANGE_DATA.REGISTER,
  },
  [TaskLogTypeKey.ADD_COMMENT_FILE]: {
    title: MapTitleCommentForTaskLog.attach.title,
    action: ACTION_CHANGE_DATA.REGISTER,
  },
  [TaskLogTypeKey.MOVE_TASK]: {
    title: MapTitleCommentForTaskLog.position.title,
    action: ACTION_CHANGE_DATA.MOVE,
  },
};

export type KeyMapCommonTaskLogType = keyof typeof MapCommonTaskLogType;

export type TypeMapTitleForDocument =
  | { [key: string]: PropsTitleCommon }
  | {
      [k in
        | keyof Partial<DocumentGroup>
        | keyof Partial<DocumentCategoryDTO>
        | keyof Partial<DocumentItemDTO>
        | keyof Partial<DocumentSubItemDTO | any>]?: PropsTitleCommon;
    };

export const MapTitleForDocumentGroup: TypeMapTitleForDocument = {
  name: {
    title: "フォルダ名",
  },
};

export const MapTitleForDocument: TypeMapTitleForDocument = {
  isShowBlackboard: {
    title: "黒板の表示",
  },
  name: {
    title: "タイトル",
  },
  title: {
    title: "タイトル",
  },
  status: {
    title: "ステータス",
  },
  level: {
    title: "階",
  },
  userAssigned: {
    title: "書類担当者",
  },
  images: {
    title: "写真",
  },
  attaches: {
    title: "添付資料",
  },
  treatmentContent: {
    title: "処置内容",
  },
  blackboard: {
    title: "黒板",
  },
  checkbox: {
    title: "チェックボックス",
  },
  isHidePin: {
    title: "ピン",
  },
};

export type KeyMapTitleForDocument = keyof typeof MapTitleForDocument;
export const MapCommonDocumentLogType = {
  [DocumentLogTypeKey.DELETE_SUB_ITEM]: {
    title: MapTitleForDocument.images?.title,
    action: ACTION_CHANGE_DATA.DELETE,
  },
  [DocumentLogTypeKey.CREATE_SUB_ITEM]: {
    title: MapTitleForDocument.images?.title,
    action: ACTION_CHANGE_DATA.CREATE,
  },
  [DocumentLogTypeKey.CREATE_TASK]: {
    title: MapTitleForTask.task.title,
    action: ACTION_CHANGE_DATA.CREATE,
  },
  [DocumentLogTypeKey.TREATMENT_CONTENT]: {
    title: MapTitleForDocument.treatmentContent!.title,
    action: ACTION_CHANGE_DATA.EDIT,
  },
  [DocumentLogTypeKey.BLACKBOARD]: {
    title: MapTitleForDocument.blackboard!.title,
    action: ACTION_CHANGE_DATA.EDIT,
  },
};

export type KeyMapCommonDocumentLogType = keyof typeof MapCommonDocumentLogType;

export const renderSuffixRevert = (isRevertDataByLog?: boolean) => {
  return isRevertDataByLog ? (
    <>
      (
      <Text as="span" color="font.danger">
        復元された
      </Text>
      )
    </>
  ) : (
    ""
  );
};

export const getTextChangeImage = (
  action: string,
  fieldColor: string | undefined,
  labelOfField: string
) => {
  let description: ReactNode = "";
  if (action !== ACTION_CHANGE_DATA.DELETE) {
    description = (
      <>
        <Text display="inline-block" color={fieldColor || DEFAULT_FIELD_COLOR}>
          {labelOfField}
        </Text>
        の状態が以下になります。
      </>
    );
  }

  return description;
};

export const getCommonFormatLog = (params: {
  action: string;
  changeValue: ReactNode;
  fieldColor?: string;
  valueColor?: string;
  labelOfField: string;
  moreText?: ReactNode;
  isRevertDataByLog?: boolean;
  afterLabel?: string;
}) => {
  return (
    <Box display="inline" mx="1rem">
      が
      <Text as="span" color={params.fieldColor || DEFAULT_FIELD_COLOR}>
        {params.labelOfField}
      </Text>
      {params?.afterLabel ?? "を"}
      <Text as="span" color={params.valueColor || DEFAULT_VALUE_COLOR}>
        {params.changeValue}
      </Text>
      {params.action}
      {params.moreText || ""}
      {renderSuffixRevert(params.isRevertDataByLog)}
    </Box>
  );
};

export const getDateDisplay = (value: string) => {
  return dayjs(value).isValid() ? formatDate(value, DATE_FORMAT) : "";
};

export const snapShotBlackboard = async (params: {
  elementId: string;
  isMobile?: boolean;
}) => {
  const { elementId, isMobile } = params;
  const blackboardEle = document.getElementById(elementId);
  if (!blackboardEle) {
    return;
  }

  return await domToJpeg(blackboardEle, {
    scale: 2,
    font: !isMobile as any,
    workerUrl: MODERN_SCREENSHOT_WORKER_URL,
    onCloneNode: (clone) => {
      const textAreas = $(clone).find("textarea");
      const menuElement = $(clone).find("div[role='menu']");
      const dateElement = $(clone).find(".date-picker-calendar");
      dateElement.css("display", "none");
      menuElement.css("display", "none");

      textAreas.each((index) => {
        const ele = textAreas[index];
        const textContent = ele?.childNodes?.[0]?.textContent || "";
        ele.textContent = textContent;
      });
    },
  });
};
