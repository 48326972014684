import { Box, Flex, Input, Spinner } from "@chakra-ui/react";
import FieldNumberPreviewWrapper from "../../../components/FieldNumberPreviewWrapper";
import { DataType } from "../../../setup/define";
import { FieldPreviewProps } from "../../types";
import { useInputValue } from "../../../hooks/useInputValue";
import { SOURCE } from "components/containers/InspectionFormat/Content/SettingPinLayoutFrame/context/pinLayoutFrameReducer";
import { DroppableBox } from "components/containers/InspectionFormat/containers/LayoutFrameBuilder";
import CreateTaskActiveIcon from "components/icon/CreateTaskActiveIcon";
import TyphographyIcon from "components/icon/TyphographyIcon";
import FloatNumberIcon from "components/icon/FloatNumberIcon";
import NumbericIcon from "components/icon/NumericIcon";

interface InputPreviewerProps extends FieldPreviewProps<string> {}

const InputPreviewer: React.FC<InputPreviewerProps> = ({
  item,
  value,
  onChange,
  isLoading,
  isReadOnly,
  isDraggable,
}) => {
  const controller = useInputValue({ value, onChange, isReadOnly });

  if (isDraggable) {
    return (
      <FieldNumberPreviewWrapper title={item.name}>
        <DroppableBox
          id={item.id}
          flex={1}
          name={item.name}
          icon={
            item.dataType === DataType.NUMBER_INT ? (
              <NumbericIcon w="3rem" h="3rem" />
            ) : (
              <FloatNumberIcon w="3rem" h="3rem" />
            )
          }
          source={SOURCE.INSPECTION_FORM}
        >
          <Input
            className="draggable-area"
            isReadOnly={isReadOnly}
            backgroundColor="white"
            height="4rem"
            maxWidth="27rem"
            placeholder={item.dataType === DataType.NUMBER_INT ? "0" : "0.0"}
            textAlign="right"
          />
        </DroppableBox>
      </FieldNumberPreviewWrapper>
    );
  }

  return (
    <FieldNumberPreviewWrapper title={item.name}>
      <Box position={"relative"}>
        <Input
          isReadOnly={isReadOnly}
          textAlign="right"
          placeholder={item.dataType === DataType.NUMBER_INT ? "0" : "0.0"}
          backgroundColor="white"
          height="4rem"
          width="31.5rem"
          maxW={"100%"}
          type="number"
          color="#171717"
          fontSize={"1.6rem"}
          _readOnly={{
            cursor: "pointer",
          }}
          {...controller}
          onKeyPress={(evt) => {
            if (evt.key === "e") {
              evt.preventDefault();
            }
          }}
          onChange={(e) => {
            const value = e.target.value;
            if (
              value &&
              item.dataType === DataType.NUMBER_INT &&
              !/^-?[0-9]+$/.test(value)
            ) {
              return;
            }

            controller.onChange(value);
          }}
          paddingRight={isLoading ? "3rem" : "1rem"}
        />
        {isLoading && (
          <Flex
            justifyContent={"center"}
            alignItems={"center"}
            position={"absolute"}
            right={0}
            top={0}
            w={"3rem"}
            h="100%"
            zIndex={10}
          >
            <Spinner />
          </Flex>
        )}
      </Box>
    </FieldNumberPreviewWrapper>
  );
};

export default InputPreviewer;
