import { Icon, IconProps } from "@chakra-ui/icons";

const DeleteIcon = (props: IconProps) => {
  return (
    <Icon width="1em" height="1em" viewBox="0 0 22 22" fill="none" {...props}>
      <path
        d="M6.69877 18.7914C6.24303 18.7914 5.85292 18.6292 5.52842 18.3047C5.20392 17.9802 5.04167 17.5901 5.04167 17.1343V5.49976H4.8125C4.61771 5.49976 4.45447 5.43383 4.32277 5.30199C4.19092 5.17014 4.125 5.00682 4.125 4.81203C4.125 4.61708 4.19092 4.45384 4.32277 4.3223C4.45447 4.1906 4.61771 4.12476 4.8125 4.12476H8.25C8.25 3.90033 8.32906 3.70905 8.48719 3.55092C8.64516 3.39295 8.83636 3.31396 9.06079 3.31396H12.9392C13.1636 3.31396 13.3548 3.39295 13.5128 3.55092C13.6709 3.70905 13.75 3.90033 13.75 4.12476H17.1875C17.3823 4.12476 17.5455 4.19068 17.6772 4.32253C17.8091 4.45437 17.875 4.61769 17.875 4.81249C17.875 5.00743 17.8091 5.17067 17.6772 5.30221C17.5455 5.43391 17.3823 5.49976 17.1875 5.49976H16.9583V17.1343C16.9583 17.5901 16.7961 17.9802 16.4716 18.3047C16.1471 18.6292 15.757 18.7914 15.3012 18.7914H6.69877ZM15.5833 5.49976H6.41667V17.1343C6.41667 17.2167 6.4431 17.2843 6.49596 17.3371C6.54882 17.39 6.61642 17.4164 6.69877 17.4164H15.3012C15.3836 17.4164 15.4512 17.39 15.504 17.3371C15.5569 17.2843 15.5833 17.2167 15.5833 17.1343V5.49976ZM9.30806 15.5831C9.50285 15.5831 9.6661 15.5172 9.79779 15.3855C9.92933 15.2537 9.9951 15.0904 9.9951 14.8956V8.02059C9.9951 7.8258 9.92918 7.66248 9.79733 7.53063C9.66564 7.39894 9.50232 7.33309 9.30738 7.33309C9.11258 7.33309 8.94934 7.39894 8.81765 7.53063C8.6861 7.66248 8.62033 7.8258 8.62033 8.02059V14.8956C8.62033 15.0904 8.68618 15.2537 8.81787 15.3855C8.94972 15.5172 9.11312 15.5831 9.30806 15.5831ZM12.6926 15.5831C12.8874 15.5831 13.0507 15.5172 13.1824 15.3855C13.3139 15.2537 13.3797 15.0904 13.3797 14.8956V8.02059C13.3797 7.8258 13.3138 7.66248 13.1821 7.53063C13.0503 7.39894 12.8869 7.33309 12.6919 7.33309C12.4971 7.33309 12.3339 7.39894 12.2022 7.53063C12.0707 7.66248 12.0049 7.8258 12.0049 8.02059V14.8956C12.0049 15.0904 12.0708 15.2537 12.2027 15.3855C12.3344 15.5172 12.4977 15.5831 12.6926 15.5831Z"
        fill="currentcolor"
      />
    </Icon>
  );
};

export default DeleteIcon;
