import intersection from "lodash/intersection";
import {
  InspectionItemType,
  MapInspectionItemColor,
  ModalType,
  Priority,
  PriorityLabel,
  TASK_PRINT_MODE,
} from "constants/enum";
import { MAX_SIZE_IMAGE } from "constants/forge";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { DocumentTask } from "interfaces/models/documentTask";
import { UserSetting } from "interfaces/models/user";
import { SettingsFromURL } from "pages/forge-viewer";
import { Dispatch } from "redux";
import { setIsMoveTaskLabel, setModalType } from "redux/forgeViewerSlice";
import { setTaskSelected } from "redux/taskSlice";
import {
  clearForgeSelection,
  getCurrentViewer,
  selectDbIds,
} from "utils/forge";
import {
  clearSelectedLabel,
  selectLabel,
} from "utils/forge/extensions/custom-label";
import { checkIsBetween, formatDate, now } from "utils/date";
import store from "redux/store";
import { isEmpty } from "lodash";
import { getAreaExtension } from "utils/forge/extensions/area-extension";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { setDocumentCategorySelected } from "redux/documentSlice";

export const configImageCompress = {
  maxSizeMB: 1.5,
  maxWidthOrHeight: MAX_SIZE_IMAGE,
  useWebWorker: true,
};

export const handleSelectTask = (
  task: TaskDTO,
  dispatch: Dispatch,
  autoClearHighlight = true
) => {
  const isCreateTask = store.getState().forgeViewer.isCreateTask;
  const isMoveTaskLabel = store.getState().forgeViewer.isMoveTaskLabel;
  const modalType = store.getState().forgeViewer.modalType;
  const state = store.getState()?.task;

  const { taskSelected } = state;

  dispatch(setDocumentCategorySelected());
  clearSelectedLabel();
  getAreaExtension()?.clearSelection();
  if (
    task?.id === taskSelected?.id &&
    !isEmpty(taskSelected) &&
    autoClearHighlight
  ) {
    (getCurrentViewer()?.utilities as any).goHome();
    dispatch(setTaskSelected());
    selectDbIds([], {});
    if (!isCreateTask) {
      clearForgeSelection();
    }

    return;
  }

  if (isMoveTaskLabel) {
    dispatch(setIsMoveTaskLabel(false));
  }
  if (task.id) {
    selectLabel([task.id]);
  }
  dispatch(setTaskSelected(task));
  if (modalType !== ModalType.TASK) {
    dispatch(setModalType(ModalType.TASK));
  }
  selectDbIds(task.externalId, {
    color:
      MapInspectionItemColor[
        (task.status || InspectionItemType.Defect) as InspectionItemType
      ],
  });
};

export const getTasksFromFilter = ({
  tasks,
  mapTaskType,
  documentTasks,
  settings,
  searchValue,
  documentCategories,
  taskIdsCreated,
}: {
  mapTaskType: Map<string, string>;
  tasks: TaskDTO[];
  documentTasks: DocumentTask[];
  settings: UserSetting & SettingsFromURL;
  searchValue: string;
  documentCategories: DocumentCategoryDTO[];
  taskIdsCreated: Array<string>;
}) => {
  if (!tasks.length) {
    return [];
  }

  const taskIds = Array.from(
    new Set(
      documentTasks
        ?.filter((doc) => settings?.documentTaskIds?.includes(doc.id))
        .map((doc) => doc.taskIds)
        .flat(1)
    )
  );

  const searchValueUppercase = (searchValue || "").toUpperCase();

  return tasks.filter((task) => {
    if (taskIdsCreated.includes(task.id)) {
      return true;
    }
    const taskTypeTitle = mapTaskType?.get(task.taskTypeId || "");
    const isHasTaskFromDocumentTasks =
      !settings?.documentTaskIds?.length || taskIds?.includes(task.id);
    const filteredByAssignedUser = !settings.userAssigned?.length
      ? true
      : settings.userAssigned?.some((id) => {
          if (id) {
            return task.userAssigned?.includes(id);
          }

          return !task.userAssigned;
        });
    const filteredByCreatedUser =
      !settings.userCreated?.length ||
      (task.userCreated && settings.userCreated?.includes(task.userCreated));

    const filteredByFamily = settings.objectTypes?.length
      ? intersection(settings.objectTypes, task.objectTypes).length
      : true;

    const filteredByContents =
      !settings.contents?.length ||
      (taskTypeTitle && settings.contents?.includes(task.taskTypeId || ""));

    const filteredByStatus = settings.status?.includes(
      task.status || InspectionItemType.Defect
    );

    const filteredByImageStatus =
      settings.hasImage === settings.hasNoImage
        ? true
        : (settings.hasImage && task.images?.length) ||
          (settings.hasNoImage && !task.images?.length);

    const filteredByTags =
      !settings?.tags?.length ||
      (task.tags && task.tags.some((tag) => settings.tags.includes(tag)));

    const filteredBySearchText =
      !searchValue ||
      String(task.indexId).includes(searchValue) ||
      formatDate(task.deadline, "YYYY/MM/DD").includes(searchValue) ||
      PriorityLabel[(task.priority || Priority.High) as Priority]
        ?.toUpperCase()
        .includes(searchValueUppercase) ||
      (taskTypeTitle?.toUpperCase() || "-").includes(searchValueUppercase);

    const filteredByTaskDate =
      settings?.taskDateFrom || settings?.taskDateTo
        ? checkIsBetween({
            targetDate: task?.taskDate,
            startDate: settings?.taskDateFrom,
            endDate: settings?.taskDateTo,
          })
        : true;

    const filteredByDeadline =
      settings?.deadlineFrom || settings?.deadlineTo
        ? checkIsBetween({
            targetDate: task?.deadline,
            startDate: settings?.deadlineFrom,
            endDate: settings?.deadlineTo,
          })
        : true;

    const filteredByDefaultDeadline = !settings?.defaultDeadline
      ? true
      : !task?.deadline;

    const filteredByPartnerCompany = !settings?.partnerCompanies?.length
      ? true
      : settings?.partnerCompanies.some((id) => {
          if (id) {
            return task?.partnerCompanyId === id;
          }

          return !task?.partnerCompanyId;
        });

    const filteredByPrintMode =
      !settings?.taskPrintMode ||
      settings.taskPrintMode === TASK_PRINT_MODE.ALL ||
      task.printMode === undefined
        ? true
        : task.printMode === settings.taskPrintMode;

    const filterByDocumentCategories =
      !settings?.taskCategoryIds?.length ||
      (task.documentItemId &&
        documentCategories.find(
          (e) =>
            (e.itemIds || e.selectedExternalIds)?.includes(
              task.documentItemId!
            ) && settings.taskCategoryIds!.includes(e.id)
        ));

    return (
      isHasTaskFromDocumentTasks &&
      filteredByAssignedUser &&
      filteredByCreatedUser &&
      filteredByStatus &&
      filteredByContents &&
      filteredByImageStatus &&
      filteredByFamily &&
      filteredByTags &&
      filteredBySearchText &&
      filteredByDeadline &&
      filteredByTaskDate &&
      filteredByPartnerCompany &&
      filteredByPrintMode &&
      filteredByDefaultDeadline &&
      filteredByPrintMode &&
      filterByDocumentCategories
    );
  });
};

export const getUpdatesWhenChangeDay = (
  task: TaskDTO,
  field: string,
  day: Date | string
) => {
  const updates: Partial<TaskDTO> = {
    [field]: day.toString(),
  };
  if (
    task.status !== InspectionItemType.Treated &&
    !!day &&
    field === "endDateScheduled"
  ) {
    updates.status = InspectionItemType.Treated;
  }
  if (
    task.status !== InspectionItemType.Confirmed &&
    !!day &&
    field === "confirmedDateTime"
  ) {
    updates.status = InspectionItemType.Confirmed;
  }

  return updates;
};

export const getUpdatesWhenChangeStatus = (
  task: TaskDTO,
  status: string,
  userId: string
) => {
  const updates: Partial<TaskDTO> = {
    status,
  };
  const isDiffCurrentStatus = (statusCompare: string) =>
    task.status !== status && status === statusCompare;

  if (isDiffCurrentStatus(InspectionItemType.Confirmed)) {
    if (!task?.confirmedDateTime) {
      updates.confirmedDateTime = now();
    }
    if (task?.userConfirmed !== userId) {
      updates.userConfirmed = userId;
    }
  }

  if (isDiffCurrentStatus(InspectionItemType.Treated)) {
    if (task?.userTreated !== userId) {
      updates.userTreated = userId;
    }
    if (!task.endDateScheduled) {
      updates.endDateScheduled = now();
    }
  }
  if (isDiffCurrentStatus(InspectionItemType.Defect)) {
    if (task.userTreated) {
      updates.userTreated = undefined;
    }
    if (task.treatedComment) {
      updates.treatedComment = undefined;
    }
  }

  return updates;
};
