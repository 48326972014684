import {
  ImageNodeAttrs,
  TaskItemNodeAttrs,
  TaskListNodeAttrs,
} from "components/TipTapEditorDoc/extendsions";
import { CustomNode, NodeType } from "components/TipTapEditorDoc/type";
import { getContentNodeTypeNormalText } from "components/TipTapEditorDoc/utils";
import { CheckboxDirectionOptionsEnum } from "constants/enum";
import { GetContentPreview } from "interfaces/models/inspectionDocument";
import {
  AdditionInfo,
  InspectionItemResult,
} from "interfaces/models/inspectionItemResult";
import { DEFAULT_CHECKBOX_ADDITIONAL_INFO } from "../context/FormBuilderContext/types";
import {
  CheckboxEditor,
  CheckboxPreviewer,
  CheckboxRaw,
  ImageEditor,
  ImagePreviewer,
  ImageRaw,
  InputEditor,
  InputNumberEditor,
  InputNumberPreviewer,
  InputNumberRaw,
  InputPreviewer,
  InputRaw,
} from "../fields";
import ImageDroppable from "../fields/image/droppable";
import withReviewer from "../hooks/withReviewer";
import { Flex, Text } from "@chakra-ui/react";
import CheckboxIcon from "components/icon/CheckboxIcon";
import { ReactElement } from "react";
import NumbericIcon from "components/icon/NumericIcon";
import FloatNumberIcon from "components/icon/FloatNumberIcon";
import TyphographyIcon from "components/icon/TyphographyIcon";
import ImageIcon from "components/icon/ImageIcon";

export enum DataType {
  IMAGE,
  TEXT,
  NUMBER_INT,
  NUMBER_FLOAT,
  CHECKBOX,
}

export const DataTypeNumber = [DataType.NUMBER_INT, DataType.NUMBER_FLOAT];

type Types = {
  [key in DataType]: {
    name: string;
    editor: React.FC<any>;
    previewer: React.FC<any>;
    droppable?: React.FC<any>;
    raw: React.FC<any>;
    getContentPreview: GetContentPreview;
    getInitData(
      item: Partial<InspectionItemResult>
    ): Partial<InspectionItemResult>;
    clearInitData(
      item: Partial<InspectionItemResult>
    ): Partial<InspectionItemResult>;
    icon?: ReactElement;
  };
};

export const types: Types = {
  [DataType.IMAGE]: {
    name: "写真",
    editor: ImageEditor,
    raw: ImageRaw,
    previewer: withReviewer(ImagePreviewer),
    droppable: withReviewer(ImageDroppable),
    icon: <ImageIcon w="1.5rem" h="1.5rem" />,
    getInitData: () => {
      return {};
    },
    clearInitData: () => {
      return {};
    },
    getContentPreview: (_, params) => {
      const { pinContent } = params;
      const updatedAt = pinContent?.updatedAt;
      const content = {
        type: NodeType.IMAGE,
        attrs: { src: pinContent?.value?.data?.src },
      } satisfies CustomNode<ImageNodeAttrs>;

      return { updatedAt, content };
    },
  },
  [DataType.TEXT]: {
    name: "テキスト",
    editor: InputEditor,
    raw: InputRaw,
    previewer: withReviewer(InputPreviewer),
    getInitData: () => ({}),
    clearInitData: () => ({}),
    icon: <TyphographyIcon w="1.5rem" h="1.5rem" />,
    getContentPreview: (_, params) => {
      const { pinContent, component } = params;
      const updatedAt = pinContent?.updatedAt;
      const content = getContentNodeTypeNormalText({
        text: pinContent?.value?.data || "",
        style: component?.style,
      });

      return { updatedAt, content };
    },
  },
  [DataType.NUMBER_INT]: {
    name: "数値 (整数)",
    editor: InputNumberEditor,
    raw: InputNumberRaw,
    icon: <NumbericIcon w="1.5rem" h="1.5rem" />,
    previewer: withReviewer(InputNumberPreviewer),
    getInitData: () => ({}),
    clearInitData: () => ({}),
    getContentPreview: (_, params) => {
      const { pinContent, component } = params;
      const updatedAt = pinContent?.updatedAt;

      const content = getContentNodeTypeNormalText({
        text: pinContent?.value?.data || "",
        style: component?.style,
        isInt: true,
      });

      return { updatedAt, content };
    },
  },
  [DataType.NUMBER_FLOAT]: {
    name: "数値 (実数)",
    editor: InputNumberEditor,
    raw: InputNumberRaw,
    icon: <FloatNumberIcon w="1.5rem" h="1.5rem" />,
    previewer: withReviewer(InputNumberPreviewer),
    getInitData: () => ({}),
    clearInitData: () => ({}),
    getContentPreview: (_, params) => {
      const { pinContent, component } = params;
      const updatedAt = pinContent?.updatedAt;
      const content = getContentNodeTypeNormalText({
        text: pinContent?.value?.data || "",
        style: component?.style,
        isFloat: true,
      });

      return { updatedAt, content };
    },
  },
  [DataType.CHECKBOX]: {
    name: "判定",
    editor: CheckboxEditor,
    raw: CheckboxRaw,
    previewer: withReviewer(CheckboxPreviewer),
    icon: <CheckboxIcon w="1.5rem" h="1.5rem" />,
    getInitData: (item) => {
      const additionInfo: AdditionInfo = {
        ...item.additionInfo,
        checkbox: DEFAULT_CHECKBOX_ADDITIONAL_INFO,
      };

      return {
        additionInfo: structuredClone(additionInfo),
      } satisfies Partial<InspectionItemResult>;
    },
    clearInitData: (item) => {
      const { checkbox, ...additionInfo } = item?.additionInfo || {};

      return {
        additionInfo: structuredClone(additionInfo),
      } satisfies Partial<InspectionItemResult>;
    },
    getContentPreview: (_, params) => {
      const { pinContent, component, itemResult, pinDetail } = params;
      const linkedData = component?.linkedData;
      const displayValue =
        itemResult?.additionInfo?.checkbox?.mapValues?.[
          `${linkedData?.extraId}`
        ]?.displayValue;

      const updatedAt = pinContent?.updatedAt;
      const content = {
        type: NodeType.TASK_LIST,
        attrs: {
          direction: CheckboxDirectionOptionsEnum.HORIZONTAL,
          type: "item",
          alignItems: component?.style?.alignItems,
        },
        content: [
          {
            type: NodeType.TASK_ITEM,
            attrs: {
              checked:
                pinContent?.value?.data &&
                pinContent?.value?.data == linkedData?.extraId,
              style: component?.style,
              groupId: `${pinDetail?.id}/${linkedData?.key}`,
              key: String(linkedData?.extraId),
            },
            content: displayValue
              ? ([
                  {
                    type: NodeType.TEXT,
                    text: displayValue,
                  },
                ] as any)
              : [],
          },
        ] satisfies CustomNode<TaskItemNodeAttrs>[],
      } satisfies CustomNode<TaskListNodeAttrs>;

      return { updatedAt, content };
    },
  },
} satisfies Types;

export const typeOptions = Object.keys(types).map((value: string) => {
  const field = (types as any)[value];

  return {
    value: Number(value),
    title: field.name,
    label: (
      <Flex alignItems={"center"} gap="0.5rem">
        {field.icon}
        <Text>{field.name}</Text>
      </Flex>
    ),
  };
});

export const defaultType = DataType.TEXT;
