import { Flex, Text } from "@chakra-ui/react";
import AvatarUser from "components/AvatarUser";
import { TaskLogTypeKey } from "constants/task";
import dayjs from "dayjs";
import { UserDTO } from "interfaces/dtos/UserDTO";
import { memo } from "react";
import { MapCommonTaskLogType } from "utils/data-logs";
import { formatDate } from "utils/date";

interface Props {
  userCreatedTask: UserDTO | null;
  taskCreatedAt: string | undefined;
}

const RecordCommentDefault = (props: Props) => {
  const { userCreatedTask, taskCreatedAt } = props;
  const logTypeInfo = MapCommonTaskLogType[TaskLogTypeKey.CREATE_TASK];

  return (
    <Flex
      background="#E5E5E5"
      p="0.5rem 1.6rem"
      flexDir="column"
      color="#737373"
    >
      <Flex alignItems="center" mt="0.5rem" color="#737373" gap="1rem">
        <AvatarUser
          w="2.4rem"
          h="2.4rem"
          borderRadius="full"
          objectFit="cover"
          src={userCreatedTask?.avatarBase64}
        />
        <Text fontSize="1rem" as="b">
          {userCreatedTask?.name || "user name"}
        </Text>
        <Text fontSize="1rem" lineHeight="1.5rem" as="b">
          {`${logTypeInfo.title}を${logTypeInfo.action}`}
        </Text>
        <Text fontSize="1rem" lineHeight="1.5rem">
          {dayjs(taskCreatedAt).isValid() ? formatDate(taskCreatedAt) : ""}
        </Text>
      </Flex>
    </Flex>
  );
};

export default memo(RecordCommentDefault);
