import { Option } from "components/containers/InspectionFormat/containers/LayoutFrameBuilder/components/SearchSelection/Item";
import { getContentNodeTypeNormalText } from "components/TipTapEditorDoc/utils";
import { GetContentPreview } from "interfaces/models/inspectionDocument";
import { SOURCE } from "../../context/commonLayoutFrameReducer";
import TyphographyIcon from "components/icon/TyphographyIcon";

export interface FieldRegister {
  id: string;
  name: string;
  icon: any;
  [key: string]: any;
  getContentPreview: GetContentPreview;
}

export type Ctx = {};

export enum LinkedDataAssetKey {
  RVT_FILE_NAME = "rvt_file_name",
  KEYNOTE_VIEW_NAME = "keynote_view_name",
  INSPECTION_TYPE_NAME = "inspection_type_name",
  INSPECTION_PIN_GROUP_NAME = "inspection_pin_group_name",
  FREEWORD_INPUT = "freeword_input",
  LIST_NUMBER = "list_number",
}

export const assetsPartials: FieldRegister[] = [
  {
    id: LinkedDataAssetKey.RVT_FILE_NAME,
    name: "rvtファイル名",
    closeable: false,
    icon: <TyphographyIcon w="1.5rem" h="1.5rem" />,
    getContentPreview: (ctx, params) => {
      const { pageComponent } = params;
      const text = ctx.rvtFileName ?? "";
      const content = getContentNodeTypeNormalText({
        text,
        defaultText: text,
        style: pageComponent?.style,
      });

      return { content, updatedAt: undefined };
    },
  },
  {
    id: LinkedDataAssetKey.KEYNOTE_VIEW_NAME,
    name: "キープラン名",
    closeable: false,
    icon: <TyphographyIcon w="1.5rem" h="1.5rem" />,
    getContentPreview: (ctx, params) => {
      const { pageComponent } = params;
      const text = ctx.keyPlanViewName ?? "";
      const content = getContentNodeTypeNormalText({
        text,
        defaultText: text,
        style: pageComponent?.style,
      });

      return { content, updatedAt: undefined };
    },
  },
  {
    id: LinkedDataAssetKey.INSPECTION_TYPE_NAME,
    name: "検査種別名",
    closeable: false,
    icon: <TyphographyIcon w="1.5rem" h="1.5rem" />,
    getContentPreview: (ctx, params) => {
      const { pageComponent } = params;
      const text = ctx.inspectionTypeName ?? "";

      const content = getContentNodeTypeNormalText({
        text,
        defaultText: text,
        style: pageComponent?.style,
      });

      return { content, updatedAt: undefined };
    },
  },
  {
    id: LinkedDataAssetKey.INSPECTION_PIN_GROUP_NAME,
    name: "ピングループ名",
    closeable: false,
    icon: <TyphographyIcon w="1.5rem" h="1.5rem" />,
    getContentPreview: (ctx, params) => {
      const { pageComponent } = params;
      const text = ctx.inspectionGroupName ?? "";

      const content = getContentNodeTypeNormalText({
        text,
        defaultText: text,
        style: pageComponent?.style,
      });

      return { content, updatedAt: undefined };
    },
  },
  {
    id: LinkedDataAssetKey.FREEWORD_INPUT,
    name: "フリーテキスト欄",
    closeable: false,
    icon: <TyphographyIcon w="1.5rem" h="1.5rem" />,
    getContentPreview: (_, params) => {
      const { pageComponent } = params;
      const content = getContentNodeTypeNormalText({
        text: "",
        style: pageComponent?.style,
      });

      return { content, updatedAt: undefined };
    },
  },
];

export const bimPartials: FieldRegister[] = [];

export const bimOptions: Option[] = bimPartials.map((item) => ({
  value: item.id,
  label: item.name,
  data: item,
}));

// Register all fields using to create pin frame
export const registers = [
  {
    source: SOURCE.ASSET,
    fields: assetsPartials.map(({ getContentPreview, ...item }) => item),
  },
];
