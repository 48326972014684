import { useEffect, useRef } from "react";

type Func = (e: UIEvent) => void;

export const useWindowResize = (onResize: Func) => {
  const ref = useRef(onResize);
  ref.current = onResize;

  useEffect(() => {
    window.addEventListener("resize", ref.current);

    return () => {
      window.removeEventListener("resize", ref.current);
    };
  }, []);
};
