import { ApiResponse, Pagination } from "interfaces/models/api";
import { InspectionContent } from "interfaces/models/inspectionContent";
import { InspectionTarget } from "interfaces/models/InspectionTarget";
import { InspectionType } from "interfaces/models/inspectionType";
import { axiosECS } from "services/baseAxios";
import { sleep } from "utils/common";
import { inspectionTargetApi } from "..";
import { getInspectionGroupList } from "./inspectionGroupApi";
import { getAllPins } from "./pinApi";
import { getInspectionContents } from "./inspectionContentApi";
import { getInspectionTypeViewList } from "../bims/bimViewApi";

const pathV2 = "/v1/inspections/types";
interface GetInspectionTypeReq extends Pagination {
  bimFileId: string;
  name?: string;
  cursor?: string;
  shouldCache?: boolean;
  projectId?: string;
  bimVersionId?: string;
}

export interface CreateInspectionTypeRes {
  inspectionType: InspectionType;
  inspectionTarget: InspectionTarget;
  inspectionContent: InspectionContent;
}

export interface CopyInspectionTypeRes {
  bimFileList: string[];
}

const DEFAULT_LIMIT = 100;

export const getInspectionType = async (
  params: GetInspectionTypeReq
): Promise<ApiResponse<InspectionType[]>> => {
  const { limit, shouldCache, ...rest } = params;

  return axiosECS.get(pathV2, {
    params: { limit: limit || DEFAULT_LIMIT, ...rest },
  });
};

export const getAllInspectionTypeWithCursor = async (
  _params: GetInspectionTypeReq
) => {
  const { shouldCache, projectId, bimVersionId, ...params } = _params;
  const inspectionTypes: InspectionType[] = [];
  let cursor: string | undefined;
  do {
    const { data, pagination } = await getInspectionType({
      ...params,
      paging: "cursor",
      cursor,
    });
    cursor = pagination?.cursor;
    if (data === undefined) {
      // case has error -> we not need continue query
      return;
    }
    if (data.length) {
      inspectionTypes.push.apply(inspectionTypes, data);
    }
  } while (cursor);

  if (shouldCache) {
    await handleCachedInspectionTypes(
      inspectionTypes,
      projectId!,
      bimVersionId!
    );
  }

  return inspectionTypes;
};

export const getInspectionTypeById = async (
  id: string
): Promise<ApiResponse<InspectionType>> => {
  return axiosECS.get(`${pathV2}/${id}`);
};

export const createInspectionType = async (
  inspectionType: InspectionType
): Promise<ApiResponse<CreateInspectionTypeRes>> => {
  return axiosECS.post(pathV2, inspectionType);
};

export const copyInspectionType = async (body: {
  inspectionTypeId: string;
  bimFiles: string[];
}): Promise<ApiResponse<CopyInspectionTypeRes>> => {
  return axiosECS.post(`${pathV2}/copy`, body);
};

export const updateInspectionType = async (
  inspectionType: Partial<InspectionType>
): Promise<ApiResponse<InspectionType>> => {
  return axiosECS.patch(pathV2, inspectionType);
};

export const deleteInspectionType = async (
  id: string
): Promise<ApiResponse<InspectionType>> => {
  return axiosECS.delete(pathV2, {
    data: { id },
  });
};

const handleCachedInspectionTypes = async (
  inspectionTypes: InspectionType[],
  projectId: string,
  bimVersionId: string
) => {
  let requests: Promise<any>[] = [];
  for (let i = 0; i < inspectionTypes.length; i++) {
    const inspectionType: InspectionType = inspectionTypes?.[i];
    if (!inspectionType?.id) {
      continue;
    }

    requests.push(getInspectionTypeById(inspectionType.id));
    requests.push(
      getInspectionGroupList({
        inspectionTypeId: inspectionType.id!,
        shouldCache: true,
      })
    );

    requests.push(
      getInspectionTypeViewList(
        projectId,
        inspectionType.id!,
        bimVersionId,
        true
      )
    );

    requests.push(
      getAllPins({
        inspectionTypeId: inspectionType.id!,
        shouldCache: true,
      })
    );
    requests.push(
      getInspectionContents({
        inspectionTypeId: inspectionType.id,
        shouldCache: true,
      })
    );
    requests.push(
      inspectionTargetApi.getCombinedObjectList(inspectionType.id!)
    );
    requests.push(
      inspectionTargetApi.getExclusionObjectList({
        inspectionTypeId: inspectionType.id!,
      })
    );

    if (requests.length >= 20) {
      await Promise.all(requests);
      await sleep(50);
      requests = [];
    }
  }

  await Promise.all(requests);
};
