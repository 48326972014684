import { ApiResponse } from "interfaces/models/api";
import { InspectionAdditionObject } from "interfaces/models/InspectionAdditionObject";
import { InspectionExclusionObject } from "interfaces/models/InspectionExclusionObject";
import { InspectionTarget } from "interfaces/models/InspectionTarget";
import { Object } from "interfaces/models/inspectionType";
import { axiosECS } from "services/baseAxios";

const pathV2 = "/v1/inspections/targets";

export const getInspectionTargetList = async (params?: {
  inspectionTypeId: string; // id of inspection type
  shouldCache?: boolean;
}): Promise<ApiResponse<InspectionTarget[]>> => {
  return axiosECS.get(pathV2, {
    params,
  });
};

export const updateInspectionTarget = async (
  inspectionTarget: InspectionTarget
): Promise<ApiResponse<InspectionTarget>> => {
  const { targetObjects, ...body } = inspectionTarget;

  return axiosECS.patch(pathV2, body);
};

// for addition objects
export const getAdditionObjectList = async (params?: {
  inspectionTargetId: string; // id of inspection target
}): Promise<ApiResponse<InspectionAdditionObject[]>> => {
  return axiosECS.get(`${pathV2}/addition-objects`, {
    params,
  });
};

export const createAdditionObject = async (
  InspectionAdditionObject: InspectionAdditionObject
): Promise<ApiResponse<InspectionAdditionObject>> => {
  return axiosECS.post(`${pathV2}/addition-objects`, InspectionAdditionObject);
};

export const deleteAdditionObject = async (
  id: string
): Promise<ApiResponse<InspectionAdditionObject>> => {
  return axiosECS.delete(`${pathV2}/addition-objects`, {
    data: { id },
  });
};

// for exclusion objects
export const getExclusionObjectList = async (params: {
  inspectionTargetId?: string;
  inspectionTypeId?: string;
}): Promise<ApiResponse<InspectionExclusionObject[]>> => {
  return axiosECS.get(`${pathV2}/exclusion-objects`, {
    params,
  });
};

export const createExclusionObject = async (
  InspectionExclusionObject: InspectionExclusionObject
): Promise<ApiResponse<InspectionExclusionObject>> => {
  return axiosECS.post(
    `${pathV2}/exclusion-objects`,
    InspectionExclusionObject
  );
};

export const deleteExclusionObject = async (
  id: string
): Promise<ApiResponse<InspectionExclusionObject>> => {
  return axiosECS.delete(`${pathV2}/exclusion-objects`, {
    data: { id },
  });
};

// for combined-objects = extraction + addition - exclusion
export const getCombinedObjectList = async (
  inspectionTypeId: string
): Promise<ApiResponse<Object[]>> => {
  return axiosECS.get(`${pathV2}/combined-objects`, {
    params: { inspectionTypeId },
  });
};
