import { Icon, IconProps } from "@chakra-ui/icons";

const HomeIcon = (props: IconProps) => {
  return (
    <Icon width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M12.9823 3.49715C12.631 3.2239 12.4553 3.08727 12.2613 3.03476C12.0902 2.98841 11.9098 2.98841 11.7387 3.03476C11.5447 3.08727 11.369 3.2239 11.0177 3.49715L4.23539 8.77228C3.78202 9.1249 3.55534 9.30121 3.39203 9.52201C3.24737 9.7176 3.1396 9.93794 3.07403 10.1722C3 10.4367 3 10.7239 3 11.2982V18.5331C3 19.6532 3 20.2133 3.21799 20.6411C3.40973 21.0174 3.71569 21.3234 4.09202 21.5152C4.51984 21.7331 5.0799 21.7331 6.2 21.7331H8.2C8.48003 21.7331 8.62004 21.7331 8.727 21.6786C8.82108 21.6307 8.89757 21.5542 8.9455 21.4601C9 21.3532 9 21.2132 9 20.9331V14.3331C9 13.7731 9 13.4931 9.10899 13.2791C9.20487 13.091 9.35785 12.938 9.54601 12.8421C9.75992 12.7331 10.0399 12.7331 10.6 12.7331H13.4C13.9601 12.7331 14.2401 12.7331 14.454 12.8421C14.6422 12.938 14.7951 13.091 14.891 13.2791C15 13.4931 15 13.7731 15 14.3331V20.9331C15 21.2132 15 21.3532 15.0545 21.4601C15.1024 21.5542 15.1789 21.6307 15.273 21.6786C15.38 21.7331 15.52 21.7331 15.8 21.7331H17.8C18.9201 21.7331 19.4802 21.7331 19.908 21.5152C20.2843 21.3234 20.5903 21.0174 20.782 20.6411C21 20.2133 21 19.6532 21 18.5331V11.2982C21 10.7239 21 10.4367 20.926 10.1722C20.8604 9.93794 20.7526 9.7176 20.608 9.52201C20.4447 9.30121 20.218 9.1249 19.7646 8.77228L12.9823 3.49715Z"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

export default HomeIcon;
