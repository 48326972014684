import base64 from "base-64";
import {
  ManifestOverview,
  ManifestOverviewType,
} from "interfaces/models/forgeViewer";
import { z } from "zod";

export const BACKEND_HOSTNAME = "https://developer.api.autodesk.com";
export const CDN_HOSTNAME = "https://cdn.derivative.autodesk.com";
export const FONT_HOSTNAME = "https://fonts.autodesk.com";

const extractBase64UrnFromBimFileId = (defaultBimPathId: string) => {
  const bimFileId = defaultBimPathId?.split("/").pop() || "";
  if (!bimFileId) {
    return;
  }

  const base64BimUrn = base64
    .encode(decodeURIComponent(bimFileId))
    .replace("/", "_");

  return base64BimUrn;
};

const manifest = async (
  urn: string,
  authToken: string
): Promise<ManifestOverviewType[]> => {
  const url = attachDomain(
    `${BACKEND_HOSTNAME}/modelderivative/v2/designdata/${urn}/manifest`
  );
  const response = await fetch(url, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
  const jsonObj = await response.json();
  const parsedObj = z.array(ManifestOverview).parse(jsonObj.derivatives);

  return parsedObj;
};

// fetch only to put cache through service worker.
const cdnManifest = async (
  base64BimUrn: string,
  authToken: string
): Promise<void> => {
  const url = attachDomain(
    `${CDN_HOSTNAME}/derivativeservice/v2/manifest/${encodeURIComponent(
      base64BimUrn
    )}`
  );
  await fetch(url, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

const getResource = async (
  urn: string,
  authToken: string
): Promise<ArrayBuffer> => {
  const url = getResourceUrl(urn);
  const response = await fetch(url, {
    headers: { Authorization: `Bearer ${authToken}` },
  });

  return await response.arrayBuffer();
};

const attachDomain = (url: string): string => {
  const encodedOrigin = encodeURIComponent(document.location.origin);

  return `${url}?domain=${encodedOrigin}`;
};

const getDerivativeUrl = (urn: string): string => {
  return attachDomain(
    `${BACKEND_HOSTNAME}/derivativeservice/v2/derivatives/${encodeURIComponent(
      urn
    )}`
  );
};

const getResourceUrl = (urn: string): string => {
  return attachDomain(
    `${CDN_HOSTNAME}/derivativeservice/v2/derivatives/${encodeURIComponent(
      urn
    )}`
  );
};

export const ForgeClient = {
  extractBase64UrnFromBimFileId,
  manifest,
  getResource,
  getDerivativeUrl,
  getResourceUrl,
  attachDomain,
  cdnManifest,
};
