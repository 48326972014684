import { Icon, IconProps } from "@chakra-ui/icons";

const CopyIcon = (props: IconProps) => {
  return (
    <Icon width="1em" height="1em" viewBox="0 0 22 22" fill="none" {...props}>
      <path
        d="M8.30294 16.0415C7.83987 16.0415 7.44792 15.8811 7.12708 15.5603C6.80625 15.2394 6.64583 14.8475 6.64583 14.3844V3.94861C6.64583 3.48554 6.80625 3.09359 7.12708 2.77275C7.44792 2.45192 7.83987 2.2915 8.30294 2.2915H15.9887C16.4518 2.2915 16.8438 2.45192 17.1646 2.77275C17.4854 3.09359 17.6458 3.48554 17.6458 3.94861V14.3844C17.6458 14.8475 17.4854 15.2394 17.1646 15.5603C16.8438 15.8811 16.4518 16.0415 15.9887 16.0415H8.30294ZM8.30294 14.6665H15.9887C16.0593 14.6665 16.1239 14.6371 16.1826 14.5783C16.2414 14.5196 16.2708 14.455 16.2708 14.3844V3.94861C16.2708 3.87802 16.2414 3.8134 16.1826 3.75473C16.1239 3.69591 16.0593 3.6665 15.9887 3.6665H8.30294C8.23235 3.6665 8.16773 3.69591 8.10906 3.75473C8.05024 3.8134 8.02083 3.87802 8.02083 3.94861V14.3844C8.02083 14.455 8.05024 14.5196 8.10906 14.5783C8.16773 14.6371 8.23235 14.6665 8.30294 14.6665ZM5.0946 19.2498C4.63153 19.2498 4.23958 19.0894 3.91875 18.7686C3.59792 18.4478 3.4375 18.0558 3.4375 17.5927V6.46944C3.4375 6.27434 3.50335 6.11095 3.63504 5.97925C3.76658 5.84771 3.9299 5.78194 4.125 5.78194C4.3201 5.78194 4.48349 5.84771 4.61519 5.97925C4.74673 6.11095 4.8125 6.27434 4.8125 6.46944V17.5927C4.8125 17.6633 4.84191 17.7279 4.90073 17.7866C4.9594 17.8454 5.02402 17.8748 5.0946 17.8748H13.4679C13.663 17.8748 13.8264 17.9406 13.9581 18.0721C14.0896 18.2038 14.1554 18.3672 14.1554 18.5623C14.1554 18.7574 14.0896 18.9208 13.9581 19.0523C13.8264 19.184 13.663 19.2498 13.4679 19.2498H5.0946Z"
        fill="currentcolor"
      />
    </Icon>
  );
};

export default CopyIcon;
