import { FLOAT_OR_INT_REGEX, NUMBER_REGEX } from "constants/regex";

export const formatNumber = (
  value?: number | string | null,
  scale = 0,
  option?: {
    sign?: boolean;
    unit?: string;
  }
) => {
  const { sign, unit } = option || {};
  if (
    value === null ||
    value === "" ||
    typeof value === "undefined" ||
    isNaN(+value)
  ) {
    return "-";
  }
  let formatedNumber = new Intl.NumberFormat("ja-JP", {
    style: "decimal",
    minimumFractionDigits: scale,
    maximumFractionDigits: scale,
  }).format(+value);

  if (sign) formatedNumber = `${+value > 0 ? "+" : ""}${formatedNumber}`;
  if (unit) formatedNumber = `${formatedNumber}${unit}`;

  return formatedNumber;
};

export const compareFloat = (a: number, b: number) => {
  return Math.abs(a - b) <= Number.EPSILON ? 0 : (a - b) / Math.abs(a - b);
};

export const isValidFloatAndIntNumber = (text: string) =>
  new RegExp(FLOAT_OR_INT_REGEX).test(text);
