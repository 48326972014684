import {
  Box,
  Flex,
  Text,
  Input as BaseInput,
  InputProps,
  Checkbox,
} from "@chakra-ui/react";
import {
  CheckboxAdditionalInfo,
  InspectionItemResult,
} from "interfaces/models/inspectionItemResult";
import { validatePartialSchemaResults } from "../../../context/FormBuilderContext/types";
import withField from "../../../hooks/withField";
import { FieldEditorProps } from "../../types";
import { withFocusOut } from "hooks/useFocusOut";

interface CheckboxEditorProps extends FieldEditorProps {}

const Input = withField<InputProps>(
  withFocusOut(BaseInput),
  (e) => e.target.value
);

const CheckboxEditor: React.FC<CheckboxEditorProps> = (props) => {
  const { groupRowIndex, inspectionResult, onChange } = props;

  const additionInfoCheckbox = Object.values(
    inspectionResult?.additionInfo?.checkbox?.mapValues || {}
  );

  const onChangeMapValuesCheckbox = (
    values: CheckboxAdditionalInfo["mapValues"]
  ) => {
    const mapValues = Object.assign(
      inspectionResult.additionInfo?.checkbox?.mapValues || {},
      values
    );

    const data = {
      additionInfo: {
        ...inspectionResult.additionInfo,
        checkbox: { ...inspectionResult.additionInfo?.checkbox, mapValues },
      },
    } satisfies Partial<InspectionItemResult>;

    onChange(data);
  };

  return (
    <Box pl="5rem">
      {additionInfoCheckbox.map((data, index) => (
        <Box key={index} mt="1.5rem">
          <Flex flexWrap="wrap" alignItems="center" gap="1rem" mb="0.5rem">
            <Text flexShrink={0} fontWeight="bold">
              {data.label}
            </Text>
          </Flex>

          <Flex alignItems="center" gap="1rem" pl="1rem">
            <Checkbox
              readOnly
              gap="0.5rem"
              flexShrink={0}
              isChecked={index === 0}
              sx={{
                "& > .chakra-checkbox__label": {
                  marginLeft: "0px",
                  maxWidth: "6rem",
                },
              }}
            ></Checkbox>
            <Input
              isInvalid={
                !validatePartialSchemaResults({
                  additionInfo: {
                    checkbox: {
                      mapValues: {
                        index: {
                          label: "",
                          displayValue: data.displayValue,
                        },
                      },
                    },
                  },
                })
              }
              path={[
                "itemResults",
                groupRowIndex,
                "additionInfo",
                "checkbox",
                "mapValues",
                index,
                "displayValue",
              ]}
              backgroundColor="#fff"
              height="4rem"
              fontSize="1.5rem"
              borderColor="#eeeeee"
              borderRadius="1rem"
              onChange={(value) =>
                onChangeMapValuesCheckbox({
                  [`${index}`]: { ...data, displayValue: value },
                })
              }
              value={data.displayValue}
            />
          </Flex>
        </Box>
      ))}
    </Box>
  );
};

export default CheckboxEditor;
