import { useBoolean } from "@chakra-ui/react";
import { HandleSelectDrawImageProps } from "components/modal/DrawToolModal";
import { FileModel, FileUploadInfo } from "interfaces/models";
import { useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { uuid } from "utils/common";
import { fileToBase64, removeFileS3, uploadFileToS3 } from "utils/file";

interface Props {
  image?: FileUploadInfo;
  setImage(image?: FileModel): void;
  handleOpenCamera: () => void;
}

const useDrawImage = (props: Props) => {
  const { setImage, handleOpenCamera, image } = props;
  const isRecaptureImageRef = useRef(false);
  const { currentUser } = useSelector((state: RootState) => state.user);
  const [isDrawing, setDrawing] = useBoolean();

  const handleSelectDrawImage = useCallback(
    async ({ file, isEdited, originFile }: HandleSelectDrawImageProps) => {
      const isExistsOriginFile = !!image?.originSrc;
      const isRecaptureImage = isRecaptureImageRef.current;
      setDrawing.on();
      let lastModifiedDateCurrent: any;
      if (isEdited && !!image && !isRecaptureImage) {
        const lastModifiedDateFileFirst = image?.lastModifiedDateFile;
        const uploadTimeFileFirst = image?.uploadTime;

        lastModifiedDateCurrent = lastModifiedDateFileFirst
          ? new Date(lastModifiedDateFileFirst)
          : uploadTimeFileFirst
          ? new Date(uploadTimeFileFirst)
          : new Date();
      } else {
        lastModifiedDateCurrent = new Date(file.lastModified);
      }
      const fileClone = new File([file], file.name, {
        type: file.type,
        lastModified: lastModifiedDateCurrent.getTime(),
      });
      const src = await fileToBase64(fileClone);

      let isRemoveOrigin = false;
      let originFileModel: FileModel = undefined as any;
      if (
        (isEdited && (!isExistsOriginFile || isRecaptureImage)) ||
        (!isEdited && isRecaptureImage)
      ) {
        originFileModel = { file: originFile, name: originFile.name };
      } else if (!isEdited && isRecaptureImage) {
        originFileModel = { file: originFile, name: originFile.name };
        isRemoveOrigin = true;
      }

      let fileUploadInfo: FileUploadInfo = {} as any;
      fileUploadInfo.src = src;
      const promiseImages = [];
      const requestId = uuid();
      promiseImages.push(
        uploadFileToS3(fileClone, fileClone?.name || "", undefined, {
          requestId,
        }).then((src) => {
          fileUploadInfo = {
            src,
            uploadTime: new Date(),
            originSrc: fileUploadInfo.originSrc ?? image?.originSrc,
            lastModifiedDateFile: lastModifiedDateCurrent,
            userUpload: currentUser?.id || "",
          };

          return src;
        })
      );

      if (isRemoveOrigin) {
        fileUploadInfo.originSrc = null as any;
        if (image?.originSrc) {
          promiseImages.push(
            removeFileS3({
              keys: [new URL(image?.originSrc).pathname.substring(1)],
              requestId,
            })
          );
        }
      }

      if (originFileModel?.file) {
        promiseImages.push(
          uploadFileToS3(
            originFileModel.file,
            originFileModel?.file?.name || "",
            undefined,
            { requestId }
          ).then((src) => {
            fileUploadInfo.originSrc = src;
          })
        );
      }

      await Promise.all(promiseImages);
      setImage(fileUploadInfo);
      setDrawing.off();
    },
    [image, setDrawing, setImage, currentUser?.id]
  );

  const handleRecaptureImage = useCallback(() => {
    isRecaptureImageRef.current = true;
    handleOpenCamera();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleOpenCamera]);

  return {
    handleSelectDrawImage,
    handleRecaptureImage,
    isRecaptureImageRef,
    isDrawing,
  };
};

export default useDrawImage;
