import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setToken } from "redux/userSlice";
import {
  checkTokenExpired,
  doRefreshToken,
  getCurrentUserAndIdToken,
} from "utils/authen";

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const isExpiredToken = checkTokenExpired();
      const { user, token } = getCurrentUserAndIdToken();
      if (!isExpiredToken && user && token) {
        // if have user info and token -> we can skip wait load user info
        setLoading(false);
        dispatch(setToken(token));
      }
      // but we still call api for get latest user info
      await doRefreshToken();
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <></>;
  }

  return children;
};

export default PrivateRoute;
