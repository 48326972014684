import { FormBuilderPayload, Types } from "./types";

export const addInspectionItem = (): any => ({
  type: Types.ADD_ITEM,
  payload: null,
});
export const deleteInspectionItem = (
  payload: FormBuilderPayload[typeof Types.DELETE_ITEM]
): any => ({ type: Types.DELETE_ITEM, payload });
export const updateInspectionItem = (
  payload: FormBuilderPayload[typeof Types.UPDATE_ITEM]
): any => ({ type: Types.UPDATE_ITEM, payload });

export const addInspectionItemResult = (
  payload: FormBuilderPayload[typeof Types.ADD_ITEM_RESULT]
): any => ({ type: Types.ADD_ITEM_RESULT, payload });

export const deleteInspectionItemResult = (
  payload: FormBuilderPayload[typeof Types.DELETE_ITEM_RESULT]
): any => ({ type: Types.DELETE_ITEM_RESULT, payload });

export const updateInspectionItemResult = (
  payload: FormBuilderPayload[typeof Types.UPDATE_ITEM_RESULT]
): any => ({ type: Types.UPDATE_ITEM_RESULT, payload });

export const updateInspectionContent = (
  payload: FormBuilderPayload[typeof Types.UPDATE_CONTENT]
): any => ({ type: Types.UPDATE_CONTENT, payload });

export const copyInspectionItem = (
  payload: FormBuilderPayload[typeof Types.COPY_ITEM]
): any => ({
  type: Types.COPY_ITEM,
  payload,
});

export const initInspectionData = (
  payload: FormBuilderPayload[typeof Types.INIT_DATA]
): any => ({
  type: Types.INIT_DATA,
  payload,
});
