import { ApiResponse } from "interfaces/models/api";
import { Blackboard } from "interfaces/models/blackboard";
import { axiosECS } from "services/baseAxios";
import { validateBodyReq, validateListString } from "utils/common";

const path = "/v1/blackboards";

export const getBlackboardList = async ({
  id,
  documentCategoryId,
  shouldCache,
}: {
  id?: string;
  documentCategoryId?: string;
  shouldCache?: boolean;
}): Promise<ApiResponse<Blackboard[]>> => {
  // return axiosECS.get(path, {
  //   params: {
  //     id,
  //     documentCategoryId,
  //     shouldCache,
  //   },
  // });
  return { data: [] };
};

export const createBlackboard = async (
  blackboard: Blackboard
): Promise<ApiResponse<Blackboard>> => {
  return axiosECS.post(path, validateBodyReq(blackboard));
};

export const updateBlackboard = async (
  blackboard: Blackboard
): Promise<ApiResponse<Blackboard>> => {
  const updatedAt = blackboard.updatedAt || new Date();

  // temporarily not in use
  // return axiosECS.patch(path, validateBodyReq({ ...blackboard, updatedAt }));
  return { data: validateBodyReq({ ...blackboard, updatedAt }) as Blackboard };
};

export const deleteBlackboardList = async (
  ids: string[]
): Promise<ApiResponse<string[]>> => {
  return axiosECS.delete(path, { data: { ids: validateListString(ids) } });
};
