import { Flex, Progress, Text } from "@chakra-ui/react";
import { useOffline } from "contexts/OfflineContext";
import useCheckProjectCached, {
  iCachedProject,
} from "hooks/useCheckProjectCached";
import useGetCurrentPath from "hooks/useGetCurrentPath";
import { memo, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsShowOfflineMsg } from "redux/appSlice";
import { RootState } from "redux/store";
import { routePath } from "routes/path";
import { getCacheProjectById } from "utils/indexedDb";
import { getHumanReadableSize } from "utils/storage";
import { message } from "./base";
import { NetworkStatusIcon, SyncDataForOfflineIcon } from "./icon";

interface Props {
  isShowSyncDataForOfflineIcon?: boolean;
}

const OfflineBar = (props: Props) => {
  const { isShowSyncDataForOfflineIcon = true } = props;

  const [cachedInfo, setCachedInfo] = useState<iCachedProject>();

  const { cachingBimFileId, dataProjectDetail, lastCheckCacheProject } =
    useSelector((state: RootState) => state.project);
  const {
    isOnline,
    isSyncOfflineData,
    isServiceWorkerReady,
    actualNetworkStatus,
    timeBeginOffline,
    syncDataOption,
  } = useSelector((state: RootState) => state.app);
  const { isLoadedViewerModelData } = useSelector(
    (state: RootState) => state.forgeViewer
  );

  const { offlineEditedDataSize } = useOffline();
  const { pathPattern } = useGetCurrentPath();
  const dispatch = useDispatch();

  const { isLoading, progress, stopCacheProject, handleBeforeSyncProjectData } =
    useCheckProjectCached({
      project: dataProjectDetail,
      cachingBimFileId,
      isServiceWorkerReady,
      cachedInfo,
      isOnline,
    });

  const isPageForgeViewer = useMemo(() => {
    return [routePath.ForgeViewer]?.includes(pathPattern);
  }, [pathPattern]);

  const syncDataTime = useMemo(() => {
    return (
      syncDataOption?.mapSyncDataTimeByProject?.[dataProjectDetail?.id || ""] ||
      null
    );
  }, [syncDataOption, dataProjectDetail?.id]);

  const isCaching = useMemo(() => {
    return !!dataProjectDetail?.id && cachingBimFileId === dataProjectDetail.id;
  }, [cachingBimFileId, dataProjectDetail?.id]);

  const uploadOfflineDataStatus = useMemo(() => {
    if (!offlineEditedDataSize) {
      return <></>;
    }

    if (!isOnline) {
      return <Text>{getHumanReadableSize(offlineEditedDataSize)}</Text>;
    }

    if (isSyncOfflineData && offlineEditedDataSize) {
      return (
        <Flex flexDir="column" pt="5px">
          <Text userSelect="none">
            {getHumanReadableSize(offlineEditedDataSize)}
          </Text>
          <Progress
            size="sm"
            w="100%"
            bgColor={"var(--chakra-colors-gray-300)"}
            colorScheme={"completed"}
            hasStripe={true}
            isAnimated={true}
            isIndeterminate
            border="5px"
          />
        </Flex>
      );
    }

    return <></>;
  }, [isOnline, isSyncOfflineData, offlineEditedDataSize]);

  useEffect(() => {
    // Do not show offline messages when in the forge viewer screen without caching the project
    if (isPageForgeViewer) {
      dispatch(setIsShowOfflineMsg((progress || 0) >= 100));
    }

    return () => {
      dispatch(setIsShowOfflineMsg(true));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPageForgeViewer, (progress || 0) >= 100]);

  useEffect(() => {
    if (
      !isOnline &&
      isPageForgeViewer &&
      isLoading !== undefined &&
      (progress || 0) < 100
    ) {
      message.warning([
        "インターネット接続が切れました。",
        "オフライン環境で利用したい場合には、オフラインモードをご活用ください。（オフラインモードを利用する際には、事前にプロジェクトデータのダウンロードが必要です）",
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline]);

  useEffect(() => {
    if (!dataProjectDetail?.id) {
      return;
    }
    (async () => {
      const cache = await getCacheProjectById(dataProjectDetail?.id);
      setCachedInfo(cache);
    })();
  }, [lastCheckCacheProject, dataProjectDetail?.id]);

  return (
    <>
      {uploadOfflineDataStatus}
      {isShowSyncDataForOfflineIcon && (
        <SyncDataForOfflineIcon
          isCaching={isCaching}
          progress={progress}
          isOnline={isOnline}
          cachingBimFileId={cachingBimFileId || ""}
          isPageForgeViewer={isPageForgeViewer}
          actualNetworkStatus={actualNetworkStatus}
          syncDataTime={syncDataTime}
          bimfileId={dataProjectDetail?.id || ""}
          stopCacheProject={stopCacheProject}
          syncNewProjectData={handleBeforeSyncProjectData}
        />
      )}
      <NetworkStatusIcon
        isOnline={isOnline}
        isCacheFinished={progress === undefined ? undefined : progress >= 100}
        isLoadingViewer={isPageForgeViewer && !isLoadedViewerModelData}
        cachingBimFileId={cachingBimFileId || ""}
        actualNetworkStatus={actualNetworkStatus}
        timeBeginOffline={timeBeginOffline}
        isPageForgeViewer={isPageForgeViewer}
      />
    </>
  );
};

export default memo(OfflineBar);
