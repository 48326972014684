import { Command, getSchema } from "@tiptap/core";
import { Node } from "@tiptap/pm/model";
import { Transaction } from "@tiptap/pm/state";
import { Editor } from "@tiptap/react";
import { getComponentStyle } from "components/containers/InspectionFormat/Content/SettingFontSize/utils/style";
import {
  CheckboxDirectionOptionsEnum,
  CheckboxDisplayOptionsEnum,
  DocumentStatusType,
} from "constants/enum";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { CellType, TemplateComponent } from "interfaces/models/component";
import { isSelfInspectionTemplate } from "models/documentCategory";
import { NormalTextNodeAttrs, TaskListNodeAttrs } from "../extendsions";
import { EditorExtensions } from "../extendsions/list";
import { CustomNode, MarkType, NodeType } from "../type";

interface ContentText {
  type: NodeType;
  text: string;
  marks?: { type: MarkType }[];
}

const setMark = (content: ContentText, markType: MarkType) => {
  content.marks = [...(content?.marks || []), { type: markType }];
};

export const getContentNodeTypeNormalText = (
  params: {
    text: string | undefined;
    style: CellType["style"];
  } & NormalTextNodeAttrs
): CustomNode<NormalTextNodeAttrs> => {
  const { text, ...attrs } = params;
  attrs.style = getComponentStyle(params.style as any);
  const result: ReturnType<typeof getContentNodeTypeNormalText> = {
    type: attrs.isMultiple
      ? NodeType.MULTIPLE_NORMAL_TEXT
      : NodeType.NORMAL_TEXT,
    attrs,
  };

  if (text) {
    const content: {
      type: NodeType;
      text: string;
      mark?: { type: MarkType }[];
    } = { type: NodeType.TEXT, text };
    if (params.style?.bold) {
      setMark(content, MarkType.BOLD);
    }

    if (params.style?.italic) {
      setMark(content, MarkType.ITALIC);
    }

    if (params.style?.underline) {
      setMark(content, MarkType.UNDERLINE);
    }

    result.content = [content];
  }

  return result;
};

export const getContentTypeCheckbox = (params: {
  cell: CellType;
  documentItem: DocumentItemDTO | undefined;
  documentType: string | undefined;
  component: TemplateComponent | undefined;
}) => {
  const { cell, documentItem, documentType, component } = params;

  const options = (
    cell.cellLinkedData?.options?.valueOfCheckbox?.split("・") || []
  ).filter((i) => !!i);
  const numberOfCheckbox = cell?.cellLinkedData?.options?.numberOfCheckbox || 1;
  const isMultipleCheckbox = numberOfCheckbox > 1;
  const isSelfInspection = isSelfInspectionTemplate(documentType);

  if (!cell.cellId || (component?.linkedHeaderId && !documentItem)) {
    return getContentNodeTypeNormalText({
      text: undefined,
      style: cell.style,
      isSubCell: !!cell?.isSubCell,
    });
  }

  const dynamicFieldValues = (documentItem?.data || {}) as {
    [key: string]: string;
  };
  const key = `${cell.cellId}-${documentItem?.id}`;
  const value = dynamicFieldValues[key] || cell.value;
  const displayCheckbox = cell?.cellLinkedData?.options?.displayCheckbox;
  const directionOfCheckbox =
    (cell?.cellLinkedData?.options
      ?.directionOfCheckbox as CheckboxDirectionOptionsEnum) ||
    CheckboxDirectionOptionsEnum.HORIZONTAL;

  const typeDefault = (type: "opt" | "item" = "item") => {
    return {
      type: NodeType.TASK_LIST,
      attrs: {
        direction: directionOfCheckbox,
        type,
      },
      content: new Array(numberOfCheckbox).fill(0).map((_, key) => {
        const option = options[key];
        let checked = value === option;

        if (numberOfCheckbox === 1) {
          checked = value === "true";
        }

        if (isSelfInspection) {
          const status = documentItem?.status;
          checked = status === DocumentStatusType.Complete;
          const isNoneLabel = !options[key];

          if (isMultipleCheckbox && isNoneLabel) {
            checked = false;
          }

          if (options[key]) {
            const value = options[Number(status) - 1];
            checked = value === options[key];
          }
        }

        return {
          type: type === "item" ? NodeType.TASK_ITEM : NodeType.TASK_OPT,
          attrs: {
            checked,
            isLastOpt: numberOfCheckbox === key + 1,
          },
          content: option
            ? [
                {
                  type: NodeType.TEXT,
                  text: option,
                },
              ]
            : [],
        };
      }),
    } as CustomNode<TaskListNodeAttrs>;
  };

  switch (displayCheckbox) {
    case CheckboxDisplayOptionsEnum.Value: {
      return getContentNodeTypeNormalText({
        text: value,
        style: cell.style,
        isSubCell: !!cell?.isSubCell,
      });
    }

    case CheckboxDisplayOptionsEnum.Options:
      if (!options.length) {
        return typeDefault();
      }

      return typeDefault("opt");

    default:
      return typeDefault();
  }
};

export const updatePinComponentNode = (params: {
  element: HTMLElement | undefined | null;
  transaction: Transaction;
  editor: Editor;
}) => {
  const { element, transaction, editor } = params;
  if (!element) {
    return;
  }

  const pos = editor.view.posAtDOM(element, 0) - 1;
  const node = transaction.doc.nodeAt(pos);
  const type = node?.type?.name;
  if (type === NodeType.PIN_COMPONENT && node) {
    transaction.setNodeMarkup(pos, undefined, {
      ...node?.attrs,
      isUpdated: true,
    });
  }
};

export const onChangeCheckbox = (params: {
  editor: Editor;
  getPos: Function;
  groupId?: string;
  key?: string;
}) => {
  const { editor, groupId, key, getPos } = params;

  handleCommandForNode({
    editor,
    command: ({ tr, editor }) => {
      const position = getPos();
      const currentNode = tr.doc.nodeAt(position);

      // reset the state of the checkbox of the same level
      resetCheckboxChecked({ groupId, position, transaction: tr, editor });

      // update attribute for all node same key
      if (groupId) {
        const checkboxEles = editor.view.dom.querySelectorAll(
          `li[data-group-id="${groupId}"][data-key="${key}"]`
        );
        checkboxEles.forEach((ele) => {
          const childPos = editor.view.posAtDOM(ele, 0) - 1;
          tr.setNodeAttribute(childPos, "checked", !currentNode?.attrs.checked);
        });
      } else {
        // update attribute for currentNode node
        tr.setNodeMarkup(position, undefined, {
          ...currentNode?.attrs,
          checked: !currentNode?.attrs.checked,
        });
      }

      return true;
    },
  });
};

// reset all check optiton to false
export const resetCheckboxChecked = (params: {
  position: number;
  transaction: Transaction;
  editor: Editor;
  groupId?: string;
}) => {
  const { groupId, position, editor, transaction } = params;
  const currentDom: HTMLDivElement = editor.view.nodeDOM(position) as any;
  const currentKey = currentDom.getAttribute("data-key");
  const checkboxEles = editor.view.dom.querySelectorAll(
    `li[data-group-id="${groupId}"]`
  );

  checkboxEles.forEach((ele) => {
    const childPos = editor.view.posAtDOM(ele, 0) - 1;
    const childNode = transaction.doc.nodeAt(childPos);
    const key = ele.getAttribute("data-key");

    if (key === currentKey) {
      return;
    }

    if (childPos !== position && childNode?.attrs?.checked) {
      transaction.setNodeMarkup(childPos, undefined, {
        ...childNode?.attrs,
        checked: false,
      });
    }
  });
};

export const checkValidateContentEditor = (content: CustomNode<any>) => {
  try {
    const schema = getSchema(EditorExtensions);
    const contentNode = Node.fromJSON(schema, content);
    contentNode.check();

    return true;
  } catch (e) {
    return false;
  }
};

export const handleCommandForNode = (params: {
  editor: Editor;
  command: (props: Parameters<Command>[0]) => boolean;
  isFocusEditor?: boolean;
}) => {
  const { editor, isFocusEditor = true, command } = params;
  let chainCommand = editor.chain();
  if (isFocusEditor) {
    chainCommand = chainCommand.focus(undefined, { scrollIntoView: false });
  }

  chainCommand.command(command).run();
};
