import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as pinApi from "apiClient/v2/inspection/pinApi";
import { REDUCER_KEY } from "constants/redux";
import {
  GetPinReq,
  Pin,
  PinDetail,
  UpdatePinGroup,
  UpdatePinGroupAction,
} from "interfaces/models/pin";
import { updateCacheAfterUpdatePinGroup } from "libs/service-worker/inspections/RequestGetInspectionPin";
import isUndefined from "lodash/isUndefined";
import uniq from "lodash/uniq";
import store from "./store";
import { stat } from "fs";
import { act } from "@testing-library/react";

export interface Filter {
  search?: string;
}

interface State {
  pins: Pin[];
  pinSelected: PinDetail | null;
  isFetchedPins: boolean;
  isFetchingPins: boolean;
  pinSelectedId: string | null;
  isPinSelectedIdOtherGroup: boolean;
  isFetchingPinDetail: boolean;
  isSelectedPinDetail: boolean;
  removing: Record<string, true>;
  deleting: Record<string, true>;
  filters: Filter;
  listSelected: string[];
}

const initialState: State = {
  pins: [],
  isFetchingPins: false,
  isFetchedPins: false,
  pinSelected: null,
  pinSelectedId: null,
  isPinSelectedIdOtherGroup: false,
  isFetchingPinDetail: false,
  removing: {},
  deleting: {},
  isSelectedPinDetail: false,
  filters: {},
  listSelected: [],
};

export const fetchPins = createAsyncThunk(
  "pin/fetchPins",
  async (param: GetPinReq) => {
    return pinApi.getAllPins(param);
  }
);

export const fetchPinDetail = createAsyncThunk(
  "pin/fetchPinDetail",
  async ({
    id,
    index,
    isPinOtherGroup,
  }: {
    index?: number;
    isPinOtherGroup?: boolean;
    id: string;
  }) => {
    const response = await pinApi.getPin(id);

    return { ...response.data, index, isPinOtherGroup };
  }
);

export const deletePinGroup = createAsyncThunk(
  "pin/deletePinGroup",
  async (ids: string[]) => {
    const response = await pinApi.deletePinList(ids);

    return response.data;
  }
);

export const removePinGroup = createAsyncThunk(
  "pin/removePinGroup",
  async (arg: Omit<UpdatePinGroup, "action">) => {
    const action = UpdatePinGroupAction.REMOVE;
    const response = await pinApi.updatePinGroup({
      ...arg,
      action,
    });

    await updateCacheAfterUpdatePinGroup({ ...arg, action });

    return response.data;
  }
);

export const upsertPinGroup = createAsyncThunk(
  "pin/addPinGroup",
  async (arg: UpdatePinGroup) => {
    const response = await pinApi.updatePinGroup(arg);

    const mapPins = new Map(store.getState().pin.pins.map((p) => [p.id, p]));

    await Promise.all(
      arg.pinIds.map((pinId) => {
        return updateCacheAfterUpdatePinGroup({
          pin: mapPins.get(pinId)!,
          ...arg,
        });
      })
    );

    return response.data;
  }
);

export const userSlice = createSlice({
  name: REDUCER_KEY.USER,
  initialState,
  reducers: {
    clearPinSelected: (state) => {
      state.pinSelected = null;
      state.pinSelectedId = null;
      state.isPinSelectedIdOtherGroup = false;
      state.isSelectedPinDetail = false;
    },
    setPinSelected: (state, action: PayloadAction<PinDetail | null>) => {
      state.pinSelected = action.payload;
    },
    updatePin: (
      state,
      action: PayloadAction<Partial<PinDetail> & { id: string }>
    ) => {
      if (state.pinSelected?.id === action.payload.id) {
        state.pinSelected = { ...state.pinSelected, ...action.payload };
      }

      const index = state.pins.findIndex((pin) => pin.id === action.payload.id);

      if (index !== -1) {
        state.pins[index] = { ...state.pins[index], ...action.payload };
      }
    },
    setPin: (state, action: PayloadAction<Partial<Pin> | Pin>) => {
      const index = state.pins.findIndex((pin) => pin.id === action.payload.id);
      if (index === -1) {
        state.pins.push(action.payload as Pin);
      } else {
        state.pins[index] = { ...state.pins[index], ...action.payload };
      }
    },
    addPins: (state, action: PayloadAction<Pin[]>) => {
      state.pins = state.pins.concat(action.payload);
    },
    resetPins: (state) => {
      state.isFetchedPins = false;
      state.pins = [];
      state.pinSelected = null;
      state.pinSelectedId = null;
      state.isPinSelectedIdOtherGroup = false;
      state.isSelectedPinDetail = false;
    },
    setFilter: (state, action: PayloadAction<Partial<Filter>>) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    setFetchingPins: (state, action: PayloadAction<boolean>) => {
      state.isFetchingPins = action.payload;
    },
    toggleAllListSelected: (
      state,
      action: PayloadAction<{ pinIds: string[]; isChecked: boolean }>
    ) => {
      const { pinIds, isChecked } = action.payload;
      if (isChecked) {
        state.listSelected = [...new Set([...state.listSelected, ...pinIds])];
      } else {
        state.listSelected = state.listSelected.filter(
          (id) => !pinIds.includes(id)
        );
      }
    },
    toggleListSelected: (
      state,
      action: PayloadAction<{ pinId: string; isChecked: boolean }>
    ) => {
      const { pinId, isChecked } = action.payload;
      if (isChecked) {
        state.listSelected = [...new Set([...state.listSelected, pinId])];
      } else {
        state.listSelected = state.listSelected.filter((id) => id !== pinId);
      }
    },
    clearListSelected: (state) => {
      state.listSelected = [];
    },
    setPinSelectedIndex: (state, action: PayloadAction<number>) => {
      if (state.pinSelected) {
        state.pinSelected.index = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPins.pending, (state, action) => {
      state.isFetchingPins = true;
      state.isFetchedPins = false;
    });
    builder.addCase(fetchPins.fulfilled, (state, action) => {
      if (action.payload) {
        state.pins = action.payload;
      }
      state.isFetchedPins = true;
      state.isFetchingPins = false;
    });
    builder.addCase(fetchPinDetail.pending, (state, action) => {
      state.pinSelected = null;
      state.pinSelectedId = action.meta.arg.id;
      state.isPinSelectedIdOtherGroup = !!action?.meta?.arg?.isPinOtherGroup;
      state.isSelectedPinDetail = true;
      state.isFetchingPinDetail = true;
    });
    builder.addCase(fetchPinDetail.fulfilled, (state, action) => {
      if (action.meta.arg.id !== state.pinSelectedId) {
        return;
      }
      if (action.payload) {
        state.pinSelected = action.payload;
      }
      state.isFetchingPinDetail = false;
    });
    builder.addCase(removePinGroup.pending, (state, action) => {
      action.meta.arg.pinIds?.forEach((pinId) => {
        state.removing[pinId] = true;
      });
    });
    builder.addCase(removePinGroup.fulfilled, (state, action) => {
      const isFromModeAddPinToGroup = action.meta.arg?.isFromModeAddPinToGroup;
      const listSelected = new Set(state.listSelected);
      const indexs = new Map(state.pins.map((pin, index) => [pin.id, index]));
      action.meta.arg.pinIds?.forEach((pinId) => {
        if (!indexs.has(pinId)) return;
        const index = indexs.get(pinId)!;

        switch (action.payload.action) {
          case UpdatePinGroupAction.REMOVE:
            if (state.pins[index].pinGroups) {
              state.pins[index].pinGroups = state.pins[index].pinGroups?.filter(
                (i) => action.payload.pinGroupId !== i.pinGroupId
              );
              if (
                action.payload?.action === UpdatePinGroupAction.REMOVE &&
                pinId === state.pinSelected?.id
              ) {
                if (isFromModeAddPinToGroup) {
                  state.pinSelected = {
                    ...state.pinSelected,
                    isPinOtherGroup: true,
                  };
                } else {
                  state.pinSelected = null;
                  state.isSelectedPinDetail = false;
                }
              }
            }
            break;
          case UpdatePinGroupAction.ADD:
            if (!state.pins[index].pinGroups) state.pins[index].pinGroups = [];
            state.pins[index].pinGroups!.push({
              pinGroupId: action.payload.pinGroupId,
              order: Date.now(),
            });
            state.pins[index].pinGroups = uniq(state.pins[index].pinGroups);
            break;
        }

        if (
          action.payload.action === UpdatePinGroupAction.REMOVE &&
          listSelected.has(pinId)
        ) {
          listSelected.delete(pinId);
        }

        if (
          pinId === state.pinSelectedId &&
          !action.meta.arg?.isFromModeAddPinToGroup
        ) {
          state.pinSelectedId = null;
        }
        delete state.removing[pinId];
      });
    });
    builder.addCase(upsertPinGroup.pending, (state, action) => {
      const mapIndex = new Map(state.pins.map((p, i) => [p.id, i]));
      action.meta.arg.pinIds.forEach((pinId) => {
        const index = mapIndex.get(pinId);
        if (isUndefined(index)) {
          return;
        }

        if (!state.pins[index].pinGroups) state.pins[index].pinGroups = [];

        switch (action.meta.arg.action) {
          case UpdatePinGroupAction.CHANGE_ORDER:
            {
              const indexGroup = state.pins[index].pinGroups!.findIndex(
                (g) => g.pinGroupId === action.meta.arg.pinGroupId
              );
              if (indexGroup !== -1 && !isUndefined(action.meta.arg.order)) {
                state.pins[index].pinGroups![indexGroup]!.order =
                  action.meta.arg.order;
              }
            }
            break;
        }
      });
    });
    builder.addCase(upsertPinGroup.fulfilled, (state, action) => {
      const mapIndex = new Map(state.pins.map((p, i) => [p.id, i]));

      action.meta.arg.pinIds.forEach((pinId) => {
        const index = mapIndex.get(pinId);

        if (isUndefined(index)) return;

        switch (action.meta.arg.action) {
          case UpdatePinGroupAction.ADD:
            state.pins[index].pinGroups!.push({
              pinGroupId: action.payload.pinGroupId,
              order: action.payload.order!,
            });
            if (pinId === state.pinSelected?.id) {
              state.pinSelected = {
                ...state.pinSelected,
                isPinOtherGroup: false,
              };
            }
            state.pins[index].pinGroups = uniq(state.pins[index].pinGroups);
            break;
          case UpdatePinGroupAction.REMOVE:
            state.pins[index].pinGroups = state.pins[index].pinGroups!.filter(
              (i) => i.pinGroupId !== action.payload.pinGroupId
            );
            if (pinId === state.pinSelected?.id) {
              state.pinSelected = {
                ...state.pinSelected,
                isPinOtherGroup: true,
              };
            }
            break;
        }
      });
    });
    builder.addCase(deletePinGroup.pending, (state, action) => {
      action.meta.arg.forEach((id) => {
        state.deleting[id] = true;
      });
    });
    builder.addCase(deletePinGroup.fulfilled, (state, action) => {
      const listSelected = new Set(state.listSelected);

      if (action.payload.length) {
        action.payload.forEach((pinId) => {
          if (pinId === state.pinSelected?.id) {
            state.pinSelectedId = null;
            state.pinSelected = null;
            state.isSelectedPinDetail = false;
          }
          delete state.deleting[pinId];
          listSelected.delete(pinId);
        });

        state.pins = state.pins.filter(
          (pin) => !action.payload.includes(pin.id)
        );
      }

      state.listSelected = [...listSelected];
    });
  },
});

export const {
  setPin,
  resetPins,
  updatePin,
  clearPinSelected,
  addPins,
  setFilter,
  setFetchingPins,
  toggleListSelected,
  clearListSelected,
  setPinSelectedIndex,
  toggleAllListSelected,
} = userSlice.actions;

export default userSlice.reducer;
