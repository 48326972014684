import { ApiResponse } from "interfaces/models/api";
import { Family } from "interfaces/models/family";
import { axiosECS } from "services/baseAxios";

const path = "/v1/families";

export const getFamilyList = async (
  shouldCache = false
): Promise<ApiResponse<Family[]>> => {
  return axiosECS.get(path, {
    params: {
      shouldCache,
    },
  });
};

export const createFamily = async (
  family: Family
): Promise<ApiResponse<Family>> => {
  return axiosECS.post(path, family);
};
export const updateFamily = async (
  family: Family
): Promise<ApiResponse<Family>> => {
  return axiosECS.patch(path, family);
};

export const deleteFamilyList = async (
  ids: string[]
): Promise<ApiResponse<any[]>> => {
  return axiosECS.delete(path, { data: { ids } });
};
