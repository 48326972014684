import { ApiResponse } from "interfaces/models/api";
import { NeptuneArea } from "interfaces/models/area";
import { axiosECS } from "services/baseAxios";
import { validateBodyReq, validateListString } from "utils/common";

const path = "/v1/areas";

export const getNeptuneAreaList = async (
  bimFileId: string
): Promise<ApiResponse<NeptuneArea[]>> => {
  // actual api temporary not in used
  // return axiosECS.get(path, {
  //   params: { bimFileId: encodeURIComponent(bimFileId || "") },
  // });
  return { data: [] };
};

export const createNeptuneArea = async (body: {
  bimFileId: string;
  version: number;
}) => {
  // actual api temporary not in used
  // return axiosECS.post(path, body);
  return { data: body };
};

export const updateNeptuneArea = async (
  area: NeptuneArea
): Promise<ApiResponse<NeptuneArea>> => {
  return axiosECS.patch(path, validateBodyReq(area));
};

export const deleteNeptuneAreaList = async (
  ids: string[]
): Promise<ApiResponse<any[]>> => {
  return axiosECS.delete(path, {
    data: { ids: validateListString(ids) },
  });
};
