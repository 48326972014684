import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

function useTextHover() {
  const textElementRef = useRef<HTMLParagraphElement>(null);
  const { levelSelected } = useSelector(
    (state: RootState) => state.forgeViewer
  );

  // compare once and add resize listener on "componentDidMount"
  useEffect(() => {
    const element = textElementRef.current;

    const isTextClamped = (elm: HTMLElement) => {
      return elm.scrollHeight > elm.clientHeight;
    };

    const observer = new MutationObserver(() => {
      if (!element) {
        setHover(false);

        return;
      }
      const isOverflow = isTextClamped(element as any);
      setHover(isOverflow);
    });

    if (element) {
      observer.observe(element, {
        characterData: true,
        subtree: true,
      });
    }

    return () => {
      element && observer.disconnect();
    };
  }, [levelSelected]);

  // Define state and function to update the value
  const [hoverStatus, setHover] = useState(false);

  return { hoverStatus, textElementRef };
}

export default useTextHover;
