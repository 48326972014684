import { error } from "console";
import uniqBy from "lodash/uniqBy";
import { View } from "apiClient/v2/forgeApi";
import {
  createBimViewList,
  getGeneratedViewList,
} from "apiClient/v2/bims/bimViewApi";
import { FORGE_DATA_VIEW } from "constants/s3";
import CreateDocHelper from "helper/createDocHelper";
import { ViewData } from "interfaces/models/bimView";
import { DataProjectModel } from "interfaces/models/dataProjectModel";
import { convertBlobUrlToFile, uploadFileToS3 } from "utils/file";
import { getCurrentViewer, getSizeOfModelBoundingBox } from "utils/forge";
import {
  checkIsGenerateViewDone,
  getInstanceTableName,
} from "utils/forge/s3Data";
import { logDev } from "utils/logs";
import { getAllBimViewByVersionId } from "apiClient/v2/forgeApi";
import { getDerivativesByVersionId } from "apiClient/v2/forgeApi";
import { VIEW_ROLE } from "constants/forge";
import { DEFAULT_SHEET_UNIT_SCALE, get3DTo2DMatrix } from "utils/forge/aec";
import { message } from "components/base";

export default class GenView {
  private _bimFileId;
  private _version;
  private _aecData: any;
  private _dataViews: {
    guid: string;
    sheetGuid?: string;
    file: File;
    name: string;
    viewData: ViewData;
  }[];

  constructor(bimFileId: string, version: string) {
    this._bimFileId = bimFileId;
    this._version = version;
    this._dataViews = [];
  }
  async setAecData(aecData: any) {
    this._aecData = aecData;
  }

  clearData() {
    this._dataViews = [];
    this._aecData = undefined;
  }

  getDataView() {
    return this._dataViews;
  }

  getViewBySheetId(
    view2ds: View[],
    derivative: any,
    sheetGuid: string,
    isMatchedSheet = false
  ) {
    if (isMatchedSheet) {
      const viewMatch = view2ds.find((v) => v.guid === derivative.guid);
      if (viewMatch) {
        return { ...viewMatch, sheetGuid };
      }
    }
    let matchGuid = undefined;
    derivative?.children?.find((child: any) => {
      const guid = this.getViewBySheetId(
        view2ds,
        child,
        sheetGuid,
        isMatchedSheet || child.guid === sheetGuid
      );
      if (guid) {
        matchGuid = guid;
      }

      return !!guid;
    });

    return matchGuid;
  }

  getVersionId() {
    const urn = `${this._bimFileId}?version=${this._version}`;

    return encodeURIComponent(urn);
  }

  async getViewGenerate(dataProjectDetail: DataProjectModel) {
    const versionId = this.getVersionId();
    const promiseDerivative = getDerivativesByVersionId({
      projectId: dataProjectDetail.projectId,
      versionId,
    });
    const promiseAllBimView = getAllBimViewByVersionId(
      this._bimFileId,
      versionId
    );
    const [responseDerivative, responseAllBimView] = await Promise.all([
      promiseDerivative,
      promiseAllBimView,
    ]);
    if (!responseDerivative?.data || !responseAllBimView?.data) {
      return;
    }

    const selectedSheetIds = Object.values(dataProjectDetail.levelData || {})
      .map((levelData) =>
        (levelData?.sheets || []).map((sheet: any) => sheet.guid)
      )
      .flat();
    const derivative = responseDerivative.data;
    const views = (responseAllBimView.data || []) as View[];
    const view3ds: View[] = [];
    const view2ds: View[] = [];
    views.forEach((v) => {
      if (v.role === VIEW_ROLE.ROLE_3D) {
        view3ds.push(v);
      } else {
        view2ds.push(v);
      }
    });
    const view2dSheets = selectedSheetIds
      .map((sheetId) => {
        return this.getViewBySheetId(view2ds, derivative, sheetId);
      })
      .filter((v) => {
        return v;
      });
    const allViews = [...view3ds, ...view2dSheets];

    // width 3d -> generate all,  width 2d , we will only generate view by sheet selected
    return uniqBy(allViews, "guid") as View[];
  }

  async checkGenerated(dataProjectDetail: DataProjectModel) {
    const urn = `${dataProjectDetail.id}?version=${dataProjectDetail.version}`;
    const versionId = encodeURIComponent(urn);
    const promiseBimView = getGeneratedViewList(versionId);
    const promiseViewGenerates = this.getViewGenerate(dataProjectDetail);
    const [responseBimView, viewGenerates] = await Promise.all([
      promiseBimView,
      promiseViewGenerates,
    ]);
    const bimViews = responseBimView?.data;
    if (!bimViews || !viewGenerates) {
      return false;
    }

    return checkIsGenerateViewDone(viewGenerates, bimViews);
  }

  async addDataView(view: View) {
    const viewer = getCurrentViewer();
    if (!viewer) {
      logDev("not have viewer");
      message.error("ビューの情報がありません。");

      return false;
    }
    const createDocHelper = new CreateDocHelper();
    const capture = await createDocHelper.captureKeynote([], false);
    const captureGrayScale = await createDocHelper.captureKeynote([]);
    if (!capture || !captureGrayScale) {
      message.error("ビューの画像を撮影できません");
      logDev("cannot capture view", view.guid);

      return;
    }

    const fileImage = await convertBlobUrlToFile(
      capture.blobUrl,
      `view-${view.guid}.webp`
    );
    const fileImageGrayScale = await convertBlobUrlToFile(
      captureGrayScale.blobUrl,
      `view-gray-scale-${view.guid}.webp`
    );

    const subPath = getInstanceTableName({
      bimFileId: this._bimFileId!,
      version: this._version!,
    });
    const mainPath = `${FORGE_DATA_VIEW}/${subPath}`;
    const files = [fileImage, fileImageGrayScale];
    const responseUploadImages = await Promise.all(
      files.map((file) => {
        return uploadFileToS3(file, file.name || "", `${mainPath}/img`, {
          keepOriginName: true,
        });
      })
    );
    const isUploadOk = responseUploadImages.every((url) => !!url);
    if (!isUploadOk) {
      message.error("ビューの画像を撮影できません");

      return;
    }

    let { width: widthOfModelBoundingBox, height: heightOfModelBoundingBox } =
      getSizeOfModelBoundingBox();
    if (view.role === VIEW_ROLE.ROLE_3D) {
      widthOfModelBoundingBox = capture.imageWidth;
      heightOfModelBoundingBox = capture.imageHeight;
    }
    const offsetX =
      (Number(capture?.canvas?.offsetWidth) - widthOfModelBoundingBox) / 2;
    const offsetY =
      (Number(capture?.canvas?.offsetHeight) - heightOfModelBoundingBox) / 2;
    const imageWidth = widthOfModelBoundingBox;
    const imageHeight = heightOfModelBoundingBox;
    const camera = viewer?.impl?.camera;
    if (!camera) {
      message.error("カメラの情報を取得できません。");

      return false;
    }
    const canvasBounding = viewer.impl.getCanvasBoundingClientRect();
    //@ts-ignore
    const matrixWorldInverse = camera.matrixWorldInverse.toArray();
    //@ts-ignore
    const projectionMatrix = camera.projectionMatrix.toArray();
    let sheetTransformMatrix: THREE.Matrix4 = new THREE.Matrix4();
    const viewport = this._aecData.viewports.find(
      (v: any) => v.sheetGuid === view?.sheetGuid
    );
    if (!!viewport) {
      const matrix = get3DTo2DMatrix(viewport, DEFAULT_SHEET_UNIT_SCALE);
      if (matrix) {
        sheetTransformMatrix = matrix.clone();
      }
    }
    //@ts-ignore
    const sheetTransformMatrixArray = sheetTransformMatrix.toArray();
    const views = [
      {
        viewId: view.guid,
        name: view.name,
        sheetGuid: view.sheetGuid,
        imageUrl: `${mainPath}/img/${fileImage.name}`,
        imageGrayScaleUrl: `${mainPath}/img/${fileImageGrayScale.name}`,
        viewData: {
          role: view.role,
          offsetX,
          offsetY,
          imageHeight,
          imageWidth,
          worldToClientInfo: {
            camera: {
              matrixWorldInverse,
              projectionMatrix,
            },
            canvasBounding,
          },
          sheetTransformMatrix: sheetTransformMatrixArray,
        },
      },
    ];
    const urn = `${this._bimFileId}?version=${this._version}`;
    const bimId = encodeURIComponent(urn);
    const response = await createBimViewList({ bimFileId: bimId, views });

    return response?.data;
  }

  async uploadViewData() {
    const subPath = getInstanceTableName({
      bimFileId: this._bimFileId!,
      version: this._version!,
    });
    const mainPath = `${FORGE_DATA_VIEW}/${subPath}`;
    const responseUploadImages = await Promise.all(
      this._dataViews.map((view) => {
        return uploadFileToS3(
          view.file,
          view.file.name || "",
          `${mainPath}/img`,
          {
            keepOriginName: true,
          }
        );
      })
    );
    const isUploadOk = responseUploadImages.every((url) => !!url);
    if (isUploadOk) {
      const views = this._dataViews.map((v) => {
        return {
          viewId: v.guid,
          name: v.name,
          sheetGuid: v.sheetGuid,
          imageUrl: `${mainPath}/img-3d/${v.file.name}`,
          viewData: v.viewData,
        };
      });
      const urn = `${this._bimFileId}?version=${this._version}`;
      const bimId = encodeURIComponent(urn);
      const response = await createBimViewList({ bimFileId: bimId, views });

      return !!response.data;
    }

    return false;
  }
}
