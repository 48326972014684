import { useQuery } from "hooks/useQuery";
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useReducer,
} from "react";
import {
  InspectionFormatHandler,
  InspectionFormatHandlerContextRef,
} from "./inspectionFormatHandler";
import {
  initialInspectionFormat,
  InspectionFormat,
  InspectionFormatActions,
  InspectionFormatReducer,
  Types,
} from "./inspectionFormatReducer";

// Define the types for the props and the state
type Props = PropsWithChildren<{}>;

// Create two contexts: one for the state (TaskTypesContext) and one for the dispatch function (TaskTypesDispatchContext)
const InspectionFormatContext = createContext<any>({
  ...initialInspectionFormat,
} as any);
const InspectionFormatDispatchContext = createContext(
  (() => {}) as React.Dispatch<InspectionFormatActions>
);

// Define the provider component
export const InspectionFormatProvider = React.forwardRef<
  InspectionFormatHandlerContextRef,
  Props
>(({ children }, ref) => {
  // @ts-ignore
  const [inspectionFormat, dispatch] = useReducer(
    InspectionFormatReducer,
    initialInspectionFormat
  );

  const query = useQuery();
  const tab = query?.tab;

  useEffect(() => {
    if (tab) {
      dispatch({ type: Types.UPDATE_ACTIVE_TAB, payload: Number(tab) as any });
    }
  }, [tab]);

  return (
    <InspectionFormatContext.Provider value={inspectionFormat}>
      <InspectionFormatDispatchContext.Provider value={dispatch}>
        <InspectionFormatHandler ref={ref}>{children}</InspectionFormatHandler>
      </InspectionFormatDispatchContext.Provider>
    </InspectionFormatContext.Provider>
  );
});

// Define hooks for accessing the state and the dispatch function
export function useContextInspectionFormat() {
  return useContext(InspectionFormatContext) as InspectionFormat;
}

export function useContextInspectionFormatDispatch() {
  return useContext(InspectionFormatDispatchContext);
}
