import { CSSProperties } from "react";
import { SOURCE } from "../../components/containers/InspectionFormat/Content/SettingPinLayoutFrame/context/pinLayoutFrameReducer";

export const MAX_PIN_COMPONENT_FONT_SIZE = 72; //px;
export const MIN_PIN_COMPONENT_FONT_SIZE = 5; //px;
export const BORDER_WIDTH_PX = 2;

export const DEFAULT_PIN_COMPONENT_STYLE: PinComponentStyle = {
  fontSize: 16, // px
  textAlign: "left",
  lineHeight: "1.5",
  alignItems: "flex-start",
};

export interface PinComponentStyle {
  fontSize: number;
  textAlign: CSSProperties["textAlign"];
  lineHeight: CSSProperties["lineHeight"];
  alignItems: CSSProperties["alignItems"];
}

export interface PinComponentLinkedData {
  extraId: number;
  key: string;
  source: SOURCE;
}

export interface PinComponent {
  id: string;
  pinLayoutId: string;
  top: number;
  left: number;
  width: number;
  height: number;
  linkedData?: PinComponentLinkedData;
  style?: PinComponentStyle;
  createdAt?: Date;
  updatedAt?: Date;
}
