import { IconProps } from "@chakra-ui/react";
import { IconBase } from "components/base";

const IconClose: React.FC<IconProps> = (props) => {
  return (
    <IconBase
      width={{ base: "2rem", md: "2rem", lg: "2.4rem" }}
      height={{ base: "2rem", md: "2rem", lg: "2.4rem" }}
      icon="/img/icon-remove-group.svg"
      {...(props as any)}
    />
  );
};

export default IconClose;
