import {
  Flex,
  Grid,
  GridItem,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
} from "@chakra-ui/react";
import Header from "./Header";
import Footer from "./Footer";
import Navbar from "./Navbar";
import React, { useCallback, useMemo, useState } from "react";
import Item, { ItemData } from "./Item";
import isArray from "lodash/isArray";
import EmptyData from "components/EmptyData";

const GAP = 8;
const DEFAULT_ZOOM = 3;

export interface ContainerProps {
  isOpen: boolean;
  items?: ItemData[];
  aspectRatio?: number | string;
  selected?: string | string[];
  isMultiple?: boolean;
  title?: any;
  navBarTitle?: string;
  isLoading?: boolean;
  onSelect?(selected: string | string[] | undefined): void;
  onBack(): void;
}

const Container: React.FC<ContainerProps> = ({
  isOpen,
  items,
  aspectRatio = 4 / 3,
  selected,
  isMultiple,
  title,
  navBarTitle,
  isLoading,
  onSelect,
  onBack,
}) => {
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const [state, setState] = useState(selected);
  const stateIsArray = isArray(state);
  const stateSet = stateIsArray ? new Set<string>(state) : new Set<string>();

  const handleSelect = useCallback(
    (item: ItemData) => {
      if (isMultiple) {
        let value = isArray(state) ? state : [];
        value = value.includes(item.id)
          ? value.filter((i) => i !== item.id)
          : [...value, item.id];
        setState?.(value);
      } else {
        const value = state === item.id ? null : item.id;
        setState?.(value);
      }
    },
    [isMultiple, state]
  );

  return (
    <Modal isCentered isOpen={isOpen} onClose={onBack}>
      <ModalOverlay />
      <ModalContent
        display="flex"
        flexDirection="column"
        width="80vw"
        height="91rem"
        maxWidth="120rem"
        maxHeight="80vh"
      >
        <Header title={title} />
        <Navbar zoom={zoom} navBarTitle={navBarTitle} onZoom={setZoom} />
        <Flex
          flexGrow={1}
          borderTop="2px solid var(--primary-border-color)"
          borderBottom="2px solid var(--primary-border-color)"
          p={{ base: "2rem", lg: `${GAP - zoom}rem` }}
          alignItems="flex-start"
          overflowY="auto"
        >
          <Flex h="auto" w="100%">
            {items?.length ? (
              <Grid
                w="100%"
                templateColumns={`repeat(${zoom}, 1fr)`}
                gap={{ base: "2rem", lg: `${GAP - zoom}rem` }}
                h="auto"
              >
                {items?.map((item) => {
                  return (
                    <GridItem minW="0" key={item.id} w="100%" h="100%">
                      <Item
                        isActive={
                          isMultiple && stateIsArray
                            ? stateSet.has(item.id)
                            : state === item.id
                        }
                        item={item}
                        onClick={() => handleSelect(item)}
                        aspectRatio={aspectRatio}
                      />
                    </GridItem>
                  );
                })}
              </Grid>
            ) : (
              <Flex
                w="100%"
                h="100%"
                alignItems={"center"}
                justifyContent={"center"}
              >
                {isLoading ? (
                  <Flex>
                    <Spinner />
                  </Flex>
                ) : (
                  <EmptyData />
                )}
              </Flex>
            )}
          </Flex>
        </Flex>
        <Footer
          onBack={onBack}
          isDisabled={state === selected}
          onSelect={() => onSelect?.(state)}
          selected={state}
        />
      </ModalContent>
    </Modal>
  );
};

export default Container;
