import useFocusOut from "./useFocusOut";

const withFocusOut = (Component: any) => {
  return (props: any) => {
    const ref = useFocusOut();

    return <Component ref={ref} {...props} />;
  };
};

export default withFocusOut;
