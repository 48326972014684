import {
  Badge,
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuItemOptionProps,
  MenuList,
  MenuOptionGroup,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { PCTooltip } from "components/PCTooltip";
import { SvgIcon } from "components/SvgIcon";
import { LEVEL_OTHER_ID } from "constants/forge";
import { Level, Sheet } from "interfaces/models";
import { useEffect, useMemo, useRef, useState } from "react";

export const RenderLevelOptionItem = ({
  isSelected,
  label,
  forceShowTooltip,
  sheet,
  isShowIconSelected = true,
  ...rest
}: MenuItemOptionProps & {
  label: string;
  isSelected: boolean;
  sheet?: Sheet;
  forceShowTooltip?: boolean;
  isShowIconSelected?: boolean;
}) => {
  const [isMouseOverText, setIsMouseOverText] = useState(false);

  return (
    <MenuItemOption
      {...rest}
      className="text-ellipsis"
      fontSize={{
        base: "1.4rem",
        sm: "1.3rem",
        xxl: "1.4rem",
      }}
      onMouseOver={() => {
        setIsMouseOverText(true);
      }}
      onMouseOut={() => {
        setIsMouseOverText(false);
      }}
      bg={!isShowIconSelected && isSelected ? "#F0F9FF" : undefined}
      fontWeight="medium"
      fontFamily="NotoSansCJKjp"
      p=".5rem 1.5rem"
      borderBottom="1px solid var(--primary-border-color)"
      _last={{ borderBottom: "none" }}
      _hover={{ bg: "#F0F9FF" }}
      _focus={{ bg: "#F0F9FF" }}
      icon={undefined}
      sx={{
        "& > span:first-of-type": {
          display: "none",
        },
        "& > span:nth-of-type(2)": {
          width: "100%",
        },
      }}
    >
      <PCTooltip label={label} isDisabled={isMouseOverText}>
        <Flex alignItems="center" gap="0.5rem" width="100%">
          {isShowIconSelected && (
            <Box flexShrink={0} width="1.6rem" height="1.6rem">
              {isSelected && (
                <SvgIcon
                  src="/img/icon-action-check_circle.svg"
                  width="1.6rem"
                  height="1.6rem"
                />
              )}
            </Box>
          )}
          <Text minW="3rem" className="text-ellipsis">
            {label}
          </Text>
          {sheet?.isMissingViewport && (
            <Badge
              fontSize="1rem"
              colorScheme="red"
              p="5px 5px 3px 5px"
              borderRadius="10px"
            >
              ビューポートなし
            </Badge>
          )}
          {sheet?.isIncorrectViewport && (
            <Badge
              fontSize="1rem"
              colorScheme="red"
              p="5px 5px 3px 5px"
              borderRadius="10px"
            >
              条件満たさないシート（図面枠がない等）
            </Badge>
          )}
        </Flex>
      </PCTooltip>
    </MenuItemOption>
  );
};

interface Props {
  levels: Level[];
  isDisabled: boolean;
  isDisabledLevelOther?: boolean;
  levelSelected: Level;
  isMobile?: boolean;
  handleChangeLevel: (guid: string | string[]) => void;
}

export default function DropDownLevel(props: Props) {
  const {
    levels,
    isDisabled,
    isDisabledLevelOther,
    levelSelected,
    isMobile,
    handleChangeLevel,
  } = props;

  const levelSelectedRef = useRef(levelSelected);

  useEffect(() => {
    levelSelectedRef.current = levelSelected;
  }, [levelSelected]);

  const onClickOption = (guid: string | string[]) => {
    if (guid === levelSelectedRef?.current?.guid) {
      return;
    }
    const level = handleChangeLevel(guid);
    levelSelectedRef.current = level!;
  };

  const levelSelectedLabel = useMemo(
    () =>
      levelSelected.guid
        ? levels.find((c) => c.guid === levelSelected.guid)?.label
        : "",
    [levels, levelSelected.guid]
  );

  return (
    <>
      <Menu>
        {({ isOpen }) => (
          <>
            <MenuButton
              className="btn-level-selected"
              as={Button}
              isDisabled={isDisabled}
              variant="whiteIcon"
              minW="11rem"
              w={{
                base: "18rem",
                sm: "14rem",
                "2xl": "18rem",
              }}
              fontSize={{ base: "1.4rem", sm: "1.3rem", xxl: "1.4rem" }}
              mt={isMobile ? "1rem" : 0}
              mr="1rem !important"
              height="4rem"
              border="1px solid #A3A3A3 !important"
              padding={{
                base: "0.8rem 1.6rem",
                lg: "0.8rem 1rem",
                "2xl": "0.8rem 1.6rem",
              }}
              textAlign={"center"}
              boxShadow="none"
              sx={{
                "&:focus": {
                  boxShadow: "none",
                },
                "&[disabled]": {
                  opacity: "0.5 !important",
                },
                "span:nth-of-type(2)": {
                  base: {
                    flex: "unset",
                    mr: "auto",
                  },
                  sm: {
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  },
                },
                mr: 0,
              }}
              leftIcon={
                !levelSelectedLabel ? (
                  <Spinner size="md" color="blue.500" />
                ) : (
                  <SvgIcon src="/img/icon-maps-floor.svg" />
                )
              }
              rightIcon={
                !!levelSelectedLabel ? (
                  <SvgIcon
                    src="/img/arrow-right3.svg"
                    transition="0.4s"
                    transform={isOpen ? "rotate(270deg)" : "rotate(90deg)"}
                  />
                ) : undefined
              }
            >
              {levelSelectedLabel}
            </MenuButton>
            <MenuList
              w={{
                base: "18rem",
                sm: "14rem",
                "2xl": "18rem",
              }}
              maxH={{
                base: "48rem",
                sm: "34rem",
                "2xl": "48rem",
              }}
              overflow="auto"
              zIndex="popover"
            >
              <MenuOptionGroup
                defaultValue={levelSelected.guid || ""}
                title=""
                type="radio"
                value={levelSelected.guid || ""}
                onChange={handleChangeLevel}
              >
                {levels
                  ?.filter(
                    (level: Level) =>
                      level.guid &&
                      (level.guid !== LEVEL_OTHER_ID || level.sheets?.length)
                  )
                  .map((d, i) => (
                    <RenderLevelOptionItem
                      key={i}
                      value={d.guid}
                      label={d.label}
                      isDisabled={
                        d.guid === LEVEL_OTHER_ID ? isDisabledLevelOther : false
                      }
                      isSelected={d.guid === levelSelected.guid}
                      onClick={() => {
                        onClickOption(d.guid);
                      }}
                    />
                  ))}
              </MenuOptionGroup>
            </MenuList>
          </>
        )}
      </Menu>
    </>
  );
}
