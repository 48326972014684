import {
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Portal,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import DropdownHover from "components/DropdownHover";
import ContentIndicationModal from "components/modal/ContentIndicationModal";
import { SvgIcon } from "components/SvgIcon";
import { TagsInput } from "components/ui/TagInput";
import {
  InspectionItemType,
  MapInspectionItemColor,
  MapInspectionItemIcon,
  MapInspectionItemType,
  TASK_PRINT_MODE,
} from "constants/enum";
import { radioDefaultCSS } from "constants/styleProps";

import { MapTitleForPrintMode, MapTitleForTask } from "utils/data-logs";
import { IconBase } from "components/base";

import DocumentRelated from "./DocumentRelated";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { useMemo, useState } from "react";
import { sortArrayByField } from "utils/array";
import { WSMessage } from "interfaces/models/websocket";
import useHandleMoveTaskLabel from "./useHandleMoveTaskLabel";
import { ClickInfo } from "utils/forge/extensions/click-extension";
import { TaskLog } from "interfaces/models/task";
import TooltipForgeView from "components/TooltipForgeView";
import ConfirmModal from "../ConfirmDeleteModal";
import { useRoles } from "hooks/usePermission";

interface Props {
  taskModalInfo: TaskDTO;
  canEditTaskModal: any;
  clickInfo: ClickInfo | undefined;
  forgeViewContainerRef: React.RefObject<HTMLDivElement>;
  onDeleteTask: () => Promise<void>;
  onClose: () => void;
  addTaskLog: (logs: TaskLog[], requestId: string) => void;
  setClickInfo: React.Dispatch<React.SetStateAction<ClickInfo | undefined>>;
  changeStatusTask: (val: string) => Promise<void>;
  onChangeIdentificationMode: (val: TASK_PRINT_MODE) => void;
  onChangeTags: (tags: string[]) => void;
  updateDataOnBlurInput: (field: keyof TaskDTO) => void;
  sendWebSocketMessage: (wsMessage: WSMessage) => void;
  sendMessageToCommonChannel: (wsMessage: WSMessage) => void;
  onChangeContentType: (value: string) => void;
}

export default function ContentCommon(props: Props) {
  const { isOnline } = useSelector((state: RootState) => state.app);
  const { families } = useSelector((state: RootState) => state.forgeViewer);
  const { taskSelected, taskTypes } = useSelector(
    (state: RootState) => state.task
  );
  const [isDeleting, setIsDeleting] = useState(false);
  const [contentIndication, setContentIndication] = useState<{
    isOpen?: boolean;
    href?: string;
  }>({});
  const { isTakasagoGroup } = useRoles();

  const {
    taskModalInfo,
    canEditTaskModal,
    clickInfo,
    forgeViewContainerRef,
    onDeleteTask,
    onClose,
    addTaskLog,
    setClickInfo,
    changeStatusTask,
    onChangeIdentificationMode,
    onChangeTags,
    updateDataOnBlurInput,
    sendWebSocketMessage,
    sendMessageToCommonChannel,
    onChangeContentType,
  } = props;

  const {
    isOpen: isOpenConfirmModal,
    onOpen: onOpenConfirmModal,
    onClose: onCloseConfirmModal,
  } = useDisclosure();

  const listTaskTypeSorted = useMemo(() => {
    const taskTypeId = taskModalInfo?.taskTypeId || "";
    const filterList = taskTypes.filter(
      (e) => e.id === taskTypeId || !e.deletedAt
    );

    return sortArrayByField(filterList || [], "createdAt", false);
  }, [taskModalInfo?.taskTypeId, taskTypes]);

  const { toggleMoveTaskLabelMode, showTooltipMoveTaskLabel } =
    useHandleMoveTaskLabel({
      taskTypes,
      clickInfo,
      setClickInfo,
      handleCloseTaskModal: onClose,
      sendWebSocketMessage,
      addTaskLog,
    });

  const onDelete = async () => {
    setIsDeleting(true);
    await onDeleteTask();
    setIsDeleting(false);
  };

  const taskStatus = (taskModalInfo?.status ||
    InspectionItemType.Defect) as InspectionItemType;

  return (
    <>
      <Flex p="16px" justifyContent="space-between">
        <Flex alignItems="center" gap="1rem">
          <Flex
            h="31px"
            background="var(--primary-border-color)"
            borderRadius="4px"
            py=".5rem"
            px="1rem"
            mr="1rem"
            color="#171717"
            alignItems="center"
            fontSize={{ base: "12px", lg: "14px" }}
          >
            <Text>#{taskModalInfo?.indexId || "-"}</Text>
          </Flex>
          <Menu closeOnSelect>
            <MenuButton
              _hover={{
                opacity: 0.7,
              }}
              _active={{
                boxShadow: `#FFFFFF 0px 0px 0px 2px, ${MapInspectionItemColor[taskStatus]} 0px 0px 0px 5px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px`,
              }}
              borderRadius="4px"
              ml="-1rem"
              cursor={isTakasagoGroup ? "pointer" : "not-allowed"}
            >
              <Box>
                <Text
                  bg={MapInspectionItemColor[taskStatus]}
                  color="#fff"
                  borderRadius="4px"
                  h="31px"
                  pt=".3rem"
                  pb={{ base: 0, lg: ".3rem" }}
                  px={{ base: ".5rem", lg: "1rem" }}
                  whiteSpace="nowrap"
                  fontSize={{ base: "12px", lg: "14px" }}
                  display="flex"
                  alignItems="center"
                >
                  <SvgIcon src={MapInspectionItemIcon[taskStatus]} />
                  {MapInspectionItemType[taskStatus]}
                </Text>
              </Box>
            </MenuButton>

            {isTakasagoGroup && (
              <MenuList zIndex={19} minWidth="fit-content">
                <MenuOptionGroup
                  value={taskStatus}
                  title=""
                  type="radio"
                  onChange={(value) => {
                    changeStatusTask?.(String(value));
                  }}
                >
                  {Object.keys(MapInspectionItemType).map((type) => (
                    <MenuItemOption
                      key={type}
                      value={type}
                      icon={<SvgIcon src="/img/icon-action-check_circle.svg" />}
                      padding="0"
                    >
                      <Text
                        bg={MapInspectionItemColor[type as InspectionItemType]}
                        color="#fff"
                        py=".3rem"
                        px="1rem"
                        mx="1rem"
                        my="0.5rem"
                        borderRadius="4px"
                        display="flex"
                        alignItems="center"
                      >
                        <SvgIcon
                          src={
                            MapInspectionItemIcon[type as InspectionItemType]
                          }
                        />
                        {MapInspectionItemType[type as InspectionItemType]}
                      </Text>
                    </MenuItemOption>
                  ))}
                </MenuOptionGroup>
              </MenuList>
            )}
          </Menu>
        </Flex>

        {isTakasagoGroup && (
          <Stack direction="row" gap="1rem">
            <IconButton
              aria-label="move"
              onClick={toggleMoveTaskLabelMode}
              variant="text"
              paddingInline="0.4rem"
              icon={
                <IconBase
                  color={showTooltipMoveTaskLabel ? "#009be0" : "#171717"}
                  width="2rem"
                  height="2rem"
                  icon="/img/icon-arrows-4-directions.svg"
                />
              }
            />
            <IconButton
              isDisabled={!isOnline && !taskSelected?.offlineId}
              aria-label="deleted"
              onClick={onOpenConfirmModal}
              variant="dangerText"
              paddingInline="0.4rem"
              icon={
                <IconBase
                  width="2.2rem"
                  height="2.2rem"
                  icon="/img/icon-delete.svg"
                />
              }
            />
          </Stack>
        )}
      </Flex>
      <Flex
        w="100%"
        color="#171717"
        fontSize="1.4rem"
        mb="0.4rem"
        px="16px"
        flexDir="column"
      >
        <Text mb="0.5rem">{MapTitleForTask.taskTypeId?.title}</Text>
        <DropdownHover
          options={listTaskTypeSorted}
          value={String(taskModalInfo.taskTypeId)}
          tags={taskModalInfo?.tags}
          sendWebSocketMessage={sendWebSocketMessage}
          sendMessageToCommonChannel={sendMessageToCommonChannel}
          onChange={(value) => onChangeContentType(value as string)}
          onClickLink={(value) => {
            setContentIndication({
              isOpen: true,
              href: value,
            });
          }}
          isOnline={isOnline}
          readOnly={!canEditTaskModal?.canEditTaskTypeField}
        />
        <ContentIndicationModal
          isOpen={contentIndication.isOpen ?? false}
          href={contentIndication.href}
          onClose={() => setContentIndication({})}
        />
      </Flex>
      <Flex
        w="100%"
        color="#171717"
        fontSize="1.4rem"
        my="10px"
        px="16px"
        borderBottom="1px solid #A3A3A3"
        pb="2rem"
        flexDir="column"
      >
        <Text w="6rem" mb="0.5rem">
          {MapTitleForTask.tags?.title}
        </Text>
        <Box flex="1">
          <TagsInput
            options={families.map((family) => ({
              name: family.name,
              value: family.name,
            }))}
            value={taskModalInfo?.tags}
            placeHolder="タグ"
            onChange={(t) => onChangeTags(t)}
            onBlur={() => {
              updateDataOnBlurInput("tags");
            }}
            isWithSubmenu
            isRevertSubmenu
            zIndex={10}
            readonly={!canEditTaskModal?.canEditTagsField}
          />
        </Box>

        <RadioGroup
          mt="2rem"
          onChange={onChangeIdentificationMode as any}
          value={taskModalInfo?.printMode || TASK_PRINT_MODE.PRINTABLE}
          sx={radioDefaultCSS}
          isDisabled={!canEditTaskModal?.canEditTaskTypeField}
        >
          <Stack direction="row" gap="2rem">
            <Radio value={TASK_PRINT_MODE.PRINTABLE}>
              {MapTitleForPrintMode[TASK_PRINT_MODE.PRINTABLE]}
            </Radio>
            <Radio value={TASK_PRINT_MODE.NOT_PRINTABLE}>
              {MapTitleForPrintMode[TASK_PRINT_MODE.NOT_PRINTABLE]}
            </Radio>
          </Stack>
        </RadioGroup>
        {/* <DocumentRelated taskModalInfo={taskModalInfo} /> */}
      </Flex>

      {showTooltipMoveTaskLabel && (
        <Portal containerRef={forgeViewContainerRef}>
          <TooltipForgeView
            title="指摘ピン移動モード"
            description="※移動先をクリックしてください。"
          />
        </Portal>
      )}
      {isOpenConfirmModal && (
        <ConfirmModal
          title="指摘削除の確認"
          content="この指摘を削除しますか？"
          buttonConfirm="削除する"
          isDelete
          isOpen={isOpenConfirmModal}
          isLoading={isDeleting}
          onClose={onCloseConfirmModal}
          onProcessing={onDelete}
        />
      )}
    </>
  );
}
