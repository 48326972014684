import {
  BLACKBOARD_SCALE_DEFAULT,
  DEFAULT_PAGE_RATIO,
  DEFAULT_SIZE_GRID,
  DEFAULT_ZOOM_PAGE_RATIO,
  GRID_TEMPLATE_SIZE,
} from "constants/document";
import { CellPropertyDocumentDataForTableHeaderOptions } from "constants/documentTemplate";
import {
  Axis,
  CellProperty,
  CellSizeSetting,
  DocumentCategoryStatusType,
  LinkedDataField,
  LinkedDynamicFieldsType,
  MapDocumentCategoryStatusType,
  MapDocumentCategoryStatusTypeColor,
  PaperDirectionType,
  PaperSize,
  PaperType,
  SortDocumentType,
  TableDefaultStyle,
  TemplateComponentType,
} from "constants/enum";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { BlackBoardInfo } from "interfaces/models/blackboard";
import { BlackboardTemplate } from "interfaces/models/blackboardTemplate";
import {
  BlackboardData,
  BlackboardOption,
  CellPosition,
  CellType,
  RowType,
  SubTableType,
  TemplateComponent,
} from "interfaces/models/component";
import {
  DocumentCategory,
  DocumentSubCategory,
} from "interfaces/models/documentCategory";
import { DocumentItem } from "interfaces/models/documentItem";
import { DocumentTemplate } from "interfaces/models/documentTemplate";
import {
  MapPinGroupStatusTypeColor,
  MapPinGroupStatusType,
  PinGroupStatusType,
  MapPinGroupStatusTypeColorText,
} from "interfaces/models/pin";
import set from "lodash/set";
import { getColorByStatus, getColorTextByStatus } from "models/document";
import {
  isEquipmentDataSheetTemplate,
  isSelfInspectionTemplate,
} from "models/documentCategory";
import {
  DEFAULT_BORDER_COLOR,
  DroppedComponent,
} from "pages/document/template-page/hooks";
import { Dispatch } from "react";
import { Position } from "react-rnd";
import { setComponents } from "redux/documentSlice";
import store, { RootState } from "redux/store";
import { sortArrayByField } from "./array";
import { setSelectionMutilColorByDbId } from "./forge";
import { getDbIdByExternalId } from "./forge/viewerData";
import { getAreaExtension } from "./forge/extensions/area-extension";
import { centimetersToPixels } from "./measure";
import { arrayToObject } from "./object";
import { generateRandomString } from "./string";
import { getAllCells, getCellPositionByCellId } from "./tableCell";

interface UpdateComponentI {
  updatedComponent: TemplateComponent;
  components: TemplateComponent[];
  dispatch: Dispatch<any>;
}

interface iGetAllSubCellSizeAfterResize {
  parentCell: CellType;
  subTable: SubTableType;
  delta: { width: number; height: number };
}

export const sortDocumentCategory = (
  sortDocument: SortDocumentType,
  documentCategories: DocumentCategory[] = []
) => {
  let listDocumentCategory = documentCategories;
  switch (sortDocument) {
    case SortDocumentType.Manager:
      listDocumentCategory = documentCategories
        .slice()
        .sort((a, b) =>
          a?.userAssigned?.length || 0 > b?.userAssigned?.length! || 0 ? 1 : -1
        );

      break;
    case SortDocumentType.Author:
      listDocumentCategory = sortArrayByField(
        documentCategories,
        "userCreated"
      );
      break;
    case SortDocumentType.Deadline:
      listDocumentCategory = sortArrayByField(documentCategories, "deadline");
      break;
    case SortDocumentType.CreationTime:
      listDocumentCategory = sortArrayByField(
        documentCategories,
        "creationDateTime"
      );
      break;
    default:
      listDocumentCategory = documentCategories;
      break;
  }

  return listDocumentCategory;
};

export const isDescendant = (
  parent: HTMLElement | null,
  child: HTMLElement | null
) => {
  if (!parent || !child) return;

  let node = child.parentNode;
  while (node != null) {
    if (node == parent) {
      return true;
    }
    node = node.parentNode;
  }

  return false;
};

export const getNameByContentType = (taskTypeId: string) => {
  const taskTypes = store.getState().task.taskTypes;
  const foundItem = taskTypes.find((item) => item.id === taskTypeId);

  if (!foundItem) return "";

  return foundItem.title;
};

export const getTableSizeIncludeSubTable = (
  component: TemplateComponent | SubTableType
) => {
  let row: number = 0;
  let column: number = 0;
  const listRow = getListRowFromComponent(component);

  if (Object.keys(component).length !== 0 && listRow) {
    if (listRow.length > 0 && listRow[0].cells) {
      row = listRow.length;
      column = listRow[0].cells.reduce((a, c) => a + (c.colSpan ?? 1), 0);

      for (let index = 0; index < listRow.length; index++) {
        const newRow = listRow[index];
        if (newRow?.cells![0]?.subTable?.rows?.length) {
          const parentRowNum = 1;
          row += newRow?.cells![0]?.subTable?.rows?.length - parentRowNum; // 1 is parentRow
        }
      }
    }
  }

  return {
    row,
    column,
  };
};

export const getTableSize = (component: TemplateComponent | SubTableType) => {
  let row: number = 0;
  let column: number = 0;
  const listRow = getListRowFromComponent(component);
  if (Object.keys(component).length !== 0 && listRow) {
    if (listRow.length > 0 && listRow[0].cells) {
      row = listRow.length;
      column = listRow[0].cells.length;
    }
  }

  return {
    row,
    column,
  };
};

export const getDisplayTableSize = (component: TemplateComponent) => {
  let row: number = 0;
  let column: number = 0;
  if (
    Object.keys(component).length !== 0 &&
    component.detail &&
    component.detail.rows
  ) {
    row = component.detail.rows.filter((row) =>
      row.cells?.some((cell) => cell.rowSpan)
    ).length;
    column =
      component.detail.rows?.[0]?.cells
        ?.map((column, index) =>
          component.detail?.rows?.map((row) => row.cells?.[index])
        )
        .filter((column) => column?.some((cell) => cell?.colSpan)).length || 0;
  }

  return {
    row,
    column,
  };
};

export const getListRowFromComponent = (
  component: TemplateComponent | SubTableType
) => {
  return "rows" in component
    ? (component as SubTableType).rows
    : (component as TemplateComponent).detail?.rows;
};

export const getCurrentMergedCellInTable = (
  currentCell: CellType,
  component: TemplateComponent | SubTableType
) => {
  if (currentCell?.colSpan && currentCell?.rowSpan) {
    return currentCell;
  }
  const cellIndex = {
    col: getIndexFromId(currentCell?.idColumn),
    row: getIndexFromId(currentCell?.idRow),
  };
  let result = {} as CellType;
  const listRow = getListRowFromComponent(component);
  listRow?.forEach((row) => {
    const rowIndex = getIndexFromId(row?.idRow);
    if (rowIndex <= cellIndex.row) {
      row.cells?.forEach((cell) => {
        const columnIndex = getIndexFromId(cell?.idColumn);
        if (columnIndex <= cellIndex.col) {
          if (
            (cell.rowSpan! > 1 || cell?.colSpan! > 1) &&
            cellIndex.row - rowIndex < cell?.rowSpan! &&
            cellIndex.col - columnIndex < cell?.colSpan!
          ) {
            result = cell;
          }
        }
      });
    }
  });

  return result;
};

const getMinSizeOfCell = (
  currentCell: CellType,
  component: TemplateComponent | SubTableType
) => {
  const cell =
    !currentCell.rowSpan && !currentCell.colSpan
      ? getCurrentMergedCellInTable(currentCell, component)
      : currentCell;

  return {
    width: GRID_TEMPLATE_SIZE / (cell.colSpan || 1),
    height: GRID_TEMPLATE_SIZE / (cell.rowSpan || 1),
  };
};

export const getMinHeightCurrentRow = (
  currentCell: CellType,
  component: TemplateComponent | SubTableType
) => {
  const indexCell = getIndexCell(currentCell);
  const listRow = getListRowFromComponent(component);

  return Math.max(
    ...(listRow?.[indexCell.row]?.cells?.map((cell) =>
      cell.subTable?.rows?.length
        ? getMinSubTableSize(cell.subTable).height
        : getMinSizeOfCell(cell, component).height
    ) || [])
  );
};

export const getMinWidthCurrentColumn = (
  currentCell: CellType,
  component: TemplateComponent | SubTableType
) => {
  const indexCell = getIndexCell(currentCell);
  const listRow = getListRowFromComponent(component);

  return Math.max(
    ...(listRow?.[0].cells
      ?.map((_, indexColumn) =>
        listRow?.map((row) => row.cells?.[indexColumn]).filter((e) => e)
      )
      ?.[indexCell.col]?.map((cell) =>
        cell?.subTable?.rows?.length
          ? getMinSubTableSize(cell.subTable).width
          : getMinSizeOfCell(cell!, component).width
      ) || [])
  );
};

export const getMinRowHeight = (component: TemplateComponent) => {
  const listRow = getListRowFromComponent(component);

  return (
    listRow?.map((row) =>
      Math.max(
        ...(row.cells || [])
          ?.filter((e) => e.cellId && e)
          ?.map((cell) => {
            return cell?.subTable?.rows?.length
              ? getMinSubTableSize(cell.subTable).height
              : getMinSizeOfCell(cell, component).height;
          })
      )
    ) || []
  );
};

export const getMinSubRowHeight = (component: SubTableType) => {
  if (!component) {
    return [];
  }

  return (
    (component?.rows || [])?.map((row) =>
      Math.max(
        ...(row.cells! || []).map(
          (cell) => getMinSizeOfCell(cell, component).height
        )
      )
    ) || []
  );
};

export const getMinColumnWidth = (component: TemplateComponent) => {
  const listRow = getListRowFromComponent(component);

  return (
    listRow?.[0].cells
      ?.map((_, indexColumn) =>
        listRow?.map((row) => row.cells?.[indexColumn])?.filter((e) => e)
      )
      .map((column) =>
        Math.max(
          ...column!.map((cell) =>
            cell?.subTable?.rows?.length
              ? getMinSubTableSize(cell.subTable).width
              : getMinSizeOfCell(cell!, component).width
          )
        )
      ) || []
  );
};

export const getMinSubColumnWidth = (component: SubTableType) => {
  if (!component) {
    return [];
  }

  const listRow = component?.rows;

  return (
    listRow?.[0]?.cells
      ?.map((column, indexColumn) =>
        listRow?.map((row) => row?.cells?.[indexColumn])
      )
      .map((column) =>
        Math.max(
          ...column!.map((cell) => getMinSizeOfCell(cell!, component).width)
        )
      ) || []
  );
};

export const getMinSubTableSize = (subTable: SubTableType) => {
  const minColumnWidth = getMinSubColumnWidth(subTable);
  const minRowHeight = getMinSubRowHeight(subTable);

  return {
    width: minColumnWidth.reduce((prev, cur) => prev + cur, 0),
    height: minRowHeight.reduce((prev, cur) => prev + cur, 0),
    // height: Math.max(Math.min(...minRowHeight), GRID_TEMPLATE_SIZE),
  };
};

export const getMaxColumns = (component: TemplateComponent) => {
  const tableDisplaySize = getDisplayTableSize(component);
  const minWidth = tableDisplaySize.column * GRID_TEMPLATE_SIZE;

  return component.size.width - minWidth;
};

export const getMaxRows = (component: TemplateComponent) => {
  const tableDisplaySize = getDisplayTableSize(component);
  const minHeight = tableDisplaySize.row * GRID_TEMPLATE_SIZE;

  return component.size.height - minHeight;
};

const getStartEndPosition = (
  component: TemplateComponent,
  zoomRatio: number,
  sizePageRatio: number,
  pageDirectionRatio: number
) => {
  const componentTopLeft = {
    x: component.position?.x * zoomRatio * sizePageRatio * pageDirectionRatio,
    y: (component.position?.y * zoomRatio * sizePageRatio) / pageDirectionRatio,
  };
  const componentBottomRight = {
    x:
      componentTopLeft.x +
      component.size?.width * zoomRatio * sizePageRatio * pageDirectionRatio,
    y:
      componentTopLeft.y +
      (component.size?.height * zoomRatio * sizePageRatio) / pageDirectionRatio,
  };

  return { componentTopLeft, componentBottomRight };
};

export const getMaxMovingWidth = (
  component: TemplateComponent,
  components: TemplateComponent[],
  zoomRatio: number = getDefaultZoomPageRatio(),
  sizePageRatio: number = DEFAULT_PAGE_RATIO,
  pageDirectionRatio: number = DEFAULT_PAGE_RATIO,
  limit: {
    width: number;
    height: number;
  } = DEFAULT_SIZE_GRID
) => {
  const { componentTopLeft, componentBottomRight } = getStartEndPosition(
    component,
    zoomRatio,
    sizePageRatio,
    pageDirectionRatio
  );
  let maxMovingWidth = limit ? limit.width : 0;
  components.forEach((otherComponent) => {
    const {
      componentTopLeft: otherComponentTopLeft,
      componentBottomRight: otherComponentBottomRight,
    } = getStartEndPosition(
      otherComponent,
      zoomRatio,
      sizePageRatio,
      pageDirectionRatio
    );
    if (
      otherComponentTopLeft.x >= componentBottomRight.x &&
      !(
        otherComponentBottomRight.y <= componentTopLeft.y ||
        otherComponentTopLeft.y >= componentBottomRight.y
      )
    ) {
      maxMovingWidth = Math.min(otherComponentTopLeft.x, maxMovingWidth);
    }
  });

  return (
    maxMovingWidth -
    componentBottomRight.x -
    TableDefaultStyle.DEFAULT_BORDER_SIZE
  );
};

export const getMaxMovingHeight = (
  component: TemplateComponent,
  components: TemplateComponent[],
  zoomRatio: number = getDefaultZoomPageRatio(),
  sizePageRatio: number = DEFAULT_PAGE_RATIO,
  pageDirectionRatio: number = DEFAULT_PAGE_RATIO,
  limit: {
    width: number;
    height: number;
  } = DEFAULT_SIZE_GRID
) => {
  const { componentTopLeft, componentBottomRight } = getStartEndPosition(
    component,
    zoomRatio,
    sizePageRatio,
    pageDirectionRatio
  );
  let maxMovingHeight = limit ? limit.height : 0;
  components.forEach((otherComponent) => {
    const {
      componentTopLeft: otherComponentTopLeft,
      componentBottomRight: otherComponentBottomRight,
    } = getStartEndPosition(
      otherComponent,
      zoomRatio,
      sizePageRatio,
      pageDirectionRatio
    );
    if (
      otherComponentTopLeft.y >= componentBottomRight.y &&
      !(
        otherComponentBottomRight.x <= componentTopLeft.x ||
        otherComponentTopLeft.x >= componentBottomRight.x
      )
    ) {
      maxMovingHeight = Math.min(otherComponentTopLeft.y, maxMovingHeight);
    }
  });

  return Math.max(
    maxMovingHeight -
      componentBottomRight.y -
      TableDefaultStyle.DEFAULT_BORDER_SIZE,
    0
  );
};

export const getColumnPercentList = (component: TemplateComponent) => {
  const listCell = component.detail?.rows
    ? component.detail?.rows[0].cells || []
    : [];

  return listCell.map((cell) => {
    return {
      idCell: cell.idColumn,
      widthPercent: (cell.width || 0) / component.size.width,
    };
  });
};

export const getMergedCellWidth = (cell: CellType, row: RowType) => {
  let width = 0;
  const cellIndex = row.cells?.findIndex(
    (item) => item.idColumn === cell.idColumn
  );
  if (cellIndex !== undefined) {
    for (let i = 0; i < (cell.colSpan || 0); i++) {
      width += row.cells![cellIndex + i].width || 0;
    }
  }

  return width;
};

export const getMergedCellHeight = (cell: CellType, column: CellType[]) => {
  let height = 0;
  const cellIndex = getIndexFromId(cell.idRow);
  for (let i = 0; i < (cell.rowSpan || 0); i++) {
    height += column[cellIndex + i].height || 0;
  }

  return height;
};

export const getIndexFromId = (id: string | undefined) => {
  return id ? Number(id.slice(id.lastIndexOf("-") + 1)) : -1;
};

export const getIndexCell = (cell: CellType) => {
  return {
    row: getIndexFromId(cell.idRow),
    col: getIndexFromId(cell.idColumn),
  };
};

export const getIndexFromSubCellId = (id: string) => {
  const splitted = id.split("-");

  return {
    parentIndex: {
      row: Number(splitted[splitted.indexOf("tr") + 1]),
      col: Number(splitted[splitted.indexOf("td") + 1]),
    },
    index: {
      row: Number(splitted[splitted.lastIndexOf("tr") + 1]),
      col: Number(splitted[splitted.lastIndexOf("td") + 1]),
    },
  };
};

export const getOriginalSize = (rows: RowType[], cell: CellType) => {
  const indexCell = getIndexCell(cell);
  const deltaWidth = rows[indexCell.row]?.cells
    ?.filter(
      (currentCell) =>
        indexCell.col < getIndexFromId(currentCell.idColumn) &&
        getIndexFromId(currentCell.idColumn) <
          indexCell.col + Number(cell.colSpan)
    )
    .reduce((totalWidth, item) => totalWidth + Number(item.width), 0);
  const deltaHeight = rows
    .filter(
      (row) =>
        indexCell.row < getIndexFromId(row.idRow) &&
        getIndexFromId(row.idRow) < indexCell.row + Number(cell.rowSpan)
    )
    .reduce(
      (totalHeight, item) =>
        totalHeight + Number(item?.cells![indexCell.col].height),
      0
    );

  return {
    width: Number(cell.width) - Number(deltaWidth),
    height: Number(cell.height) - Number(deltaHeight),
  };
};

export const getAllCellSize = (rows: RowType[], selectedCell: CellType) => {
  const indexCell = getIndexCell(selectedCell);
  const measuredRow =
    rows.length === selectedCell.rowSpan
      ? rows[indexCell.row]
      : indexCell.row === 0
      ? rows[indexCell.row + Number(selectedCell.rowSpan)]
      : rows[0];
  const widthList = measuredRow.cells?.map((cell) =>
    Number(cell.colSpan) > 1 ? getOriginalSize(rows, cell).width : cell.width
  ) as number[];

  const measuredColumn =
    rows[0].cells?.length === selectedCell.colSpan
      ? rows.map((row) => row.cells![indexCell.col]) // fix height original cell
      : indexCell.col === 0
      ? rows.map(
          (row) => row.cells![indexCell.col + Number(selectedCell.colSpan)]
        )
      : rows.map((row) => row.cells![0]);
  const heightList = measuredColumn.map((cell) =>
    Number(cell.rowSpan) > 1 ? getOriginalSize(rows, cell).height : cell.height
  ) as number[];

  return {
    widthList,
    heightList,
  };
};

export const getScaleBlackboard = (
  blackBoardElement: HTMLElement,
  size: { width: number; height: number }
) => {
  const blackBoardWidth = blackBoardElement?.offsetWidth || 0;
  const blackBoardHeight = blackBoardElement?.offsetHeight || 0;

  const widthScaleExpected = size.width * BLACKBOARD_SCALE_DEFAULT;
  const heightScaleExpected = size.height * BLACKBOARD_SCALE_DEFAULT;

  const scaleX = widthScaleExpected / blackBoardWidth;
  const scaleY = heightScaleExpected / blackBoardHeight;

  return blackBoardWidth / blackBoardHeight >=
    widthScaleExpected / heightScaleExpected
    ? scaleX
    : scaleY;
};

export const updateDOM = (
  cell: CellType,
  value: number,
  axis: CellSizeSetting.WIDTH | CellSizeSetting.HEIGHT
) => {
  const element = document.getElementById(cell?.cellId) as HTMLDivElement;

  if (element) element.style[axis] = `${value}px`;

  if (axis === CellSizeSetting.HEIGHT) {
    const textElement = document.getElementById(`text-${cell?.cellId}`);
    if (textElement) {
      textElement.style.maxHeight = `${value}px`;
    }
  }
};

export const updateRowDOM = (idRow: string, value: number) => {
  const rowElement = document.getElementById(idRow);

  if (rowElement) {
    rowElement.style.height = `${value}px`;
  }
};

export const updateColDOM = (idCol: string, value: number) => {
  const colElement = document.getElementById(`col-${idCol}`);
  if (colElement) {
    colElement.style.width = `${value}px`;
  }
};

export const getCellFromCellId = (
  component: TemplateComponent,
  cellId: string
) => {
  const listRow = [...(component?.detail?.rows ?? [])];

  const indexCell = getIndexFromId(cellId);

  const indexRow = Number(cellId.split("-")[2]);

  return listRow![indexRow]?.cells![indexCell];
};

export const updateBorderHeaderTable = (
  component: TemplateComponent,
  borderHeader: string,
  borderCell: string
) => {
  const headerTable = document.getElementById(
    `header-${component.componentId}`
  ) as HTMLElement;

  if (headerTable) headerTable.style.borderWidth = borderHeader;

  const rows = [...(component.detail?.rows ?? [])];

  rows.forEach((row, index) => {
    if (index === 0) {
      row.cells?.forEach((cell) => {
        const cellElement = document.getElementById(cell?.idColumn);

        if (cellElement) {
          cellElement.style.borderWidth = borderCell;
        }
      });
    }
  });
};

export const getRepeatSubCell = (
  components: TemplateComponent[],
  component: TemplateComponent,
  subCellId: string
) => {
  const indexCell = getIndexFromSubCellId(subCellId);

  const linkedTables = components.filter((c) => !!c?.linkedHeaderId);

  if (component?.type === TemplateComponentType.TableHeader && linkedTables) {
    if (indexCell.index.row === 0) return [];

    const subCellAll = linkedTables.flatMap((table) => {
      return (
        table.detail?.rows?.flatMap((row) =>
          row.cells?.[indexCell.parentIndex.col].subTable?.rows?.map(
            (subRow) => subRow.cells?.[indexCell.index.col] as CellType
          )
        ) ?? []
      );
    });

    return subCellAll;
  }

  return component.detail?.rows?.flatMap((row) =>
    row.cells?.[indexCell.parentIndex.col].subTable?.rows?.map(
      (subRow) => subRow.cells?.[indexCell.index.col]
    )
  );
};

export const getRepeatSubCellInRow = (
  component: TemplateComponent,
  subCellId: string
) => {
  const indexCell = getCellPositionByCellId({
    cellId: subCellId,
    component,
  });

  return component.detail?.rows
    ?.filter((row) => row.isDuplicateRow)
    .map(
      (row) =>
        row.cells?.[indexCell.parentIndex.col].subTable?.rows?.[
          indexCell.index.row
        ].cells?.[indexCell.index.col] as CellType
    );
};

export const getImpactedSubCellInRow = (
  component: TemplateComponent,
  subCellId: string
) => {
  const indexCell = getIndexFromSubCellId(subCellId);

  return component.detail?.rows?.[indexCell.parentIndex.row].cells
    ?.filter((cell) => cell.subTable?.rows?.length)
    .flatMap((cell) => {
      return cell.subTable?.rows?.[indexCell.index.row].cells?.filter(
        (subCell) => subCell && subCell.idColumn !== subCellId
      );
    });
};

export const getRepeatSubRow = (row: RowType, component: TemplateComponent) => {
  const indexCell = getIndexFromSubCellId(row.cells![0].idColumn);

  return component.detail?.rows
    ?.filter((row) => row.isDuplicateRow)
    .map(
      (row) =>
        row.cells?.[indexCell.parentIndex.col].subTable?.rows?.[
          indexCell.index.row
        ] as RowType
    );
};

export const getParentCell = (
  subCell: CellType,
  component: TemplateComponent
) => {
  const parentIndex = getIndexFromSubCellId(subCell.idColumn).parentIndex;

  return component.detail?.rows?.[parentIndex.row].cells?.[
    parentIndex.col
  ] as CellType;
};

export const sortNearestComponentPosition = (
  component: TemplateComponent,
  c1: TemplateComponent,
  c2: TemplateComponent,
  newPosition: Position,
  axis: Axis.VERTICAL | Axis.HORIZONTAL
) => {
  if (axis === Axis.HORIZONTAL) {
    if (newPosition!.x >= component.position.x) {
      return c1.position.x - c2.position.x;
    }

    return c2.position.x + c2.size.width - c1.position.x - c1.size.width;
  } else {
    if (newPosition!.y >= component.position.y) {
      return c1.position.y - c2.position.y;
    }

    return c2.position.y + c2.size.height - c1.position.y - c1.size.height;
  }
};

export const isInsideSelection = (
  component: TemplateComponent,
  position: Position,
  size: { width: number; height: number }
) => {
  const isHorizontal =
    component.position.x >= position.x &&
    component.position.x + component.size.width <= position.x + size.width;

  const isVertical =
    component.position.y >= position.y &&
    component.position.y + component.size.height <= position.y + size.height;

  return isHorizontal && isVertical;
};

export const getMapDocumentStatus = (documentItems: DocumentItemDTO[]) => {
  const mapDocumentItemStatus: { [key: string]: string } = {};

  documentItems?.forEach((item) => {
    Object.assign(mapDocumentItemStatus, {
      [String(item?.id)]: item?.status,
    });
  });

  return mapDocumentItemStatus;
};

export const isComponentIncludesCell = (
  component: TemplateComponent,
  cell: CellType
) => component.componentId === cell.idColumn.split("-")[0];

export const resizeSubTable = (
  parentCell: CellType,
  subTable: SubTableType,
  delta: { width: number; height: number },
  option?: {
    isResizeAllRow: boolean;
  }
) => {
  const listRow = [...(subTable.rows ?? [])];
  let allCellSize = getAllSubCellSizeAfterResize({
    parentCell,
    subTable,
    delta,
  });
  if (option?.isResizeAllRow) {
    allCellSize = allCellSize.map((cellSize) =>
      cellSize.map(({ width }) => ({
        width,
        height: delta.height,
      }))
    );
  }

  return {
    ...subTable,
    rows: listRow.map((row, indexRow) => ({
      ...row,
      cells: row.cells?.map((cell, indexCell) => {
        const mergedCellSize = getMergedSubCellSize(
          cell,
          subTable,
          allCellSize
        );
        const currentCellSize = allCellSize[indexRow][indexCell];

        const newWidth =
          Number(cell.colSpan) > 1
            ? mergedCellSize?.width
            : currentCellSize?.width;
        const newHeight =
          Number(cell.rowSpan) > 1
            ? mergedCellSize?.height
            : currentCellSize?.height;

        return {
          ...cell,
          width: newWidth,
          height: newHeight,
        };
      }),
    })),
  } as SubTableType;
};

export const getMergedSubCellSize = (
  cell: CellType,
  subTable: SubTableType,
  allCellSize: {
    width: number;
    height: number;
  }[][]
) => {
  const indexCell = getIndexCell(cell);
  const width = subTable.rows?.[indexCell.row]?.cells
    ?.filter(
      (currentCell) =>
        indexCell.col <= getIndexFromId(currentCell.idColumn) &&
        getIndexFromId(currentCell.idColumn) <
          indexCell.col + Number(cell.colSpan)
    )
    ?.reduce((totalWidth, item) => {
      const indexItem = getIndexCell(item);

      return totalWidth + allCellSize[indexItem.row]?.[indexItem.col]?.width;
    }, 0);
  const height = subTable.rows
    ?.filter(
      (row) =>
        indexCell.row <= getIndexFromId(row.idRow) &&
        getIndexFromId(row.idRow) < indexCell.row + Number(cell.rowSpan)
    )
    ?.reduce((totalHeight, item) => {
      const indexItem = getIndexCell(item.cells![indexCell.col]);

      return totalHeight + allCellSize[indexItem.row]?.[indexItem.col]?.height;
    }, 0);

  return {
    width: width as number,
    height: height as number,
  };
};

export const getAllSubCellSizeAfterResize = ({
  parentCell,
  subTable,
  delta,
}: iGetAllSubCellSizeAfterResize) => {
  const listRow = [...(subTable.rows ?? [])];
  const subTableSize = getTableSize(subTable);

  let allCellSize = listRow.map((row) => {
    return row.cells!.map((cell) => ({
      width:
        Number(cell.colSpan) > 1
          ? getOriginalSize(listRow, cell).width
          : cell.width!,
      height:
        Number(cell.rowSpan) > 1
          ? getOriginalSize(listRow, cell).height
          : cell.height!,
    }));
  });

  if (delta.width !== 0) {
    const minColumnWidth = getMinSubColumnWidth(subTable);
    const minWidth = minColumnWidth.reduce((prev, cur) => prev + cur, 0);

    if (delta.width > 0) {
      const widthUp = delta.width / subTableSize.column;

      allCellSize = listRow.map((row, indexRow) => {
        return row.cells!.map((cell, indexCell) => {
          const currentCell = allCellSize[indexRow][indexCell];

          return {
            ...currentCell,
            width: currentCell.width! + widthUp,
          };
        });
      });
    } else if (delta.width + ((parentCell.width || 0) - minWidth) <= 0) {
      allCellSize = listRow.map((row, indexRow) => {
        return row.cells!.map((cell, indexCell) => {
          return {
            ...allCellSize[indexRow][indexCell],
            width: minColumnWidth[indexCell],
          };
        });
      });
    } else {
      const widthTableCanResize = (parentCell.width || 0) - minWidth;
      const avgDown = ((-1 * delta.width) / widthTableCanResize) * 100;
      allCellSize = listRow.map((row, indexRow) => {
        return row.cells!.map((cell, indexCell) => {
          const currentCell = allCellSize[indexRow][indexCell];
          const width =
            currentCell.width! -
            ((currentCell.width! - minColumnWidth[indexCell]) * avgDown) / 100;

          return {
            ...currentCell,
            width: width,
          };
        });
      });
    }
  }

  if (delta.height !== 0) {
    const minRowHeight = getMinSubRowHeight(subTable);
    const minHeight = minRowHeight.reduce((prev, cur) => prev + cur, 0);

    if (delta.height > 0) {
      const heightUp = delta.height / subTableSize.row;

      allCellSize = listRow.map((row, indexRow) => {
        return row.cells!.map((cell, indexCell) => {
          const currentCell = allCellSize[indexRow][indexCell];
          const height = currentCell.height! + heightUp;

          return {
            ...currentCell,
            height: height,
          };
        });
      });
    } else if (delta.height + ((parentCell.height || 0) - minHeight) <= 0) {
      allCellSize = listRow.map((row, indexRow) => {
        return row.cells!.map((cell, indexCell) => {
          return {
            ...allCellSize[indexRow][indexCell],
            height: minRowHeight[indexRow],
          };
        });
      });
    } else {
      const heightTableCanResize = (parentCell.height || 0) - minHeight;
      const avgDown = ((-1 * delta.height) / heightTableCanResize) * 100;

      allCellSize = listRow.map((row, indexRow) => {
        return row.cells!.map((cell, indexCell) => {
          const currentCell = allCellSize[indexRow][indexCell];
          const height =
            currentCell.height! -
            ((currentCell.height! - minRowHeight[indexRow]) * avgDown) / 100;

          return {
            ...currentCell,
            height: height,
          };
        });
      });
    }
  }

  return allCellSize;
};

export const updateSubTableDOM = (
  subTable: SubTableType,
  allCellSize: {
    width: number;
    height: number;
  }[][],
  delta: { width: number; height: number }
) => {
  const listRow = [...(subTable.rows ?? [])];

  listRow?.forEach((subRow, indexSubRow) => {
    updateRowDOM(subRow.idRow, allCellSize[indexSubRow][0].height);

    subRow.cells?.forEach((subCell, indexSubCell) => {
      if (indexSubRow === 0) {
        updateColDOM(subCell.idColumn, allCellSize[0][indexSubCell].width);
      }

      delta.width !== 0 &&
        updateDOM(
          subCell,
          allCellSize[indexSubRow][indexSubCell].width,
          CellSizeSetting.WIDTH
        );

      delta.height !== 0 &&
        updateDOM(
          subCell,
          allCellSize[indexSubRow][indexSubCell].height *
            (subCell?.rowSpan || 1),
          CellSizeSetting.HEIGHT
        );
    });
  });
};

export const modifyProperties = (
  component: TemplateComponent,
  currentTemplate: DocumentTemplate,
  selectedCells: CellType[],
  { value, cellProperty, cellLinkedData, style }: Partial<CellType>
) => {
  const listRow = [...(component?.detail?.rows ?? [])];
  const isSelectSubCell = selectedCells.some((cell) => cell.isSubCell);

  const cellOpt = {
    ...(value !== undefined ? { value } : {}),
    ...(style !== undefined ? { style } : {}),
    ...(cellProperty !== undefined ? { cellProperty } : {}),
    ...(cellLinkedData !== undefined ? { cellLinkedData } : {}),
  };

  const getDynamicFieldLabel = (
    componentType: string,
    isModuleChiller: boolean,
    cell: CellType,
    cellOpt: any
  ) => {
    const isDynamicCellModuleChiller =
      isModuleChiller &&
      cell?.cellLinkedData?.field ===
        LinkedDataField.COMMON.DYNAMIC_FIELDS_FOR_ITEM &&
      componentType !== TemplateComponentType.TableHeader;

    return (
      (isDynamicCellModuleChiller
        ? cell?.cellLinkedData?.options?.dynamicFieldLabel
        : cellOpt?.cellLinkedData?.options?.dynamicFieldLabel) || ""
    );
  };

  const newRows = listRow.map((row) => {
    let listCell: CellType[] = [...(row.cells ?? [])];
    listCell = listCell.map((cell) => {
      const isSelected =
        !isSelectSubCell &&
        selectedCells?.find((selectedCell) => {
          if (isSelfInspectionTemplate(currentTemplate?.documentType)) {
            return selectedCell?.idColumn === cell?.idColumn;
          }

          return (
            (!!component.linkedHeaderId || selectedCell.idRow === cell.idRow) &&
            getIndexFromId(selectedCell.idColumn) ===
              getIndexFromId(cell.idColumn)
          );
        });

      if (isSelected) {
        const dynamicFieldLabel = getDynamicFieldLabel(
          component.type,
          isEquipmentDataSheetTemplate(currentTemplate?.documentType),
          cell,
          cellOpt
        );
        const newCell = {
          ...cell,
          ...cellOpt,
          cellLinkedData: {
            ...cellOpt?.cellLinkedData,
            options: {
              ...cellOpt?.cellLinkedData?.options,
              dynamicFieldLabel,
            },
          },
        };

        if (
          cellLinkedData?.options?.dynamicFieldSection?.id === null &&
          newCell?.cellLinkedData?.options?.dynamicFieldSection?.id
        ) {
          delete newCell?.cellLinkedData?.options?.dynamicFieldSection?.id;
        }

        return newCell;
      }

      const indexCell = getIndexCell(cell);
      const isParentSelected = selectedCells?.find((selectedCell) => {
        const indexSelectedCell = getIndexFromSubCellId(selectedCell.idColumn);

        return indexCell.col === indexSelectedCell.parentIndex.col;
      });

      if (isSelectSubCell && isParentSelected) {
        return {
          ...cell,
          subTable: {
            ...cell.subTable,
            rows: cell.subTable?.rows?.map((subRow) => ({
              ...subRow,
              cells: subRow.cells?.map((subCell) => {
                if (
                  selectedCells.find((item) => {
                    const indexItem = getIndexCell(item);
                    const indexSubCell = getIndexCell(subCell);

                    return (
                      indexItem.col === indexSubCell.col &&
                      indexItem.row === indexSubCell.row
                    );
                  })
                ) {
                  const dynamicFieldLabel = getDynamicFieldLabel(
                    component.type,
                    isEquipmentDataSheetTemplate(currentTemplate?.documentType),
                    subCell,
                    cellOpt
                  );

                  const newSubCell = {
                    ...subCell,
                    ...cellOpt,
                    cellLinkedData: {
                      ...cellOpt?.cellLinkedData,
                      options: {
                        ...cellOpt?.cellLinkedData?.options,
                        dynamicFieldLabel,
                      },
                    },
                  };

                  if (
                    cellLinkedData?.options?.dynamicFieldSection?.id === null &&
                    newSubCell?.cellLinkedData?.options?.dynamicFieldSection?.id
                  ) {
                    delete newSubCell?.cellLinkedData?.options
                      ?.dynamicFieldSection?.id;
                  }

                  return newSubCell;
                }

                return subCell;
              }),
            })),
          },
        };
      }

      return cell;
    });

    return {
      ...row,
      cells: listCell,
    };
  });

  return {
    ...component,
    detail: {
      ...component?.detail,
      rows: newRows,
    },
  } as TemplateComponent;
};

export const sortByTitle = (
  arr?: (DocumentItem | DocumentSubCategory | BlackBoardInfo)[]
) => {
  if (!arr?.length) return [];

  const arrNoTitle = arr
    .filter((item) => !item.title)
    .sort((item1, item2) => {
      return (
        ((item1 as DocumentItem).indexId ||
          (item1 as DocumentSubCategory).documentItems?.[0].indexId ||
          0) -
        ((item2 as DocumentItem).indexId ||
          (item2 as DocumentSubCategory).documentItems?.[0].indexId ||
          0)
      );
    });
  const arrHasTitle = arr
    .filter((item) => item.title)
    .slice()
    .filter((item) => !!item)
    .sort((item1, item2) => {
      const title1 = item1.title!?.split("-");
      const title2 = item2.title!?.split("-");

      const length = Math.min(title1.length, title2.length);

      for (let i = 0; i < length; i++) {
        if (title1[i] === title2[i]) continue;
        if (!isNaN(Number(title1[i])) && !isNaN(Number(title2[i]))) {
          return Number(title1[i]) - Number(title2[i]);
        }

        return title1[i] < title2[i] ? -1 : 1;
      }

      if (title1.length !== title2.length) {
        return title1.length - title2.length;
      }

      return (
        ((item1 as DocumentItem).indexId ||
          (item1 as DocumentSubCategory).documentItems?.[0].indexId ||
          0) -
        ((item2 as any).indexId ||
          (item2 as DocumentSubCategory).documentItems?.[0].indexId ||
          0)
      );
    });

  return [...arrNoTitle, ...arrHasTitle];
};

export const isOverlap = (
  dragComponent: {
    position: { x: number; y: number };
    size: { width: number; height: number };
  },
  dropArea: {
    position: { x: number; y: number };
    size: { width: number; height: number };
  }
) => {
  const dragTopLeft = dragComponent.position;
  const dragBottomRight = {
    x: dragComponent.position.x + dragComponent.size.width,
    y: dragComponent.position.y + dragComponent.size.height,
  };
  const dropAreaTopLeft = dropArea.position;
  const dropAreaBottomRight = {
    x: dropArea.position.x + dropArea.size.width,
    y: dropArea.position.y + dropArea.size.height,
  };

  if (
    dragTopLeft.x >= dropAreaBottomRight.x ||
    dropAreaTopLeft.x >= dragBottomRight.x
  ) {
    return false;
  }
  if (
    dragBottomRight.y <= dropAreaTopLeft.y ||
    dropAreaBottomRight.y <= dragTopLeft.y
  ) {
    return false;
  }

  return true;
};

interface Area {
  x?: number;
  y?: number;
  height?: number;
  width?: number;
}

const checkIsDroppedComponentIntersectedWithArea = (
  droppedComponent: DroppedComponent,
  area: Area
) => {
  return {
    //return true if droppedComponent intersected with another component on X axis
    xDir:
      (droppedComponent?.position?.y >= Number(area.y) &&
        droppedComponent?.position?.y <=
          Number(area?.y) + Number(area?.height)) ||
      (droppedComponent?.position?.y + droppedComponent?.size?.height >=
        Number(area.y) &&
        droppedComponent?.position?.y + droppedComponent?.size?.height <=
          Number(area?.y) + Number(area?.height)),
    //return true if droppedComponent intersected with another component on Y axis
    yDir:
      (droppedComponent?.position?.x >= Number(area.x) &&
        droppedComponent?.position?.x <=
          Number(area?.x) + Number(area?.width)) ||
      (droppedComponent?.position?.x + droppedComponent?.size?.width >=
        Number(area.x) &&
        droppedComponent?.position?.x + droppedComponent?.size?.width <=
          Number(area?.x) + Number(area?.width)),
  };
};

interface iGetDropableZone {
  droppedComponent: DroppedComponent;
  documentContainerSize: Pick<
    RootState["document"],
    "documentContainerSize"
  >["documentContainerSize"];
  components: TemplateComponent[];
}

export const getDropableZone = ({
  droppedComponent,
  documentContainerSize,
  components,
}: iGetDropableZone) => {
  const dropableZone = {
    x: droppedComponent?.position?.x,
    y: droppedComponent?.position?.y,
    height: documentContainerSize?.height - droppedComponent?.position?.y,
    width: documentContainerSize?.width - droppedComponent?.position?.x,
  };

  const componentAreas = components?.map((component) => ({
    x: component?.position?.x,
    y: component?.position?.y,
    width: component?.size?.width,
    height: component?.size?.height,
  }));

  componentAreas?.forEach((area) => {
    if (droppedComponent?.position?.y > area?.y + area?.height) {
      dropableZone.y = area?.y + area?.height;
    }

    if (
      droppedComponent?.position?.y < area?.y &&
      area?.y - droppedComponent?.position?.y < dropableZone?.height &&
      checkIsDroppedComponentIntersectedWithArea(droppedComponent, area).yDir
    ) {
      dropableZone.height = area?.y - droppedComponent?.position?.y;
    }

    if (
      droppedComponent?.position?.x < area.x &&
      area?.x - droppedComponent?.position?.x < dropableZone?.width &&
      checkIsDroppedComponentIntersectedWithArea(droppedComponent, area).xDir
    ) {
      dropableZone.width = area.x - droppedComponent?.position?.x;
    }
  });

  return dropableZone;
};

/**
 * The function returns the first component in an array that has the property "isLinkedTable" set to
 * true, or an empty object if none are found.
 * @param {GetLinkedTableI}
 */
export const getLinkedTable = ({
  components,
}: {
  components: TemplateComponent[];
}) => {
  return components?.find(
    (component) => !!component.linkedHeaderId
  ) as TemplateComponent;
};

/**
 * The function returns the table header component from an array of template components.
 */
export const getHeader = ({
  components,
}: {
  components: TemplateComponent[];
}) => {
  return components?.find(
    (component) => component.type === TemplateComponentType.TableHeader
  ) as TemplateComponent;
};

/**
 * This function updates a component in a list of components
 * @param {UpdateComponentI}
 */
export const updateComponent = ({
  updatedComponent,
  components,
  dispatch,
}: UpdateComponentI) => {
  const updatedComponents = components.map((component) => {
    if (component.componentId === updatedComponent.componentId) {
      return updatedComponent;
    }

    return component;
  });

  dispatch(setComponents(updatedComponents));
};

export const getDocumentStatus = (status?: any) => {
  let newStatus = status || "";
  if (
    !newStatus ||
    !Object.keys(MapDocumentCategoryStatusType).includes(newStatus)
  ) {
    newStatus = PinGroupStatusType.NotStarted;
  }
  const textColor =
    MapPinGroupStatusTypeColorText[newStatus as PinGroupStatusType];
  const title = MapPinGroupStatusType[newStatus as PinGroupStatusType];
  const bgColor =
    MapPinGroupStatusTypeColor[
      (status as PinGroupStatusType) || PinGroupStatusType.NotStarted
    ];

  return {
    status: newStatus,
    bgColor,
    title,
    textColor,
  };
};

/**
 * This function return BlackboardData
 * @param {blackboardTemplate}
 */
export const getBlackboardDataFromTemplate = (
  blackboardTemplate: BlackboardTemplate
) => {
  const components: TemplateComponent[] = blackboardTemplate?.components || [];

  const cells: CellType[] = [];
  (components || []).forEach((component) => {
    cells.push(...getAllCells(component));
  });

  const generateBlackboardData = (cells: (CellType | undefined)[]) => {
    const blackboardData: (BlackboardOption & {
      field: string;
    })[] = [];
    cells?.forEach((cell) => {
      if (
        cell?.cellId &&
        cell?.cellProperty === CellProperty.DOCUMENT_DATA &&
        cell?.cellLinkedData?.options?.blackboard?.isShow !== false
      ) {
        blackboardData.push({
          cellBlackboardId: cell?.cellId,
          cells: cell?.cellLinkedData?.options?.blackboard?.cells || [],
          label: cell?.cellLinkedData?.options?.blackboard?.label,
          order: cell?.cellLinkedData?.options?.blackboard?.order || 1,
          field: cell?.cellLinkedData?.field || "",
          isShowTitle: cell?.cellLinkedData?.options?.blackboard?.isShowTitle,
        });
      }

      if (cell?.subTable?.rows?.length) {
        cell.subTable.rows.forEach((row) => {
          if (!row?.cells?.length) {
            return;
          }

          blackboardData.push(...generateBlackboardData(row.cells));
        });
      }
    });

    return blackboardData;
  };

  const blackboardData: BlackboardData[] = sortArrayByField<BlackboardData>(
    generateBlackboardData(cells),
    "order"
  );

  return blackboardData;
};

/**
 * This function will generate subTable for field comment
 * @param cell, blackboardData
 */
export const generateSubTableForComment = ({
  cell,
  blackboardData,
  type = "Default",
}: {
  cell: CellType;
  blackboardData: BlackboardData[];
  type?: "FilterPhoto" | "Default";
}) => {
  const initCellPosition: CellPosition = {
    idRow: generateRandomString(36, 10),
    idColumn: generateRandomString(36, 10),
  };
  let newBlackboardData = structuredClone(blackboardData);
  if (type === "FilterPhoto") {
    newBlackboardData = [
      {
        label: "No.",
        field: CellProperty.NO,
        valueDefault: "1",
      },
      ...newBlackboardData,
    ];
  }

  const newSubCellHeight = Math.max(
    Number(cell.height) / newBlackboardData?.length,
    GRID_TEMPLATE_SIZE
  );

  const subTable = {
    rows: (newBlackboardData || [])?.map((item, rowIndex: any) => {
      const isShowTitle = item?.isShowTitle !== false;

      return {
        idRow: `${cell.idColumn}-tr-${rowIndex}`,
        cells: [
          {
            // this position apply for resize when repeate sub-table
            idRow: `${cell.idColumn}-tr-${rowIndex}`,
            idColumn: `${cell.idColumn}-tr-${rowIndex}-td-0`,

            cellId: generateRandomString(36, 10),
            width: Number(cell.width) / 2,
            height: newSubCellHeight,
            value: item.label,
            cellProperty: CellProperty.TEXT,
            style: {
              fontSize: cell.style?.fontSize,
              border: true,
              borderColor: cell?.style?.borderColor || DEFAULT_BORDER_COLOR,
              borderLeft: true,
              borderRight: true,
              borderBottom: true,
              borderTop: true,
            },
            colSpan: isShowTitle ? 1 : 0,
            rowSpan: 1,
            isSubCell: true,
            // this position apply for change style when repeate sub-table
            position: {
              ...initCellPosition,
              idColumn: generateRandomString(36, 10),
            },
          },
          {
            // this position apply for resize when repeate sub-table
            idRow: `${cell.idColumn}-tr-${rowIndex}`,
            idColumn: `${cell.idColumn}-tr-${rowIndex}-td-1`,
            cellId: generateRandomString(36, 10),
            width: Number(cell.width) / (isShowTitle ? 2 : 1),
            height: newSubCellHeight,
            cellProperty: CellProperty.BLACK_BOARD_DATA,
            value: item?.valueDefault,
            style: {
              fontSize: cell.style?.fontSize,
              border: true,
              borderColor: cell?.style?.borderColor || DEFAULT_BORDER_COLOR,
              borderLeft: true,
              borderRight: true,
              borderBottom: true,
              borderTop: true,
            },
            cellLinkedData: {
              field: item.field,
              type: CellProperty.TEXT,
              options: {
                blackboard: item,
              },
            },
            colSpan: isShowTitle ? 1 : 2,
            rowSpan: 1,
            isSubCell: true,
            // this position apply for change style when repeate sub-table
            position: {
              ...initCellPosition,
              idColumn: generateRandomString(36, 10),
            },
          },
        ],
      };
    }) as RowType[],
  };

  return subTable;
};

export const getDefaultZoomPageRatio = () => {
  const leftPanelWidth = 300;
  const htmlEle = document.querySelector("html");
  if (!htmlEle) {
    return 1;
  }

  const fontSize = getComputedStyle(htmlEle).fontSize;
  const fontSizeNumber = Number(fontSize.replace("px", ""));
  const rightPanelWidth = fontSizeNumber * 40;
  const contentEditorContainerWidth =
    window.innerWidth - leftPanelWidth - rightPanelWidth;
  const currentTemplate = store.getState().document.currentTemplate;
  const pageType = currentTemplate?.pages?.[0]?.pageSize;
  if (!pageType) {
    return 1;
  }

  const pageSize = PaperSize[pageType].width;
  const pageSizeNumber = centimetersToPixels(pageSize);

  if (!pageSizeNumber) {
    return 1;
  }

  return pageSizeNumber > contentEditorContainerWidth
    ? DEFAULT_ZOOM_PAGE_RATIO
    : 1;
};

export const documentItemColor = (status?: string) => {
  if (!status) {
    return MapDocumentCategoryStatusTypeColor[
      DocumentCategoryStatusType.NotStarted
    ];
  }

  return MapDocumentCategoryStatusTypeColor[
    status as DocumentCategoryStatusType
  ];
};

export const updateForgeWhenSelectCategory = (
  documentCategory: DocumentCategoryDTO
) => {
  const documentItems = documentCategory.documentItems || [];
  const selections =
    documentItems.map((item) => {
      const { r, g, b } = new THREE.Color(getColorByStatus(item.status));

      return {
        dbId: getDbIdByExternalId(item.externalId),
        color: new THREE.Vector4(r, g, b, 1),
      };
    }) || [];
  setSelectionMutilColorByDbId({
    selections,
  });
  if (documentCategory.neptuneAreaIds?.length) {
    getAreaExtension()?.select(documentCategory);
  }
};

export const transformComponentForOldTemplate = (
  template: DocumentTemplate
) => {
  const processCell = ({
    cell,
    isTableHeader,
    isLinkedTable,
    template,
    currentComponent,
  }: {
    cell: CellType;
    isTableHeader: boolean;
    isLinkedTable: boolean;
    currentComponent: TemplateComponent;
    template: DocumentTemplate;
  }) => {
    const isHasCellData = "cellData" in cell && !Array.isArray(cell.cellData);
    const tableHeaderOptions =
      CellPropertyDocumentDataForTableHeaderOptions[template.documentType];
    const components: TemplateComponent[] = template?.components || "[]";
    const tableHeaderComponent = components.find(
      (item) => item.type === TemplateComponentType.TableHeader
    );
    const isLinkedImage =
      currentComponent.type === TemplateComponentType.LinkedImage;

    if (isLinkedImage && !cell.cellProperty) {
      cell.style = {
        ...(cell.style || {}),
        borderLeft: true,
        borderRight: true,
      };
    }

    // transform cell type average measure's value has error
    const isAverageValueCell =
      cell.cellLinkedData?.options?.dynamicFieldType ===
      LinkedDynamicFieldsType.MEASURE_AVERAGE_VALUE;
    const allCells = getAllCells(currentComponent);

    if (isAverageValueCell) {
      const mapAllCells = arrayToObject(allCells, "cellId");
      const cellLinkedMeasureValue = (
        cell?.cellLinkedData?.options?.cellLinkedMeasureValue || []
      )?.filter(
        (cellId) =>
          mapAllCells?.[cellId]?.cellLinkedData?.options?.dynamicFieldType ===
          LinkedDynamicFieldsType.MEASURE_VALUE
      );

      set(
        cell,
        "cellLinkedData.options.cellLinkedMeasureValue",
        cellLinkedMeasureValue
      );
    }

    // transform cell's position
    if (!cell.position) {
      const getIdColumn = (cell: CellType) =>
        `${cell.idColumn.split("-").shift() || ""}${cell.idColumn.slice(-1)}`;
      let idColumn = getIdColumn(cell);
      const allCells = getAllCells(currentComponent);
      const isDuplicateCellId = allCells
        .filter((c) => c.idColumn !== cell.idColumn && c.idRow !== cell.idRow)
        .some((c) => c.cellId === cell.cellId);

      if (isDuplicateCellId) {
        cell.cellId = generateRandomString(36, 10);
      }

      const isMapPositionHeader =
        isLinkedTable &&
        currentComponent.linkedHeaderId === tableHeaderComponent?.componentId;
      if (isMapPositionHeader) {
        const index = getIndexCell(cell);
        const cellHeader =
          tableHeaderComponent?.detail?.rows?.[0]?.cells?.[index?.col];
        if (cellHeader) {
          idColumn = cellHeader.position
            ? cellHeader?.position?.idColumn
            : getIdColumn(cellHeader);
        }
      }

      cell.position = {
        idColumn,
        idRow: cell.idRow.split("-").join(""),
      };
    }

    // transform cell linked data
    if (isTableHeader && isHasCellData) {
      const option = tableHeaderOptions.find(
        (opt) => opt.value === cell.cellData
      );
      if (option) {
        cell.cellProperty = option.property;
        cell.cellLinkedData = {
          type: option.dataType || option.linkedDataType,
          field: option.value,
        };
      }
    }

    if (
      isLinkedTable &&
      (isHasCellData ||
        [CellProperty.IMAGE, CellProperty.COMMENT].includes(
          cell.cellProperty as any
        ))
    ) {
      const option = tableHeaderOptions.find((opt) => {
        if (isHasCellData) {
          return opt.value === cell.cellData;
        }

        return opt.value === cell.cellProperty;
      });

      if (option) {
        cell.cellProperty = option.property;
        cell.cellLinkedData = {
          type: option.linkedDataType,
          field: option.value,
        };
      }

      const blackboardTemplateDetail = template?.blackboardTemplateDetail;
      if (
        cell.cellLinkedData?.field === CellProperty.COMMENT &&
        !blackboardTemplateDetail?.id
      ) {
        delete cell?.subTable;
      }
    }

    if (isHasCellData && cell.cellData === CellProperty.NO) {
      cell.cellProperty = CellProperty.NO;
    }

    if (
      !isLinkedTable &&
      isHasCellData &&
      cell.cellProperty === CellProperty.DOCUMENT_DATA
    ) {
      let field = cell?.cellData as any;

      switch (cell.cellData) {
        case "floor":
          field = LinkedDataField.DOCUMENT_CATEGORY.LEVEL;
          break;

        case "nameUserCreate":
          field = LinkedDataField.DOCUMENT_CATEGORY.MANUFACTOR;
          break;

        default:
          break;
      }

      cell.cellLinkedData = {
        field,
      };
    }

    delete (cell as any).cellData;

    return cell;
  };

  const processRow = ({
    row,
    template,
    isTableHeader,
    isLinkedTable,
    currentComponent,
  }: {
    row: RowType;
    template: DocumentTemplate;
    isTableHeader: boolean;
    isLinkedTable: boolean;
    currentComponent: TemplateComponent;
  }) => {
    if (!row.cells) return row;
    row.cells = row.cells.map((cell) =>
      processCell({
        cell,
        isTableHeader,
        isLinkedTable,
        template,
        currentComponent,
      })
    );

    return row;
  };

  const processComponent = (
    component: TemplateComponent,
    template: DocumentTemplate
  ) => {
    const isTable = component.type === TemplateComponentType.Table;
    const isTableHeader = component.type === TemplateComponentType.TableHeader;
    const isLinkedTable = !!component?.linkedHeaderId;
    const isLinkedImage = component.type === TemplateComponentType.LinkedImage;

    // reset position for component has max size
    if (isTable || isTableHeader) {
      const pageInfo = template?.pages?.[component?.page];
      if (pageInfo) {
        const _pageSize = (pageInfo?.pageSize ||
          PaperType.A4) as keyof typeof PaperSize;
        let numOfColumns = PaperSize[_pageSize]?.numOfColumns;
        let numOfRows = PaperSize[_pageSize]?.numOfRows;
        if (pageInfo?.pageDirection === PaperDirectionType.HORIZONTAL) {
          [numOfColumns, numOfRows] = [numOfRows, numOfColumns];
        }
        const maxWidth = numOfColumns * CellSizeSetting.MIN_WIDTH;
        if (Math.round(component?.realSize?.width || 0) >= maxWidth) {
          set(component, "size.width", maxWidth);
          set(component, "realSize.width", maxWidth);
          set(component, "position.x", 0);
          set(component, "realPosition.x", 0);
        }
      }
    }

    if (!(isTable || isTableHeader || isLinkedImage)) {
      return component;
    }

    if (component.detail?.rows) {
      component.detail.rows = component.detail.rows.map((row) =>
        processRow({
          row,
          template,
          isTableHeader,
          isLinkedTable,
          currentComponent: component,
        })
      );
    }

    return component;
  };

  const components: TemplateComponent[] = template?.components || [];
  const newComponents = components.map((component) =>
    processComponent(component, template)
  );

  const updatedTemplate = {
    ...template,
    components: newComponents,
  } as DocumentTemplate;

  return updatedTemplate;
};
