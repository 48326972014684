export function cropImage(
  img: HTMLImageElement,
  displayTop: number,
  displayLeft: number,
  displayWidth: number,
  displayHeight: number
): Promise<string> {
  return new Promise((resolve) => {
    const tempCanvas = document.createElement("canvas");
    const tempCtx = tempCanvas.getContext("2d")!;

    const naturalWidth = img.naturalWidth;
    const naturalHeight = img.naturalHeight;

    const displayWidthCanvas = img.width;
    const displayHeightCanvas = img.height;

    const scaleX = naturalWidth / displayWidthCanvas;
    const scaleY = naturalHeight / displayHeightCanvas;

    const left = displayLeft * scaleX;
    const top = displayTop * scaleY;
    const width = displayWidth * scaleX;
    const height = displayHeight * scaleY;

    tempCanvas.width = width;
    tempCanvas.height = height;

    tempCtx.drawImage(img, left, top, width, height, 0, 0, width, height);

    const croppedImageURL = tempCanvas.toDataURL();

    resolve(croppedImageURL);
  });
}

export function calculateTextWidth(params: {
  fontSize: number;
  text: string;
  font?: string;
  offsetCharacterWidth?: number;
}) {
  const {
    text,
    fontSize,
    font = "sans-serif",
    offsetCharacterWidth = 0,
  } = params;

  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d")!;
  ctx.font = `${fontSize}px ${font}`;
  ctx.textBaseline = "top";
  const metrics = ctx.measureText(text);
  const textWidth = metrics.width + offsetCharacterWidth * text.length;

  return textWidth;
}

export function calculateTextHeight(params: {
  maxWidth: number;
  fontSize: number;
  lineHeight: number;
  text: string;
}) {
  const { maxWidth, fontSize, text, lineHeight } = params;
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d")!;
  ctx.font = `${fontSize}px sans-serif`;
  ctx.textBaseline = "top";
  let textLine = "";
  let textHeight = 0;

  for (let n = 0; n < text.length; n++) {
    textLine += `${text[n]}`;
    const metrics = ctx.measureText(textLine);
    const textWidth = metrics.width;
    if (textWidth > maxWidth && n > 0) {
      textHeight += lineHeight;
      textLine = `${text[n]}`;
    }
    if (n === text.length - 1) {
      textHeight += lineHeight;
    }
  }

  return textHeight;
}
