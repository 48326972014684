import { TaskLogTypeKey } from "constants/task";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { TaskComment, TaskLogDTO } from "interfaces/models/task";
import { User } from "interfaces/models/user";
import store from "redux/store";
import { uuid } from "utils/common";
import { getLocalStorage } from "utils/storage";

export const getMapTaskTypeKeyFromTaskLogs = (taskLogs: TaskLogDTO[]) => {
  return taskLogs.reduce(
    (acc, item) => Object.assign(acc, { [item.field]: item.type ?? -1 }),
    {}
  );
};

export const getMapDisplayValueFromTaskLogs = (taskLogs: TaskLogDTO[]) => {
  return taskLogs.reduce(
    (acc, item) =>
      Object.assign(acc, { [item.field]: item.value.displayValue }),
    {}
  );
};

export const getTaskContentLog = ({
  field: _field,
  taskId,
  value,
  type,
  isRevert = false,
  isLogDefault = false,
}: {
  field: keyof TaskDTO;
  taskId: string;
  value: any;
  type: TaskLogTypeKey | null;
  isRevert?: boolean;
  isLogDefault?: boolean;
}) => {
  const offlineId = uuid();
  const now = new Date();
  const user: User = getLocalStorage("userInfo");

  let field = _field as keyof TaskDTO;
  const title = typeof value !== "string" ? "" : value;

  if (type === TaskLogTypeKey.CREATE_TASK) {
    field = "unknown" as any;
  }

  const logValue: TaskComment["value"] = { data: value, isLogDefault };
  if (isRevert) {
    logValue.isRevertDataByLog = true;
  }

  const state = store.getState();
  switch (field) {
    case "taskTypeId":
      logValue.displayValue = state.task.taskTypes.find(
        (type) => type.id === value
      )?.title;
      break;

    case "partnerCompanyId":
      logValue.displayValue = state.project.partnerCompanies.find(
        (com) => com.id === value
      )?.name;
      break;
    case "userConfirmed":
    case "userAssigned":
      logValue.displayValue = state.user.users.find(
        (user) => user.id === value
      )?.name;
      break;

    default:
      break;
  }

  return {
    id: offlineId,
    offlineId,
    taskId,
    title,
    field,
    value: logValue,
    createdAt: now,
    updatedAt: now,
    createdBy: user.id,
    type: type ?? -1,
  } as TaskLogDTO;
};
