import { Image } from "@chakra-ui/react";
import { withPresignedUrl } from "components/HOC/presignedUrl";
import { memo } from "react";
import { FieldRawProps } from "../../types";
import imagePlaceholderSrc from "./image-placeholder.png";

const ImagePresigned = withPresignedUrl(Image);

const ImageRaw = (props: FieldRawProps) => {
  return (
    <>
      <ImagePresigned
        m="auto"
        height="100%"
        width="100%"
        objectFit="contain"
        src={imagePlaceholderSrc}
      />
    </>
  );
};

export default memo(ImageRaw);
