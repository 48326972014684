import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import store, { RootState } from "redux/store";
import { getDatabaseSize } from "utils/indexedDb";

interface ContextProps {
  isOnline: boolean;
  offlineEditedDataSize: number | undefined;
  recomputeDataSize: () => void;
}

export const OfflineContext = createContext<ContextProps>({
  isOnline: true,
  offlineEditedDataSize: undefined,
  recomputeDataSize: () => {},
});

export const useOffline = () => {
  return useContext(OfflineContext);
};

export const useRecomputeDataSize = (data: any[]) => {
  const { recomputeDataSize } = useOffline();

  useEffect(() => {
    recomputeDataSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, data);
};

export const OfflineProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [offlineEditedDataSize, setOfflineEditedDataSize] = useState<
    number | undefined
  >(undefined);
  const { isOnline } = useSelector((state: RootState) => state.app);

  const timeoutRef = useRef<any>();

  const recomputeDataSize = useCallback(() => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      getDatabaseSize().then((value) => {
        setOfflineEditedDataSize(value);
      });
    }, 200);
  }, []);

  useEffect(() => {
    const unSubscribe = store.subscribe(() => {
      recomputeDataSize();
    });
    recomputeDataSize();

    return () => {
      unSubscribe();
    };
  }, [isOnline, recomputeDataSize]);

  return (
    <OfflineContext.Provider
      value={{ offlineEditedDataSize, recomputeDataSize, isOnline }}
    >
      {children}
    </OfflineContext.Provider>
  );
};
