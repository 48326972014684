export const STATIC_FILES_CACHE = "neptune-static-files";
export const BUNDLE_FILES_CACHE = "neptune-bundle-files";
export const API_GET_CACHE = "neptune-api-cache";
export const SERVER_ORIGIN = (process.env.REACT_APP_API_HOST_URL || "").slice(
  0,
  (process.env.REACT_APP_API_HOST_URL || "").lastIndexOf("/new-api")
);

export const API_HOST_PREFIX = (process.env.REACT_APP_API_HOST_URL || "")
  .split("/")
  .pop();

export const API_SKIP_TO_CACHE = ["https://api-js.mixpanel.com/track"];

export const POST_API_TO_CACHE = [
  "/refreshToken",
  "/refresh-token",
  "https://api-js.mixpanel.com/track",
  "cdn.tiny.cloud",
];

export const S3_URL_NEED_CACHED = [
  "/s3-presigned-url",
  "/get-s3-file-size",
  "/check-s3-file-exist",
  "/object-metadata",
];

export const PROJECT_DATA_CACHE_PROGRESS_STORE =
  "project-data-cache-progress-store";
export const NETWORK_STORE = "network-store";
export const NETWORK_STORE_KEY = "status";
export const FILE_STORE = "file-store";
export const DATA_STORE = "data-store";
export const PROJECT_CACHE_INFO = "project-cache-info";

export enum StoreName {
  "TASK_TYPES" = "task-types",
  "DOCUMENT_CATEGORIES" = "document-categories",
  "BLACKBOARDS" = "blackboards",
  "SUB_ITEMS" = "sub-items",
  "DOCUMENT_ITEMS" = "document-items",
  "TASKS" = "tasks",
  "TASK_COMMENTS" = "task-comments",
  "DATA_LOGS" = "data-logs",
  "DOCUMENTS" = "documents",
  "DOCUMENT_KEYNOTES" = "document-keynotes",
  "DOCUMENT_TASKS" = "document-tasks",
  "USER_SETTINGS" = "user-settings",
  "DOCUMENT_GROUP" = "document-groups",
  "BIM_FILES" = "bim-files",
  "DOCUMENT_TEMPLATES" = "document-templates",
  "FAMILIES" = "families",
  "TASK_SHEET_TEMPLATE" = "task-sheet-templates",
  "INSPECTION_CONTENT" = "inspection-content",
  "INSPECTION_ITEM" = "inspection-item",
  "INSPECTION_ITEM_RESULT" = "inspection-item-result",
  "INSPECTION_TYPE" = "inspection-type",
  "INSPECTION_PIN" = "inspection-pin",
  "INSPECTION_GROUP" = "inspection-group",
  "INSPECTION_PIN_CONTENT" = "inspection-pin-content",
  "INSPECTION_PIN_GROUP" = "inspection-pin-group",
}

export const AVAILABLE_STORES = [
  StoreName.TASK_TYPES,
  StoreName.DOCUMENT_CATEGORIES,
  StoreName.BLACKBOARDS,
  StoreName.DOCUMENT_ITEMS,
  StoreName.SUB_ITEMS,
  StoreName.TASKS,
  StoreName.TASK_COMMENTS,
  StoreName.DATA_LOGS,
  StoreName.DOCUMENTS,
  StoreName.DOCUMENT_KEYNOTES,
  StoreName.DOCUMENT_TASKS,
  StoreName.USER_SETTINGS,
  StoreName.DOCUMENT_GROUP,
  StoreName.BIM_FILES,
  StoreName.DOCUMENT_TEMPLATES,
  StoreName.FAMILIES,
];
export const DOCUMENT_CATEGORIES = "document-categories";

export enum Operation {
  Post, // POST
  Delete,
  Patch, // PUT
}

export enum UpdateToOnlineStatus {
  Fail,
  Success,
}

export enum PATH_API {
  INSPECTION_GROUP = "inspections/groups",
  INSPECTION_CONTENT = "inspections/contents",
  INSPECTION_ITEM = "inspections/items",
  INSPECTION_ITEM_RESULT = "inspections/items/results",
  INSPECTION_TYPE = "inspections/types",
  INSPECTION_PIN = "inspections/pins",
  INSPECTION_PIN_CONTENT = "inspections/pins/content",
  INSPECTION_PIN_GROUP = "inspections/pins/group",
  SUB_ITEMS = "document-items/sub-items",
  BIM_FILES = "bims/files",
  USER_SETTINGS = "users/settings",
}

export const LIST_PATH_API_DETAIL = [
  `/v1/${PATH_API.BIM_FILES}/data-generate`,
  `/v1/${PATH_API.BIM_FILES}/:id`,
  "/v1/documents/:id",
  "/v1/documents/formats/:id",
  "/v1/documents/page-layouts/:id",
  "/v1/documents/pin-layouts/:id",
  "/v1/inspections/groups/:id",
  "/v1/inspections/types/:id",
  `/v1/${PATH_API.INSPECTION_PIN}/:id`,
  "/v1/auth/current-user",
  "/v1/auth/aps-token",
  "/v1/auth/token",
  "/v1/blackboard-templates/:id",
  "/v1/task-types/count-items/",
  "/v1/document-tasks/:id",
  "/v1/document-templates/:id",
  "/v1/aps/projects/:id",
  "/v1/aps/projects/:id/bims/first",
  "/v1/aps/projects/:id/bims/version/:versionId/derivatives",
  "/v1/aps/projects/:id/bims/version/:versionId/aec",
  "/v1/projects/:id",
  "/v1/s3/forge-data",
  "/v1/tasks/:id",
  "/v1/task-sheet-templates/:id",
  "/v1/task-types/:id",
  "/v1/task-types/count-items",
  "/v1/users/profiles",
];

export const URL_NO_CACHE = [
  "/s3-initial-multipart-upload",
  "/s3-complete-multipart-upload",
  "/s3-presigned-url-multipart-upload",
  "/new-websocket/",
  // cause this api using stream
  "/inspections/instances/get-instance-props",
];
