export const AXIOS_TIMEOUT_CODE = "ECONNABORTED";
export const AXIOS_TIMEOUT_ERROR_MESSAGE = "AXIOS_TIMEOUT_ERROR_MESSAGE";
export const LIMIT_ITEM_PER_LOAD = 20;

export const API_APP_ERROR_CODE = Object.freeze({
  CANCEL: "CANCEL",
});

export const API_RESULT_CODE = {
  OK: "OK",
  ERROR: "ERROR",
};
export const MAX_TIME_WAIT = 1000 * 20;
