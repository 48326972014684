import { ApiResponse } from "interfaces/models/api";
import { PinComponent } from "interfaces/models/pinComponent";
import { axiosECS } from "services/baseAxios";

interface GetPinComponentReq {
  pinLayoutId: string;
}

const pathV2 = "/v1/documents/pin-components";

export const getPinComponentList = async (
  params: GetPinComponentReq
): Promise<ApiResponse<PinComponent[]>> => {
  return axiosECS.get(pathV2, {
    params,
  });
};

export const createPinComponentList = async (body: {
  pinLayoutId: string;
  components: PinComponent[];
}): Promise<ApiResponse> => {
  return axiosECS.post(pathV2, body);
};
