import { io, Socket } from "socket.io-client";

export class AppWebSocket {
  private static _instance: Socket;

  initialize(token: string) {
    const socket = io(process.env.REACT_APP_COMMENT_WEB_SOCKET!, {
      path: "/new-websocket/",
      auth: { token },
      withCredentials: true,
      autoConnect: false,
      reconnection: true,
      reconnectionDelay: 5000,
    });
    AppWebSocket._instance = socket;
  }

  static get instance(): Socket {
    if (!AppWebSocket._instance) {
      throw new Error("Cannot access Websocket before initialize");
    }

    return AppWebSocket._instance;
  }
}
