import {
  Box,
  Button,
  Flex,
  forwardRef,
  IconButton,
  Img,
  PlacementWithLogical,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
  Portal,
  Text,
  Textarea,
  useBoolean,
  useDisclosure,
} from "@chakra-ui/react";
import { taskTypeApi } from "apiClient/v2";
import { DEFAULT_OFFSET_HEIGHT_POPOVER } from "constants/document";
import { defaultImagePath, TypeOfFile } from "constants/file";
import { S3_PATH } from "constants/s3";
import { MessageType } from "constants/websocket";
import { TaskType } from "interfaces/models/taskType";
import { WSMessage } from "interfaces/models/websocket";
import React, { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setTaskType } from "redux/taskSlice";
import { fileToBase64, uploadFileToS3 } from "utils/file";
import { logDev } from "utils/logs";
import { checkDiffTwoObject } from "utils/object";
import { IconBase } from "./base";
import FileUpload from "./FileUpload";
import FormUploadFile from "./FormUploadFile";
import { withPresignedUrl } from "./HOC/presignedUrl";
import { PCTooltip } from "./PCTooltip";
import { SvgIcon } from "./SvgIcon";

const ImgPresigned = withPresignedUrl(Img);
interface Props extends PopoverProps {
  portalRef?: React.MutableRefObject<HTMLElement | null>;
  children?: ReactNode;
  taskType?: TaskType;
  isAddNew?: boolean;
  placement?: PlacementWithLogical;
  offsetHeight?: number;
  isOnline: boolean;

  handleOnSelectTask: (
    taskType: TaskType,
    isCloseModal: boolean
  ) => Promise<void>;
  onSaveAddNew?: (params: { taskType: TaskType }) => void;
  sendWebSocketMessage?: (message: WSMessage) => void;
}

enum HoverType {
  Bad = "Bad",
  Good = "Good",
}

const HoverBadGood = forwardRef<Props, "button">(
  (
    {
      portalRef,
      taskType,
      children,
      isAddNew,
      placement = "left",
      offsetHeight = DEFAULT_OFFSET_HEIGHT_POPOVER,
      isOnline = true,
      handleOnSelectTask,
      onSaveAddNew,
      sendWebSocketMessage,
      ...rest
    },
    ref
  ) => {
    console.log(taskType);

    const taskTypeUpdateRef = useRef<TaskType | undefined>(undefined);
    const [height, setHeight] = useState(0);
    const dispatch = useDispatch();

    const onChangeModalRef = (e: HTMLElement | null) => {
      if (e) {
        setHeight(e.offsetHeight);
      }
    };

    const onSave = async (
      type: HoverType,
      value: EditInfo,
      keyRemove?: string
    ) => {
      if (isAddNew) {
        _onSaveAddNew(type, value);

        return;
      }

      if (!taskType) return;

      taskTypeUpdateRef.current = {
        ...taskType,
        ...taskTypeUpdateRef.current,
      };
      switch (type) {
        case HoverType.Bad:
          taskTypeUpdateRef.current = {
            ...taskTypeUpdateRef.current,
            badDescription: value.desc,
          };

          if (keyRemove) {
            taskTypeUpdateRef.current = {
              ...taskTypeUpdateRef.current,
              badImageUrl: "",
            };
          } else if (value.imgFile) {
            const badImageUrl = await uploadFileToS3(
              value.imgFile,
              value.imgFile.name || "",
              S3_PATH.Task
            );

            taskTypeUpdateRef.current = {
              ...taskTypeUpdateRef.current,
              badImageUrl,
            };
          }
          break;

        case HoverType.Good:
          taskTypeUpdateRef.current = {
            ...taskTypeUpdateRef.current,
            goodDescription: value.desc,
          };

          if (keyRemove) {
            taskTypeUpdateRef.current = {
              ...taskTypeUpdateRef.current,
              goodImageUrl: "",
            };
          } else if (value.imgFile) {
            const goodImageUrl = await uploadFileToS3(
              value.imgFile,
              value.imgFile.name || "",
              S3_PATH.Task
            );

            taskTypeUpdateRef.current = {
              ...taskTypeUpdateRef.current,
              goodImageUrl,
            };
          }
          break;
      }
      const isCreateTaskType = !taskTypeUpdateRef.current.id;
      const { dataChanges } = checkDiffTwoObject({
        object: taskTypeUpdateRef.current,
        base: taskType,
      });

      const { data: res } = isCreateTaskType
        ? await taskTypeApi.createTaskType(taskTypeUpdateRef.current)
        : await taskTypeApi.updateTaskType({
            id: taskTypeUpdateRef.current.id,
            ...dataChanges,
          } as TaskType);

      sendWebSocketMessage?.({
        type: isCreateTaskType
          ? MessageType.ADD_TASK_TYPE
          : MessageType.UPDATE_TASK_TYPE,
        data: res,
      });

      taskTypeUpdateRef.current.id = res.id;
      dispatch(setTaskType(taskTypeUpdateRef.current));

      if (isCreateTaskType) {
        handleOnSelectTask(taskTypeUpdateRef.current, false);
      }
    };

    const addNewReq = useRef({} as TaskType);

    const _onSaveAddNew = async (type: HoverType, value: EditInfo) => {
      switch (type) {
        case HoverType.Bad:
          addNewReq.current.badDescription = value.desc;
          addNewReq.current.badImageUrl = value.imgFile as unknown as string;
          break;
        case HoverType.Good:
          addNewReq.current.goodDescription = value.desc;
          addNewReq.current.goodImageUrl = value.imgFile as unknown as string;
          break;
      }
      onSaveAddNew?.({ taskType: addNewReq.current });
    };

    return (
      <Popover
        placement={placement}
        isLazy
        lazyBehavior="keepMounted"
        closeOnBlur={false}
        eventListeners={false}
        preventOverflow={false}
        offset={[offsetHeight, 17]}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={false}
        gutter={14}
        arrowSize={22}
        {...rest}
      >
        <PopoverTrigger>
          <Box w="100%">{children}</Box>
        </PopoverTrigger>
        <Portal>
          <PopoverContent
            border="none !important"
            boxShadow="rgba(149, 157, 165, 0.2) 0px 8px 24px"
            p="0.5rem 1rem 1rem"
            width="inherit"
            maxW="430px"
            maxH="400px"
            _focus={{}}
          >
            <Box
              style={{
                right: "var(--popper-arrow-offset)",
                width: "var(--popper-arrow-size, 8px)",
                height: "var(--popper-arrow-size, 8px)",
                zIndex: "1",
                position: "absolute",
                transform: `${
                  height
                    ? `translate3d(0px, calc((${
                        height - offsetHeight
                      }px)/2), 0px)`
                    : "translate3d(0px, 152.5px, 0px)"
                }`,
                top: "0",
              }}
            >
              <Box
                style={{
                  transform: "rotate(45deg)",
                  background: "var(--popper-arrow-bg)",
                  top: "-3rem",
                  left: "7px",
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  zIndex: "inherit",
                  boxShadow: "1px -1px 1px 0 var(--popper-arrow-shadow-color)",
                }}
              />
            </Box>
            <PopoverBody ref={onChangeModalRef}>
              <PCTooltip label={taskType?.title ?? ""} maxW="300px">
                <Text
                  fontWeight="bold"
                  lineHeight="1.6rem"
                  wordBreak="break-all"
                  w="fit-content"
                  className="limit-1-rows"
                >
                  {taskType?.title ?? ""}
                </Text>
              </PCTooltip>

              <Flex
                flexDir="column"
                mt="1.5rem"
                gap="1.5rem"
                {...(rest.isOpen ? { id: "good-bad-item-show" } : {})}
              >
                <Item
                  type={HoverType.Bad}
                  taskType={taskType}
                  isOnline={isOnline}
                  onSave={(value, keyRemove) =>
                    onSave(HoverType.Bad, value, keyRemove)
                  }
                  isAddNew={isAddNew}
                />
                <Item
                  type={HoverType.Good}
                  taskType={taskType}
                  isOnline={isOnline}
                  onSave={(value, keyRemove) =>
                    onSave(HoverType.Good, value, keyRemove)
                  }
                  isAddNew={isAddNew}
                />
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    );
  }
);

export default HoverBadGood;

interface EditInfo {
  desc?: string;
  imgFile?: File;
}

const Item = React.forwardRef<
  HTMLTextAreaElement,
  {
    type: HoverType;
    taskType?: TaskType;
    isOnline?: boolean;
    onSave: (
      value: { desc?: string; imgSrc?: string },
      keyRemove?: string
    ) => void;
    isAddNew?: boolean;
  }
>(({ isOnline = true, type, taskType, onSave, isAddNew }, ref) => {
  const description = useMemo(
    () =>
      type === HoverType.Bad
        ? taskType?.badDescription
        : taskType?.goodDescription,
    [type, taskType]
  );

  const imgSrc = useMemo(
    () =>
      type === HoverType.Bad ? taskType?.badImageUrl : taskType?.goodImageUrl,
    [type, taskType]
  );

  const [isModeEdit, setModeEdit] = useBoolean(isAddNew ?? false);
  const [isEditData, setIsEditData] = useState(false);
  const [desc, setDesc] = useState(description);
  const [imageSrc, setImageSrc] = useState(imgSrc);
  const imgFile = useRef<File>();

  const {
    isOpen: isOpenEditImage,
    onOpen: onOpenEditImage,
    onClose: onCloseEditImage,
  } = useDisclosure();

  useEffect(() => {
    setDesc(description);
  }, [description]);

  useEffect(() => {
    setImageSrc(imgSrc);
  }, [imgSrc]);

  const handleClickUploadFile = (e: React.MouseEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    target.value = "";
  };

  const handleChangeFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    !isEditData && setIsEditData(true);
    const file = ((e.target as any).files as FileList)[0] || {};
    imgFile.current = file;
    setImageSrc(await fileToBase64(file));
  };

  const handleRemove = () => {
    if (!isOnline) {
      return;
    }

    !isEditData && setIsEditData(true);
    imgFile.current = undefined;
    setImageSrc(undefined);
  };

  const handleSave = async () => {
    setModeEdit.toggle();
    if (isModeEdit) {
      if (!isEditData) return;

      const v: EditInfo = {
        desc: desc,
        imgFile: imgFile.current,
      };
      logDev(`onSave ${type}`, v);

      if (!imageSrc) {
        const key = imgSrc?.slice(imgSrc?.lastIndexOf("task"));
        onSave(v, key);
      } else {
        onSave(v);
      }

      setIsEditData(false);
    }
  };

  return (
    <Flex>
      <Flex
        flexDir="column"
        position="relative"
        w="19rem"
        h="12rem"
        bgColor="#00000020"
        onMouseEnter={onOpenEditImage}
        onMouseLeave={onCloseEditImage}
      >
        <Text
          position="absolute"
          bg={type == HoverType.Bad ? "#E85959" : "#6DB46D"}
          lineHeight="2.5rem"
          color="#FFFFFF"
          textAlign="center"
          w="6rem"
          left={0}
          top={0}
        >
          {type}
        </Text>

        <ImgPresigned
          src={imageSrc}
          fallbackSrc={defaultImagePath}
          h="100%"
          objectFit="cover"
        />

        <Flex
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          alignItems="center"
          justifyContent="center"
          backgroundColor={isOpenEditImage ? "blackAlpha.600" : "unset"}
          display={
            isModeEdit && (!imageSrc || isOpenEditImage) ? "flex" : "none"
          }
        >
          <FormUploadFile
            onClick={handleClickUploadFile}
            onChange={handleChangeFile}
            type={TypeOfFile.IMAGE}
          >
            <FileUpload width="auto" accept="image/*" mr="1rem">
              <Button leftIcon={<SvgIcon src="/img/icon-image-white.svg" />}>
                選択
              </Button>
            </FileUpload>
          </FormUploadFile>

          {isOpenEditImage && (
            <Flex
              position="absolute"
              right="1rem"
              bottom="1rem"
              display="flex"
              alignItems="center"
              justifyContent="center"
              h="2.4rem"
              w="2.4rem"
              bg="white"
              borderRadius="50%"
            >
              <IconButton
                aria-label="delete"
                paddingInline="0.4rem"
                isDisabled={!isOnline}
                icon={<IconBase icon="/img/icon-delete.svg" />}
                onClick={handleRemove}
                variant="dangerText"
                boxShadow="none !important"
              />
            </Flex>
          )}
        </Flex>
      </Flex>
      <Flex ml="1.5rem" flexDir="column" w="19rem" gap="0.5rem">
        {isModeEdit ? (
          <Textarea
            ref={ref}
            h="7.5rem"
            resize="none"
            fontSize="1.2rem"
            lineHeight="1.6rem"
            value={desc ?? ""}
            placeholder="テキストを入力"
            onChange={(ev) => {
              !isEditData && setIsEditData(true);
              setDesc(ev.currentTarget.value);
            }}
          />
        ) : (
          <PCTooltip label={desc} maxW="300px">
            <Text
              fontSize="1.2rem"
              lineHeight="1.8rem"
              w="fit-content"
              className="text limit-4-rows"
            >
              {desc}
            </Text>
          </PCTooltip>
        )}
        <Button
          variant={isModeEdit ? "filled" : "outline"}
          ml="auto"
          mt="auto"
          onClick={handleSave}
        >
          {isModeEdit ? "完了" : "編集"}
        </Button>
      </Flex>
    </Flex>
  );
});
