import { memo } from "react";

import { routePath } from "routes/path";
import BreadCrumb from "components/BreadCrumb";
import { RootState } from "redux/store";
import { useSelector } from "react-redux";
import { generatePath } from "react-router-dom";

export interface BreadCrumbProps {}

const BreadCrumbBimFile = (props: BreadCrumbProps) => {
  const { projectDetail, dataProjectDetail } = useSelector(
    (state: RootState) => state.project
  );

  return (
    <BreadCrumb
      isWrapText={true}
      paths={[
        {
          name: "プロジェクト一覧",
          link: routePath.Home,
          styleProps: {
            minW: { base: "25%", lg: "20%", xl: "auto" },
          },
        },
        {
          name: projectDetail?.name,
          link: `${routePath.Home}#project-${projectDetail?.id}`,
          styleProps: {
            minW: { base: "30%", xl: "auto" },
          },
        },
        {
          link: generatePath(routePath.InspectionList, {
            bimId: dataProjectDetail?.id || "",
            projectId: projectDetail?.id || "",
          }),
          name: dataProjectDetail?.name || "",
        },
      ]}
      breadcrumbLinkProps={{
        _hover: { textDecoration: "none", cursor: "default" },
        color: "black",
      }}
    />
  );
};

export default memo(BreadCrumbBimFile);
