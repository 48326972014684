import { iKeyLabel } from "components/ui/KeyLabel";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { DocumentTask } from "interfaces/models/documentTask";
import {
  getTaskLabelOfDocumentTask,
  transformKeyLabelData,
  transformKeylabelsByEdited,
} from "models/documentTask";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import cloneDeep from "lodash/cloneDeep";

interface iProps {
  tasks: TaskDTO[] | null;
  documentTask: DocumentTask;
  loading: boolean;
  editMode?: boolean;
}

const useKeyPlan = ({ tasks, documentTask, loading, editMode }: iProps) => {
  const [isChangeRatio, setIsChangeRatio] = useState(false);
  const [isLoadKeyplanImage, setIsLoadKeyplanImage] = useState(true);
  const [isResetDataKeyplan, setIsResetDataKeylan] = useState(false);
  const [keyLabelsEdited, setKeylabelsEdited] = useState<Partial<iKeyLabel>[]>(
    []
  );
  const [keyplanImage, setKeyPlanImage] = useState("");
  const [keyplanImageWidth, setKeyplanImageWidth] = useState(0);
  const [keyplanImageHeight, setKeyplanImageHeight] = useState(0);

  const { projectDetail } = useSelector((state: RootState) => state.project);
  const { nextPreviewPage } = useSelector((state: RootState) => state.document);

  const keyplanContainerRef = useRef<HTMLDivElement>(null);

  const keyplanImageSize = useMemo(
    () => ({ width: keyplanImageWidth, height: keyplanImageHeight }),
    [keyplanImageWidth, keyplanImageHeight]
  );

  const keyLabelsProps = useMemo((): iKeyLabel[] | null => {
    if (!isLoadKeyplanImage && !keyplanImage) {
      return [];
    }

    if (
      !documentTask?.mainImageData ||
      !keyplanContainerRef?.current ||
      isLoadKeyplanImage ||
      !tasks ||
      !projectDetail?.id ||
      !keyplanImageWidth ||
      !keyplanImageHeight
    ) {
      return [];
    }

    const taskLabelOfDocumentTask = getTaskLabelOfDocumentTask(documentTask);
    const taskLabelByGuid = taskLabelOfDocumentTask?.taskLabelByGuid;
    const mainImageData = taskLabelOfDocumentTask?.mainImageData;

    if (!taskLabelByGuid || !mainImageData) {
      return [];
    }

    const currentImageWidth = taskLabelByGuid?.imageWidth;
    const currentImageHeight = taskLabelByGuid?.imageHeight;
    const keyLabelsData = taskLabelByGuid?.keyLabels || [];
    const keylabelsEdited = taskLabelByGuid?.keyLabelsEdited || [];

    const scaleX = keyplanImageWidth / currentImageWidth;
    const scaleY = keyplanImageHeight / currentImageHeight;

    return keyLabelsData?.map((keylabel) => {
      const keylabelEdited =
        keylabelsEdited.find((item) => item.id === keylabel.id) || {};

      return transformKeyLabelData({
        keylabel: { ...keylabel, ...keylabelEdited },
        scaleX,
        scaleY,
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    keyplanImage,
    tasks,
    projectDetail?.id,
    documentTask,
    isLoadKeyplanImage,
    keyplanImageWidth,
    keyplanImageHeight,
    nextPreviewPage,
  ]);

  useEffect(() => {
    if (!editMode) {
      setKeylabelsEdited([]);
    }
  }, [editMode]);

  useEffect(() => {
    if (!tasks || isLoadKeyplanImage || !keyplanContainerRef.current) {
      return;
    }

    const image = keyplanContainerRef.current!;
    const observer = new ResizeObserver(() => {
      setKeyplanImageWidth(image.clientWidth);
      setKeyplanImageHeight(image.clientHeight);
    });
    if (image) {
      observer.observe(image);
    }

    return () => {
      if (image) {
        observer.unobserve(image);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    documentTask,
    isLoadKeyplanImage,
    keyplanContainerRef.current,
    loading,
    tasks,
  ]);

  useEffect(() => {
    (async () => {
      if (!documentTask?.id) {
        return;
      }

      const taskLabelOfDocumentTask = getTaskLabelOfDocumentTask(documentTask);
      const taskLabelByGuid = taskLabelOfDocumentTask?.taskLabelByGuid;
      const image = taskLabelByGuid?.imageUrl as any;

      setKeyPlanImage(image);
      if (!image) {
        setIsLoadKeyplanImage(false);
      }
    })();
  }, [documentTask]);

  const onLoadKeyplanImage = useCallback(() => {
    setIsLoadKeyplanImage(false);
  }, []);

  const callbackDragKeynoteStop = useCallback(
    (keylabel: Partial<iKeyLabel>) => {
      setKeylabelsEdited((prev) => {
        const res = structuredClone(prev);
        const index = res.findIndex((item) => item.id === keylabel?.id);
        if (index !== -1) {
          res[index] = keylabel;
        } else {
          res.push(keylabel);
        }

        return res;
      });
    },
    []
  );

  const handleTransformKeylabelEdited = useCallback(() => {
    const taskLabelOfDocumentTask = getTaskLabelOfDocumentTask(documentTask);
    const taskLabelByGuid = cloneDeep(taskLabelOfDocumentTask?.taskLabelByGuid);
    const mainImageData = cloneDeep(taskLabelOfDocumentTask?.mainImageData);
    if (!mainImageData || !taskLabelByGuid) {
      return;
    }

    const currentGuid = mainImageData.guid;

    if (!taskLabelByGuid || !mainImageData) {
      return;
    }

    const keyLabels = taskLabelByGuid?.keyLabels || [];

    const { cloneKeyLabels, cloneKeyLabelsEdited } = transformKeylabelsByEdited(
      {
        keyLabels,
        keyLabelsEdited,
        currentKeyLabelsEdited: taskLabelByGuid?.keyLabelsEdited || [],
      }
    );

    taskLabelByGuid.keyLabelsEdited = cloneKeyLabelsEdited;
    taskLabelByGuid.keyLabels = cloneKeyLabels;
    mainImageData.mapTaskLabelsByGuid = {
      ...mainImageData.mapTaskLabelsByGuid,
      [currentGuid]: taskLabelByGuid,
    };

    return mainImageData;
  }, [documentTask, keyLabelsEdited]);

  return {
    keyplanImageSize,
    isChangeRatio,
    keyLabelsEdited,
    isResetDataKeyplan,
    isLoadKeyplanImage,
    keyplanImageWidth,
    keyplanImageHeight,
    keyplanContainerRef,
    keyplanImage,
    onLoadKeyplanImage,
    setKeyPlanImage,
    setIsLoadKeyplanImage,
    keyLabelsProps,
    callbackDragKeynoteStop,
    setIsResetDataKeylan,
    handleTransformKeylabelEdited,
    setIsChangeRatio,
  };
};

export default useKeyPlan;
