import { Attribute, Node } from "@tiptap/core";
import { NodeType } from "../type";

export interface PageWrapperNodeAttrs {}

export const PageWrapperNode = Node.create({
  name: NodeType.PAGE_WRAPPER,
  group: "block",
  content: `(${NodeType.PAGE_CONTAINER})*`,
  selectable: false,
  // fence the cursor for regular editing operations
  // [https://tiptap.dev/docs/editor/core-concepts/schema#isolating]
  isolating: true,

  addAttributes() {
    const attrs: { [key in keyof PageWrapperNodeAttrs]: Attribute } = {};

    return attrs;
  },

  parseHTML() {
    return [
      {
        tag: "div",
      },
    ];
  },

  renderHTML() {
    const style =
      "display: flex; align-items: center; flex-direction: column; gap: 1rem";

    return ["div", { style }, 0];
  },
});
