import { Icon, IconProps } from "@chakra-ui/icons";

const MinusCircleIcon = (props: IconProps) => {
  return (
    <Icon width="1em" height="1em" viewBox="0 0 22 22" fill="none" {...props}>
      <path
        d="M14.666 11.6878C14.8608 11.6878 15.0241 11.6219 15.1557 11.4901C15.2876 11.3582 15.3535 11.1949 15.3535 11.0001C15.3535 10.8052 15.2876 10.6419 15.1557 10.5104C15.0241 10.3787 14.8608 10.3128 14.666 10.3128C10.3613 10.3128 12.055 10.3128 7.33268 10.3128C7.13789 10.3128 6.97465 10.3788 6.84295 10.5106C6.71111 10.6424 6.64518 10.8058 6.64518 11.0006C6.64518 11.1955 6.71111 11.3587 6.84295 11.4903C6.97465 11.622 7.13789 11.6878 7.33268 11.6878C12.0551 11.6878 10.3612 11.6878 14.666 11.6878ZM11.001 19.7087C9.79645 19.7087 8.66429 19.4801 7.60447 19.023C6.54465 18.5659 5.62279 17.9455 4.83889 17.1619C4.05499 16.3783 3.43433 15.4569 2.97691 14.3975C2.51965 13.3381 2.29102 12.2063 2.29102 11.0019C2.29102 9.79743 2.51957 8.66527 2.97668 7.60545C3.43379 6.54563 4.05415 5.62377 4.83775 4.83987C5.62134 4.05596 6.54282 3.4353 7.60218 2.97789C8.66154 2.52062 9.7934 2.29199 10.9977 2.29199C12.2022 2.29199 13.3344 2.52055 14.3942 2.97766C15.454 3.43477 16.3759 4.05512 17.1598 4.83872C17.9437 5.62232 18.5644 6.5438 19.0218 7.60316C19.4791 8.66252 19.7077 9.79437 19.7077 10.9987C19.7077 12.2032 19.4791 13.3354 19.022 14.3952C18.5649 15.455 17.9446 16.3769 17.161 17.1608C16.3774 17.9447 15.4559 18.5653 14.3965 19.0228C13.3372 19.48 12.2053 19.7087 11.001 19.7087ZM10.9993 18.3337C13.0466 18.3337 14.7806 17.6232 16.2014 16.2024C17.6223 14.7816 18.3327 13.0475 18.3327 11.0003C18.3327 8.9531 17.6223 7.21908 16.2014 5.79824C14.7806 4.37741 13.0466 3.66699 10.9993 3.66699C8.95213 3.66699 7.2181 4.37741 5.79727 5.79824C4.37643 7.21908 3.66602 8.9531 3.66602 11.0003C3.66602 13.0475 4.37643 14.7816 5.79727 16.2024C7.2181 17.6232 8.95213 18.3337 10.9993 18.3337Z"
        fill="currentcolor"
      />
    </Icon>
  );
};

export default MinusCircleIcon;
