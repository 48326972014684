import { ApiResponse } from "interfaces/models/api";
import { UserSetting } from "interfaces/models/user";
import { axiosECS } from "services/baseAxios";
import { cloneDeep } from "lodash";
import { PATH_API } from "constants/serviceWorker";

const path = `/v1/${PATH_API.USER_SETTINGS}`;

export const getUserSetting = async (
  shouldCache = false
): Promise<ApiResponse<UserSetting[]>> => {
  return axiosECS.get(path, {
    params: {
      shouldCache,
    },
  });
};

export const updateUserSetting = async (
  setting: UserSetting
): Promise<ApiResponse<UserSetting>> => {
  const bodyClone = cloneDeep(setting) as { [key: string]: any };
  const dateFields = [
    "taskDateFrom",
    "taskDateTo",
    "deadlineFrom",
    "deadlineTo",
    "documentEndDateScheduledFrom",
    "documentEndDateScheduledTo",
    "documentGroups",
  ];

  dateFields.forEach((e) => (bodyClone[e] = bodyClone[e] || null));
  for (const key in bodyClone) {
    if (bodyClone[key] === "") {
      bodyClone[key] = bodyClone[key] || null;
    }
  }

  return axiosECS.patch(path, bodyClone);
};
