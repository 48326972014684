import { bimFileApi, documentItemApi } from "apiClient/v2";

import { DISPLAY_MODE } from "constants/forge";
import { NeptuneArea, Space } from "interfaces/models/area";
import { DataProjectModel } from "interfaces/models/dataProjectModel";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { setDisplayMode, setLevelSelected } from "redux/forgeViewerSlice";
import { setDataProjectDetail } from "redux/projectSlice";
import { RootState } from "redux/store";
import { getNetworkStatus } from "utils/common";

import { getAreaExtension } from "utils/forge/extensions/area-extension";

import { getLocalStorage, setLocalStorage } from "utils/storage";
import useArea from "../useArea";
import useGenerateSpace from "./useGenerateSpace";
import useGenerateFamilyInstance from "./useGenerateFamilyInstance";
import useGenerateAllFamilyInstance from "./useGenerateAllFamilyInstance";
import useGenerateView from "./useGenerateView";
import { routePath } from "routes/path";
import { message } from "components/base";
import { useRoles } from "hooks/usePermission";
import { useQuery } from "hooks/useQuery";
import { DataGenerate, getDataGenerate } from "apiClient/v2/bims/bimFileApi";

interface Props {
  isSettingFloor: boolean;
}

export interface GenerateStatus {
  space: boolean;
  allInstance: boolean;
  instance: boolean;
  view: boolean;
}

export default function useGenerateData(props: Props) {
  //#region forge data
  const { isSettingFloor } = props;
  const query = useQuery();

  const {
    isGeneratingSpaces,
    isGeneratingFamilyInstances,
    isGeneratingAllFamilyInstance,
    isGeneratingView,
    levels,
    isInitialized,
  } = useSelector((state: RootState) => state.forgeViewer);
  const navigate = useNavigate();
  const { isTakasagoGroup } = useRoles();
  const { currentUser } = useSelector((state: RootState) => state.user);

  const dispatch = useDispatch();
  const { bimFileId, version } = useParams();
  const { dataProjectDetail } = useSelector(
    (state: RootState) => state.project
  );
  const [isCheckingGenerate, setCheckingGenerate] = useState(
    getNetworkStatus()
  );
  const [timeCheckGenerate, setTimeCheckGenerate] = useState(Date.now());
  const { areas, spaces } = useArea({});
  const areasRef = useRef<NeptuneArea[]>([]);
  const spacesRef = useRef<Space[]>([]);
  areasRef.current = areas;
  spacesRef.current = spaces;
  const [dataGenerated, setDataGenerated] = useState<DataGenerate>();

  useEffect(() => {
    if (!dataProjectDetail?.id) {
      return;
    }
    (async () => {
      const { id, version, projectId } = dataProjectDetail;
      const urn = `${id}?version=${version}`;
      const versionId = encodeURIComponent(urn);
      const response = await getDataGenerate(projectId, versionId);
      setDataGenerated(response?.data);
    })();
  }, [dataProjectDetail?.id, dataProjectDetail?.version]);

  // SPACE -> FAMILY -> ALL FAMILY INSTANCE

  useLayoutEffect(() => {
    // if is offline -> not checking generate data
    if (dataProjectDetail?.id && !isSettingFloor && isInitialized) {
      setCheckingGenerate(getNetworkStatus());
    }
  }, [dataProjectDetail?.id, isSettingFloor, isInitialized]);

  const generatingStatusRef = useRef<GenerateStatus>({
    space: false,
    allInstance: false,
    instance: false,
    view: false,
  });
  const updateReduxDataProject = useCallback(
    (isGenerated: boolean) => {
      dispatch(
        setDataProjectDetail({
          ...dataProjectDetail,
          isGenerated,
          defaultBimPathId: dataProjectDetail?.defaultBimPathId,
        } as DataProjectModel)
      );
    },
    [dataProjectDetail, dispatch]
  );

  const { isSpaceDataAllLevel } = useGenerateSpace({
    generatingStatusRef,
    setTimeCheckGenerate,
  });
  const { lastFetchFamilyArgs } = useGenerateFamilyInstance({
    generatingStatusRef,
    isSettingFloor,
    isSpaceDataAllLevel,
    setTimeCheckGenerate,
  });
  useGenerateAllFamilyInstance({
    generatingStatusRef,
    isSettingFloor,
    dataGenerated,
    setTimeCheckGenerate,
  });
  const { totalViewGenerated, totalView } = useGenerateView({
    generatingStatusRef,
    isSettingFloor,
    dataGenerated,
    setTimeCheckGenerate,
  });

  const onAfterGenerating = async () => {
    if (isTakasagoGroup) {
      await Promise.all([
        documentItemApi.updateItemArea({
          bimFileId: bimFileId!,
          version: version!,
        }),
        bimFileApi.updateDataGenerate({
          id: bimFileId,
          isGenerated: true,
        }),
      ]);
    }
    updateReduxDataProject(true);
    const defaultLevel = levels.filter((level) => level.guid)?.[0];
    lastFetchFamilyArgs.current.levelSelected = defaultLevel;
    dispatch(setLevelSelected(defaultLevel));
    dispatch(
      setDisplayMode(
        !!defaultLevel?.guid && defaultLevel?.sheets?.length
          ? DISPLAY_MODE["2D"]
          : DISPLAY_MODE["3D"]
      )
    );
    const currentLevel = getLocalStorage("currentLevel") || {};
    currentLevel[String(bimFileId)] = defaultLevel.guid;
    setLocalStorage("currentLevel", currentLevel);
    const areaExtension = getAreaExtension();
    // set areas by level selected
    areaExtension?.setAreasToDraw(areasRef.current);
    areaExtension?.setSpacesToDraw(spacesRef.current);
    message.success("データの生成に成功しました。");
    setCheckingGenerate(false);
    if (query["isGenerate"]) {
      navigate(
        generatePath(routePath.InspectionList, {
          bimId: dataProjectDetail?.id,
          projectId: dataProjectDetail?.projectId,
        })
      );
    }
  };

  const onBeforeGenerating = async () => {
    if (!dataProjectDetail?.isGenerated) {
      return;
    }
    const isOnline = getNetworkStatus();
    if (isTakasagoGroup && isOnline && dataProjectDetail?.isGenerated) {
      await bimFileApi.updateProject({
        id: bimFileId,
        isGenerated: false,
        defaultBimPathId: dataProjectDetail?.defaultBimPathId,
      });
    }
    updateReduxDataProject(false);
  };

  useEffect(() => {
    if (!currentUser) {
      return;
    }
    if (
      !isTakasagoGroup &&
      (isGeneratingAllFamilyInstance ||
        isGeneratingSpaces ||
        isGeneratingFamilyInstances ||
        isGeneratingView)
    ) {
      message.warning(
        "プロジェクトはまだデータを生成されていないので、管理者に連絡してください。"
      );

      return navigate(routePath.Home);
    }
    // make sure have some field generated false but data is generated success
    if (!dataProjectDetail?.id) {
      return;
    }
    if (
      generatingStatusRef.current.allInstance &&
      generatingStatusRef.current.instance &&
      generatingStatusRef.current.space &&
      generatingStatusRef.current.view
    ) {
      setCheckingGenerate(false);
      (async () => {
        if (!dataProjectDetail.isGenerated) {
          await bimFileApi.updateDataGenerate({
            id: bimFileId,
            isGenerated: true,
          });
          updateReduxDataProject(true);
        }
        // only redirect to list inspection list if access page forge viewer with params isGenerate
        if (query["isGenerate"]) {
          message.success("データの生成に成功しました。");
          navigate(
            generatePath(routePath.InspectionList, {
              bimId: dataProjectDetail?.id,
              projectId: dataProjectDetail?.projectId,
            })
          );
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isTakasagoGroup,
    !!currentUser,
    dataProjectDetail?.isGenerated,
    dataProjectDetail?.id,
    dataProjectDetail?.projectId,
    timeCheckGenerate,
  ]);

  useEffect(() => {
    if (
      isGeneratingAllFamilyInstance ||
      isGeneratingSpaces ||
      isGeneratingFamilyInstances ||
      isGeneratingView
    ) {
      onBeforeGenerating();

      return;
    }
    if (
      generatingStatusRef.current.allInstance &&
      generatingStatusRef.current.instance &&
      generatingStatusRef.current.space &&
      generatingStatusRef.current.view
    ) {
      onAfterGenerating();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isGeneratingAllFamilyInstance,
    isGeneratingFamilyInstances,
    isGeneratingSpaces,
    isGeneratingView,
  ]);

  return {
    totalViewGenerated,
    totalView,
    isCheckingGenerate,
    isGeneratingAllFamilyInstance,
    isGeneratingSpaces,
    isGeneratingFamilyInstances,
    isGeneratingView,
  };
}
