import { DocumentCategoryKey, DocumentItemKey } from "constants/enum";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { DocumentSubItem } from "interfaces/models/documentItem";
import { CreatePinList } from "interfaces/models/pin";
import { getNetworkStatus, uuid } from "./common";

enum TypeHandleInitData {
  TASK,
  SUB_ITEM,
  DOCUMENT_CATEGORY,
  DOCUMENT_ITEM,
}

const transformBodyInitData = <T>({
  data,
  type,
}: {
  data: T;
  type: TypeHandleInitData;
}) => {
  const initData = {} as any;
  let fieldsInitData = [] as any[];
  const dataDefaultByField: Record<string, any> = {};

  if (type === TypeHandleInitData.TASK) {
    fieldsInitData = [
      "tags",
      "userAssigned",
      "images",
      "confirmedImages",
      "attaches",
    ] as (keyof TaskDTO)[];
    fieldsInitData.forEach((field) => {
      dataDefaultByField[field] = [];
    });
  }

  if (type === TypeHandleInitData.DOCUMENT_ITEM) {
    fieldsInitData = [
      DocumentItemKey.TAGS,
      DocumentItemKey.ATTACHES,
      DocumentItemKey.DATA,
    ] as (keyof DocumentItemDTO)[];

    fieldsInitData.forEach((field) => {
      dataDefaultByField[field] = [];
      if (field === DocumentItemKey.DATA) {
        dataDefaultByField[field] = {};
      }
    });
  }

  if (type === TypeHandleInitData.DOCUMENT_CATEGORY) {
    fieldsInitData = [
      DocumentCategoryKey.TAGS,
      DocumentCategoryKey.USER_ASSIGNED,
      DocumentCategoryKey.DATA,
      DocumentCategoryKey.USER_APPROVED,
      DocumentCategoryKey.PARTNER_USERS,
      DocumentCategoryKey.SELECTED_EXTERNAL_IDS,
      DocumentCategoryKey.NEPTUNE_AREA_IDS,
      DocumentCategoryKey.ITEM_IDS,
    ] as (keyof DocumentCategoryDTO)[];

    fieldsInitData.forEach((field) => {
      dataDefaultByField[field] = [];
      if (field === DocumentCategoryKey.DATA) {
        dataDefaultByField[field] = {};
      }
    });
  }

  if (type === TypeHandleInitData.SUB_ITEM) {
    fieldsInitData = [
      "attaches",
      "takeImageDates",
      "data",
    ] as (keyof DocumentSubItem)[];

    fieldsInitData.forEach((field) => {
      dataDefaultByField[field] = [];
      if (field === "data") {
        dataDefaultByField[field] = {};
      }
    });
  }

  fieldsInitData.forEach((key) => {
    initData[key] = data?.[key as keyof T] || dataDefaultByField[key];
  });

  return initData;
};

export const transformBodyForCombineData = <T>(params: {
  body: T & { initData?: Partial<T> };
  bodyBefore: T | undefined;
  typeInitData: TypeHandleInitData;
}) => {
  const isOnline = getNetworkStatus();
  const body = structuredClone(params.body);
  if (!isOnline && !!params?.bodyBefore) {
    body.initData = transformBodyInitData({
      data: params.bodyBefore,
      type: params.typeInitData,
    });
  }

  return body;
};

export const transformBodyForCreatePinList = (args: CreatePinList) => {
  const isOnline = getNetworkStatus();
  if (isOnline) {
    return args;
  }

  const pins = args.pins.map(({ order, ...pin }) => {
    const id = uuid();

    return {
      ...pin,
      pinGroups: [{ pinGroupId: args.pinGroupId, order }],
      id,
      offlineId: id,
    };
  });

  return { ...args, pins };
};
