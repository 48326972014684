import { Box } from "@chakra-ui/react";
import { fillFakeContent } from "components/containers/InspectionFormat/Content/SettingFontSize/utils/style";
import { memo, useEffect, useRef } from "react";
import { FieldRawProps } from "../../types";

const InputNumberRaw = (props: FieldRawProps) => {
  const { containerRef, item, linkedData, style, ...boxProps } = props;
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!containerRef.current || !contentRef.current) {
      return;
    }

    const defaultText = "12346789";
    fillFakeContent({
      textEle: contentRef.current,
      parentElement: containerRef.current,
      defaultText,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [style?.fontSize]);

  return <Box ref={contentRef} {...boxProps} />;
};

export default memo(InputNumberRaw);
