import { Image, ImageProps } from "@chakra-ui/react";
import { withPresignedUrl } from "components/HOC/presignedUrl";
import SelectTooltip from "./SelectTooltip";

const ImagePresigned = withPresignedUrl(Image);

interface Props {
  label: string;
  src: string;
  isSkipPresigned?: boolean;
  onClick?: () => void;
}

const IconTooltip = ({
  label,
  src,
  isSkipPresigned = true,
  onClick,
  ...props
}: Props & ImageProps) => {
  return (
    <SelectTooltip textAlign="center" label={label}>
      <ImagePresigned
        src={src}
        alt=""
        h="1.8rem"
        w="1.8rem"
        cursor="pointer"
        onClick={onClick}
        isSkipPresigned={isSkipPresigned}
        {...props}
      />
    </SelectTooltip>
  );
};

export default IconTooltip;
